import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// Importing helper funcstions
import { gradientBgTextBox } from "../../helpers/themeColors";

// Importing contexts
import MainContext from "../../../components/helpers/context/mainContext";

// Importing texts
import TxtInter from "../../01_atoms/text/Inter";

// Importing components
import AccountBtn from "../../02_molecules/buttons/bigBtns/AccountBtn";
import ApproveBtn from "../../02_molecules/buttons/bigBtns/ApproveBtn";
import HomeBtn from "../../02_molecules/buttons/bigBtns/HomeBtn";
import SaveBtn from "../../02_molecules/buttons/bigBtns/SaveBtn";
import LogoutBtn from "../../02_molecules/buttons/bigBtns/LogoutBtn";
import LoginBtn from "../../02_molecules/buttons/bigBtns/LoginBtn";
import PreviewBtn from "../../02_molecules/buttons/bigBtns/PreviewBtn";
import EditBtn from "../../02_molecules/buttons/bigBtns/EditBtn";
import UserPanelBtn from "../../02_molecules/buttons/bigBtns/UserPanelBtn";

import style from "./style";

const UserPanel = (props) => {
  // Context
  const {
    breakpoint1,
    breakpoint2,
    breakpoint3,
    setUser,
    user,
    userStartup,
    // API related

    screenWidth
  } = useContext(MainContext);
  const navigate = useNavigate();

  // Navigate to main startup listing
  const navigateToLogin = () => {
    navigate("/login");
  };

  const navigateToCommunity = () => {
    const communityId = localStorage.getItem("@communityId");
    if (communityId) {
      navigate(`/community/${communityId}`);
    }
  };

  // Constants
  const largeCardWidth = 348;
  const green = { r: 53, g: 167, b: 43, a: 1 };

  // Variables
  const surfaceWidth = breakpoint3
    ? screenWidth - 12 * 2
    : breakpoint2
    ? screenWidth - 12 * 2
    : screenWidth - 36 * 2;
  const surfaceMaxWidth =
    props.fromHome && breakpoint1
      ? largeCardWidth * 2 + 24 + 12 * 2
      : props.fromHome && breakpoint2
      ? largeCardWidth * 3 + 24 * 2 + 12 * 2
      : props.fromHome
      ? largeCardWidth * 3 + 24 * 2 + 12 * 2
      : 1600 - 48 * 2 + 24; // 1600 - 48 on either side + 24 for overlapping slightly beyond the bottom section

  // scrollPosition
  const [scrollTop, setScrollTop] = useState(true);

  const handleScroll = () => {
    const position = window.pageYOffset; // Find the top offset of the window
    setScrollTop(position === 0); // Whenever user scrolls to the top, make scrollTop true
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true }); // listen to scroll event
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // useStates
  const [showMore, setShowMore] = useState(false);
  const [showProgress, setShowProgress] = useState(
    props.listingStatus === "to" || props.listingStatus === "do" || props.shouldApproveVisible
  );
  const [showReview, setShowReview] = useState(props.listingStatus === "re");

  // Logout function
  const logout = () => {
    return (
      localStorage.removeItem("@jwtauth"),
      localStorage.removeItem("@empId"),
      localStorage.removeItem("@orgid"),
      localStorage.removeItem("@user"),
      localStorage.removeItem("@communityId"),
      setTimeout(() => {
        navigateToLogin(), setUser(null);
      }, 2000)
    );
  };

  //for dynamic styling
  const style2 = {
    container: {
      position: breakpoint2 ? "fixed" : "absolute",
      zIndex: 10
    },
    outerDiv: {
      ...style.outerDiv,
      margin: breakpoint2 ? "12px 12px 36px" : `36px` // Change vertical margin for screens bigger than a phone
    },
    userPanelInnerDiv: {
      ...style.userPanelInnerDiv,
      justifyContent: showMore ? "flex-end" : "space-between",
      width: `${surfaceWidth}px`,
      maxWidth: `${surfaceMaxWidth}px`
    },
    progressBar: {
      ...style.progressBar,
      ...style.transition,
      ...gradientBgTextBox(green, "", 0.6, "N"),
      width: `${props.progress}%`
    }
  };

  return (
    <div style={style2.container}>
      <div style={style2.outerDiv}>
        {/* User panel surface */}
        <div style={style.userPanelOuterDiv}>
          <div style={style2.userPanelInnerDiv}>
            {/* Startup edit page */}
            {!showMore && props.fromStartupEdit && (
              <>
                <div style={{ ...style.flexRow, ...style.alignItemsCenter }}>
                  <SaveBtn
                    onClick={props.onClickSave}
                    breakpoint3={breakpoint3}
                    content={
                      props.listingStatus === "co"
                        ? "Save"
                        : props.progress === 100
                        ? "Submit"
                        : "Save"
                    }
                  />

                  {showProgress && (
                    <div style={{ ...style.icon18, ...style.marginLeft9 }}>
                      <TxtInter
                        size={17.28}
                        weight={300}
                        content={`${Math.round(props.progress)}% done`}
                        style={style.transition}
                      />
                    </div>
                  )}

                  {showReview && (
                    <div style={{ ...style.icon18, ...style.marginLeft9 }}>
                      <TxtInter
                        size={17.28}
                        weight={300}
                        content={"Under review..."}
                        style={style.transition}
                      />
                    </div>
                  )}
                </div>
              </>
            )}

            {/* Startup page */}
            {!showMore && props.fromStartup && (
              <div style={style.btnWrap}>
                <HomeBtn
                  onClick={() => {
                    // navigateToCommunity();
                    props.onClickHome();
                  }}
                  pressed={props.pressed}
                />

                {((showProgress && props.sameStartup) || props.shouldApproveVisible) && (
                  <div style={{ ...style.icon18, ...style.marginLeft9 }}>
                    <TxtInter
                      size={17.28}
                      weight={300}
                      content={`${Math.round(props.progress)}% done`}
                      style={style.transition}
                    />
                  </div>
                )}

                {showReview && props.sameStartup && (
                  <div style={{ ...style.icon18, ...style.marginLeft9 }}>
                    <TxtInter
                      size={17.28}
                      weight={300}
                      content={"Under review..."}
                      style={style.transition}
                    />
                  </div>
                )}
              </div>
            )}

            {/* Startup page */}
            {!showMore && !props.fromStartup && !props.fromStartupEdit && (
              <HomeBtn
                onClick={() => {
                  props.onClickHome();
                }}
                pressed={props.fromHome}
              />
            )}

            <div style={style.btnWrap}>
              {/* Edit button for startup page */}
              {((props.allowEdit &&
                !props.fromHome &&
                props.sameStartup &&
                !props.fromStartupEdit) ||
                (props.fromStartup && props.shouldApproveVisible)) && (
                <EditBtn onClick={props.onClickEdit} breakpoint3={breakpoint3} />
              )}

              {/* Visible only to Community manager */}
              {props.isAdmin && !props.eventEdit && (
                <EditBtn onClick={props.onClickEdit2} breakpoint3={breakpoint3} />
              )}

              {/* Approve A startup button (ONLY visible to the community manager) */}
              {props.fromStartup && props.shouldApproveVisible && props.progress === 100 && (
                <ApproveBtn onClick={props.onClickApprove} content={"Approve"} />
              )}

              {/* Your listing btn */}
              {(props.fromHome || (!props.sameStartup && props.fromStartup)) &&
                user &&
                userStartup &&
                !props.isAdmin && (
                  <PreviewBtn onClick={props.onClickPreview} breakpoint3={breakpoint3} />
                )}

              {/* Event buttons for event manager */}
              {/* Show this when edit event is on */}
              {props.isAdmin && props.eventEdit && props.fromEvent && (
                <SaveBtn
                  onClick={props.onClickEdit2}
                  breakpoint3={breakpoint3}
                  content={"Save"}
                  darkMode={props.darkMode}
                />
              )}

              {/* Show this when edit event is off */}
              {props.isAdmin && !props.eventEdit && props.shouldApproveVisible && (
                <EditBtn
                  onClick={props.onClickEdit2}
                  breakpoint3={breakpoint3}
                  darkMode={props.darkMode}
                />
              )}

              {/* Logout button */}
              {showMore && (
                <>
                  <AccountBtn
                    breakpoint3={breakpoint3}
                    onClick={props.onClickAccount}
                    pressed={props.pressed}
                  />
                  <LogoutBtn onClick={() => logout()} breakpoint3={breakpoint3} />
                </>
              )}

              {/* User panel button */}
              {user ? (
                <UserPanelBtn
                  onClick={() => setShowMore(!showMore)}
                  user={user}
                  userStartup={userStartup}
                  breakpoint3={breakpoint3}
                  pressed={showMore}
                />
              ) : (
                <LoginBtn
                  onClick={() => {
                    navigateToLogin();
                  }}
                  breakpoint3={breakpoint3}
                />
              )}
            </div>
          </div>

          {/* Progress bar */}
          {showProgress && (props.sameStartup || props.shouldApproveVisible) && !showMore && (
            <div style={style2.progressBar}></div>
          )}

          {/* Surface blur effect */}
          <div style={style.blurEffect}></div>
        </div>
      </div>
    </div>
  );
};

export default UserPanel;
