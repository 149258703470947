import React, { useContext } from "react";
import SrRefColour from "../../../ref/colours";
import MainContext from "../../../components/helpers/context/mainContext";
import { style } from "./style";

const SetupTemplate = ({ children }) => {
  // Main context
  const { breakpoint3, scrollBoxWidth } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    screen: {
      ...style.screen,
      justifyContent: breakpoint3 ? "none" : "center"
    },
    borderCard: {
      ...style.borderCard,
      width: `${scrollBoxWidth}px`,
      border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
      marginTop: breakpoint3 ? "24px" : 0
    }
  };

  return (
    // Background
    <div style={style2.screen}>
      {/* Bordered card */}
      <div style={style2.borderCard}>
        {/* Shram logo at the top */}
        <div style={style.shramLogo}>
          <img src={require("../../../media/logo.png")} height={50} alt={"Shram logo"} />
        </div>

        {/* Children */}
        {children}
      </div>
    </div>
  );
};

export default SetupTemplate;
