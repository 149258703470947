import { useContext } from "react";

// Importing Context
import MainContext from "../../helpers/context/mainContext";
import CommunityContext from "../../helpers/context/communityContext";

// Importing texts
import TxtInter from "../../01_atoms/text/Inter";

// Importing components
import AddNewBtn from "../../02_molecules/buttons/iconBtns/AddNewBtn";
import AttendeeCard from "../../03_compounds/AttendeeCard";

import style from "./style";

const PlanningView = ({ w, id }) => {
  // Importing States from CommunityContext
  const {
    guest,
    guestAdd,
    setGuestAdd,
    specialGuest,
    specialGuestAdd,
    setSpecialGuestAdd,
    startupAdd,
    setStartupAdd,
    pendingStartups,
    setFetchMore
  } = useContext(CommunityContext);

  // Importing from mainContext
  const { breakpoint1 } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      width: !breakpoint1 && `${w}px`,
      maxWidth: `${w}px`,
      margin: "0 auto"
    }
  };

  return (
    <div style={style2.outerDiv}>
      {/* guests  */}
      <div style={style.guestWrap}>
        <div style={style.guestHeadingWrap}>
          <div
            style={{
              width: "300px"
            }}
          >
            <TxtInter weight={600} size={28.8} content={"Delegates"} />
          </div>

          <div style={style.flexRow}>
            <div style={style.status}>
              <TxtInter weight={500} size={14.4} content={"Invite"} />
              <TxtInter weight={500} size={14.4} content={"sent"} />
            </div>

            <div style={style.status}>
              <TxtInter weight={500} size={14.4} content={"Setup"} />
              <TxtInter weight={500} size={14.4} content={"account"} />
            </div>
          </div>
        </div>

        <div style={style.attendeeWrap}>
          {/* Fetched data of startups  */}
          {guest?.map((e) => (
            <AttendeeCard
              type={"guest"}
              name={e.fname}
              mobile={e.phone_no}
              email={e.email_id}
              listingStatus={e.is_setup}
              setFetchMore={setFetchMore}
              // orgId={e.org.id}
              guestId={e.id}
              userType={e.user_type}
              startupAdd={startupAdd}
              setStartupAdd={setStartupAdd}
              eventId={id}
              adding={false}
            />
          ))}
        </div>
      </div>

      {/* guest add btn  */}
      {guestAdd ? (
        <div style={style.marginTop12}>
          <AttendeeCard
            type={"guest"}
            org={""}
            name={""}
            mobile={""}
            email={""}
            listingStatus={""}
            setFetchMore={setFetchMore}
            orgId={""}
            guestId={""}
            startupAdd={startupAdd}
            setStartupAdd={setStartupAdd}
            setGuestAdd={setGuestAdd}
            setSpecialGuestAdd={setSpecialGuestAdd}
            eventId={id}
            adding={true}
          />
        </div>
      ) : (
        <div style={{ margin: "12px 12px 0" }}>
          <AddNewBtn onClick={() => setGuestAdd(true)} text={"Delegate"} />
        </div>
      )}

      {/* special guestss  */}
      <div style={style.specialGuestOuterDiv}>
        <div style={style.specialGuestInnerDiv}>
          <div
            style={{
              width: "300px"
            }}
          >
            <TxtInter weight={600} size={28.8} content={"Jurors"} />
          </div>

          <div style={style.flexRow}>
            <div style={style.status}>
              <TxtInter weight={500} size={14.4} content={"Invite"} />
              <TxtInter weight={500} size={14.4} content={"sent"} />
            </div>

            <div style={style.status}>
              <TxtInter weight={500} size={14.4} content={"Setup"} />
              <TxtInter weight={500} size={14.4} content={"account"} />
            </div>
          </div>
        </div>

        <div style={style.specialGuestWrap}>
          {/* Fetched data of startups  */}
          {specialGuest?.map((e) => (
            <>
              <AttendeeCard
                type={"specialGuest"}
                name={e.fname}
                mobile={e.phone_no}
                email={e.email_id}
                listingStatus={e.is_setup}
                setFetchMore={setFetchMore}
                // orgId={e.org.id}
                userType={e.user_type}
                guestId={e.id}
                startupAdd={startupAdd}
                setStartupAdd={setStartupAdd}
                setSpecialGuestAdd={setSpecialGuestAdd}
                eventId={id}
                adding={false}
              />
            </>
          ))}
        </div>
      </div>

      {/*special guest add btn  */}
      {specialGuestAdd ? (
        <div style={style.marginTop12}>
          <AttendeeCard
            type={"specialGuest"}
            org={""}
            name={""}
            mobile={""}
            email={""}
            listingStatus={""}
            setFetchMore={setFetchMore}
            orgId={""}
            guestId={""}
            startupAdd={startupAdd}
            setStartupAdd={setStartupAdd}
            setSpecialGuestAdd={setSpecialGuestAdd}
            setGuestAdd={setGuestAdd}
            eventId={id}
            adding={true}
          />
        </div>
      ) : (
        <div style={{ margin: "12px 12px 0" }}>
          <AddNewBtn onClick={() => setSpecialGuestAdd(true)} text={"Juror"} />
        </div>
      )}

      {/* startups  */}
      <div style={style.startupOuterWrap}>
        <div style={style.startupInnerWrap}>
          <div
            style={{
              width: "300px"
            }}
          >
            <TxtInter weight={600} size={28.8} content={"Startups"} />
          </div>

          <div style={style.flexRow}>
            <div style={style.status}>
              <TxtInter weight={500} size={14.4} content={"Invite"} />
              <TxtInter weight={500} size={14.4} content={"sent"} />
            </div>

            <div style={style.status}>
              <TxtInter weight={500} size={14.4} content={"Setup"} />
              <TxtInter weight={500} size={14.4} content={"account"} />
            </div>

            <div style={style.status}>
              <TxtInter weight={500} size={14.4} content={"Listing"} />
              <TxtInter weight={500} size={14.4} content={"underway"} />
            </div>

            <div style={style.status}>
              <TxtInter weight={500} size={14.4} content={"Listing"} />
              <TxtInter weight={500} size={14.4} content={"complete"} />
            </div>

            <div style={style.status}>
              <TxtInter weight={500} size={14.4} content={"Listing"} />
              <TxtInter weight={500} size={14.4} content={"published"} />
            </div>
          </div>
        </div>

        <div style={style.startupWrap}>
          {/* Fetched data of startups  */}
          {pendingStartups?.map((e) => (
            <AttendeeCard
              type={"participant"}
              org={e.org?.name}
              name={e.userData ? e.userData[0]?.fname : ""}
              mobile={e.userData ? e.userData[0]?.phone_no : ""}
              email={e.userData ? e.userData[0]?.email_id : ""}
              listingStatus={e.org?.listing_status}
              // listingStatus={"re"}
              representer={e.userData?.filter((i) => i.representer === "Y")[0]}
              setFetchMore={setFetchMore}
              orgId={e.org?.id}
              userType={e.user_type}
              guestId={e.userData ? e.userData[0]?.id : ""}
              startupAdd={startupAdd}
              setStartupAdd={setStartupAdd}
              setSpecialGuestAdd={setSpecialGuestAdd}
              setGuestAdd={setGuestAdd}
              eventId={id}
              adding={false}
            />
          ))}
        </div>
      </div>

      {/* add new startups  */}
      {startupAdd ? (
        <div style={{ ...style.marginTop12, ...style.marginBot12 }}>
          <AttendeeCard
            type={"participant"}
            org={""}
            name={""}
            mobile={""}
            email={""}
            listingStatus={""}
            setFetchMore={setFetchMore}
            orgId={""}
            guestId={""}
            startupAdd={startupAdd}
            setStartupAdd={setStartupAdd}
            eventId={id}
            adding={true}
          />
        </div>
      ) : (
        <div style={{ margin: "12px" }}>
          <AddNewBtn onClick={() => setStartupAdd(true)} text={"Startup"} />
        </div>
      )}
    </div>
  );
};

export default PlanningView;
