const style = {
  marginLeft12: {
    marginLeft: "12px"
  },
  outerWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
};

export default style;
