import { useContext } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

// Importing Context
import MainContext from "../../helpers/context/mainContext";
import CommunityContext from "../../helpers/context/communityContext";

// Importing texts
import TxtInter from "../../01_atoms/text/Inter";

// Importing Components
import LargeCard from "../../02_molecules/cards/largeCard";
import EventChoiceBtn from "../../02_molecules/buttons/iconBtns/EventChoiceBtn";
import UserPanel from "../../03_compounds/userPanel";
import CommunityCover from "../../04_organisms/CommunityCover";
import PlanningView from "../../04_organisms/PlanningView";

import style from "./style";

const CommunitySubCard = ({
  // screenPadding,
  navigateToOwnStartup,
  darkMode,
  setShowReferral,
  setShowInvites,
  id,
  handleEventStatus,
  scrollRef,
  showInvites,
  navigateToStartup,
  w
}) => {
  const {
    showLogin,
    isAdmin,
    pendingOrg,
    startups,
    orgs,
    eventEdit,
    setEventEdit,
    setAccountEdit,
    communityDefaultValue,
    navigateToCommunity,
    showPreview,
    setShowPreview
  } = useContext(CommunityContext);

  const { breakpoint3, snackbar } = useContext(MainContext);

  const screenPadding = breakpoint3 ? 24 : 48;
  const nameLimit = 15;
  const descriptionLimit = 31;
  const largeCardWidth = 348 - 6;

  //Importing states from main context
  const {
    eventData,
    breakpoint1,
    // API related
    user
  } = useContext(MainContext);

  const communityInviteLink =
    "https://platform.shram.io/login?referral_code=" + eventData?.referral_code;

  // Dynamic styling for this component
  const style2 = {
    startupCardHolder: {
      ...style.startupCardHolder,
      width: !breakpoint1 && `${w}px`,
      maxWidth: `${w}px`
    },

    publishBtnInnerWrap: {
      ...style.publishBtnInnerWrap,
      maxWidth: `${w}px`
    },

    largeCardHolder: {
      ...style.flexWrap,
      width: !breakpoint1 && `${w}px`,
      maxWidth: `${w}px`,
      margin: "24px auto 0"
    },

    // Styling of component which is visible when there are no startups
    emptyStartupOuterDiv: {
      width: "calc(100% - 24px)",
      margin: "0 auto",
      minWidth: `${w}px`
    },
    lowerSection: {
      margin: eventData.status == "live" ? "48px auto 36px" : ""
    }
  };

  console.log(startups);
  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={"The Growth Platform your startup deserves"} />
        <meta property="og:title" content={"The Rise Summit | Shram"} />
        <meta property="og:image" content={`require("../../../media/logo512_green.png)`} />
        <meta property="og:url" content={"https://platform.shram.io/"} />
        <title>{eventData?.name ? eventData?.name : communityDefaultValue.name} | Shram</title>
        <link rel="canonical" href="https://platform.shram.io/" />
      </Helmet>

      <div style={style.screen}>
        {/* cover image */}
        <div style={style.coverImageWrap}>
          <img
            src={eventData?.cover ? eventData?.cover : communityDefaultValue.cover}
            style={style.coverImage}
          />
        </div>

        {/* User panel */}
        {!showLogin && (
          <UserPanel
            screenPadding={screenPadding}
            onClickHome={() => navigateToCommunity()}
            onClickApprove={() => {}}
            onClickAccount={() => setAccountEdit(true)}
            onClickPreview={() => navigateToOwnStartup(user.orgId)}
            eventEdit={eventEdit}
            onClickEdit2={() => setEventEdit(!eventEdit)}
            fromHome={true}
            fromEvent={true}
            darkMode={!darkMode}
            setShowReferral={setShowReferral}
            setShowInvites={setShowInvites}
            eventData={eventData}
            isAdmin={isAdmin}
            allowEdit={true}
          />
        )}

        {/* Event cover */}
        <CommunityCover
          w={w}
          snackbar={snackbar}
          handleEventStatus={handleEventStatus}
          communityInviteLink={communityInviteLink}
        />

        <>
          {/* Lower section */}
          <div style={style2.lowerSection} ref={scrollRef}>
            {/* Choice buttons */}
            {/* We can have the functionality of inviting startups after uncommenting it */}
            {isAdmin && eventData.status == "live" && (
              <div style={{ ...style.flexRow, ...style.gap12, margin: "0 18px" }}>
                <EventChoiceBtn
                  onClick={() => {
                    setShowInvites(true);
                    setShowPreview(false);
                  }}
                  text={"INVITE ATTENDEES"}
                  type={"invite"}
                  pressed={showInvites}
                />

                <EventChoiceBtn
                  onClick={() => {
                    setShowInvites(false);
                    setShowPreview(true);
                  }}
                  text={"PREVIEW LISTINGS"}
                  type={"preview"}
                  pressed={showPreview}
                />
              </div>
            )}

            {/* Show pendind startups */}
            {!showInvites && isAdmin && eventData.status == "live" && pendingOrg.length > 0 && (
              <>
                {/* Preview of completed listings */}
                <div>
                  <div style={{ margin: `${isAdmin ? 36 : 0}px 24px 0` }}>
                    <TxtInter
                      weight={600}
                      size={28.8}
                      content={isAdmin ? "Pending startups" : "Explore lovely startups"}
                    />
                  </div>

                  <div style={style2.startupCardHolder}>
                    {/* Large cards for startup */}
                    {pendingOrg
                      // .filter((s) => s.id !== startup.id)
                      .sort((a, b) => {
                        if (a.name < b.name) {
                          return -1;
                        } else {
                          return +1;
                        }
                      })
                      // .filter((e) => e.event_id === 16)
                      ?.map((startup) => (
                        <div style={style.margin12} key={startup.id}>
                          <LargeCard
                            startup={startup}
                            onClick={() => {
                              navigateToStartup(startup);
                            }}
                            largeCardWidth={largeCardWidth}
                            nameLimit={nameLimit}
                            descriptionLimit={descriptionLimit}
                            glowOpacity={0.1}
                            blur={1}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}

            {/* Event planning mode */}
            {showInvites ? (
              <PlanningView w={w} id={id} />
            ) : // Show only if there is at least one startup
            orgs.length > 0 && eventData.status == "live" ? (
              <>
                {/* Preview of completed listings */}
                <div>
                  <div style={{ margin: `${isAdmin ? 36 : 0}px 24px 0` }}>
                    <TxtInter
                      weight={600}
                      size={28.8}
                      content={isAdmin ? "Published startups" : "Explore lovely startups"}
                    />
                  </div>
                  <div style={style2.largeCardHolder}>
                    {/* Large cards for startup */}
                    {orgs
                      // .filter((s) => s.id !== startup.id)
                      .sort((a, b) => {
                        if (a.name < b.name) {
                          return -1;
                        } else {
                          return +1;
                        }
                      })
                      // .filter((e) => e.event_id === 16)
                      ?.map((startup) => (
                        <div style={style.margin12} key={startup.id}>
                          <LargeCard
                            startup={startup}
                            onClick={() => {
                              navigateToStartup(startup);
                            }}
                            largeCardWidth={largeCardWidth}
                            nameLimit={nameLimit}
                            descriptionLimit={descriptionLimit}
                            glowOpacity={0.1}
                            blur={1}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </>
            ) : (
              eventData.status == "live" && (
                <div style={style2.emptyStartupOuterDiv}>
                  <div style={style.emptyStartupInnerDiv}>
                    <TxtInter
                      weight={300}
                      size={17.28}
                      content={
                        "There aren't any startups that have completed their listings as of now."
                      }
                    />
                  </div>
                </div>
              )
            )}
          </div>
        </>
      </div>
    </HelmetProvider>
  );
};

export default CommunitySubCard;
