import { useContext } from "react";
import MainContext from "../../helpers/context/mainContext";
import TxtInter from "../../01_atoms/text/Inter";
import Avatar from "../../01_atoms/avatar/Avatar";
import style from "./style";

const EditComponent = ({ returnUnivitedCofounders }) => {
  const { startup, returnPrimary } = useContext(MainContext);

  // Dynamic style for this component
  const style2 = {
    avatarStyle: {
      ...style.teamSize,
      ...style.marginHor6,
      borderColor: returnPrimary
    }
  };

  return (
    <div style={style.editOuterContainer}>
      {startup.team
        .filter((m) => m.orgLead === "Y" && m.representer === "N")
        .map((m) => (
          // Other team leads pic
          <div style={style.marginHor6} key={m}>
            <Avatar
              width={42}
              pic={m.pic ? m.pic : require("../../../media/no_user.png")}
              borderColor={returnPrimary}
              key={m}
            />
          </div>
        ))}

      {/* Default avatars for other team leads */}
      {returnUnivitedCofounders().map((m) => (
        <div style={style.marginHor6} key={m}>
          <Avatar
            width={42}
            pic={require("../../../media/no_user.png")}
            borderColor={returnPrimary}
          />
        </div>
      ))}

      {/* Number of remaining team members in a circle */}
      {startup.teamSize -
        startup.team.filter((m) => m.orgLead === "Y" && m.representer === "N").length -
        1 && (
        <div style={style2.avatarStyle}>
          <TxtInter
            size={14.4}
            weight={500}
            content={`+${
              startup.teamSize -
              startup.team.filter((m) => m.orgLead === "Y" && m.representer === "N").length -
              1
            }`}
            style={{
              color: returnPrimary
            }}
          />
        </div>
      )}
    </div>
  );
};

const Component = () => {
  const { breakpoint3, startup, returnPrimary } = useContext(MainContext);

  // Dynamic style for this component
  const style2 = {
    outerWrap: {
      ...style.outerWrap,
      paddingLeft: breakpoint3 ? "18px" : "6px",
      paddingRight: breakpoint3 ? "18px" : "6px"
    },
    textWrap: {
      ...style.textWrap,
      borderColor: returnPrimary
    }
  };

  return (
    <div style={style2.outerWrap}>
      {startup.team
        .sort((a, b) => {
          if (a.fname < b.fname) {
            return -1;
          } else {
            return +1;
          }
        })
        .filter((m) => m.orgLead === "Y" && m.representer === "N")
        .map((m) => (
          // Other team leads pic
          <div style={style.marginHor6}>
            <Avatar
              width={42}
              pic={m.pic ? m.pic : require("../../../media/no_user.png")}
              borderColor={returnPrimary}
              key={m}
            />
          </div>
        ))}

      {/* Number of remaining team members in a circle */}
      {startup.teamSize -
        startup.team.filter((m) => m.orgLead === "Y" && m.representer === "N").length -
        1 >
        0 && (
        <div style={style2.textWrap}>
          <TxtInter
            size={14.4}
            weight={500}
            content={`+${
              startup.teamSize -
              startup.team.filter((m) => m.orgLead === "Y" && m.representer === "N").length -
              1
            }`}
            style={{ color: returnPrimary }}
          />
        </div>
      )}
    </div>
  );
};

const OtherTeamLeads = () => {
  // ifEdit state to check if user is on startup page or startupEdit page
  const { ifEdit, returnUnivitedCofounders } = useContext(MainContext);

  return ifEdit ? (
    <EditComponent returnUnivitedCofounders={returnUnivitedCofounders} />
  ) : (
    <Component />
  );
};

export default OtherTeamLeads;
