import React, { useState, useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import SrRefColour from "../../../ref/colours";
import TxtInter from "../../../components/01_atoms/text/Inter";
import TxtRubik from "../../../components/01_atoms/text/rubik";
import MainContext from "../../../components/helpers/context/mainContext";
import BackFAB from "../../../components/01_atoms/buttons/FABs/BackFAB";
import { SrCompIconArrowBack } from "../../../components/01_atoms/icons";
import SetupTemplate from "../../../components/01_atoms/setupTemplate";

import { style } from "./style";

const Choice = () => {
  // Main context
  const {
    scrollBoxWidth,
    // API related
    setupNew,
    setNew
  } = useContext(MainContext);
  const navigate = useNavigate();

  // Get the seacrh params from location
  const [searchParams] = useSearchParams();

  // If user doesn't exist, navigate to login
  useEffect(() => {
    !setupNew.newProfile === true && navigateToLogin();
  }, []);

  // Navigate to main startup listing
  const navigateToLogin = () => {
    navigate("/login");
  };

  // Navigate to main startup listing
  const navigateToUserType = () => {
    navigate("/userType");
  };

  // Navigate to UserProfile on clicking create
  const navigateToUserProfilefromCreate = () => {
    setNew({ ...setupNew, orgJoin: false });
    navigate("/userProfile");
  };

  // useEffect(() => {
  //   const code = searchParams.get("inviteCode");
  //   if (code) navigateToUserProfilefromJoin();
  //   else navigateToUserProfilefromCreate();
  // });

  // Navigate to UserProfile on clicking join
  const navigateToUserProfilefromJoin = () => {
    setNew({ ...setupNew, orgJoin: true });
    navigate("/userProfile");
  };

  const ChoiceBox = (props) => {
    const [btnHover, setBtnHover] = useState(false);

    return (
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={props.onClick}
        style={style.clickable}
      >
        <div
          style={{
            ...style.flexRow,
            ...style.choiceBox,
            borderColor: btnHover ? SrRefColour.Neutral2A : SrRefColour.Neutral2B,
            backgroundColor: btnHover ? SrRefColour.Neutral1D : SrRefColour.Neutral0,
            ...style.transition
          }}
        >
          {/* Left */}
          <div style={style.height80}>
            <img src={props.image} width={80} alt={"Choice"} />
          </div>

          {/* Right */}
          <div style={style.marginLeft12}>
            <TxtRubik
              size={21.6}
              weight={600}
              content={props.h1}
              state={"enabled"}
              style={style.colorBlack}
            />

            <div
              style={{
                ...style.flexRow,
                ...style.alignItemsCenter,
                ...style.spaceBetween,
                ...style.fitContent
              }}
            >
              {/* Text */}
              <TxtInter
                size={17.28}
                weight={400}
                content={props.h2}
                state={"enabled"}
                style={style.colorBlack}
              />

              {/* Arrow icon */}
              <div style={{ ...style.icon, ...style.marginLeft12 }}>
                <div style={style.invertIcon}>
                  <SrCompIconArrowBack size={24} />
                </div>
              </div>
            </div>

            <TxtInter
              size={14.4}
              weight={300}
              content={props.h3}
              state={"enabled"}
              style={style.marginTop6}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={style.outerMost}>
      <SetupTemplate>
        {/* Choice screen */}
        <div style={{ ...style.flexColumn, ...{ width: `${scrollBoxWidth}px` } }}>
          <div style={style.paddingHor24}>
            {/* Create org */}
            <ChoiceBox
              onClick={() => navigateToUserProfilefromCreate()}
              image={require("../../../media/setup_guide_create_org.png")}
              h1={"Create"}
              h2={"your Organization"}
              h3={"If you are new to Shram"}
            />

            {/* Join org */}
            <div style={style.marginTop24}>
              <ChoiceBox
                onClick={() => navigateToUserProfilefromJoin()}
                image={require("../../../media/setup_guide_join_org.png")}
                h1={"Join"}
                h2={"an Organization"}
                h3={"If you have an invite code"}
              />
            </div>
          </div>

          {/* Back button */}
          <BackFAB onClick={() => navigateToUserType()} />
        </div>
      </SetupTemplate>
    </div>
  );
};

export default Choice;
