import React from "react";
import TxtInter from "../01_atoms/text/Inter";

const white = [255, 255, 255];

// For industry chip
const returnRGBA = (r, g, b, a) => {
  return `rgba(${r},${g},${b},${a})`;
};

//colorChannelA and colorChannelB are ints ranging from 0 to 255
function colorChannelMixer(colorChannelA, colorChannelB, amountToMix) {
  var channelA = colorChannelA * amountToMix;
  var channelB = colorChannelB * (1 - amountToMix);
  return parseInt(channelA + channelB);
}

//rgbA and rgbB are arrays, amountToMix ranges from 0.0 to 1.0
//example (red): rgbA = [255,0,0]
function colorMixer(rgbA, rgbB, amountToMix) {
  var r = colorChannelMixer(rgbA[0], rgbB[0], amountToMix);
  var g = colorChannelMixer(rgbA[1], rgbB[1], amountToMix);
  var b = colorChannelMixer(rgbA[2], rgbB[2], amountToMix);
  return { r, g, b };
}

function concatColorMixer(rgbA, rgbB, amountToMix) {
  const { r, g, b } = colorMixer(rgbA, rgbB, amountToMix);
  return "rgb(" + r + "," + g + "," + b + ")";
}

// Get text color based on contrast ratio
const rgbToHex = ({ r, g, b }) => {
  return "#" + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1);
};

const getRGB = (c) => {
  return parseInt(c, 16) || c;
};

const getsRGB = (c) => {
  return getRGB(c) / 255 <= 0.03928
    ? getRGB(c) / 255 / 12.92
    : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
};

// Formula of luminance or bightness from https://webaim.org/articles/contrast/
const getLuminance = (hexColor) => {
  return (
    0.2126 * getsRGB(hexColor.substr(1, 2)) +
    0.7152 * getsRGB(hexColor.substr(3, 2)) +
    0.0722 * getsRGB(hexColor.substr(-2))
  );
};

// Contrast = (L1 + 0.05) / (L2 + 0.05),
// whereby: L1 is the relative luminance of the lighter of the colors, and
// L2 is the relative luminance of the darker of the colors
const getContrast = (f, b) => {
  const L1 = getLuminance(f);
  const L2 = getLuminance(b);
  return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
};

const getTextColor = (bgColor, invertBackground) => {
  const whiteContrast = getContrast(bgColor, "#ffffff");
  const blackContrast = getContrast(bgColor, "#000000");

  // If invertBackground is true, then just render black text
  return invertBackground === "Y"
    ? "#000000"
    : whiteContrast > blackContrast
    ? "#ffffff"
    : "#000000";
};

// Get text colour if two options for background colours exist
const gradientText = (color1, color2, invertBackground) => {
  return {
    color: getTextColor(
      color1
        ? rgbToHex(colorMixer([color1?.r, color1?.g, color1?.b], white, color1?.a))
        : rgbToHex(colorMixer([color2?.r, color2?.g, color2?.b], white, color2?.a)),
      invertBackground
    )
  };
};

// Get background colour gradient
const gradientBgTextBox = (color1, color2, opacity, invertBackground) => {
  return {
    // If invertBackground is true, then just render white background
    background:
      invertBackground === "Y"
        ? "#ffffff"
        : color1
        ? `linear-gradient(to bottom right,
        ${concatColorMixer([color1?.r, color1?.g, color1?.b], white, color1?.a)},
        ${concatColorMixer([color1?.r, color1?.g, color1?.b], white, color1?.a * opacity)}
      )`
        : `linear-gradient(to bottom right,
      ${concatColorMixer([color2?.r, color2?.g, color2?.b], white, color2?.a)},
      ${concatColorMixer([color2?.r, color2?.g, color2?.b], white, color2?.a * opacity)}
    )`
  };
};

// Get background colour gradient
const gradientBorderTextBox = (color1, color2, invertBackground) => {
  return {
    border: color1
      ? `1px solid ${concatColorMixer([color1?.r, color1?.g, color1?.b], white, color1?.a)}`
      : `1px solid ${concatColorMixer([color2?.r, color2?.g, color2?.b], white, color2?.a)}`
  };
};

// Get a new line for text that is separated by "\n"
const newLineText = (props, color1, color2, invertBackground) => {
  const newText = props
    ? props
        .split("\n")
        .map((str) =>
          str ? (
            <TxtInter
              size={17.28}
              weight={400}
              content={str}
              style={gradientText(color1, color2, invertBackground)}
            />
          ) : (
            <br />
          )
        )
    : [];

  return newText;
};

const gradientTextColour = (color1, color2) => {
  return {
    background: `linear-gradient(to right, ${color1}, ${color2})`,
    "-webkit-background-clip": "text",
    "-webkit-text-fill-color": "transparent"
  };
};

export {
  white,
  returnRGBA,
  colorMixer,
  concatColorMixer,
  rgbToHex,
  getTextColor,
  gradientText,
  gradientBgTextBox,
  gradientBorderTextBox,
  newLineText,
  gradientTextColour
};
