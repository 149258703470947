import SrRefColour from "../../../../../ref/colours";

const style = {
  clickable: {
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },

  colorBlack: { color: SrRefColour.Neutral1A },
  customPadding1: { padding: "12px 18px" },
  customPadding2: { padding: "12px 24px" },

  divStyle: {
    width: "fit-content",
    position: "absolute",
    bottom: "24px",
    borderRadius: "6px",
    zIndex: 5,
    padding: "12px 18px",
    transition: "0.2s ease-in",
    left: "24px"
  },

  //div stle for next FAB button
  nextDivStyle: {
    width: "fit-content",
    position: "absolute",
    bottom: "24px",
    borderRadius: "6px",
    zIndex: 5,
    padding: "12px 18px",
    transition: "0.2s ease-in",
    right: "24px"
  }
};

export default style;
