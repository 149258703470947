import React, { useState } from "react";
import SrRefColour from "../../../../../ref/colours";
// import { SrRefTextIntRe12 } from "../../../../../ref/text";
import TxtInter from "../../../../01_atoms/text/Inter";
import { SrCompIconThumbUpFilled, SrCompIconThumbUpUnfilled } from "../../../../01_atoms/icons";
import style from "./style";

// ThumbUp button
const ThumbUpBtn = ({ pressed, darkMode, onClick, breakpoint3 }) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    phoneOuterDiv: {
      ...style.shell,
      transition: "0.2s ease-in",
      backgroundColor: darkMode ? "rgb(0,0,0,0.15)" : "rgb(255,255,255,0.8)",
      borderColor: pressed
        ? darkMode
          ? SrRefColour.Neutral0
          : SrRefColour.Neutral1A
        : btnHover
        ? darkMode
          ? SrRefColour.Neutral2B
          : SrRefColour.Neutral2A
        : darkMode
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    },
    laptopOuterDiv: {
      ...style.laptopOuterDiv,
      backgroundColor: darkMode ? "rgb(0,0,0,0.15)" : "rgb(255,255,255,0.8)",
      borderColor: pressed
        ? darkMode
          ? SrRefColour.Neutral0
          : SrRefColour.Neutral1A
        : btnHover
        ? darkMode
          ? SrRefColour.Neutral2B
          : SrRefColour.Neutral2A
        : darkMode
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={onClick}
        style={style.outerDiv}
      >
        {breakpoint3 ? (
          <div style={style2.phoneOuterDiv}>
            <div style={style.icon24}>
              {pressed ? (
                <SrCompIconThumbUpFilled
                  size={24}
                  color={darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              ) : (
                <SrCompIconThumbUpUnfilled
                  size={24}
                  color={darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              )}
            </div>
          </div>
        ) : (
          // For larger screens
          <div style={style2.laptopOuterDiv}>
            <TxtInter
              size={17.28}
              weight={400}
              content={"Upvote"}
              style={{
                ...style.transition,
                color: darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A
              }}
            />

            <div style={{ ...style.icon20, ...style.marginLeft6 }}>
              {pressed ? (
                <SrCompIconThumbUpFilled
                  size={20}
                  color={darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              ) : (
                <SrCompIconThumbUpUnfilled
                  size={20}
                  color={darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ThumbUpBtn;
