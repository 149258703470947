const Error = (status) => {
  switch (status) {
    // Bad request
    case 400:
      return {
        status,
        error: "We could not handle your request. Please try again later.",
        success: false
      };

    // Unauthorized
    case 401:
      return {
        status,
        error: "You are not authorized. Please Login again and retry.",
        success: false
      };

    // Forbidden (Authorized, but can't access the specific activity)
    case 403:
      return {
        status,
        error: "Your current permissions do not allow you to do the action.",
        success: false
      };

    // Not Found
    case 404:
      return {
        status,
        error: "The activity you're trying to access is no longer available.",
        success: false
      };

    // Internal Server Error
    case 500:
      return {
        status,
        error: "Some error occurred. Please try again",
        success: false
      };

    // Default case (if none of the above cases match)
    default:
      return {
        status,
        error: "Something went wrong. Please recheck your internet connection and try again",
        success: false
      };
  }
};

export default Error;
