import SrRefColour from "../../../ref/colours";

const style = {
  componentColor: {
    marginTop: "6px",
    color: SrRefColour.Neutral2A,
    textAlign: "end"
  }
};

export default style;
