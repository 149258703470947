import SrRefColour from "../../../../../ref/colours";

const style = {
  icon24: { width: "24px", height: "24px" },
  clickable: {
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },

  // Widths, heights and margins
  marginHor6: {
    marginLeft: "6px",
    marginRight: "6px"
  },

  // Custom style for HomeBtn
  homeInnerDiv: {
    padding: "12px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)",
    transition: "0.2s ease-in"
  }
};

export default style;
