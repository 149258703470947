import { useContext } from "react";
import MainContext from "../../helpers/context/mainContext";
import TxtInter from "../../01_atoms/text/Inter";

import { StageArr } from "../../helpers/localData";

import style from "./style";

const Stage = ({ onClick }) => {
  // ifEdit state to check if user is on startup page or startupEdit page
  const { ifEdit, startup } = useContext(MainContext);

  // Dynamic style for this component
  const style2 = {
    outerDiv: {
      ...style.flexRow,
      marginLeft: ifEdit ? "8px" : "12px"
    }
  };

  // Click listener which only runs on Edit state
  const handleClick = () => {
    if (!ifEdit) return;
    onClick();
  };

  return (
    <div style={style2.outerDiv} onClick={handleClick}>
      <TxtInter
        size={17.28}
        weight={600}
        content={startup.other.stage === -1 ? "Your startup's" : StageArr[startup.other.stage]}
      />
      <TxtInter size={17.28} weight={400} content={"stage"} style={style.marginLeft6} />
    </div>
  );
};

export default Stage;
