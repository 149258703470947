import { useContext } from "react";
import TxtInter from "../../01_atoms/text/Inter";
import MainContext from "../../helpers/context/mainContext";

const OrgName = () => {
  const { startup } = useContext(MainContext);

  return (
    <TxtInter size={43.2} weight={600} content={startup.name ? startup.name : "Startup's name"} />
  );
};

export default OrgName;
