import React from "react";
import { white, concatColorMixer, gradientText } from "../../../helpers/themeColors";
import TxtInter from "../../text/Inter";
import style from "./style";

// Chip for industry
const IndustryChip = ({ accentColor, primaryColor, industry }) => {
  // Dynamic style for this component
  const style2 = {
    outerDiv: {
      ...style.industryOuterDiv,
      backgroundColor: accentColor
        ? concatColorMixer([accentColor.r, accentColor.g, accentColor.b], white, accentColor.a)
        : concatColorMixer([primaryColor.r, primaryColor.g, primaryColor.b], white, primaryColor.a)
    }
  };

  return (
    <div style={style2.outerDiv}>
      <TxtInter
        weight={400}
        size={12.96}
        content={industry}
        style={gradientText(accentColor, primaryColor)}
      />
    </div>
  );
};

export default IndustryChip;
