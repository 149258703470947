import React from "react";
import TxtInter from "../text/Inter";
import style from "./style";

// To check the number of characters entry
const SizeLimit = ({ isShown, lengthOfDesc, lengthLimit }) => {
  return (
    isShown === true && (
      <div>
        <TxtInter
          content={`${lengthOfDesc}/${lengthLimit}`}
          style={style.componentColor}
          weight={400}
          size={11.52}
        />
        {/* <SrRefTextIntRe08 content={`${lengthOfDesc}/${lengthLimit}`} style={style.componentColor} /> */}
      </div>
    )
  );
};

export default SizeLimit;
