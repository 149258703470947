import React, { useState } from "react";
import SrRefColour from "../../../../ref/colours";
import TxtInter from "../../text/Inter";
import SizeLimit from "../../characterLimit";
import style from "./style";

// Text input for Inter regular font size 12
const TextInputIntRe12 = ({
  topText,
  topTextAlwaysOn,
  condition,
  content,
  value,
  type,
  min,
  max,
  limit,
  placeholder,
  isShown,
  onChange,
  onClick,
  noLimit
}) => {
  const [inputHover, setInputHover] = useState(false);
  const [inputClick, setInputClick] = useState(false);

  const setInputHoverFunc = () => {
    setInputClick(true);
  };

  const fontColor =
    !content && condition
      ? SrRefColour.Neutral2A
      : !content
      ? SrRefColour.Neutral2B
      : condition
      ? SrRefColour.Neutral1A
      : SrRefColour.Neutral2A;

  // Dynamic styling for this component
  const style2 = {
    inputStyle: {
      ...style.inputStyle,
      border:
        inputHover || inputClick
          ? `1px solid ${SrRefColour.Neutral2A}`
          : `1px solid ${SrRefColour.Neutral2B}`
    }
  };

  return (
    <div style={style.positionRelative}>
      {/* Placeholder text at the top */}
      {!topTextAlwaysOn && topText && !value ? (
        <div></div>
      ) : (
        <div style={style.textInputTop}>
          <TxtInter size={10.8} weight={300} content={topText} color={fontColor} />
        </div>
      )}

      <input
        type={type}
        min={min}
        max={max}
        limit={limit}
        name={name}
        placeholder={placeholder}
        id={"textInput"}
        isShown={isShown}
        value={value}
        onChange={onChange}
        onMouseEnter={() => setInputHover(true)}
        onMouseLeave={() => setInputHover(false)}
        onClick={onClick}
        style={style2.inputStyle}
        required
      />
      {/* Size limit for text inputs */}
      {!noLimit && (
        <SizeLimit isShown={isShown} lengthOfDesc={value ? value.length : 0} lengthLimit={limit} />
      )}
    </div>
  );
};

export default TextInputIntRe12;
