const style = {
  // Custom styling for this component
  outerDiv: {
    display: "flex",
    flexDirection: "row",
    gap: "6px",
    marginLeft: "6px"
  },

  //Custom styling for this EditComponent
  editOuterDiv: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "2px"
  }
};

export default style;
