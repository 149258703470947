import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TxtInter from "../../../components/01_atoms/text/Inter";

import { apiendpoint } from "../../../components/helpers/apiendpoint";
import MainContext from "../../../components/helpers/context/mainContext";
import { OrgLogos, Industries, StatusArr } from "../../../components/helpers/localData";

// import { AvatarUpload } from "../../../components/01_atoms/avatar";
import AvatarUpload from "../../../components/01_atoms/avatar/AvatarUpload";
import BackFAB from "../../../components/01_atoms/buttons/FABs/BackFAB";
import NextFAB from "../../../components/01_atoms/buttons/FABs/NextFab";

import Dot from "../../../components/01_atoms/dot";
import ListInputInt12 from "../../../components/01_atoms/listInputs/ListInputInt12";
import ListPopupInt12 from "../../../components/01_atoms/listInputs/ListPopupInt12";
import SetupTemplate from "../../../components/01_atoms/setupTemplate";
import TextInputIntRe12 from "../../../components/01_atoms/textInputs/TextInputIntRe12";
import CropperPopup2 from "../../../components/02_molecules/setupPopups/cropperPopup/CropperPopup2";

import { style } from "./style";

const OrgCreate = () => {
  // Main context
  const {
    scrollBoxWidth,
    // API related
    setupNew,
    setNew,
    setUserId,
    loadUser,
    scrollToTop
  } = useContext(MainContext);
  const navigate = useNavigate();

  // To get the Invite Code from the search params
  const [searchParams] = useSearchParams();

  // If user doesn't exist, navigate to login
  useEffect(() => {
    !setupNew.newProfile === true && navigateToLogin();
  }, []);

  // Navigate to main startup listing
  const navigateHome = () => {
    navigate("/home");
    scrollToTop();
  };

  // Navigate to main startup listing
  const navigateToLogin = () => {
    navigate("/login");
  };

  //
  const navigateToCommunity = () => {
    let communityId = localStorage.getItem("@communityId");
    let orgid = localStorage.getItem("@orgid");
    if (communityId) {
      navigate(`/community/${communityId}`);
    } else if (orgid) {
      navigate(`/startup/${orgid}`);
    }
  };

  // State to set org id
  const [orgId, setOrgId] = useState();

  // Navigate to a particular startup listing
  const navigateToStartup = (e) => {
    navigate(`/startup/${e}`, { state: { id: e } });
    setOrgId(e);
    scrollToTop();
  };

  // Navigate to main startup listing
  const navigateToUserProfile = () => {
    navigate("/userProfile");
  };

  // scrollPosition
  const [scrollPosition, setScrollposition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset; // Find the top offset of the window
    setScrollposition(position); // Set scrollPosition = top offset of the window
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true }); // listen to scroll event
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll view
  const [screen, setScreen] = useState(0);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollLeft = screen * scrollBoxWidth;
  }, [screen]);

  // Snackbar
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  // Goal recommendations
  const goalText = [
    "Build our product",
    "Get our first customer",
    "Complete X project",
    "Earn $ in revenue"
  ];

  // useStates
  const [orgName, setOrgName] = useState("");
  const [orgNameEdit, setOrgNameEdit] = useState(false);
  const [logo, setLogo] = useState(OrgLogos[Math.floor(Math.random() * 10)]);
  const [orgBrief, setOrgBrief] = useState("");
  const [orgBriefEdit, setOrgBriefEdit] = useState(false);
  const [orgPurpose, setOrgPurpose] = useState("");
  const [orgPurposeEdit, setOrgPurposeEdit] = useState(false);
  const [orgIndustry, setOrgIndustry] = useState(1);
  const [sector1, setSector1] = useState("");
  const [sector1Edit, setSector1Edit] = useState(false);
  const [sector2, setSector2] = useState("");
  const [sector2Edit, setSector2Edit] = useState(false);
  const [cofounderCount, setCofounderCount] = useState("");
  const [cofounderCountEdit, setCofounderCountEdit] = useState(false);
  const [teamSize, setTeamSize] = useState("");
  const [teamSizeEdit, setTeamSizeEdit] = useState(false);
  const [orgStatus, setOrgStatus] = useState("");
  const [orgStatusEdit, setOrgStatusEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [snackText, setSnackText] = useState("");
  const [snackVisible, setSnackVisible] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [isOrgIndSelecting, setOrgIndSelecting] = useState(false);
  const [isOrgStatusSelecting, setOrgStatusSelecting] = useState(false);
  const [showLeaveSetup, setShowLeaveSetup] = useState(false);

  // Image
  const imageInputRef = useRef();
  const [image, setImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  // Upload image
  const uploadFile = (image) => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    if (image.size / 1024 / 1024 <= 10) {
      const fd = new FormData();
      fd.append("file", image);

      fetch(`${apiendpoint}/empCreateOrg/orgLogo`, {
        method: "POST",
        body: fd,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then((response) => {
          if (response.ok) return response.json();
          else {
            throw new Error("unauthorised");
          }
        })
        .then((json) => {
          setLogo(json.url);
          setNew({
            ...setupNew,
            addOrg: {
              ...setupNew.addOrg,
              logo: json.url
            }
          });
          setShowCropper(false);
          snackbar("Image uploaded successfully");
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setShowCropper(false);
          snackbar("An error occurred, please try again.");
          setLoading(false);
        });
    } else {
      setShowCropper(false);
      snackbar("Image size should be less than 10mb");
    }
  };

  const createOrg = async () => {
    // do all the api calls

    const referral_code = searchParams.get("referral_code");
    console.log(referral_code);
    var quarter = Math.floor(new Date().getMonth() / 3) + 1;
    var year = Math.floor(new Date().getFullYear());
    var term = Math.floor(new Date().getMonth() % 3) + 1;
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    fetch(`${apiendpoint}/empCreateOrg?quarter=${quarter}&year=${year}&term=${term}`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        addEmp: {
          designation: setupNew.addEmp.designation,
          DOB: null,
          email_id: setupNew.addEmp.emailId,
          fname: setupNew.addEmp.fName,
          lname: setupNew.addEmp.lName,
          gender: null,
          whatsapp_no: setupNew.addEmp.whatsappNo,
          picture: setupNew.addEmp.pp,
          linkedin: null,
          user_type: "emp"
        },
        addOrg: {
          name: orgName,
          logo: setupNew.addOrg.logo ? setupNew.addOrg.logo : logo,
          brief: orgBrief,
          purpose: orgPurpose,
          industry: orgIndustry,
          sector1: sector1,
          sector2: sector2,
          team_size: teamSize,
          cofounder_count: cofounderCount,
          available_days: "1,2,3,4,5,6",
          event1: "N"
        },
        setup: "platform",
        orgGoal: orgStatus,
        pod: {
          name: orgName,
          description: orgBrief,
          member_count: teamSize
        },
        referral_code
      })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Unauthorized");
      })
      .then((json) => {
        const fetchStorage = async () => {
          localStorage.setItem("@empId", String(json.data.emp_id));
          localStorage.setItem("@orgid", String(json.data.org_id));

          let communityId = json.data.communityId;
          communityId && localStorage.setItem("@communityId", String(communityId));
          communityId && localStorage.setItem("@initial", `/community/${json.data.community}`);
          setUserId(parseInt(json.data.emp_id));
          localStorage.setItem("@setupComplete", "Y");
        };
        fetchStorage();
        // navigateHome();
        // navigateToStartup(json.data.org_id);
        loadUser();

        // Navigate to the initial page in which user first landed
        const initialLocation = localStorage.getItem("@initial");
        initialLocation && localStorage.removeItem("@initial");

        // Navigate to home if initial link is not present
        initialLocation ? navigate(initialLocation) : navigateToCommunity();
      })
      .catch((error) => {
        snackbar("An error occurred, please try again!");
        console.log(error);
        // navigateToLogin();
      });
  };

  // handleSubmit
  const handleSubmit = () => {
    if (screen === 0 && orgName === "")
      snackbar("Please write your organization's name to continue");
    else if (screen === 2 && orgBrief === "")
      snackbar("Please write your organization's brief to continue");
    else if (screen === 3 && orgPurpose === "")
      snackbar("Please write your organization's purpose to continue");
    else if (screen === 4 && orgIndustry === -1)
      snackbar("Please select your organization's industry to continue");
    else if (screen === 5 && (sector1 === "" || sector2 === ""))
      snackbar("Please write any two sectors that define your industry better");
    else if (screen === 6 && (teamSize === "" || cofounderCount === ""))
      snackbar("Please write your organization's team size to continue");
    else if (screen === 6 && (teamSize !== "" || cofounderCount !== "")) {
      setScreen(screen + 1);
      setTimeout(() => {
        createOrg();
      }, 2000);
    } else setScreen(screen + 1);
  };

  return (
    <div style={style.outerMost}>
      <SetupTemplate>
        {/* Scroll view */}
        <div
          style={{
            ...style.scrollWrap,
            ...{ width: `${scrollBoxWidth}px`, height: "316px" }
          }}
          ref={scrollRef}
        >
          {/* Enter org name screen */}
          <div style={style.scroll}>
            <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
              {/* Text */}
              <TxtInter
                size={21.6}
                weight={400}
                content={"What is your Organization called?"}
                state={"enabled"}
                style={style.colorBlack}
              />

              {/* Input for org name */}
              <div style={style.marginTop18}>
                <TextInputIntRe12
                  type="text"
                  name="orgName"
                  isShown={true}
                  limit="25"
                  placeholder={"Organization's short name"}
                  value={orgName}
                  onChange={(e) => {
                    if (e.target.value.length < 26) {
                      setOrgName(e.target.value);
                    }
                  }}
                  onClick={() => setOrgNameEdit(true)}
                />
              </div>
            </div>
          </div>

          {/* Upload org logo screen */}
          <div style={style.scroll}>
            <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
              {/* Text */}
              <TxtInter
                size={21.6}
                weight={400}
                content={"Upload your logo"}
                state={"enabled"}
                style={style.colorBlack}
              />

              {/* Image upload avatar */}
              <div
                style={{
                  ...style.justifyContentCenter,
                  ...style.marginTop24
                }}
              >
                <AvatarUpload
                  width={122}
                  pic={setupNew.addOrg.logo ? setupNew.addOrg.logo : logo}
                  imageInputRef={imageInputRef}
                  onChange={(e) => {
                    let files;
                    if (e.dataTransfer) {
                      files = e.dataTransfer.files;
                    } else if (e.target) {
                      files = e.target.files;
                    }
                    const reader = new FileReader();
                    reader.onload = () => {
                      setImage(reader.result);
                    };
                    reader.readAsDataURL(files[0]);
                    setShowCropper(true);
                  }}
                  innerWidth={6}
                />
              </div>

              {/* Note to users */}
              {/* <div style={style.marginTop24}>
                <SrRefTextIntLi10
                  content={
                    "Sadly, this image picker only accepts images less than size 1mb and resolution 1000x1000px. Sorry :)"
                  }
                />
              </div> */}
            </div>
          </div>

          {/* Enter org brief screen */}
          <div style={style.scroll}>
            <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
              {/* Text */}
              <TxtInter
                size={21.6}
                weight={400}
                content={"Describe what you do in one line"}
                state={"enabled"}
                style={style.colorBlack}
              />

              {/* Input for org brief */}
              <div style={style.marginTop18}>
                <TextInputIntRe12
                  type="text"
                  name="orgBrief"
                  isShown={true}
                  limit="50"
                  placeholder={`"Food libraries in your neighbourhood"`}
                  value={orgBrief}
                  onChange={(e) => {
                    if (e.target.value.length < 51) {
                      setOrgBrief(e.target.value);
                    }
                  }}
                  onClick={() => setOrgBriefEdit(true)}
                />
              </div>
            </div>
          </div>

          {/* Enter org purpose screen */}
          <div style={style.scroll}>
            <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
              {/* Text */}
              <TxtInter
                size={21.6}
                weight={400}
                content={"What is your vision?"}
                state={"enabled"}
                style={style.colorBlack}
              />

              {/* Input for purpose */}
              <div style={style.marginTop18}>
                <TextInputIntRe12
                  type="text"
                  name="orgPurpose"
                  isShown={true}
                  limit="50"
                  placeholder={`For ex: "Solving world hunger"`}
                  value={orgPurpose}
                  onChange={(e) => {
                    if (e.target.value.length < 51) {
                      setOrgPurpose(e.target.value);
                    }
                  }}
                  onClick={() => setOrgPurposeEdit(true)}
                />
              </div>
            </div>
          </div>

          {/* Enter org industry screen */}
          <div style={style.scroll}>
            <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
              {/* Text */}
              <TxtInter
                size={21.6}
                weight={400}
                content={"What Industry do you belong to?"}
                state={"enabled"}
                style={style.colorBlack}
              />

              {/* List input for industry */}
              <div style={style.marginTop18}>
                <ListInputInt12
                  onPress={() =>
                    isOrgIndSelecting ? setOrgIndSelecting(false) : setOrgIndSelecting(true)
                  }
                  placeholder={"Industry"}
                  condition={orgIndustry !== -1}
                  // -1 because industry are stored in array in
                  // frontend which starts from 0 and industry in db from 1
                  content={Industries[orgIndustry - 1]}
                />
              </div>

              {console.log(orgIndustry)}
              {/* List popup for industry */}
              {isOrgIndSelecting && (
                <ListPopupInt12
                  array={Industries}
                  value={orgIndustry}
                  setValue={setOrgIndustry}
                  setupIndustry={true}
                  setValueSelect={setOrgIndSelecting}
                />
              )}
            </div>
          </div>

          {/* Enter sectors screen */}
          <div style={style.scroll}>
            <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
              {/* Text */}
              <TxtInter
                size={21.6}
                weight={400}
                content={"Add two sectors you are related to"}
                state={"enabled"}
                style={style.colorBlack}
              />

              {/* Input for sector 1 */}
              <div style={style.marginTop18}>
                <TextInputIntRe12
                  type="text"
                  name="sector1"
                  isShown={true}
                  limit="20"
                  placeholder={"For ex: Food delivery"}
                  topText={"Sector 1"}
                  value={sector1}
                  onChange={(e) => {
                    if (e.target.value.length < 21) {
                      setSector1(e.target.value);
                    }
                  }}
                  onClick={() => setSector1Edit(true)}
                />
              </div>

              {/* Input for sector 2 */}
              <div style={style.marginTop18}>
                <TextInputIntRe12
                  type="text"
                  name="sector2"
                  isShown={true}
                  limit="20"
                  placeholder={"For ex: Logistics"}
                  topText={"Sector 2"}
                  value={sector2}
                  onChange={(e) => {
                    if (e.target.value.length < 21) {
                      setSector2(e.target.value);
                    }
                  }}
                  onClick={() => setSector2Edit(true)}
                />
              </div>
            </div>
          </div>

          {/* Enter team size screen */}
          <div style={style.scroll}>
            <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
              {/* Text */}
              <TxtInter
                size={21.6}
                weight={400}
                content={"What is your team size?"}
                state={"enabled"}
                style={style.colorBlack}
              />

              {/* Input for cofounder count */}
              <div style={style.marginTop18}>
                <TextInputIntRe12
                  type="number"
                  name="cofounderCount"
                  placeholder={`Total no of co-founders`}
                  topText={"Total no of co-founders"}
                  value={cofounderCount}
                  onChange={(e) => setCofounderCount(e.target.value)}
                  onClick={() => setCofounderCountEdit(true)}
                  noLimit={true}
                />
              </div>

              {/* Input for team size */}
              <div style={style.marginTop18}>
                <TextInputIntRe12
                  type="number"
                  name="teamSize"
                  placeholder={`Total no of team members`}
                  topText={"Total no of team members"}
                  value={teamSize}
                  onChange={(e) => setTeamSize(e.target.value)}
                  onClick={() => setTeamSizeEdit(true)}
                  noLimit={true}
                />
              </div>
            </div>
          </div>

          {/* AHA moment screen */}
          <div style={style.scroll}>
            <div
              style={{
                ...{ width: `${scrollBoxWidth}px` },
                ...style.paddingHor24
              }}
            >
              <div
                style={{
                  ...style.flexColumn,
                  ...style.alignItemsCenter
                }}
              >
                {/* Cover image */}
                <img
                  src={require("../../../media/setup_guide_aha_2.png")}
                  width={180}
                  alt={"Organization created!"}
                />

                {/* Aha moment text */}
                <TxtInter
                  size={21.6}
                  weight={400}
                  content={"Organization created!"}
                  state={"enabled"}
                  style={{ ...style.marginTop24, ...style.textCenter, ...style.colorBlack }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Back button */}
        {screen !== 7 && (
          <BackFAB
            onClick={() => (screen === 0 ? navigateToUserProfile() : setScreen((p) => p - 1))}
          />
        )}

        {/* Dot carousal */}
        {screen !== 7 && (
          <div style={style.dotArray}>
            <Dot screen={screen} actualScreen={0} />
            <Dot screen={screen} actualScreen={1} />
            <Dot screen={screen} actualScreen={2} />
            <Dot screen={screen} actualScreen={3} />
            <Dot screen={screen} actualScreen={4} />
            <Dot screen={screen} actualScreen={5} />
            <Dot screen={screen} actualScreen={6} />
          </div>
        )}

        {/* Next button */}
        {screen !== 7 && (
          <NextFAB
            content={
              screen === 1 && !setupNew.addOrg.logo ? "Skip" : screen === 6 ? "Done" : "Next"
            }
            onClick={() => handleSubmit()}
            // onClick={() => (screen === 8 ? setScreen(1) : setScreen((p) => p + 1))}
          />
        )}
      </SetupTemplate>

      {/* Image cropper */}
      {showCropper && (
        <CropperPopup2
          image={image}
          setImage={setImage}
          onClick={uploadFile}
          onClick2={() => setShowCropper(false)}
          scrollPosition={scrollPosition}
          noPadding={true}
        />
      )}

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default OrgCreate;
