import SrRefColour from "../../../../../ref/colours";

const style = {
  justifyContentCenter: {
    display: "flex",
    justifyContent: "center"
  },
  borderRadius39: {
    borderRadius: "39px"
  },
  clickable: {
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },
  transition: {
    transition: "0.2s ease-in"
  },

  // Widths, heights and margins
  maxHeight39: { maxHeight: "39px" },
  marginHor6: {
    marginLeft: "6px",
    marginRight: "6px"
  },

  logo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  inputStyle: {
    marginLeft: "6px",
    transition: "0.2s ease-in"
  },

  // Custom style for UserPanelButton
  userOuterDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    transition: "0.2s ease-in",
    width: "fit-content",
    padding: "4.5px",
    border: `1px solid ${SrRefColour.Neutral2B}`,
    borderRadius: "50px",
    backgroundColor: "rgb(255,255,255,0.8)"
  }
};

export default style;
