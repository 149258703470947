import SrRefColour from "../../../../ref/colours";

export const style = {
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  alignItemsCenter: {
    display: "flex",
    alignItems: "center"
  },
  justifyContentCenter: {
    display: "flex",
    justifyContent: "center"
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between"
  },
  scrollBox: {
    position: "relative",
    minHeight: "510px"
  },
  screen: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    minHeight: "100vh",
    maxWidth: "1600px",
    margin: "0 auto",
    backgroundColor: SrRefColour.Neutral0,
    overflowX: "hidden"
  },
  surfaceBorder: {
    borderRadius: "12px",
    backgroundColor: SrRefColour.Neutral0
  },
  scrollWrap: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden"
  },
  scroll: {
    display: "inline-flex"
  },
  resendOTP: {
    width: "fit-content",
    borderWidth: "0 0 1px",
    borderStyle: "solid"
  },
  borderTop: {
    borderWidth: "0.5px 0 0",
    borderStyle: "solid",
    borderColor: SrRefColour.Neutral2A
  },
  textAlignCenter: {
    textAlign: "center"
  },
  choiceBox: {
    padding: "12px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "18px"
  },
  icon: { width: "24px", height: "24px" },
  invertIcon: { transform: "rotateY(180deg)" },
  fitContent: {
    width: "fit-content"
  },
  clickable: {
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },
  transition: {
    transition: "0.2s ease-in"
  },
  vpScrollable: {
    height: "200px",
    marginTop: "18px",
    borderRadius: "6px",
    overflowY: "auto"
  },
  colorBlack: { color: SrRefColour.Neutral1A },
  dotArray: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  customPadding: {
    paddingTop: "18px",
    paddingBottom: "24px",
    paddingLeft: "12px",
    paddingRight: "24px"
  },
  paddingTop24: { paddingTop: "24px" },
  paddingHor24: {
    paddingLeft: "24px",
    paddingRight: "24px"
  },

  // Widths, heights and margins
  width40: { width: "40%" },
  width100: { width: "100%" },
  height50: { height: "50px" },
  marginTop3: { marginTop: "3px" },
  marginTop6: { marginTop: "6px" },
  marginTop12: { marginTop: "12px" },
  marginTop18: { marginTop: "18px" },
  marginTop24: { marginTop: "24px" },
  marginTop48: { marginTop: "48px" },
  marginBot48: { marginBottom: "48px" },
  marginBot72: { marginBottom: "72px" },
  marginHor12: {
    marginLeft: "12px",
    marginRight: "12px"
  },
  marginHor24: {
    marginLeft: "24px",
    marginRight: "24px"
  },
  marginLeft12: {
    marginLeft: "12px"
  },
  marginLeft15: {
    marginLeft: "15px"
  },

  // Custom styling for component

  cardWrap: {
    borderRadius: "12px",
    backgroundColor: SrRefColour.Neutral0,
    position: "relative",
    minHeight: "510px"
  },
  scrollViewContainer: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    marginTop: "48px",
    height: "316px"
  },
  logoOuterWrap: {
    height: "50px",
    marginTop: "48px",
    marginBottom: "72px",
    marginLeft: "24px",
    marginRight: "24px"
  },
  avatarWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  inputWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  inputWrap1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "18px"
  },
  inputStyle: {
    width: "100%"
  }
};
