import SrRefColour from "../../../ref/colours";

export const style = {
  outerMost: {
    position: "relative",
    width: "100vw",
    backgroundColor: SrRefColour.Neutral0
  },
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  alignItemsCenter: {
    display: "flex",
    alignItems: "center"
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between"
  },
  scrollBox: {
    position: "relative",
    minHeight: "510px"
  },
  screen: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100vw",
    minHeight: "100vh",
    maxWidth: "1600px",
    margin: "0 auto",
    backgroundColor: SrRefColour.Neutral0,
    overflowX: "hidden"
  },
  surfaceBorder: {
    borderRadius: "12px",
    backgroundColor: SrRefColour.Neutral0
  },
  choiceBox: {
    padding: "12px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "18px"
  },
  icon: { width: "24px", height: "24px" },
  invertIcon: { transform: "rotateY(180deg)" },
  fitContent: {
    width: "fit-content"
  },
  clickable: {
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },
  transition: {
    transition: "0.2s ease-in"
  },
  colorBlack: { color: SrRefColour.Neutral1A },
  paddingHor24: {
    paddingLeft: "24px",
    paddingRight: "24px"
  },

  // Widths, heights and margins
  height50: { height: "50px" },
  marginTop6: { marginTop: "6px" },
  marginTop24: { marginTop: "24px" },
  marginTop48: { marginTop: "48px" },
  marginBot72: { marginBottom: "72px" },
  marginHor24: {
    marginLeft: "24px",
    marginRight: "24px"
  },
  marginLeft12: {
    marginLeft: "24px"
  }
};
