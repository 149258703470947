import SrRefColour from "../../../ref/colours";

const style = {
  flexWrap: { display: "flex", flexWrap: "wrap" },
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  screen: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100vw",
    minHeight: "100vh",
    maxWidth: "1600px",
    margin: "0 auto",
    backgroundColor: SrRefColour.Neutral0,
    overflowX: "hidden"
  },
  colorBlack: { color: "black" },

  gap12: {
    gap: "12px"
  },
  status: { width: "100px", textAlign: "center" },

  margin12: {
    margin: "12px"
  },
  marginLeft12: {
    marginLeft: "12px"
  },

  coverImageWrap: {
    position: "absolute",
    height: "20rem",
    aspectRatio: "6/1",
    backgroundColor: "grey"
  },
  coverImage: {
    width: "100%",
    height: "100%",
    objectFit: "fill"
  },

  eventCoverOuterWrap: {
    width: "100%",
    paddingBottom: "72px",
    borderWidth: "0 0 1px 0",
    borderStyle: "solid",
    backgroundColor: "white",
    borderColor: SrRefColour.Neutral2B
  },
  eventCoverInnerWrap: {
    justifyContent: "center",
    margin: "0 auto"
  },
  eventDetailOuterDiv: {
    display: "flex",
    flexDirection: "column",
    gap: "36px"
  },

  eventDetailInnerDiv: {
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    width: "calc(100% - 24px)",
    margin: "0 auto"
  },

  avatarOuterWrap: {
    width: "fit-content",
    marginTop: "5rem",
    marginLeft: "-6px",
    marginRight: "-6px",
    padding: "3px",
    borderRadius: "18px",
    transition: "0.2s ease-in"
  },
  avatarInnerWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    borderRadius: "16px",
    position: "relative",
    width: "fit-content",
    transition: "0.2s ease-in"
  },

  eventTitle: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width: "fit-content",
    height: "fit-content"
  },
  adminOuterWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "18px",
    marginTop: "-18px"
  },

  adminInnerWrap: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
    width: "calc(100% - 24px)",
    margin: "0 auto"
  },
  adminTitle: {
    minHeight: "32px",
    marginLeft: "12px",
    color: "black"
  },
  adminListContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "18px",
    overflow: "scroll"
  },
  inviteLinkOuterWrap: {
    display: "flex",
    flexDirection: "row",
    gap: "18px",
    overflow: "scroll",
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },
  inviteLinkInnerDiv: {
    display: "flex",
    alignItems: "center",
    minHeight: "32px",
    color: SrRefColour.Neutral1A,
    padding: "6px 12px",
    borderRadius: "6px",
    backgroundColor: SrRefColour.Neutral1B
  },
  startupCardHolder: {
    display: "flex",
    flexWrap: "wrap",
    margin: "24px auto 0"
  },
  publishBtnOuterWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "18px"
  },

  publishBtnInnerWrap: {
    display: "flex",
    flexDirection: "row",
    width: "calc(100% - 24px)",
    margin: "0 auto",
    gap: "6px"
  },

  publishBtn: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
    marginTop: "6px",
    marginLeft: "12px",
    marginRight: "12px",
    borderRadius: "6px",
    cursor: "pointer",
    border: "none",
    textDecoration: "none",
    backgroundColor: "#FF4F4F",
    padding: "12px 12px 12px 18px"
  },
  emptyStartupInnerDiv: {
    margin: "36px 18px 48px"
  },
  editBtnWrap: {
    position: "absolute",
    top: "8px",
    right: "8px"
  },
  copyBtnWrap: {
    marginLeft: "12px",
    width: "20px",
    height: "20px"
  }
};

export default style;
