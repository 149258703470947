import React, { useState, useContext, useRef, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Importing colors
import SrRefColour from "../../../../ref/colours";
// Importing helper functions
import { returnRGBA } from "../../../helpers/themeColors";

// Importing APIs
import { changeCommunityStatus } from "../../../helpers/community";
import { imageUploadApi } from "../../../helpers/baseFuncs";

// Importing Context
import MainContext from "../../../helpers/context/mainContext";

// Importing texts
import TxtInter from "../../../01_atoms/text/Inter";
import TxtRubik from "../../../01_atoms/text/rubik";

// Importing Components
import RectUpload from "../../../01_atoms/avatar/ReactUpload";
import AvatarUpload from "../../../01_atoms/avatar/AvatarUpload";
import BackFAB from "../../../01_atoms/buttons/FABs/BackFAB";
import NextFAB from "../../../01_atoms/buttons/FABs/NextFab";
import Dot from "../../../01_atoms/dot";
import TextInputIntRe12 from "../../../01_atoms/textInputs/TextInputIntRe12";
import TextAreaIntRe12 from "../../../02_molecules/textAreas";
import CropperPopup from "../cropperPopup/CropperPopup";

import { style } from "./style";

const EventPopup = ({
  eventData,
  setEventData,
  setFetchMore,
  setEdit,
  setEditHover,
  allowScroll,
  eventId,
  setEventInfoEdit
}) => {
  // Main context
  const {
    breakpoint3,
    scrollBoxWidth,
    // API related,
    user,
    snackbar
  } = useContext(MainContext);

  // Scroll view
  const [screen, setScreen] = useState(0);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollLeft = screen * scrollBoxWidth;
  }, [screen]);

  // Startup theme colours
  // const primaryColor =
  //   user.userType === "emp" ? userStartup.theme?.primary.color : "rgba(200,200,200,1)";
  const primaryColor = "rgba(200,200,200,1)";
  const returnPrimary = returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, 1);

  // useStates
  const [pp, setPP] = useState(eventData.logo);
  const [coverPP, setCoverPP] = useState(eventData.cover);
  const [loading, setLoading] = useState(false);

  // Image
  const imageInputRef = useRef();
  const coverImageRef = useRef();
  const [image, setImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [showCoverImageCropper, setCoverCropper] = useState(false);

  // Upload infographics
  const uploadFile = async (image) => {
    let imgUrl = "";
    if (image.size / 1024 / 1024 <= 10) {
      console.log("Rendered");
      var json = await imageUploadApi(`/platform/eventgraphics`, image);

      if (!json.success) {
        console.log(json);
        setShowCropper(false);
        snackbar("An error occurred, please try again.");
        setLoading(false);
        return;
      }

      console.log(json.ur);
      setPP(json.url);

      imgUrl = json.url;
      const data = await changeCommunityStatus(eventId, {
        data: {
          event_details: { logo: imgUrl }
        }
      });

      if (!data.success) {
        console.log(json);
        setShowCropper(false);
        snackbar("An error occurred, please try again.");
        setLoading(false);
        return;
      }

      setFetchMore(true);
      setShowCropper(false);
      snackbar("Image uploaded successfully");
      setLoading(false);
    } else {
      setShowCropper(false);
      snackbar("Image size should be less than 10mb");
    }
  };

  const uploadCoverImage = async (image) => {
    let imgUrl = "";
    if (image.size / 1024 / 1024 <= 10) {
      var json = await imageUploadApi(`/platform/eventgraphics`, image);

      if (!json.success) {
        console.log(json);
        setShowCropper(false);
        snackbar("An error occurred, please try again.");
        setLoading(false);
        return;
      }

      setCoverPP(json.url);
      imgUrl = json.url;
      const data = await changeCommunityStatus(eventId, {
        data: {
          event_details: { cover: imgUrl }
        }
      });

      if (!data.success) {
        console.log(json);
        setShowCropper(false);
        snackbar("An error occurred, please try again.");
        setLoading(false);
        return;
      }

      setFetchMore(true);
      setCoverCropper(false);
      snackbar("Image uploaded successfully");
      setLoading(false);
    } else {
      setCoverCropper(false);
      snackbar("Image size should be less than 10mb");
    }
  };

  const editUser = async () => {
    const json = await changeCommunityStatus(eventId, { data: { event_details: eventData } });
    if (!json.success) {
      snackbar("Error occured in editing community :(");
      return;
    }
    setFetchMore(true);
    setEventInfoEdit(false);
  };

  //   useEffect(() => {
  //     return editUser;
  //   }, [fName, lName, pp, emailId, phoneNoWA, designation]);

  // handleSubmit
  const handleSubmit = async () => {
    if (screen === 0 && eventData.title === "") snackbar("Please write your title of the event");
    else if (screen === 1 && !eventData.logo) snackbar("Please add your logo");
    // else if (screen === 2 && !props.eventData.event_start_date)
    //   snackbar("Please add event start date");
    // else if (screen === 2 && !props.eventData.event_end_date) snackbar("Please add event end date");
    // else if (screen === 2 && !props.eventData.location) snackbar("Please add location");
    else if (screen === 3 && eventData.brief === "") snackbar("Please brief about the event");
    else if (screen === 3) {
      setScreen(screen + 1);
      setTimeout(() => {
        editUser();
        setScreen(0);
        setEdit(false);
      }, 1000);
    } else setScreen(screen + 1);
  };

  // Dynamic styling for this component
  const style2 = {
    backgroundDiv: {
      ...style.alignItemsCenter,
      justifyContent: breakpoint3 ? "none" : "center"
    },

    borderCard: {
      ...style.surfaceBorder,
      ...style.scrollBox,
      width: `${scrollBoxWidth}px`,
      border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
      marginTop: breakpoint3 ? "24px" : 0
    },

    scrollVViewWrap: {
      ...style.scrollVViewWrap,
      width: `${scrollBoxWidth}px`
    }
  };

  return (
    <div>
      {/* Background */}
      <div style={style2.backgroundDiv}>
        {/* Bordered card */}
        <div style={style2.borderCard}>
          {/* Event Title at the top */}
          <div style={style.eventOuterDiv}>
            {!pp ? (
              <div style={style.titleWrap}>
                {/* Event Title */}
                <TxtRubik size={36} weight={600} content={eventData?.name} />
              </div>
            ) : (
              // Shram logo at the top
              <div style={style.logoWrap}>
                <img src={pp} height={50} alt={"Community logo"} />
              </div>
            )}
          </div>

          {/* Scroll view */}
          <div style={style2.scrollVViewWrap} ref={scrollRef}>
            {/* Enter title screen */}
            <div style={style.scroll}>
              <div style={{ width: `${scrollBoxWidth}px`, ...style.paddingHor24 }}>
                {/* Text */}
                <TxtInter
                  size={21.6}
                  weight={400}
                  content={"Enter the name of your community?"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for title of event*/}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="title"
                    isShown={true}
                    limit="30"
                    placeholder={"Community name"}
                    topText={"Community name"}
                    value={eventData?.name}
                    onChange={(e) => {
                      if (e.target.value.length < 31) {
                        setEventData({ ...eventData, name: e.target.value });
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Upload Event Logo screen */}
            <div style={style.scroll}>
              <div style={{ width: `${scrollBoxWidth}px`, ...style.paddingHor24 }}>
                {/* Text */}
                <TxtInter
                  size={21.6}
                  weight={400}
                  content={"Upload your profile picture"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Image upload avatar */}
                <div style={style.imageUploadWrap}>
                  <AvatarUpload
                    width={122}
                    pic={eventData.logo ? eventData.logo : pp}
                    imageInputRef={imageInputRef}
                    onChange={(e) => {
                      let files;
                      if (e.dataTransfer) {
                        files = e.dataTransfer.files;
                      } else if (e.target) {
                        files = e.target.files;
                      }
                      const reader = new FileReader();
                      reader.onload = () => {
                        setImage(reader.result);
                      };
                      reader.readAsDataURL(files[0]);
                      setShowCropper(true);
                    }}
                    innerWidth={6}
                  />
                </div>
              </div>
            </div>

            {/* Community brief  */}
            <div style={style.scroll}>
              <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                {/* Text */}
                <TxtInter
                  size={21.6}
                  weight={400}
                  content={"Describe your community briefly"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                <div style={style.marginTop18}>
                  <TextAreaIntRe12
                    isShown={true}
                    limit="500"
                    name="brief"
                    placeholder={"Community brief"}
                    value={eventData?.description}
                    onChange={(e) => {
                      if (e.target.value.length < 501) {
                        setEventData({ ...eventData, description: e.target.value });
                      }
                    }}
                    rows={4}
                  />
                </div>
              </div>
            </div>

            {/* Upload Community cover */}
            <div style={style.scroll}>
              <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                {/* Text */}
                <TxtInter
                  size={21.6}
                  weight={400}
                  content={"Upload your cover picture"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Cover upload avatar */}
                <div style={style.coverUploadWrap}>
                  <RectUpload
                    width={360}
                    height={60}
                    pic={eventData.cover ? eventData.cover : coverPP}
                    imageInputRef={coverImageRef}
                    onChange={(e) => {
                      let files;
                      if (e.dataTransfer) {
                        files = e.dataTransfer.files;
                      } else if (e.target) {
                        files = e.target.files;
                      }
                      const reader = new FileReader();
                      reader.onload = () => {
                        setCoverImage(reader.result);
                      };
                      reader.readAsDataURL(files[0]);
                      setCoverCropper(true);
                    }}
                    darkMode={true}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Back button */}
          <BackFAB
            onClick={() =>
              screen === 0
                ? [setEdit(false), setEditHover(false), allowScroll()]
                : setScreen((p) => p - 1)
            }
          />

          {/* Dot carousal */}
          <div style={style.dotArray}>
            <Dot screen={screen} actualScreen={0} />
            <Dot screen={screen} actualScreen={1} />
            <Dot screen={screen} actualScreen={2} />
            <Dot screen={screen} actualScreen={3} />
          </div>

          {/* Next button */}
          <NextFAB content={screen === 3 ? "Done" : "Next"} onClick={() => handleSubmit()} />

          {/* Image cropper */}
          {showCropper && (
            <CropperPopup
              image={image}
              onClick={uploadFile}
              setImage={setShowCropper}
              aspectRatio={1}
            />
          )}

          {/* Cover cropper */}
          {showCoverImageCropper && (
            <CropperPopup
              image={coverImage}
              onClick={uploadCoverImage}
              setImage={setCoverCropper}
              aspectRatio={6}
            />
          )}
        </div>
      </div>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default EventPopup;
