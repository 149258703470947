import React from "react";
import TxtInter from "../text/Inter";
import style from "./style";

const ShramStamp = (props) => {
  return (
    <div style={style.shramStamp}>
      <div style={style.innerDiv}>
        {/* "Powered by" text */}
        {!props.noText && (
          <TxtInter content={"Powered by"} style={style.colorGrey} size={17.28} weight={300} />
        )}

        {/* Shram logo */}
        <a
          href="https://www.shram.io/"
          style={style.clickable}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div style={style.imgWrap}>
            <img src={require("../../../media/logo.png")} height={30} alt={"Shram logo"} />
          </div>
        </a>
      </div>
    </div>
  );
};

export default ShramStamp;
