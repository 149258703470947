import SrRefColour from "../../../../ref/colours";

const style = {
  flexRow: { display: "flex", flexDirection: "row" },
  spaceBetween: { display: "flex", justifyContent: "space-between" },
  textInput: {
    width: "100%",
    padding: "12px 9px",
    borderRadius: "6px",
    backgroundColor: SrRefColour.Neutral1C,
    resize: "none",
    outline: "none"
  },
  clickable: {
    cursor: "pointer"
  },
  transition: {
    transition: "0.2s ease-in"
  },
  scrollWrapV: {
    display: "flex",
    flexDirection: "column",
    height: "150px",
    borderRadius: "12px",
    backgroundColor: SrRefColour.Neutral1D,
    overflow: "auto"
  },
  scrollV: {
    display: "inline-flex"
  },
  list: {
    width: "100%",
    paddingTop: "16px",
    borderStyle: "solid",
    borderColor: SrRefColour.Neutral2B
  },

  // Widths, heights and margins
  marginHor12: {
    marginLeft: "12px",
    marginRight: "12px"
  },
  marginBot12: {
    marginBottom: "12px"
  },

  // Custom styling for ListInputInt12 component
  inputOuterDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    padding: "12px 9px",
    borderRadius: "6px",
    backgroundColor: SrRefColour.Neutral1C,
    resize: "none",
    outline: "none",
    cursor: "pointer",
    transition: "0.2s ease-in"
  },

  // Custom style for this component
  outerDiv: {
    display: "inline-flex",
    cursor: "pointer",
    transition: "0.2s ease-in"
  }
};

export default style;
