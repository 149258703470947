import SrRefColour from "../../../../ref/colours";

const style = {
  pic: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "1.5px",
    borderWidth: "1px",
    borderStyle: "solid",
    backgroundColor: SrRefColour.Neutral0
  },
  positionAbsolute: {
    position: "absolute"
  },
  positionRelative: {
    position: "relative",
    width: "fit-content"
  },
  // editBtn: {
  //   display: "flex",
  //   justifyContent: "center",
  //   alignItems: "center",
  //   width: "32px",
  //   height: "32px",
  //   borderWidth: "1px",
  //   borderStyle: "solid",
  //   borderRadius: "16px"
  // },
  video: { width: "240px", height: "160px", borderRadius: "24px" },
  // topRight11: { top: "11px", right: "11px" },
  centeredTextWrap: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  centeredText: {
    position: "absolute",
    marginLeft: "48px",
    marginRight: "48px",
    textAlign: "center"
  },
  centeredText2: {
    position: "absolute",
    marginLeft: "12px",
    marginRight: "12px",
    textAlign: "center"
  },
  borderRadius12: { borderRadius: "12px" },
  borderRadius16: { borderRadius: "16px" },
  borderRadius18: { borderRadius: "18px" },
  clickable: {
    cursor: "pointer",
    textDecoration: "none"
  },
  // transition: {
  //   transition: "0.2s ease-in"
  // },
  marginTop6: {
    marginTop: "6px"
  },

  //custom style for component

  editWrap: {
    position: "absolute",
    top: "11px",
    right: "11px"
  },

  //styles for avatar upload component
  avatarUpInnerDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "32px",
    height: "32px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "16px",
    ransition: "0.2s ease-in"
  },

  //styles for react upload component
  reactUpOuterDiv: {
    borderRadius: "18px",
    cursor: "pointer",
    textDecoration: "none",
    padding: "3px",
    transition: "0.2s ease-in"
  },
  reactUpInnerDiv: {
    borderRadius: "16px",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in",
    position: "relative",
    padding: "8px"
  },

  reactUpContainerDiv: {
    transition: "0.2s ease-in",
    width: "240px",
    height: "160px",
    borderRadius: "24px"
  },

  reactUpEditBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "32px",
    height: "32px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "16px",
    transition: "0.2s ease-in"
  }
};

export default style;
