import React, { useState } from "react";
import SrRefColour from "../../../ref/colours";
import TxtInter from "../../01_atoms/text/Inter";
import { SrCompIconExpandLess, SrCompIconExpandMore } from "../../01_atoms/icons";
import style from "./style";

// Note dropdown
const NoteDropdown = (props) => {
  const [hover, setHover] = useState(false);
  const [showText, setShowText] = useState(false);

  const Link = (props) => {
    const [linkHover, setLinkHover] = useState(false);

    // Dynamic styling
    const style2 = {
      textStyle: {
        ...style.textStyle,
        color: linkHover ? SrRefColour.Neutral2A : SrRefColour.Neutral1A,
        textDecoration: `underline ${linkHover ? SrRefColour.Neutral2A : SrRefColour.Neutral1A}`
      }
    };

    return (
      <div
        onMouseEnter={() => setLinkHover(true)}
        onMouseLeave={() => setLinkHover(false)}
        onClick={props.onClick}
        style={style.textWrap}
      >
        <TxtInter size={17.28} weight={300} content={props.content} style={style2.textStyle} />
      </div>
    );
  };

  // Dynamic styling
  const style2 = {
    outerDiv: {
      ...style.transition,
      width: "fit-content",
      maxWidth: props.breakpoint3
        ? "100%"
        : props.breakpoint2
        ? "348px"
        : `${(props.width - 24) / 2}px`,
      padding: "2px",
      border: hover ? "1px solid rgba(0,0,0,0.1)" : "1px solid rgba(0,0,0,0)",
      borderRadius: "14px"
    },
    textStyle: {
      ...style.paddingHor6,
      paddingBottom: props.link ? "6px" : "12px"
    }
  };

  return (
    <div>
      <div style={style2.outerDiv}>
        <div style={style.innerDiv}>
          <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => setShowText(!showText)}
            style={style.clickable}
          >
            <div style={style.dropdownWrap}>
              {/* Text on dropdown chip */}
              <TxtInter weight={500} size={17.28} content={`${props.text1}`} />

              {/* Dropdown */}
              <div style={style.marginLeft6}>
                <div style={style.icon}>
                  {showText ? (
                    <SrCompIconExpandLess size={24} />
                  ) : (
                    <SrCompIconExpandMore size={24} />
                  )}
                </div>
              </div>
            </div>

            {/* Text on clicking on dropdown */}
            {showText && (
              <div>
                <TxtInter
                  weight={300}
                  size={17.28}
                  content={`${props.text2}`}
                  style={style2.textStyle}
                />
                {props.onClick && <Link onClick={props.onClick} content={`${props.linkText}`} />}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteDropdown;
