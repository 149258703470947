import React, { useState } from "react";
import SrRefColour from "../../../../ref/colours";
import TxtInter from "../../text/Inter";
import { SrCompIconEdit } from "../../icons";
import style from "./style";

// Avatar for big image
const RectUpload = ({ width, height, pic, infographic, imageInputRef, onChange }) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.reactUpOuterDiv,
      backgroundColor: btnHover && SrRefColour.Neutral1C
    },
    innerDiv: {
      ...style.reactUpInnerDiv,
      border: `1px dotted ${SrRefColour.Neutral2B}`
    },
    containerDiv: {
      ...style.reactUpContainerDiv,
      width: `${width}px`,
      height: `${height}px`
    },
    editButton: {
      ...style.reactUpEditBtn,
      borderColor: btnHover ? SrRefColour.Neutral2A : SrRefColour.Neutral2B,
      backgroundColor: btnHover ? SrRefColour.Neutral1C : SrRefColour.Neutral0
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={() => {
        imageInputRef.current.click();
      }}
      style={style.clickable}
    >
      {/* Avatar */}
      <div style={style2.outerDiv}>
        <div style={style2.innerDiv}>
          <div style={style2.containerDiv}>
            {infographic && !pic ? (
              // If it is an infographic without an uploaded image
              <div style={style.centeredTextWrap}>
                <img
                  src={
                    pic
                      ? pic
                      : infographic
                      ? require("../../../../media/image_default.png")
                      : require("../../../../media/video_default.png")
                  }
                  width={width}
                  height={height}
                  alt={"Picture"}
                  style={style.borderRadius12}
                />

                {/* Overlay text */}
                <div style={style.centeredText}>
                  <TxtInter weight={600} size={21.6} content={"Your infographic"} />
                  <div style={style.marginTop6}>
                    <TxtInter
                      weight={400}
                      size={17.28}
                      content={
                        "Use this image to emphasize on your unique features, your product line, etc. Image should be less than 10mb"
                      }
                    />
                  </div>
                </div>
              </div>
            ) : !pic ? (
              // If it is not an infographic there is no thumnail
              <div style={style.centeredTextWrap}>
                <img
                  src={
                    pic
                      ? pic
                      : infographic
                      ? require("../../../../media/image_default.png")
                      : require("../../../../media/video_default.png")
                  }
                  width={width}
                  height={height}
                  alt={"Picture"}
                  style={style.borderRadius12}
                />

                {/* Overlay text */}
                <div style={style.centeredText2}>
                  <div style={style.marginTop6}>
                    <TxtInter
                      weight={400}
                      size={17.28}
                      content={"Image should be less than 10mb "}
                    />
                  </div>
                </div>
              </div>
            ) : (
              // If it is not an infographic and there is a thumnail
              <img
                src={
                  pic
                    ? pic
                    : infographic
                    ? require("../../../../media/image_default.png")
                    : require("../../../../media/video_default.png")
                }
                width={width}
                height={height}
                alt={"Picture"}
                style={style.borderRadius12}
              />
            )}
          </div>

          <div style={style.editWrap}>
            {/* Edit button */}
            <div style={style2.editButton}>
              {/* Input */}
              <input
                type="file"
                ref={imageInputRef}
                onChange={onChange}
                style={{ display: "none" }}
              />

              {/* Edit icon */}
              <SrCompIconEdit size={20} color={SrRefColour.Neutral2A} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RectUpload;
