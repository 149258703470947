const SrRefColour = {
  // Neutral colours
  Neutral0: "#FFFFFF", // rgba(255, 255, 255, 1)
  Neutral1A: "#000000", // rgba(0, 0, 0, 1)
  Neutral1B: "#EBEBEB", // rgba(0, 0, 0, 0.08)
  Neutral1C: "#F5F5F5", // rgba(0, 0, 0, 0.04)
  Neutral1D: "#FAFAFA", // rgba(0, 0, 0, 0.02)

  Neutral2A: "#999999", // rgba(153, 153, 153, 1)
  Neutral2B: "#C8C8C8", // rgba(153, 153, 153, 0.38)

  Neutral3: "#CCCCCC", // rgba(204, 204, 204, 1)
  Neutral4: "#DDDDDD", // rgba(221, 221, 221, 1)
  Neutral5: "#FFFBF7", // rgba(255, 251, 247, 1)

  // Task status colours
  StatusUnA: "#999999", // rgba(153, 153, 153, 1)
  StatusUnB: "#D8D8D8", // rgba(153, 153, 153, 0.38)
  StatusUnC: "#F5F5F5", // rgba(153, 153, 153, 0.1)
  StatusUnD: "#FBFBFB", // rgba(153, 153, 153, 0.04)

  StatusToA: "#666666", // rgba(102, 102, 102, 1)
  StatusToB: "#C5C5C5", // rgba(102, 102, 102, 0.38)
  StatusToC: "#F0F0F0", // rgba(102, 102, 102, 0.1)
  StatusToD: "#F9F9F9", // rgba(102, 102, 102, 0.04)

  StatusDoA: "#FFA600", // rgba(255, 160, 0, 1)
  StatusDoB: "#FFDD9E", // rgba(255, 160, 0, 0.38)
  StatusDoC: "#FFF6E5", // rgba(255, 160, 0, 0.1)
  StatusDoD: "#FFFBF5", // rgba(255, 160, 0, 0.04)

  StatusReA: "#1B88BA", // rgba(27, 136, 186, 1)
  StatusReB: "#A8D2E5", // rgba(27, 136, 186, 0.38)
  StatusReC: "#E8F3F8", // rgba(27, 136, 186, 0.1)
  StatusReD: "#F6FAFC", // rgba(27, 136, 186, 0.04)

  StatusCoA: "#35A72B", // rgba(53, 167, 43, 1)
  StatusCoB: "#B2DEAE", // rgba(53, 167, 43, 0.38)
  StatusCoC: "#EBF6EA", // rgba(53, 167, 43, 0.1)
  StatusCoD: "#F7FBF7", // rgba(53, 167, 43, 0.04)

  StatusInA: "#BA1B1B", // rgba(186, 27, 27, 1)
  StatusInB: "#E5A8A8", // rgba(186, 27, 27, 0.38)
  StatusInC: "#F8E8E8", // rgba(186, 27, 27, 0.1)
  StatusInD: "#FCF6F6", // rgba(186, 27, 27, 0.04)

  // Correct and wrong colours
  GreyA: "#999999", // StatusUnA
  GreyB: "#D8D8D8", // StatusUnB
  GreyC: "#F5F5F5", // StatusUnC
  GreyD: "#FBFBFB", // StatusUnD

  YellowA: "#FFA600", // StatusDoA
  YellowB: "#FFDD9E", // StatusDoA
  YellowC: "#FFF6E5", // StatusDoA
  YellowD: "#FFFBF5", // StatusDoA

  BlueA: "#1B88BA", // StatusReA
  BlueB: "#A8D2E5", // StatusReA
  BlueC: "#E8F3F8", // StatusReA
  BlueD: "#F6FAFC", // StatusReA

  GreenA: "#35A72B", // StatusCoA
  GreenB: "#B2DEAE", // StatusCoB
  GreenC: "#EBF6EA", // StatusCoC
  GreenD: "#F7FBF7", // StatusCoD

  RedA: "#BA1B1B", // StatusInA
  RedB: "#E5A8A8", // StatusInB
  RedC: "#F8E8E8", // StatusInC
  RedD: "#FCF6F6", // StatusInD
  RedE: "#FEFAFA", // rgba(186, 27, 27, 0.02)

  // Leadership and groundwork score colours
  ScoreLead: "#FFB342", // rgba(255, 179, 66, 1)
  ScoreGround: "#A6690D", // rgba(166, 105, 13, 1)

  // Goals tab bottom bar colours
  NavPastSelect: "#A6A6A6", // rgba(166, 166, 166, 1)
  NavPast: "#CCCCCC", // rgba(221, 221, 221, 1)
  NavPastDeselect: "#E5E5E5", // rgba(221, 221, 221, 0.5)

  NavNowSelect: "#3B7783", // rgba(59, 119, 131, 1)
  NavNow: "#A5D8E3", // rgba(165, 216, 227, 1)
  NavNowDeselect: "#D2EBF1", // rgba(165, 216, 227, 0.5)

  NavNextSelect: "#7DC0CF", // rgba(125, 192, 207, 1)
  NavNext: "#CEEDF4", // rgba(206, 237, 244, 1)
  NavNextDeselect: "#E7F6F9", // rgba(206, 237, 244, 0.5)

  // Skeleton colours
  boneColor: "#E8E8E8", // rgba(232, 232, 232, 1)
  highlightColor: "#F4F4F4", // rgba(244, 244, 244, 1)

  // Progress bar colours
  barOrange: "#ED8A17", // rgba(237, 138, 23, 1)
  barGreen: "#35A72B", // StatusCoA
  barGrey: "rgba(0, 0, 0, 0.38)", // "#D8D8D8"
  barLightGrey: "rgba(0, 0, 0, 0.04)", // "#FBFBFB"

  // Progress bar background colours
  barBgYellow: "#FFD05B", // rgba(255, 208, 91, 1)
  barBgLightBlue: "#C7E4F3", // rgba(199, 228, 243, 1)
  barBgOrange: "#FEC05B", // rgba(254, 192, 91, 1)
  barBgGrey: "#F5F5F5", // Neutral1C
  barBgLightGrey: "#FAFAFA", // Neutral1D
  barBgWhite: "#FFFFFF", // Neutral0

  // General colours
  gen: {
    yellowMunsell: {
      A: "#F1CD00", // rgba(241, 205, 0, 1)
      B: "#FAEC9E", // rgba(241, 205, 0, 0.38)
      C: "#FDF7D9", // rgba(241, 205, 0, 0.15)
      D: "#FEFCF0" // rgba(241, 205, 0, 0.06)
    },
    gamboge: {
      A: "#E89A16", // rgba(232, 154, 22, 1)
      B: "#F6D9A6", // rgba(232, 154, 22, 0.38)
      C: "#FCF0DC", // rgba(232, 154, 22, 0.15)
      D: "#FEF9F1" // rgba(232, 154, 22, 0.06)
    },
    flame: {
      A: "#DF662B", // rgba(233, 102, 43, 1)
      B: "#F3C5AE", // rgba(233, 102, 43, 0.38)
      C: "#FAE8DF", // rgba(233, 102, 43, 0.15)
      D: "#FDF6F2" // rgba(233, 102, 43, 0.06)
    },
    englishVermillion: {
      A: "#CA4046", // rgba(202, 64, 70, 1)
      B: "#EBB6B9", // rgba(202, 64, 70, 0.38)
      C: "#F7E2E3", // rgba(202, 64, 70, 0.15)
      D: "#FCF4F4" // rgba(202, 64, 70, 0.06)
    },
    sweetBrown: {
      A: "#A7393F", // rgba(167, 57, 63, 1)
      B: "#DEB4B6", // rgba(167, 57, 63, 0.38)
      C: "#F2E1E2", // rgba(167, 57, 63, 0.15)
      D: "#FAF3F3" // rgba(167, 57, 63, 0.06)
    },
    fuzzyWuzzy: {
      A: "#C56A66", // rgba(197, 106, 102, 1)
      B: "#E9C6C5", // rgba(197, 106, 102, 0.38)
      C: "#F6E9E8", // rgba(197, 106, 102, 0.15)
      D: "#FCF6F6" // rgba(197, 106, 102, 0.06)
    },
    vivdTangerine: {
      A: "#EA9C8E", // rgba(234, 156, 142, 1)
      B: "#F7D9D4", // rgba(234, 156, 142, 0.38)
      C: "#FCF0EE", // rgba(234, 156, 142, 0.15)
      D: "#FEF9F8" // rgba(234, 156, 142, 0.06)
    },
    mauvelous: {
      A: "#FB9DAC", // rgba(251, 157, 172, 1)
      B: "#FDDADF", // rgba(251, 157, 172, 0.38)
      C: "#FEF0F3", // rgba(251, 157, 172, 0.15)
      D: "#FFF9FA" // rgba(251, 157, 172, 0.06)
    },
    paradisePink: {
      A: "#D94F70", // rgba(217, 79, 112, 1)
      B: "#F1BCC9", // rgba(217, 79, 112, 0.38)
      C: "#F9E5EA", // rgba(217, 79, 112, 0.15)
      D: "#FDF4F6" // rgba(217, 79, 112, 0.06)
    },
    plum: {
      A: "#7F4A90", // rgba(217, 74, 144, 1)
      B: "#CEBAD5", // rgba(217, 74, 144, 0.38)
      C: "#ECE4EE", // rgba(217, 74, 144, 0.15)
      D: "#F7F4F8" // rgba(217, 74, 144, 0.06)
    },
    indigo: {
      A: "#440E8C", // rgba(68, 14, 140, 1)
      B: "#B8A3D3", // rgba(68, 14, 140, 0.38)
      C: "#E3DBEE", // rgba(68, 14, 140, 0.15)
      D: "#F4F1F8" // rgba(68, 14, 140, 0.06)
    },
    resolutionBlue: {
      A: "#0D2A82", // rgba(13, 42, 130, 1)
      B: "#A3AECF", // rgba(13, 42, 130, 0.38)
      C: "#DBDFEC", // rgba(13, 42, 130, 0.15)
      D: "#F0F2F7" // rgba(13, 42, 130, 0.06)
    },
    YInMnBlue: {
      A: "#3C57A1", // rgba(60, 87, 161, 1)
      B: "#B5BFDB", // rgba(60, 87, 161, 0.38)
      C: "#E2E6F1", // rgba(60, 87, 161, 0.15)
      D: "#F3F5F9" // rgba(60, 87, 161, 0.06)
    },
    carolinaBlue: {
      A: "#3394CC", // rgba(51, 148, 204, 1)
      B: "#B1D7EC", // rgba(51, 148, 204, 0.38)
      C: "#E0EFF7", // rgba(51, 148, 204, 0.15)
      D: "#F3F9FC" // rgba(51, 148, 204, 0.06)
    },
    cadetBlue: {
      A: "#489FAE", // rgba(72, 159, 174, 1)
      B: "#B9DBE0", // rgba(72, 159, 174, 0.38)
      C: "#E4F1F3", // rgba(72, 159, 174, 0.15)
      D: "#F4F9FA" // rgba(72, 159, 174, 0.06)
    },
    polishedPine: {
      A: "#5DAA90", // rgba(93, 170, 144, 1)
      B: "#C1DFD5", // rgba(93, 170, 144, 0.38)
      C: "#E7F2EE", // rgba(93, 170, 144, 0.15)
      D: "#F5FAF8" // rgba(93, 170, 144, 0.06)
    },
    seaGreen: {
      A: "#1A895A", // rgba(26, 137, 90, 1)
      B: "#A8D2C0", // rgba(26, 137, 90, 0.38)
      C: "#DDEDE6", // rgba(26, 137, 90, 0.15)
      D: "#F1F8F5" // rgba(26, 137, 90, 0.06)
    },
    androidGreen: {
      A: "#A6BC38", // rgba(166, 188, 56, 1)
      B: "#DDE6B3", // rgba(166, 188, 56, 0.38)
      C: "#F2F5E1", // rgba(166, 188, 56, 0.15)
      D: "#FAFBF3" // rgba(166, 188, 56, 0.06)
    }
  }
};

export default SrRefColour;
