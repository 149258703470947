import React, { useState } from "react";
import SrRefColour from "../../../../../ref/colours";
import TxtInter from "../../../../01_atoms/text/Inter";
import style from "./style";

const UserPanelBtn = ({ pressed, onClick, breakpoint3, userStartup, user }) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.userOuterDiv,
      borderColor: pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    },
    picWrap: {
      ...style.maxHeight39,
      marginLeft: userStartup ? "12px" : 0
    }
  };

  return (
    <div style={{ ...style.justifyContentCenter, ...style.marginHor6 }}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={onClick}
        style={style.clickable}
      >
        <div style={style2.outerDiv}>
          {/* Dont delete this condition. It might seem unnecessary but protects us from a weird .logo error */}
          {user && (
            // If the user is logged in
            <div style={style.logo}>
              {userStartup && (
                <>
                  {/* User org logo */}
                  <div style={style.maxHeight39}>
                    <img
                      src={userStartup.logo}
                      width={39}
                      alt={"User org logo"}
                      style={style.borderRadius39}
                    />
                  </div>

                  {/* Org name on screens larger than a phone */}
                  {!breakpoint3 && (
                    <TxtInter
                      size={17.28}
                      weight={400}
                      content={userStartup.name}
                      style={style.inputStyle}
                    />
                  )}
                </>
              )}

              {/* User pic */}
              <div style={style2.picWrap}>
                <img src={user.pic} width={39} alt={"User pic"} style={style.borderRadius39} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserPanelBtn;
