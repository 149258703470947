import React from "react";
import style from "./style";

const Popup = ({ scrollPosition, modal, noPadding, onClick, children }) => {
  //for dynamic styling
  const style2 = {
    outerDiv: {
      ...style.outerDiv,
      top: `${scrollPosition}px`,
      alignItems: modal ? "flex-end" : "center"
    },
    containerDiv: {
      ...style.containerDiv,
      padding: noPadding ? 0 : "24px",
      borderRadius: modal ? "12px 12px 0 0" : "12px"
    }
  };

  return (
    <div style={style2.outerDiv}>
      {/* Popup content */}
      <div style={style2.containerDiv}>{children}</div>

      {/* Blur effect */}
      <div onClick={onClick} style={style.blurContainer}></div>
    </div>
  );
};

export default Popup;
