const style = {
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  inlineTable: {
    display: "inline-table"
  },
  chip1: {
    width: "max-content",
    padding: "6px 12px",
    borderRadius: "24px"
  },
  chip2: {
    width: "max-content",
    padding: "6px 12px",
    borderRadius: "9px"
  },

  // Widths, heights and margins
  marginHor3: {
    marginLeft: "3px",
    marginRight: "3px"
  },
  marginBottom_4: {
    marginBottom: "-4px"
  },
  marginLeft6: {
    marginLeft: "6px"
  },
  marginLeft12: {
    marginLeft: "12px"
  },

  //custom style for this component

  //style for industry chip component
  industryOuterDiv: {
    width: "max-content",
    padding: "6px 12px",
    borderRadius: "24px",
    display: "inline-table",
    marginLeft: "3px",
    marginRight: "3px"
  },

  //style for sector chip component
  sectorOuterDiv: {
    width: "max-content",
    padding: "6px 12px",
    borderRadius: "9px",
    display: "inline-table",
    marginLeft: "3px",
    marginRight: "3px"
  },

  //style for sector chip component
  BITSOuterDiv: {
    // width: "max-content",
    padding: "6px 12px",
    borderRadius: "9px",
    display: "inline-table",
    marginLeft: "3px",
    marginRight: "3px"
  },

  //style for status chip component
  statusOuterDiv: {
    display: "flex",
    flexDirection: "row",
    width: "max-content",
    padding: "6px 12px",
    borderRadius: "9px"
  }
};

export default style;
