import React, { useState } from "react";

import SrRefColour from "../../../../../ref/colours";
import TxtInter from "../../../../01_atoms/text/Inter";

import { SrCompIconCalendarViewMonth, SrCompIconVisibility } from "../../../../01_atoms/icons";

import style from "./style";

// Event choice button
const EventChoiceBtn = ({ onClick, pressed, text, type }) => {
  const [btnHover, setBtnHover] = useState(false);

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={onClick}
      style={style.clickable}
    >
      <div
        style={{
          ...style.flexRow,
          ...style.alignItemsCenter,
          ...style.button3,
          ...style.transition,
          borderColor: btnHover || pressed ? SrRefColour.Neutral1A : SrRefColour.Neutral2A,
          backgroundColor: btnHover || pressed ? SrRefColour.Neutral1B : SrRefColour.Neutral1C
        }}
      >
        {/* Login text */}
        <TxtInter
          weight={400}
          size={14.4}
          content={`${text}`}
          state={"enabled"}
          style={style.colorBlack}
        />

        {/* Arrow icon */}
        <div style={{ ...style.icon20, ...style.marginLeft6 }}>
          {type === "invite" ? (
            <SrCompIconCalendarViewMonth size={20} />
          ) : (
            <SrCompIconVisibility size={20} />
          )}
        </div>
      </div>
    </div>
  );
};

export default EventChoiceBtn;
