import SrRefColour from "../../../ref/colours";

export const style = {
  outerMost: {
    position: "relative",
    width: "100vw",
    backgroundColor: SrRefColour.Neutral0
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  screen: {
    width: "100vw",
    maxWidth: "1600px",
    margin: "0 auto",
    backgroundColor: SrRefColour.Neutral0,
    overflowX: "hidden"
  },

  // Widths, heights and margins
  marginTop36: {
    marginTop: "36px"
  },

  outerWrap: {
    display: "flex",
    flexDirection: "column",
    width: "100vw",
    maxWidth: "1600px",
    margin: "0 auto",
    backgroundColor: SrRefColour.Neutral0,
    overflowX: "hidden"
  }
};
