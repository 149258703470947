import { useContext } from "react";

import SrRefColour from "../../../ref/colours";
import MainContext from "../../helpers/context/mainContext";

import EditBtn0 from "../../01_atoms/buttons/iconBtns/EditBtn0";
import Avatar from "../../01_atoms/avatar/Avatar";
import OrgName from "../../03_compounds/OrgName";
import OrgDescription from "../../03_compounds/OrgDescription";
import IndustryAndSectors from "../../03_compounds/IndustryAndSectors";
import style from "./style";

const OrgDetails = () => {
  const {
    ifEdit,
    breakpoint3,
    startup,
    setupNew,
    returnPrimary,
    setEdit1,
    editHover1,
    setEditHover1,
    setAllHoverFalse
  } = useContext(MainContext);

  //Dynamic styling for this component
  const style2 = {
    editOuterDiv: {
      ...style.editOuterDiv,
      backgroundColor: editHover1 && SrRefColour.Neutral1C
    },
    editInnerDiv: {
      ...style.editInnerDiv,
      border: `1px dotted ${SrRefColour.Neutral2B}`
    },

    editLogoWrap: {
      ...style.largeEditInnerDiv,
      border: `1px dotted ${SrRefColour.Neutral2B}`
    },

    //Dynamic styling for this component on large screen
    largeEditOuterDiv: {
      ...style.largeEditOuterDiv,
      backgroundColor: editHover1 && SrRefColour.Neutral1C
    },

    editButton: {
      position: "absolute",
      top: "8px",
      right: "8px"
    }
  };

  return breakpoint3 ? (
    <div
      onMouseEnter={() => ifEdit && [setAllHoverFalse(), setEditHover1(true)]}
      onMouseLeave={() => ifEdit && setEditHover1(false)}
      style={ifEdit ? style2.editOuterDiv : null}
    >
      <div style={ifEdit ? style2.editInnerDiv : style.flexColumn}>
        {/* Org logo */}
        <div style={ifEdit ? style.paddingHor8 : style.marginHor24}>
          <Avatar
            width={95}
            pic={startup.logo ? startup.logo : require("../../../media/no_org.png")}
            borderColor={returnPrimary}
          />
        </div>

        <div style={style.flexColumn}>
          {/* Org name */}
          <div
            style={ifEdit ? style.editOrgNameWrap : style.orgNameWrap}
            onClick={() => ifEdit && setEdit1(1)}
          >
            <OrgName />
          </div>

          {/* Org description */}
          <div
            style={ifEdit ? style.editOrgDescWrap : style.orgDescWrap}
            onClick={() => ifEdit && setEdit1(2)}
          >
            <OrgDescription />
          </div>

          {/* Org industry and sectors */}
          <div style={ifEdit ? style.editIndustryChipWrap : null}>
            <div style={style.industryChipWrap}>
              <IndustryAndSectors />
            </div>
          </div>
        </div>

        {/* Edit btn visible only on edit state */}
        {ifEdit && (
          <div style={style2.editButton} onClick={() => setEdit1(-1)}>
            <EditBtn0 selected={editHover1} />
          </div>
        )}
      </div>
    </div>
  ) : (
    <div
      onMouseEnter={() => ifEdit && [setAllHoverFalse(), setEditHover1(true)]}
      onMouseLeave={() => ifEdit && setEditHover1(false)}
      style={ifEdit ? style2.largeEditOuterDiv : null}
    >
      <div style={ifEdit ? style2.editLogoWrap : style.logoWrap}>
        {/* Org logo */}
        <Avatar
          width={122}
          pic={
            setupNew.addOrg.logo
              ? setupNew.addOrg.logo
              : startup.logo
              ? startup.logo
              : require("../../../media/no_org.png")
          }
          borderColor={returnPrimary}
          onClick={() => ifEdit && setEdit1(-1)}
        />

        <div style={style.orgWrap}>
          {/* Org name */}
          <div style={style.marginHor24} onClick={() => ifEdit && setEdit1(1)}>
            <OrgName />
          </div>

          {/* Org description */}
          <div style={style.marginHor24} onClick={() => ifEdit && setEdit1(2)}>
            <OrgDescription />
          </div>

          {/* Org industry and sectors */}
          <div style={style.largeIndustryChipWrap}>
            <div style={ifEdit ? style.marginHor21 : null}>
              <IndustryAndSectors />
            </div>
          </div>
        </div>

        {ifEdit && (
          <div style={style2.editButton} onClick={() => setEdit1(-1)}>
            <EditBtn0 selected={editHover1} />
          </div>
        )}
      </div>
    </div>
  );
};

export default OrgDetails;
