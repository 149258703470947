import SrRefColour from "../../../ref/colours";

const style = {
  outerMost: {
    position: "relative",
    width: "100vw",
    backgroundColor: SrRefColour.Neutral0
  }
};

export default style;
