import SrRefColour from "../../../ref/colours";

export const style = {
  outerMost: {
    position: "relative",
    width: "100vw",
    backgroundColor: SrRefColour.Neutral0
  },
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  alignItemsCenter: {
    display: "flex",
    alignItems: "center"
  },
  justifyContentCenter: {
    display: "flex",
    justifyContent: "center"
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between"
  },
  scrollBox: {
    position: "relative",
    minHeight: "510px"
  },
  screen: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100vw",
    minHeight: "100vh",
    maxWidth: "1600px",
    margin: "0 auto",
    backgroundColor: SrRefColour.Neutral0,
    overflowX: "hidden"
  },
  surfaceBorder: {
    borderRadius: "12px",
    backgroundColor: SrRefColour.Neutral0
  },
  scrollWrap: {
    display: "flex",
    flexDirection: "row",
    overflow: "auto"
  },
  scroll: {
    display: "inline-flex"
  },
  resendOTP: {
    width: "fit-content",
    borderWidth: "0 0 1px",
    borderStyle: "solid"
  },
  borderTop: {
    borderWidth: "0.5px 0 0",
    borderStyle: "solid",
    borderColor: SrRefColour.Neutral2A
  },
  textAlignCenter: {
    textAlign: "center"
  },
  clickable: {
    width: "fit-content",
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },
  transition: {
    transition: "0.2s ease-in"
  },
  colorBlack: { color: SrRefColour.Neutral1A },
  customPadding: {
    paddingTop: "18px",
    paddingBottom: "24px",
    paddingLeft: "12px",
    paddingRight: "24px"
  },
  paddingTop24: { paddingTop: "24px" },
  paddingHor24: {
    paddingLeft: "24px",
    paddingRight: "24px"
  },

  // Widths, heights and margins
  width100: { width: "100%" },
  marginTop6: { marginTop: "6px" },
  marginTop12: { marginTop: "12px" },
  marginTop18: { marginTop: "18px" },
  marginTop24: { marginTop: "24px" },
  marginTop48: { marginTop: "48px" },
  marginBot24: { marginBottom: "24px" },
  marginBot72: { marginBottom: "72px" },
  marginHor24: {
    marginLeft: "24px",
    marginRight: "24px"
  },
  marginLeft12: {
    marginLeft: "12px"
  }
};
