import SrRefColour from "../../../../ref/colours";

export const style = {
  alignItemsCenter: {
    display: "flex",
    alignItems: "center"
  },
  scrollBox: {
    position: "relative",
    minHeight: "510px"
  },
  surfaceBorder: {
    borderRadius: "12px",
    backgroundColor: SrRefColour.Neutral0
  },
  scroll: {
    display: "inline-flex"
  },
  colorBlack: { color: SrRefColour.Neutral1A },
  dotArray: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  paddingHor24: {
    paddingLeft: "24px",
    paddingRight: "24px"
  },

  // Widths, heights and margins
  marginTop18: { marginTop: "18px" },
  titleWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  logoWrap: {
    height: "50px",
    marginTop: "48px",
    marginBottom: "72px"
    // ...style.marginHor24
  },
  eventOuterDiv: {
    height: "50px",
    marginTop: "48px",
    marginBottom: "72px",
    marginLeft: "24px",
    marginRight: "24px"
  },
  scrollVViewWrap: {
    display: "flex",
    flexDirection: "row",
    overflow: "hidden",
    marginTop: "48px",
    height: "316px"
  },
  imageUploadWrap: {
    display: "flex",
    justifyContent: "center",
    marginTop: "24px"
  },
  coverUploadWrap: {
    display: "flex",
    justifyContent: "center",
    marginTop: "24px"
  }
};
