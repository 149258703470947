import React, { useState } from "react";
import SrRefColour from "../../../../ref/colours";
// import { SrRefTextIntRe10 } from "../../../../ref/text";
import TxtInter from "../../text/Inter";
import style from "./style";

// List popup for font Int12
const ListPopupInt12 = ({
  array,
  value,
  setValue,
  setValueSelect,
  setStartup,
  startup,
  setupIndustry,
  setupStage,
  orgStatus,
  orgYear,
  setupStatus,
  socialMedia,
  location
}) => {
  // List item for font Int12
  const ListItemInt12 = (params) => {
    const [inputHover, setInputHover] = useState(false);

    // Dynamic styling for this component
    const style2 = {
      innerDiv: {
        ...style.list,
        ...style.marginHor12,
        paddingBottom: params.id !== params.array.length - 1 ? 0 : "6px",
        borderWidth: params.id !== params.array.length - 1 ? "0 0 0.5px" : 0
      },
      inputStyle: {
        ...style.marginBot12,
        color:
          params.array[params.value] === params.ind || inputHover
            ? SrRefColour.Neutral1A
            : SrRefColour.Neutral2A
      }
    };

    return (
      <div
        onMouseEnter={() => setInputHover(true)}
        onMouseLeave={() => setInputHover(false)}
        onClick={() => {
          // +1 because array starts from 0 and industry are stored from 1 in db
          let value = params.setupIndustry ? params.id + 1 : params.id;
          params.setValue(value);
          params.setValueSelect(false);
          // If setting up startup industry through the setup guide
          params.setupIndustry &&
            params.setStartup({
              ...params.startup,
              industry: params.id + 1 // +1 becuase it is the index of array and we need to store the id from industry which starts from 0
            });
          // If setting up startup stage through the setup guide
          params.setupStage &&
            params.setStartup({
              ...params.startup,
              other: {
                ...params.startup.other,
                location: params.location,
                year: params.orgYear,
                stage: params.id,
                socialMedia: params.socialMedia,
                status: params.orgStatus
              }
            });

          params.setupStatus &&
            params.setStartup({
              ...params.startup,
              other: {
                ...params.startup.other,
                status: params.id
              }
            });
        }}
        style={style.outerDiv}
        key={params.id}
      >
        <div style={style2.innerDiv}>
          {/* <SrRefTextIntRe10 content={params.ind} state={"enabled"} style={style2.inputStyle} /> */}
          <TxtInter
            size={14.4}
            weight={400}
            content={params.ind}
            state={"enabled"}
            style={style2.inputStyle}
          />
        </div>
      </div>
    );
  };

  return (
    <div style={style.scrollWrapV}>
      {array.map((ind, id) => (
        <ListItemInt12
          ind={ind}
          id={id}
          array={array}
          value={value}
          setValue={setValue}
          setValueSelect={setValueSelect}
          // Useful for editing in the setup guide
          setStartup={setStartup}
          startup={startup}
          // If setting up startup industry through the setup guide
          setupIndustry={setupIndustry}
          // If setting up startup stage through the setup guide
          setupStage={setupStage}
          setupStatus={setupStatus}
          location={location}
          orgYear={orgYear}
          socialMedia={socialMedia}
          orgStatus={orgStatus}
        />
      ))}
    </div>
  );
};

export default ListPopupInt12;
