const Startups = [
  {
    id: 0,
    name: "Minimalistic",
    description: "Your brand’s merchandise store, made easy",
    logo: require("../../media/startups/minimalistic_logo.png"),
    theme: {
      primary: { color: "rgba(54,162,218,0.4" },
      secondary: { color: "rgba(103,189,72,0.4" },
      tertiary: { color: "rgba(233,35,62,0.4" },
      accent: { color: "" },
      invertBackground: false
    },
    industry: "Manufacturing",
    sectors: ["Branding", "Clothing and accessories"],
    team: [
      {
        id: 4,
        fname: "Karna",
        lname: "Nanda",
        designation: "Founder, CEO",
        pic: require("../../media/startups/minimalistic_lead_1.png"),
        linkedIn: "https://www.linkedin.com/in/karna-nanda/",
        orgId: 1,
        orgLead: true,
        representer: true
      },
      {
        id: 5,
        pic: require("../../media/startups/minimalistic_lead_2.png"),
        orgId: 1,
        orgLead: true,
        representer: false
      }
    ],
    teamSize: 3,
    other: {
      year: 2021,
      location: "Mumbai",
      zaubacorp:
        "https://www.zaubacorp.com/company/MINIMALISTIC-GARMENTS-EXPORTS-PRIVATE-LIMITED/U18209MH2018PTC313992",
      stage: 3,
      socialMedia: {
        discord: "",
        facebook: "",
        instagram: "https://www.instagram.com/mr_minimalisticg/",
        linkedIn: "",
        medium: "",
        pinterest: "",
        reddit: "",
        substack: "",
        twitter: "",
        whatsapp: "",
        youtube: ""
      },
      status: "Building tech"
    },
    pitch: {
      problem:
        "Brands that wish to set up their own store know little about manufacturing\n\nMiddlemen make it expensive. Quality assurance and delivery is another hassle",
      solution:
        "We manufacture premium quality brand merchandise at pocket friendly prices, without any hassles\n\nMinimalistic is branding done easier",
      image: require("../../media/startups/minimalistic_info.png"),
      video: {
        link: "https://www.youtube.com/shorts/AeIDnPx1pDw",
        thumbnail: require("../../media/startups/minimalistic_video.png")
      },
      website: "https://puntastik.com/",
      features: "• Variety of brand merchandise\n• Premium quality\n• Budget-friendly",
      unique:
        "• Can manufacture a single piece\n• One day dispatch\n• Set up your store and we will deliver",
      market: "• Creative professionals\n• Startups\n• Local stores"
    }
  },
  {
    id: 1,
    name: "Tactic",
    description: "Trade like the top 1%",
    logo: require("../../media/startups/tactic_logo.png"),
    theme: {
      primary: { color: "rgba(0,0,0,1)" },
      secondary: { color: "rgba(140,189,14,1)" },
      tertiary: { color: "" },
      accent: { color: "" },
      invertBackground: false
    },
    industry: "Financial Services",
    sectors: ["Stock trading", "Advisory"],
    team: [
      {
        id: 6,
        fname: "Parvez",
        lname: "Dosani",
        designation: "Co-founder, CEO",
        pic: require("../../media/startups/tactic_lead_1.png"),
        linkedIn: "https://www.linkedin.com/in/parvezdosani/",
        orgId: 2,
        orgLead: true,
        representer: true
      },
      {
        id: 7,
        pic: require("../../media/startups/tactic_lead_2.png"),
        orgId: 2,
        orgLead: true,
        representer: false
      },
      {
        id: 8,
        pic: require("../../media/startups/tactic_lead_3.png"),
        orgId: 2,
        orgLead: true,
        representer: false
      }
    ],
    teamSize: 4,
    other: {
      year: 2022,
      location: "Hyderabad",
      zaubacorp: "",
      stage: 1,
      socialMedia: {
        discord: "",
        facebook: "",
        instagram: "",
        linkedIn: "https://www.linkedin.com/company/finaveapp/",
        medium: "",
        pinterest: "",
        reddit: "",
        substack: "",
        twitter: "",
        whatsapp: "",
        youtube: ""
      },
      status: "Testing app"
    },
    pitch: {
      problem:
        "Less than 1% of traders make money\n\nTrading courses are expensive. Advisory lacks authenticity and transparency making trading a poor experience",
      solution:
        "A mobile app where trade ideas by top SEBI registered experts are personalized for users to follow\n\nTactic makes trading passive",
      image: require("../../media/startups/tactic_info.png"),
      video: { link: "", thumbnail: "" },
      website: "https://www.tactic.money/",
      features:
        "• Intraday and positional trade ideas by SEBI experts\n• An inclusive dashboard for users\n• Create a closed community of traders",
      unique:
        "• Hybrid approach while picking trade ideas (algorithm+manual)\n• Personalised approach for users (position sizing+ideas)",
      market: "• Trading communities\n• 21-27 year olds\n• Interested in generating passive income"
    }
  },
  {
    id: 2,
    name: "DocTrue",
    description: "One stop solution to your clinic wait time",
    logo: require("../../media/startups/doctrue_logo.png"),
    theme: {
      primary: { color: "rgba(36,164,222,0.4)" },
      secondary: { color: "rgba(50,100,202,0.4)" },
      tertiary: { color: "" },
      accent: { color: "" },
      invertBackground: false
    },
    industry: "Healthcare",
    sectors: ["Queue management", "Appointment booking"],
    team: [
      {
        id: 9,
        fname: "Akshay",
        lname: "Ramesh",
        designation: "Co-founder, CEO",
        pic: require("../../media/startups/doctrue_lead_1.png"),
        linkedIn: "https://www.linkedin.com/in/akshayrameshh/",
        orgId: 3,
        orgLead: true,
        representer: true
      },
      {
        id: 10,
        pic: require("../../media/startups/doctrue_lead_2.png"),
        orgId: 3,
        orgLead: true,
        representer: false
      }
    ],
    teamSize: 4,
    other: {
      year: 2022,
      location: "Bengaluru",
      zaubacorp:
        "https://www.zaubacorp.com/company/DOCTRUE-TECHNOLOGIES-PRIVATE-LIMITED/U72900KA2022PTC167367",
      stage: 1,
      socialMedia: {
        discord: "",
        facebook: "",
        instagram: "",
        linkedIn: "https://www.linkedin.com/company/doctrue/",
        medium: "",
        pinterest: "",
        reddit: "",
        substack: "",
        twitter: "",
        whatsapp: "",
        youtube: ""
      },
      status: "Prototyping"
    },
    pitch: {
      problem:
        "On an average, a patient waits for more than 30-40 minutes for a doctor consultation that lasts 5-10 minutes\n\n85% of patients find this to be frustrating",
      solution:
        "A mobile app helps patients book appointments and avoid long waiting periods in the OPD using our virtual live queue system",
      image: require("../../media/startups/doctrue_info.png"),
      video: {
        link: "https://www.youtube.com/watch?v=4uw3ejvO1Zc&ab_channel=DocTrue",
        thumbnail: require("../../media/startups/doctrue_video.png")
      },
      website: "",
      features:
        "• Simple appointment bookings\n• Live update on doctor delays\n• Real-time clinic status, in-clinic patients updates, etc",
      unique: "• Automated virtual queue system\n• Live updates about the clinic and doctor",
      market:
        "• Doctors with higher volume of patients\n• 25-45+ olds, IT professionals\n• Individual doctors or clinic practioners"
    }
  },
  {
    id: 3,
    name: "Solocl",
    description: "AI-powered site search for India",
    logo: require("../../media/startups/solocl_logo.png"),
    theme: {
      primary: { color: "rgba(87,50,128,0.4)" },
      secondary: { color: "rgba(249,191,26,0.4)" },
      tertiary: { color: "" },
      accent: { color: "" },
      invertBackground: false
    },
    industry: "B2B SaaS",
    sectors: ["Search", "Machine learning"],
    team: [
      {
        id: 11,
        fname: "Tanmay",
        lname: "Dhote",
        designation: "Co-founder, CEO",
        pic: require("../../media/startups/solocl_lead_1.png"),
        linkedIn: "https://www.linkedin.com/in/tanmay-dhote-a4560367/",
        orgId: 4,
        orgLead: true,
        representer: true
      },
      {
        id: 12,
        pic: require("../../media/startups/solocl_lead_2.png"),
        orgId: 4,
        orgLead: true,
        representer: false
      }
    ],
    teamSize: 4,
    other: {
      year: 2022,
      location: "Nagpur",
      zaubacorp:
        "https://www.zaubacorp.com/company/SOLOCL-TECHNOLOGIES-PRIVATE-LIMITED/U72900MH2021PTC362640",
      stage: 1,
      socialMedia: {
        discord: "",
        facebook: "",
        instagram: "",
        linkedIn: "https://www.linkedin.com/company/solocl/",
        medium: "",
        pinterest: "",
        reddit: "",
        substack: "https://discoveryunlocked.substack.com",
        twitter: "https://twitter.com/soloclsearch",
        whatsapp: "",
        youtube: ""
      },
      status: "Building with users"
    },
    pitch: {
      problem:
        "For 89% Indian users, search experiences on websites/apps are alienating since they are predominantly in English\n\nBuilding search in-house is technically complex, costly and time-consuming",
      solution:
        "We build search as a service that allows users to search with voice, natural language, and phonetically spelled words across multiple Indian languages",
      image: require("../../media/startups/solocl_info.png"),
      video: {
        link: "https://www.youtube.com/watch?v=mBpVmSFOoN0&ab_channel=Solocl",
        thumbnail: require("../../media/startups/solocl_video.png")
      },
      website: "https://www.solocl.com/",
      features: "• High spelling error-tolerance\n• Voice search\n• Easy to integrate",
      unique: "• Multi-language support\n• Handles natural language queries",
      market:
        "• Early to mid-stage startups\n• Marketplaces/media domains\n• Companies building for “Bharat”"
    }
  },
  {
    id: 4,
    name: "Bomlife",
    description: "Chemical-free high yield agriculture",
    logo: require("../../media/startups/bomlife_logo.png"),
    theme: {
      primary: { color: "rgba(255,122,6,0.4)" },
      secondary: { color: "rgba(88,172,63,0.4)" },
      tertiary: { color: "" },
      accent: { color: "" },
      invertBackground: false
    },
    industry: "Agriculture",
    sectors: ["Fertilizers", "Organic"],
    team: [
      {
        id: 13,
        fname: "Amlan",
        lname: "Datta",
        designation: "Co-founder, CEO",
        pic: require("../../media/startups/bomlife_lead_1.png"),
        linkedIn: "https://www.linkedin.com/in/dattaamlan/",
        orgId: 5,
        orgLead: true,
        representer: true
      },
      {
        id: 14,
        pic: require("../../media/startups/bomlife_lead_2.png"),
        orgId: 5,
        orgLead: true,
        representer: false
      }
    ],
    teamSize: 7,
    other: {
      year: 2020,
      location: "Kolkata",
      zaubacorp: "https://www.zaubacorp.com/company/BOMLIFE-PRIVATE-LIMITED/U74999WB2020PTC242257",
      stage: 3,
      socialMedia: {
        discord: "",
        facebook: "https://www.facebook.com/bomlife.in",
        instagram: "https://www.instagram.com/bomlife_hitech/",
        linkedIn: "",
        medium: "",
        pinterest: "",
        reddit: "",
        substack: "",
        twitter: "",
        whatsapp: "",
        youtube: "https://www.youtube.com/channel/UCEQYlSp2fTIUa8zUNNOYwWA"
      },
      status: "Expanding distribution"
    },
    pitch: {
      problem:
        "Organic fertilizers cannot supply nutrients to support commercial yield demands\n\nFarmers do not have access to bio-organic solutions to mitigate pests or diseases",
      solution:
        "IP protected formulation of powerful strains of micro-organisms that:\n1. Fixes nitrogen,\n2. Solubilizes phosphorous, and\n3. Mobilizes potassium",
      image: require("../../media/startups/bomlife_info.png"),
      video: {
        link: "https://www.youtube.com/watch?v=xy9okE8tGFE&ab_channel=BomLife",
        thumbnail: require("../../media/startups/bomlife_video.png")
      },
      website: "https://bomlife.in/index.html",
      features:
        "• Enhancement of organic carbon\n• Reduction of soil-borne pathogens\n• Higher water retention\n• Nutritious food for consumers",
      unique:
        "• Enrichment of microbial population\n• 1.5 times the standard organic yield\n• Lower production cost in 2-3 months\n• Toxin-free life for farmers",
      market: "• Focused on sugarcane, paddy and tea\n• Can provide for any crop in the future"
    }
  },
  {
    id: 5,
    name: "Shram",
    description: "Work management for startups",
    logo: require("../../media/startups/shram_logo.png"),
    theme: {
      primary: { color: "rgba(36,89,137,0.4)" },
      secondary: { color: "rgba(255,181,0,0.4)" },
      tertiary: { color: "" },
      accent: { color: "" },
      invertBackground: false
    },
    industry: "B2B SaaS",
    sectors: ["Work management", "Platform"],
    team: [
      {
        id: 0,
        fname: "Jay",
        lname: "Gadekar",
        designation: "Co-founder, CEO",
        pic: require("../../media/startups/shram_lead_1.png"),
        linkedIn: "https://www.linkedin.com/in/jaygadekar/",
        orgId: 0,
        orgLead: true,
        representer: true
      },
      {
        id: 1,
        pic: require("../../media/startups/shram_lead_2.png"),
        orgId: 0,
        orgLead: true,
        representer: false
      },
      {
        id: 2,
        pic: require("../../media/startups/shram_lead_3.png"),
        orgId: 0,
        orgLead: true,
        representer: false
      },
      {
        id: 3,
        pic: require("../../media/startups/shram_lead_4.png"),
        orgId: 0,
        orgLead: true,
        representer: false
      }
    ],
    teamSize: 8,
    other: {
      year: 2021,
      location: "Mumbai",
      zaubacorp:
        "https://www.zaubacorp.com/company/SHRAM-INSIGHTS-PRIVATE-LIMITED/U72200MH2021PTC372737",
      stage: 2,
      socialMedia: {
        discord: "",
        facebook: "",
        instagram: "https://www.instagram.com/shram.io/",
        linkedIn: "https://www.linkedin.com/company/77073721/",
        medium: "https://medium.com/@shram.io",
        pinterest: "",
        reddit: "",
        substack: "",
        twitter: "",
        whatsapp: "",
        youtube: ""
      },
      status: "Onboarding users"
    },
    pitch: {
      problem:
        "Early-stage startups lack a robust organizational framework\n\nHence, they struggle to maintain momentum",
      solution:
        "A mobile-first tool that helps startups:\n1. Organize their thoughts,\n2. Plan their goals, and\n3. Inspire their team\n\nShram is work management for startups",
      image: require("../../media/startups/shram_info.png"),
      video: {
        link: "https://www.youtube.com/watch?v=ZILZF4b0R1Y&ab_channel=OjasvikaSahu",
        thumbnail: require("../../media/startups/shram_video.png")
      },
      website: "https://www.shram.io/",
      features:
        "• Establish your organization virtually\n• Create cross-functional teams\n• Make a tree-map of your goals\n • Delegate responsibilities to your team",
      unique:
        "• Adopt industry best practices\n• Overview both your team and work\n• Get unique insights for improvement\n• Be noticed for maintaining momentum",
      market: "• Scalable startups\n• Team size < 15\n• Ideation/validation stage"
    }
  },
  {
    id: 6,
    name: "Nyahari",
    description: "Serving healthy nutrition to everyone",
    logo: require("../../media/startups/nyahari_logo.png"),
    theme: {
      primary: { color: "rgba(226,93,38,0.4)" },
      secondary: { color: "rgba(250,228,32,0.4)" },
      tertiary: { color: "" },
      accent: { color: "" },
      invertBackground: false
    },
    industry: "Food/Beverage",
    sectors: ["Online food delivery", "Healthy food"],
    team: [
      {
        id: 17,
        fname: "Rutuja",
        lname: "Bhagwat",
        designation: "Co-founder",
        pic: require("../../media/startups/nyahari_lead_1.png"),
        linkedIn: "https://www.linkedin.com/in/rutuja-bhagwat-jain-b8b88a1ab/",
        orgId: 6,
        orgLead: true,
        representer: true
      },
      {
        id: 18,
        pic: require("../../media/startups/nyahari_lead_2.png"),
        orgId: 6,
        orgLead: true,
        representer: false
      }
    ],
    teamSize: 4,
    other: {
      year: 2022,
      location: "Pune",
      zaubacorp: "",
      stage: 1,
      socialMedia: {
        discord: "",
        facebook: "",
        instagram: "https://instagram.com/nyahari_pune?igshid=YmMyMTA2M2Y=",
        linkedIn: "https://www.linkedin.com/company/nyahari/",
        medium: "",
        pinterest: "",
        reddit: "",
        substack: "",
        twitter: "",
        whatsapp: "",
        youtube: ""
      },
      status: "Building a customer base"
    },
    pitch: {
      problem:
        "Despite breakfast being an important part of the diet, it remains unattended by majority of people",
      solution:
        "Order customised healthy and homemade breakfast at your doorstep everyday\n\nNyahari is the daily breakfast you need but ignore",
      image: require("../../media/startups/nyahari_info.png"),
      video: {
        link: "https://www.youtube.com/watch?v=Qb_vWqMSho0&ab_channel=RutujaBhagwat",
        thumbnail: require("../../media/startups/nyahari_video.png")
      },
      website: "https://www.nyahari.com/",
      features:
        "• Order on WhatsApp/website\n• Choose from various healthy dishes\n• Delivered on time\n• Really affordable",
      unique: "• Homemade food\n• Customised dishes\n• Pre-scheduling as an option",
      market: "• Corporates\n• Sports communities\n• Health freaks\n• Currently delivering in Pune"
    }
  },
  {
    id: 7,
    name: "Serri",
    description: "Google Assistant for communities",
    logo: require("../../media/startups/serri_logo.png"),
    theme: {
      primary: { color: "rgba(19,74,146,0.4)" },
      secondary: { color: "rgba(67,180,231,0.4)" },
      tertiary: { color: "" },
      accent: { color: "" },
      invertBackground: false
    },
    industry: "B2B SaaS",
    sectors: ["Community", "Machine learning"],
    team: [
      {
        id: 21,
        fname: "Saad",
        lname: "Jamal",
        designation: "Founder, CEO",
        pic: require("../../media/startups/serri_lead_1.png"),
        linkedIn: "https://www.linkedin.com/in/saad-jamal/",
        orgId: 9,
        orgLead: true,
        representer: true
      },
      {
        id: 22,
        pic: require("../../media/startups/serri_lead_2.png"),
        orgId: 9,
        orgLead: true,
        representer: false
      },
      {
        id: 23,
        pic: require("../../media/startups/serri_lead_3.png"),
        orgId: 9,
        orgLead: true,
        representer: false
      },
      {
        id: 24,
        pic: require("../../media/startups/serri_lead_4.png"),
        orgId: 9,
        orgLead: true,
        representer: false
      }
    ],
    teamSize: 7,
    other: {
      year: 2022,
      location: "New-Delhi",
      zaubacorp:
        "https://www.zaubacorp.com/company/SERRI-TECH-LABS-PRIVATE-LIMITED/U74999UR2022PTC014830",
      stage: 2,
      socialMedia: {
        discord: "",
        facebook: "",
        instagram: "",
        linkedIn: "https://www.linkedin.com/company/serri/",
        medium: "",
        pinterest: "",
        reddit: "",
        substack: "",
        twitter: "https://twitter.com/serri_club",
        whatsapp: "",
        youtube: ""
      },
      status: "Raising funds"
    },
    pitch: {
      problem:
        "3.8 billion people i.e. 76% of internet users participate in 50 million online communities\n\nNonetheless, community members feel disconnected & community managers are clueless",
      solution:
        "1. Serrch helps you discover & connect with most interesting folks in your communities\n\n2. SerriOS is the coolest AI-powered insights & outreach app to help you engage, grow & monetise your community across multiple platforms",
      image: require("../../media/startups/serri_info.png"),
      video: {
        link: "https://www.youtube.com/watch?v=suFiF0qvo6Q&ab_channel=SaadJamal",
        thumbnail: require("../../media/startups/serri_video.png")
      },
      website: "https://serri.club/",
      features:
        "• Discover relevant members efficiently\n• Personalise community outreach at scale\n• Insights and analytics dashboard",
      unique:
        "• Multi-platform chatbot solutions\n• No apps to be downloaded or websites to be visited for members\n• Real-time interest-based insights about community members",
      market: "• Well-funded web3 communities\n• > 250 members"
    }
  },
  {
    id: 8,
    name: "Volume",
    description: "Bite-sized visual publications",
    logo: require("../../media/startups/volume_logo.png"),
    theme: {
      primary: { color: "rgba(0,0,0,1)" },
      secondary: { color: "rgba(255,224,66,1)" },
      tertiary: { color: "" },
      accent: { color: "rgba(255,224,66,1)" },
      invertBackground: false
    },
    industry: "Media",
    sectors: ["Digital publishing", "Personal development"],
    team: [
      {
        id: 19,
        fname: "Jonah",
        lname: "Eapen",
        designation: "Co-founder, CEO",
        pic: require("../../media/startups/volume_lead_1.png"),
        linkedIn: "https://www.linkedin.com/in/jonaheapen/",
        orgId: 8,
        orgLead: true,
        representer: true
      },
      {
        id: 20,
        pic: require("../../media/startups/volume_lead_2.png"),
        orgId: 8,
        orgLead: true,
        representer: false
      }
    ],
    teamSize: 9,
    other: {
      year: 2021,
      location: "Hyderabad",
      zaubacorp:
        "https://www.zaubacorp.com/company/VOLUME-STORIES-PRIVATE-LIMITED/U72900TG2021PTC148363",
      stage: 2,
      socialMedia: {
        discord: "",
        facebook: "",
        instagram: "https://www.instagram.com/volume_hq/",
        linkedIn: "https://www.linkedin.com/company/volumestories/",
        medium: "",
        pinterest: "",
        reddit: "",
        substack: "",
        twitter: "",
        whatsapp: "",
        youtube: ""
      },
      status: "Trying monetization"
    },
    pitch: {
      problem:
        "Extracting insights from podcasts, books and videos is time consuming\n\nFinding information that matters is like finding a needle in a haystack",
      solution:
        "Building a mobile-first content experience that is:\n1. Bite-sized: consume content on the go,\n2. Curated: cuts away the noise,\n3. Holistic: one stop platform to stay relevant",
      image: require("../../media/startups/volume_info.png"),
      video: {
        link: "https://www.youtube.com/watch?v=ZaIPNnBKe00&ab_channel=TykeInvest",
        thumbnail: require("../../media/startups/volume_video.png")
      },
      website: "https://www.volumestories.com/",
      features:
        "• Personalise your interests\n• Read or listen to content you love\n• Bookmark or save for later for offline reading\n• Subscribe to your favourite publications",
      unique:
        "• Authorised partners create content\n• Hand picked and curated\n• Visual and interactive\n• Build habits and win meaningful rewards",
      market:
        "• College students and working gen-z\n• Interested in leadership, personal development and entrepreneurship\n• B2B: Early stage startup teams"
    }
  },
  {
    id: 9,
    name: "Morff",
    description: "Managed hiring platform for creative talent",
    logo: require("../../media/startups/morff_logo.png"),
    theme: {
      primary: { color: "rgba(255,0,118,1)" },
      secondary: { color: "rgba(0,38,255,1)" },
      tertiary: { color: "" },
      accent: { color: "" },
      invertBackground: true
    },
    industry: "Design",
    sectors: ["Managed hiring platform", "Startup assistance"],
    team: [
      {
        id: 25,
        fname: "Komal",
        lname: "Goyal",
        designation: "Co-founder",
        pic: require("../../media/startups/morff_lead_1.png"),
        linkedIn: "https://www.linkedin.com/in/komal-goyal-design/",
        orgId: 10,
        orgLead: true,
        representer: true
      },
      {
        id: 26,
        pic: require("../../media/startups/morff_lead_2.png"),
        orgId: 10,
        orgLead: true,
        representer: false
      },
      {
        id: 27,
        pic: require("../../media/startups/morff_lead_3.png"),
        orgId: 10,
        orgLead: true,
        representer: false
      },
      {
        id: 28,
        pic: require("../../media/startups/morff_lead_4.png"),
        orgId: 10,
        orgLead: true,
        representer: false
      }
    ],
    teamSize: 11,
    other: {
      year: 2020,
      location: "Pune",
      zaubacorp:
        "https://www.zaubacorp.com/company/SERRI-TECH-LABS-PRIVATE-LIMITED/U74999UR2022PTC014830",
      stage: 3,
      socialMedia: {
        discord: "",
        facebook: "https://www.facebook.com/morff.community",
        instagram: "https://www.instagram.com/morff.io/",
        linkedIn: "https://www.linkedin.com/company/morff-io/",
        medium: "",
        pinterest: "",
        reddit: "",
        substack: "",
        twitter: "https://twitter.com/morffofficial",
        whatsapp: "https://api.whatsapp.com/send/?phone=914048215264",
        youtube: "https://www.youtube.com/@morff9225"
      },
      status: "Building tech"
    },
    pitch: {
      problem:
        "Organizations don’t have access to quality creative talent to establish and maintain a brand digital presence whilst creating a great user experience in products/services",
      solution:
        "A website & an upcoming AI-integrated app for businesses to make design:\n1. Attainable,\n2. Reliable, and\n3. Approachable\n\nMorff is creative talent for your brand",
      image: require("../../media/startups/morff_info.png"),
      video: {
        link: "https://www.youtube.com/watch?v=U7YKCNVtggA&ab_channel=Morff",
        thumbnail: require("../../media/startups/morff_video.png")
      },
      website: "https://www.morff.io/",
      features:
        "• Deeply vetted world class designers with varied scope\n• Verification, vetting and sourcing\n• 2100+ Creative professionals",
      unique:
        "• Profile recommendations and project management\n• Efficient access with Morff Recommendation Engine\n• Only design platform for digital and physical spaces",
      market: "• Managers\n• Corporates\n• Owners of SMEs/Startup founders"
    }
  },
  {
    id: 10,
    name: "Edden",
    description: "Uber for your startup tech-needs",
    logo: require("../../media/startups/edden_logo.png"),
    theme: {
      primary: { color: "rgba(0,0,0,1)" },
      secondary: { color: "" },
      tertiary: { color: "" },
      accent: { color: "" },
      invertBackground: false
    },
    industry: "B2B Saas",
    sectors: ["Web-development", "Technology"],
    team: [
      {
        id: 15,
        fname: "Nihal",
        lname: "Goyal",
        designation: "Co-founder",
        pic: require("../../media/startups/edden_lead_1.png"),
        linkedIn: "https://www.linkedin.com/in/nihalgoyal/",
        orgId: 7,
        orgLead: true,
        representer: true
      },
      {
        id: 16,
        pic: require("../../media/startups/edden_lead_2.png"),
        orgId: 7,
        orgLead: true,
        representer: false
      }
    ],
    teamSize: 10,
    other: {
      year: 2022,
      location: "New-Delhi",
      zaubacorp:
        "https://www.zaubacorp.com/company/EDDEN-INTERNET-PRIVATE-LIMITED/U72900GJ2022PTC135304?",
      stage: 2,
      socialMedia: {
        discord: "",
        facebook: "https://www.facebook.com/profile.php?id=100086760127890&mibextid=ZbWKwL",
        instagram: "https://instagram.com/edden.app?igshid=N2ZiY2E3YmU=",
        linkedIn: "https://www.linkedin.com/company/edden-app/",
        medium: "",
        pinterest: "",
        reddit: "",
        substack: "",
        twitter: "",
        whatsapp: "",
        youtube: ""
      },
      status: "Onboarding users"
    },
    pitch: {
      problem:
        "Tech-development for startups is a nightmare for non-technical founders\n\nFinding a CTO and building an in-house tech team is expensive and tedious",
      solution:
        "A platform that helps you find the right tech team to develop a MVP for startups\n\nEdden is your startup’s first tech team",
      image: require("../../media/startups/edden_info.png"),
      video: {
        link: "https://www.youtube.com/shorts/o-lDMeAdqfY",
        thumbnail: require("../../media/startups/edden_video.png")
      },
      website: "https://edden.app/",
      features:
        "• Create your product roadmap\n• We can design your app if need be\n• Development and testing\n• We deploy and launch your app",
      unique:
        "• We build your app in 3 months\n• Upfront pricing\n• We consider the business aspect of the products we build",
      market: "• Early-stage startups\n• With small/no tech-team\n• Ideation stage"
    }
  }
];

export default Startups;
