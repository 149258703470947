import React, { useState } from "react";
import SrRefColour from "../../../../../ref/colours";
import { SrCompIconDelete } from "../../../../../components/01_atoms/icons";
import style from "./style";

// Delete button
const DeleteBtn0 = ({ onClick, selected }) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    wrap: {
      borderColor: selected || btnHover ? SrRefColour.Neutral2A : SrRefColour.Neutral2B,
      backgroundColor: selected || btnHover ? SrRefColour.Neutral1C : SrRefColour.Neutral0,
      ...style.button,
      ...style.transition
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={onClick}
      style={style.clickable}
    >
      {/* Edit button */}
      <div style={style2.wrap}>
        {/* Add icon */}
        <SrCompIconDelete size={20} color={SrRefColour.Neutral2A} />
      </div>
    </div>
  );
};

export default DeleteBtn0;
