import React, { useState, useContext, useRef, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SrRefColour from "../../../../ref/colours";
// import { SrRefTextRubSb25, SrRefTextIntRe15, SrRefTextIntRe10 } from "../../../../ref/text";
import TxtInter from "../../../01_atoms/text/Inter";
import TxtRubik from "../../../01_atoms/text/rubik";

import { apiendpoint } from "../../../helpers/apiendpoint";
import MainContext from "../../../helpers/context/mainContext";
import { returnRGBA } from "../../../helpers/themeColors";

import NextFAB from "../../../01_atoms/buttons/FABs/NextFab";
import BackFAB from "../../../01_atoms/buttons/FABs/BackFAB";

import Dot from "../../../01_atoms/dot";
import TextInputIntRe12 from "../../../01_atoms/textInputs/TextInputIntRe12";
import CropperPopup from "../cropperPopup/CropperPopup";
import { imageUploadApi } from "../../../helpers/baseFuncs";
import { addAdmin } from "../../../helpers/community";

import { style } from "./style";

const AdminPopup = ({
  setSpeakerAdd,
  setSpeakerEdit,
  allowScroll,
  title,
  setEditHover,
  eventId,
  setFetchMore,
  speakerAdd,
  data
}) => {
  // Main context
  const {
    breakpoint3,
    scrollBoxWidth,
    // API related,
    user,
    userStartup
  } = useContext(MainContext);

  // Scroll view
  const [screen, setScreen] = useState(0);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollLeft = screen * scrollBoxWidth;
  }, [screen]);

  // Snackbar
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  // Startup theme colours
  // const primaryColor =
  //   user.userType === "emp" ? userStartup.theme?.primary.color : "rgba(200,200,200,1)";
  const primaryColor = "rgba(200,200,200,1)";
  const returnPrimary = returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, 1);

  // useStates
  // console.log(props.data, "data");
  const [pp, setPP] = useState(data?.image);
  const [loading, setLoading] = useState(false);

  // Image
  const imageInputRef = useRef();
  const [image, setImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  const [eventData, setEventData] = useState({
    name: "",
    phone_no: "",
    email: ""
  });

  const uploadFile = async (image) => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    let imgUrl = "";
    if (image.size / 1024 / 1024 <= 10) {
      var json = await imageUploadApi(`/platform/eventgraphics`, image);

      if (!json.success) {
        setShowCropper(false);
        snackbar("An error occurred, please try again.");
        setLoading(false);
      }

      setPP(json.url);
      imgUrl = json.url;

      // Only used when we are editing admin pp (USED IN EARLIER VERSION ONLY, NOT USED NOW)
      if (!speakerAdd)
        fetch(`${apiendpoint}/event/speaker/${data.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`
          },
          body: JSON.stringify({
            image: imgUrl
          })
        })
          .then((res) => {
            if (res.ok) return res.json();
            else throw new Error("Unauthorized");
          })
          .then((data) => {
            setFetchMore(true);
          });

      setShowCropper(false);
      snackbar("Image uploaded successfully");
      setLoading(false);
    } else {
      setShowCropper(false);
      snackbar("Image size should be less than 10mb");
    }
  };

  // func to edit user
  const editUser = () => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    fetch(`${apiendpoint}/event/speaker/${data.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify(eventData)
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((data) => {
        snackbar("Admins updated");
        setFetchMore(true);
        setSpeakerEdit(-1);
      });
  };

  // Function to add community admin
  const handleAddUser = async () => {
    const body = {
      user: {
        fname: eventData.name,
        email_id: eventData.email,
        phone_no: eventData.phone_no
      }
    };

    const json = await addAdmin(eventId, { data: body });

    if (json.success) {
      snackbar("Admin added");
      setFetchMore(true);
      setSpeakerEdit(-1);
      setSpeakerAdd(false);
      return;
    }

    snackbar("Error in adding admin");
  };

  // handleSubmit
  const handleSubmit = async () => {
    if (screen === 0 && !eventData.name) snackbar("Please write the speaker's name");
    else if (screen === 1 && (!eventData.phone_no || !eventData.email))
      snackbar("Please enter admin's user name and email address!");
    else if (screen === 1) {
      setScreen(screen + 1);
      setTimeout(() => {
        if (speakerAdd) handleAddUser();
        else editUser();
        setScreen(0);
        setSpeakerEdit(false);
      }, 1000);
    } else setScreen(screen + 1);
  };

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.alignItemsCenter,
      justifyContent: breakpoint3 ? "none" : "center"
    },
    headingWrap: {
      width: `${scrollBoxWidth}px`,
      ...style.paddingHor24
    },

    innerDiv: {
      ...style.surfaceBorder,
      ...style.scrollBox,
      width: `${scrollBoxWidth}px`,
      border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
      marginTop: breakpoint3 ? "24px" : 0
    },
    bodyWrap: {
      ...style.bodyWrap,
      width: `${scrollBoxWidth}px`
    }
  };

  return (
    <div>
      {/* Background */}
      <div style={style2.outerDiv}>
        {/* Bordered card */}
        <div style={style2.innerDiv}>
          {/* Event Title at the top */}
          <div style={style.titleWrap}>
            {title ? (
              <div style={style.titleInnerWrap}>
                {/* Event Title */}
                <TxtRubik weight={600} size={36} content={title} style={style.marginLeft12} />
              </div>
            ) : (
              // Shram logo at the top
              <div style={style.logoWrap}>
                <img src={require("../../../../media/logo.png")} height={50} alt={"Shram logo"} />
              </div>
            )}
          </div>

          {/* Scroll view */}
          <div style={style2.bodyWrap} ref={scrollRef}>
            {/* Enter name screen */}
            <div style={style.scroll}>
              <div style={style2.headingWrap}>
                {/* Text */}

                <TxtInter
                  weight={400}
                  size={17.28}
                  content={"Admin's name"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for speaker's name */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="name"
                    isShown={true}
                    limit="30"
                    placeholder={"Admin's full name"}
                    topText={"Name"}
                    value={eventData?.name}
                    onChange={(e) => {
                      if (e.target.value.length < 31) {
                        setEventData({ ...eventData, name: e.target.value });
                      }
                    }}
                  />
                </div>
              </div>
            </div>

            {/* Speaker's image */}
            {/* <div style={style.scroll}>
              <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
              
                <SrRefTextIntRe15
                  content={"Speaker's profile picture"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

               
                <div
                  style={{
                    ...style.justifyContentCenter,
                    ...style.marginTop24
                  }}
                >
                  <AvatarUpload
                    width={122}
                    pic={props.data?.image ? props.data?.image : pp}
                    imageInputRef={imageInputRef}
                    onChange={(e) => {
                      let files;
                      if (e.dataTransfer) {
                        files = e.dataTransfer.files;
                      } else if (e.target) {
                        files = e.target.files;
                      }
                      const reader = new FileReader();
                      reader.onload = () => {
                        console.log(reader.result);
                        setImage(reader.result);
                      };
                      reader.readAsDataURL(files[0]);
                      setShowCropper(true);
                    }}
                    innerWidth={6}
                    type={"pic"}
                  />
                </div>
              </div>
            </div> */}

            {/* Designation */}
            <div style={style.scroll}>
              <div style={style2.headingWrap}>
                {/* Admin's phone number */}
                <TxtInter
                  weight={400}
                  size={17.28}
                  content={"Admin's phone number"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="number"
                    name="phone_no"
                    isShown={true}
                    limit="10"
                    placeholder={"Phone number"}
                    topText={"Designation"}
                    value={eventData?.phone_no}
                    onChange={(e) => {
                      if (e.target.value.length < 11) {
                        setEventData({ ...eventData, phone_no: e.target.value });
                      }
                    }}
                  />
                </div>

                {/* Admin's email address */}
                <TxtInter
                  weight={400}
                  size={17.28}
                  content={"Admins Email Address"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="designation"
                    isShown={true}
                    limit="50"
                    placeholder={"Email Address"}
                    topText={"Designation"}
                    value={eventData?.email}
                    onChange={(e) => {
                      if (e.target.value.length < 51) {
                        setEventData({ ...eventData, email: e.target.value });
                      }
                    }}
                  />
                </div>
              </div>
              {/* 

                
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="url"
                    isShown={true}
                    limit="100"
                    placeholder={"Speaker's LinkedIn URL"}
                    topText={"LinkedIn"}
                    value={eventData?.url}
                    onChange={(e) => {
                      if (e.target.value.length < 101) {
                        setEventData({ ...eventData, url: e.target.value });
                      }
                    }}
                  />
                </div>
              </div> */}
            </div>
          </div>

          {/* Back button */}
          <BackFAB
            onClick={() =>
              screen === 0
                ? [setSpeakerAdd(false), setSpeakerEdit(-1), setEditHover(false), allowScroll()]
                : setScreen((p) => p - 1)
            }
          />

          {/* Dot carousal */}
          <div style={style.dotArray}>
            <Dot screen={screen} actualScreen={0} />
            <Dot screen={screen} actualScreen={1} />
          </div>

          {/* Next button */}
          <NextFAB content={screen === 1 ? "Done" : "Next"} onClick={() => handleSubmit()} />

          {/* Image cropper */}
          {showCropper && <CropperPopup image={image} onClick={uploadFile} setImage={setImage} />}
        </div>
      </div>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default AdminPopup;
