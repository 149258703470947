import SrRefColour from "../../../ref/colours";

const style = {
  outerMost: {
    position: "relative",
    width: "100vw",
    backgroundColor: SrRefColour.Neutral0
  },
  screen: {
    width: "100vw",
    maxWidth: "1600px",
    minHeight: "100vh",
    margin: "0 auto",
    backgroundColor: SrRefColour.Neutral0,
    overflowX: "hidden"
  }
};

export default style;
