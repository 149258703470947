import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SrRefColour from "../../../ref/colours";

import { apiendpoint } from "../../../components/helpers/apiendpoint";
import MainContext from "../../../components/helpers/context/mainContext";

import TxtInter from "../../../components/01_atoms/text/Inter";
import TxtRubik from "../../../components/01_atoms/text/rubik";
import Avatar from "../../../components/01_atoms/avatar/Avatar";
import NextFAB from "../../../components/01_atoms/buttons/FABs/NextFab";
import BackFAB from "../../../components/01_atoms/buttons/FABs/BackFAB";
import Dot from "../../../components/01_atoms/dot";
import TextInputIntRe12 from "../../../components/01_atoms/textInputs/TextInputIntRe12";
import Popup from "../../../components/01_atoms/popup";
import SetupTemplate from "../../../components/01_atoms/setupTemplate";
import LoginBtn from "../../../components/02_molecules/buttons/iconBtns/LoginBtn";

import { style } from "./style";

const Login = () => {
  // Main context
  const {
    scrollBoxWidth,
    popupWidth,
    // API related
    user,
    userId,
    setUserId,
    setupNew,
    setNew,
    loadUser,
    scrollToTop
  } = useContext(MainContext);
  const navigate = useNavigate();

  // To get the Invite Code from the search params
  const [searchParams] = useSearchParams();

  // If user exists, navigate to home
  useEffect(() => {
    // user && navigateHome();
    user && navigateToCommunity();
  }, []);

  window.onload = function () {
    // if (user) navigateHome();
    user && navigateToCommunity();
  };

  // Navigate to main startup listing
  const navigateLanding = () => {
    navigate("/");
  };

  // Navigate to main startup listing
  // const navigateHome = () => {
  //   navigate("/home");s
  // };

  const navigateToCommunity = () => {
    let communityId = localStorage.getItem("@communityId");
    let orgid = localStorage.getItem("@orgid");
    if (communityId) {
      navigate(`/community/${communityId}`);
      return;
    }

    // If community does not exist
    if (orgid) {
      navigate(`/startup/${orgid}`);
    }
  };

  // Navigate to Choice
  const navigateToType = () => {
    setNew({
      ...setupNew,
      addEmp: { emailId: setupNew.addEmp.emailId },
      newProfile: true
    });

    // Add Invite Code in the link if it is present in the link
    var link = "";
    const invite_code = searchParams.get("inviteCode");
    const referral_code = searchParams.get("referral_code");

    link = invite_code ? `/userType?inviteCode=${invite_code}` : "/usertype";
    if (referral_code) {
      link = `/userType?referral_code=${referral_code}`;
    }

    navigate(link);
  };

  // Navigate to a particular startup listing
  const navigateToStartup = (e) => {
    navigate(`/startup/${e.id}`, { state: { id: e.id } });
    scrollToTop();
  };

  // scrollPosition
  const [scrollPosition, setScrollposition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset; // Find the top offset of the window
    setScrollposition(position); // Set scrollPosition = top offset of the window
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true }); // listen to scroll event
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll view
  const [screen, setScreen] = useState(0);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollLeft = screen * scrollBoxWidth;
  }, [screen]);

  // Snackbar
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  // useStates
  const [mobileEdit, setMobileEdit] = useState(false);
  const [otp, setOTP] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  const [showOrg, setShowOrg] = useState(false);
  const [orgId, setOrgId] = useState(-1);

  const [loading, setLoading] = useState(false);
  const [orgLoaded, setOrgLoaded] = useState(false);

  // Employees reordered in an array
  const employeesInOrder = () => {
    let temp = orgInfo.employees.sort((a, b) => {
      if (a.fname < b.fname) {
        return -1;
      } else {
        return +1;
      }
    });

    let array = [
      // Org representatives
      ...temp.filter((e) => e.representer === "Y"),
      // Org co-founders
      ...temp.filter((e) => e.representer === "N" && e.is_org_lead === "Y"),
      // Org team members
      ...temp.filter((e) => e.representer === "N" && e.is_org_lead === "N")
    ];

    return array;
  };

  // Timer for otp
  let [timerClock, setTimerClock] = useState(15);
  let [runTimer, setRunTimer] = useState(false);

  // Timer logic for resending OTP
  useEffect(() => {
    if (runTimer) {
      const timer = setInterval(() => {
        setTimerClock((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [runTimer]);

  useEffect(() => {
    if (timerClock === 0) setRunTimer(false);
  }, [timerClock]);

  // Dummy org data
  const [orgInfo, setOrgInfo] = useState({
    orgName: "Organization Name",
    purpose: "Organization Purpose",
    employees: [],
    logo: null
  });

  // Remove first character if it is 0
  const remove0 = (num) => {
    if (num.charAt(0) === "0") {
      num = num.substring(1);
    }
    return num;
  };

  // checks if digits in the mobile number is equal to 10
  const validatePhoneNumber = (num) => {
    const regEx = /^\d{10}$/;
    return regEx.test(remove0(num));
  };

  // getOTP function
  const getOTP = () => {
    fetch(`${apiendpoint}/auth/generateOtp`, {
      method: "POST",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify({ email: setupNew.addEmp.emailId })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Unauthorized");
      })
      .then((json) => {
        if (json.type !== "success") throw Error("Some error occured. Please try again.");

        const setStorage = async () => {
          setUserId(parseInt(json.data.emp_id));
        };
        setStorage();
        setOTP("");

        console.log("OTP sent successfully");
      })
      .catch((err) => console.log(err));
  };

  // verifyOTP function
  const verifyOTP = () => {
    var OTP = otp;
    fetch(`${apiendpoint}/auth/verifyOtp`, {
      method: "POST",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify({
        phone_otp: OTP,
        emp_id: userId
      })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Unauthorized");
      })
      .then((json) => {
        // Store data first
        const storeData = async () => {
          localStorage.setItem("@jwtauth", String(json.data.token));
          localStorage.setItem("@empId", String(json.data.emp_id));
        };

        storeData();
        setOtpVerified(true);

        // Extraxt OTP from json body
        if (json.community) {
          localStorage.setItem("@communityId", String(json.community));
          localStorage.setItem("@initial", `/community/${json.community}`);
        }

        // Once the OTP is verified, fetch and show the org details
        if (json.data.org && json.is_setup == "Y") {
          // Then store org and call org only if org exists
          const storeOrg = async () => {
            localStorage.setItem("@orgid", String(json.data.org.id));
          };
          "json.data", json.data;
          storeOrg();
          setShowOrg(true);
          loadUser();
          setOrgId(json.data.org.id);
          setOrgInfo({
            id: json.data.org.id,
            logo: json.data.org.logo,
            orgName: json.data.org.orgName,
            purpose: json.data.org.purpose,
            description: json.data.org.description,
            employees: json.data.org.employees,
            fname: json.data.emp_fname,
            userId: json.data.emp_id
          });
          setLoading(false);
          setOrgLoaded(true);
        } else if (json.data.is_setup === "Y") {
          loadUser();
          setTimeout(() => {
            if (json.community) {
              localStorage.setItem("@communityId", String(json.community));
            }

            // Navigate to the initial page in which user first landed
            const initialLocation = localStorage.getItem("@initial");
            initialLocation && localStorage.removeItem("@initial");

            // Navigate to home if initial link is not present
            initialLocation ? navigate(initialLocation) : navigateToCommunity();
          }, 2000);
        }
        // If nothing is being returned, then, it s a number not linked to an org
        else navigateToType();
      })
      .catch((error) => {
        console.log(error);
        snackbar("OTP entered is incorrect");
      });
  };

  // handleSubmit
  const handleSubmit = async () => {
    if (screen === 0 && setupNew.addEmp.emailId === "") snackbar("Please enter your email");
    else if (screen === 0 && !setupNew.addEmp.emailId)
      snackbar("Please enter a valid email number");
    else if (screen === 0 && setupNew.addEmp.emailId) {
      getOTP();
      setTimerClock(15);
      setRunTimer(true);
      setScreen(screen + 1);
    } else if (screen === 1 && otp === -1) snackbar("Please enter the OTP sent on your email");
    else if (screen === 1 && otp !== -1) {
      verifyOTP();
      setRunTimer(false);
    } else {
      setScreen(screen + 1);
      setOTP(-1);
    }
  };

  // Dynamic styling for this components
  const style2 = {
    outerWrap: {
      ...style.outerMost,
      overflowY: showOrg ? "hidden" : "auto"
    },
    scrollWrap: {
      ...style.scrollWrap,
      width: `${scrollBoxWidth}px`,
      height: "316px"
    },

    resnedTextWrap: {
      ...style.resendOTP,
      color: runTimer ? SrRefColour.Neutral3 : SrRefColour.Neutral1A,
      borderColor: runTimer ? SrRefColour.Neutral3 : SrRefColour.Neutral1A
    },
    ahaScreenOuterWrap: {
      width: `${scrollBoxWidth}px`,
      ...style.paddingHor24
    }
  };

  return (
    <div>
      <div style={style2.outerWrap}>
        <SetupTemplate>
          {/* Scroll view */}
          <div style={style2.scrollWrap} ref={scrollRef}>
            {/* Enter mobile number screen */}
            <div style={style.scroll}>
              <div style={{ width: `${scrollBoxWidth}px`, ...style.paddingHor24 }}>
                {/* Text */}
                <TxtInter
                  size={21.6}
                  weight={400}
                  content={"Enter your Email ID"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for email number */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="email"
                    name="email"
                    placeholder={"For ex: example@shram.io"}
                    value={setupNew.addEmp.emailId}
                    // Functions
                    onChange={(e) =>
                      setNew({
                        ...setupNew,
                        addEmp: { ...setupNew.addEmp, emailId: e.target.value }
                      })
                    }
                    onClick={() => setMobileEdit(true)}
                    noLimit={true}
                  />
                </div>
              </div>
            </div>

            {/* Enter otp screen */}
            <div style={style.scroll}>
              <div style={{ width: `${scrollBoxWidth}px`, ...style.paddingHor24 }}>
                {/* Text */}
                <TxtInter
                  size={21.6}
                  weight={400}
                  content={"Enter OTP"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for otp */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    isShown={true}
                    type="number"
                    name="phoneNo"
                    placeholder={"6-digit OTP"}
                    value={otp === -1 ? "" : otp}
                    onChange={(e) => {
                      setOTP(e.target.value);
                    }}
                    onClick={() => setMobileEdit(true)}
                    noLimit={true}
                  />
                </div>

                <div style={style.marginTop24}>
                  <TxtInter
                    size={14.4}
                    weight={300}
                    content={"Did not receive the OTP?"}
                    state={"enabled"}
                    style={style.colorBlack}
                  />
                </div>

                <div style={style.resentBtnWrap}>
                  <div
                    onClick={() => {
                      return (
                        !runTimer && (setOTP(-1), getOTP(), setRunTimer(true), setTimerClock(15)) // Return only if btn is not clicked
                      );
                    }}
                    style={{
                      cursor: runTimer ? "auto" : "pointer" // Show pointer only if btn is not clicked
                    }}
                  >
                    <TxtInter
                      size={14.4}
                      weight={300}
                      content={"Resend OTP"}
                      state={runTimer ? "disabled" : "enabled"}
                      style={style2.resnedTextWrap}
                    />
                  </div>

                  {runTimer && (
                    <TxtInter
                      size={14.4}
                      weight={300}
                      content={`${timerClock}s`}
                      state={"disabled"}
                    />
                  )}
                </div>
              </div>
            </div>

            {/* AHA moment screen */}
            <div style={style.scroll}>
              <div style={style2.ahaScreenOuterWrap}>
                <div style={style.ahaScreenInnerWrap}>
                  {/* Cover image */}
                  <img
                    src={require("../../../media/setup_guide_aha_4.png")}
                    width={180}
                    alt={"Welcome back!"}
                  />

                  {/* Aha moment text */}
                  <TxtInter
                    size={21.6}
                    weight={400}
                    content={"Welcome back!"}
                    state={"enabled"}
                    style={{ ...style.marginTop24, ...style.textCenter, ...style.colorBlack }}
                  />
                </div>
              </div>
            </div>

            {/* Back button */}
            {screen !== 2 && (
              <BackFAB
                onClick={() => {
                  screen === 0 ? navigateLanding() : setScreen((p) => p - 1);
                  setOTP(-1);
                  setRunTimer(false);
                  return true;
                }}
              />
            )}

            {/* Dot carousal */}
            {screen !== 2 && (
              <div style={style.dotArray}>
                <Dot screen={screen} actualScreen={0} />
                <Dot screen={screen} actualScreen={1} />
              </div>
            )}

            {/* Next button */}
            {screen !== 2 && (
              <NextFAB
                content={screen === 1 ? "Submit" : "Next"}
                onClick={() => handleSubmit()}
                // onClick={() => (screen === 2 ? setScreen(1) : setScreen((p) => p + 1))}
              />
            )}
          </div>
        </SetupTemplate>

        {/* Popup for user's org */}
        {showOrg && (
          <Popup onClick={() => setShowOrg(false)} scrollPosition={scrollPosition} noPadding={true}>
            <div style={{ width: `${popupWidth}px` }}>
              <div style={{ ...style.marginHor24, ...style.marginTop24 }}>
                {/* Org logo */}
                <div style={style.profilePicShell}>
                  {/* Show org logo when available or else show default icon */}
                  {orgInfo.logo ? (
                    <Avatar width={75} pic={orgInfo.logo} borderColor={SrRefColour.Neutral2A} />
                  ) : (
                    <div></div>
                  )}
                </div>

                {/* Org name */}
                <TxtRubik
                  size={43.2}
                  weight={600}
                  content={orgInfo.orgName}
                  state={"enabled"}
                  style={{ ...style.marginTop6, ...style.colorBlack }}
                />

                {/* Purpose text */}
                <TxtInter
                  size={17.28}
                  weight={300}
                  // content={orgInfo.purpose}
                  content={orgInfo.description}
                  state={"enabled"}
                  style={style.marginTop6}
                />
              </div>

              {/* Avatar list */}
              <div style={{ ...style.flexRow, ...style.customPadding, ...style.overflowX }}>
                <div style={style.scroll}>
                  {employeesInOrder().map((emp, id) => (
                    // Avatar for each co-worker
                    <Avatar
                      width={39}
                      pic={emp.picture}
                      borderColor={SrRefColour.Neutral2A}
                      key={id}
                      style={style.marginLeft12}
                    />
                  ))}
                </div>
              </div>

              <div
                style={{
                  ...style.borderTop,
                  ...style.paddingTop24,
                  ...style.paddingHor24,
                  ...style.marginBot24
                }}
              >
                {/* Intro text */}
                <TxtInter
                  size={14.4}
                  weight={300}
                  content={`Hello ${orgInfo.fname}! It seems you are part of the above organization.`}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Log into account link */}
                <div style={style.marginTop24}>
                  <LoginBtn
                    onClick={() => {
                      setShowOrg(false);
                      setScreen(screen + 1);
                      setTimeout(() => {
                        // const startupId = localStorage.getItem("@startupId");
                        // if (startupId) navigateToStartup({ id: startupId });
                        // else navigateToStartup({ id: orgId });
                        navigateToCommunity();
                      }, 2000);
                    }}
                    text={"Login"}
                  />
                </div>
              </div>
            </div>
          </Popup>
        )}
      </div>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default Login;
