import React, { useContext } from "react";

// Importing contexts
import MainContext from "../../../components/helpers/context/mainContext";

// Importing components
import ThumbUpBtn from "../../02_molecules/buttons/bigBtns/ThumbUpBtn";
import CommentBtn from "../../02_molecules/buttons/bigBtns/CommentBtn";
import ConnectBtn from "../../02_molecules/buttons/bigBtns/ConnectBtn";
import RateBtn from "../../02_molecules/buttons/bigBtns/RateBtn";
import style from "./style";

const ReviewPanel = ({
  changePosition,
  scrollPosition,
  bodyScrollHeight,
  windowHeight,
  onClickUpVote,
  onClickValueProp,
  valueProp,
  upVote,
  upVoteCount,
  onClickInterest,
  interest,
  comment,
  onClickComment,
  specialGuest
}) => {
  // Context
  const { screenWidth, breakpoint2, breakpoint3 } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.userPanelWrap,
      bottom: changePosition
        ? breakpoint3
          ? scrollPosition + windowHeight - (bodyScrollHeight - (60 + 36 + 24))
          : scrollPosition + windowHeight - (bodyScrollHeight - (60 + 48 + 30))
        : 0
    },
    innerWrap: {
      ...style.innerWrap,
      maxWidth: breakpoint2 && `${screenWidth - 24}px`
    }
  };

  return (
    <div style={style2.outerDiv}>
      <div style={style2.innerWrap}>
        {/* User panel surface */}
        <div style={style.buttonOuterWrap}>
          <div style={style.buttonWrap}>
            {specialGuest ? (
              // Rating
              <RateBtn
                onChange={(e) => onClickValueProp(e.target.value)}
                pressed={valueProp}
                breakpoint3={breakpoint3}
              />
            ) : (
              // Upvote
              <ThumbUpBtn
                onClick={onClickUpVote}
                upVoteCount={upVoteCount}
                pressed={upVote}
                breakpoint3={breakpoint3}
              />
            )}

            {/* Comment */}
            <ConnectBtn onClick={onClickInterest} pressed={interest} breakpoint3={breakpoint3} />

            {/* Comment */}
            <CommentBtn onClick={onClickComment} pressed={comment} breakpoint3={breakpoint3} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewPanel;
