import React, { useState } from "react";
import SrRefColour from "../../../../../ref/colours";
// import { SrRefTextIntRe12 } from "../../../../../ref/text";
import TxtInter from "../../../../01_atoms/text/Inter";
import { SrCompIconSave } from "../../../../01_atoms/icons";
import style from "./style";

// Save button
const SaveBtn = ({ pressed, onClick, breakpoint3, content }) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    phoneContainer: {
      ...style.savePhoneContainer,
      borderColor: pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    },
    screenContainer: {
      ...style.saveScreenContainer,
      borderColor: pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={onClick}
        style={style.clickable}
      >
        {breakpoint3 ? (
          // For phone
          <div style={style2.phoneContainer}>
            <div style={style.icon24}>
              <SrCompIconSave size={24} />
            </div>
          </div>
        ) : (
          // For larger screens
          <div style={style2.screenContainer}>
            <TxtInter size={17.28} weight={400} content={content} style={style.transition} />
            <div style={style.iconStyle}>
              <SrCompIconSave size={20} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SaveBtn;
