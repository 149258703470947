import React, { useState, useEffect, useRef, useContext } from "react";

import SrRefColour from "../../../../ref/colours";

import MainContext from "../../../helpers/context/mainContext";
import { returnRGBA } from "../../../helpers/themeColors";

import Avatar from "../../../01_atoms/avatar/Avatar";
import SectorChip from "../../../01_atoms/chips/SectorChip";
import IndustryChip from "../../../01_atoms/chips/IndustryChip";

import style from "./style";
import TxtInter from "../../../01_atoms/text/Inter";
import TxtRubik from "../../../01_atoms/text/rubik";

const LargeCard = ({
  startup,
  noHover,
  select,
  largeCardWidth,
  glowOpacity,
  onClick,
  nameLimit,
  defaultColor,
  descriptionLimit,
  blur
}) => {
  // Context
  const { colorSplit } = useContext(MainContext);

  // Startup theme colours
  const primaryColor = colorSplit(startup.theme.primary.color);
  const accentColor = startup.theme.accent.color && colorSplit(startup.theme.accent.color);
  const invertBackground = startup.theme.invertBackground;

  const [cardHover, setCardHover] = useState(false);

  // Get dimensions of the card
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const cardRef = useRef(null);

  useEffect(() => {
    if (cardRef.current) {
      setDimensions({
        width: cardRef.current.offsetWidth,
        height: cardRef.current.offsetHeight
      });
    }
  }, []);

  // Dynamic styling
  const style2 = {
    outerDiv: {
      ...style.outerDiv,
      cursor: noHover ? "default" : "pointer",
      padding: "2px",
      border: cardHover || select ? "1px solid rgba(0,0,0,0.15)" : "1px solid rgba(0,0,0,0)",
      borderRadius: "21px"
    },
    innerDiv: {
      ...style.innerDiv,
      width: `${largeCardWidth}px`,
      backgroundColor: cardHover || select ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.8)",
      borderColor: SrRefColour.Neutral2B
    },
    blurEffect: {
      ...style.blurEffect,
      width: `${dimensions.width + 24}px`,
      height: `${dimensions.height + 24}px`,
      backgroundColor: accentColor
        ? returnRGBA(accentColor.r, accentColor.g, accentColor.b, glowOpacity)
        : returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, glowOpacity),
      filter: `blur(${blur}rem)`
    }
  };

  return (
    <div style={style.positionRelative}>
      <div
        onMouseEnter={() => setCardHover(true)}
        onMouseLeave={() => setCardHover(false)}
        onClick={onClick}
        style={style2.outerDiv}
        key={startup.id}
      >
        {/* Card body */}
        <div style={style2.innerDiv} ref={cardRef}>
          {/* Org logo */}
          <div style={style.marginLeft18}>
            <Avatar
              width={75}
              pic={startup.logo}
              borderColor={returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, 1)}
            />
          </div>

          <TxtRubik
            size={36}
            weight={600}
            content={
              startup.name?.length > nameLimit + 3
                ? startup.name.slice(0, nameLimit) + "..."
                : startup.name
            }
            state={"enabled"}
            style={style.nameStyle}
          />

          {/* Org description */}
          <TxtInter
            weight={500}
            size={17.28}
            content={
              startup.description?.length > descriptionLimit + 3
                ? startup.description.slice(0, descriptionLimit) + "..."
                : startup.description
            }
            state={"enabled"}
            style={style.descriptionStyle}
          />

          {/* Chips for industry and sectors */}
          <div style={style.scrollWrap}>
            <div style={style.chipInnerDiv}>
              {/* Chip for industry */}
              <IndustryChip
                industry={startup.industry}
                primaryColor={primaryColor}
                accentColor={accentColor}
                invertBackground={invertBackground}
              />

              {/* Chip for sectors */}
              {startup.sectors.map((sector) => (
                <SectorChip
                  sector={sector}
                  primaryColor={primaryColor}
                  accentColor={accentColor}
                  invertBackground={invertBackground}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Blur effect in the background */}
      <div style={style2.blurEffect}></div>
    </div>
  );
};

export default LargeCard;
