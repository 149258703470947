import React, { useState } from "react";
import SrRefColour from "../../../../../ref/colours";
import { SrCompIconEdit } from "../../../../../components/01_atoms/icons";
import style from "./style";

const EditBtn0 = ({ selected, onClick }) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    divStyle: {
      ...style.divStyle,
      borderColor: selected || btnHover ? SrRefColour.Neutral2A : SrRefColour.Neutral2B,
      backgroundColor: selected || btnHover ? SrRefColour.Neutral1C : SrRefColour.Neutral0
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={onClick}
      style={style.clickable}
    >
      {/* Edit button */}
      <div style={style2.divStyle}>
        {/* Edit icon */}
        <SrCompIconEdit size={20} color={SrRefColour.Neutral2A} />
      </div>
    </div>
  );
};

export default EditBtn0;
