import React, { useState } from "react";
import SrRefColour from "../../../../../ref/colours";
// import { SrRefTextIntRe12 } from "../../../../../ref/text";
import TxtInter from "../../../../01_atoms/text/Inter";
import { SrCompIconBrowseActivity } from "../../../../01_atoms/icons";
import style from "./style";

// Preview button
const PreviewBtn = ({ pressed, onClick, breakpoint3 }) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    phoneContainer: {
      ...style.previewPhoneContainer,
      borderColor: pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    },
    screenContainer: {
      ...style.previewScreenContainer,
      borderColor: pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={onClick}
        style={style.clickable}
      >
        {breakpoint3 ? (
          // For phone
          <div style={style2.phoneContainer}>
            <div style={style.icon24}>
              <SrCompIconBrowseActivity size={24} />
            </div>
          </div>
        ) : (
          // For larger screens
          <div style={style2.screenContainer}>
            <TxtInter size={17.28} weight={400} content={"Your listing"} style={style.transition} />
            <div style={style.iconStyle}>
              <SrCompIconBrowseActivity size={20} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviewBtn;
