import SrRefColour from "../../../ref/colours";
import { fsControl } from "../../helpers/sizeController";

export const style = {
  textArea: {
    width: "100%",
    padding: "12px 9px",
    borderRadius: "6px",
    border: "none",
    backgroundColor: "transparent",
    resize: "none",
    outline: "none"
  },
  textInput: {
    width: "100%",
    padding: "12px 9px",
    borderRadius: "6px",
    backgroundColor: SrRefColour.Neutral1C,
    resize: "none",
    outline: "none"
  },
  rubSb30: {
    fontFamily: "Rubik",
    fontWeight: "600",
    fontSize: fsControl(3)
  },
  intSb15: {
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: fsControl(1.5)
  },
  intSb12: {
    fontFamily: "Inter",
    fontWeight: "600",
    fontSize: fsControl(1.2)
  },
  intRe12: {
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: fsControl(1.2)
  },
  intMe10: {
    fontFamily: "Inter",
    fontWeight: "500",
    fontSize: fsControl(1)
  },
  intRe09: {
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: fsControl(0.9)
  },
  transition: {
    transition: "0.2s ease-in"
  },

  // Custom style for this component

  textStyle: {
    width: "100%",
    padding: "12px 9px",
    borderRadius: "6px",
    backgroundColor: SrRefColour.Neutral1C,
    resize: "none",
    outline: "none",
    transition: "0.2s ease-in",
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: fsControl(1.2)
  }
};
