import SrRefColour from "../../../ref/colours";

export const style = {
  background: {
    backgroundColor: "#f4f7f7",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh"
  },
  logo: { width: "150px" }

  // Widths, heights and margins
};
