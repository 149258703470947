import React, { useState, useRef, useLayoutEffect } from "react";
import SrRefColour from "../../../ref/colours";
import SizeLimit from "../../01_atoms/characterLimit";
import { style } from "./style";

// Text input for Inter regular font size 12
const TextAreaIntRe12 = ({
  value,
  gradientText,
  noLimit,
  placeholder,
  isShown,
  readOnly,
  limit,
  rows,
  onChange,
  onClick
}) => {
  // Text area height resizing
  const textAreaRef = useRef(null);

  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    textAreaRef.current.style.height = "inherit";
    // Set height
    textAreaRef.current.style.height = `${Math.max(textAreaRef.current.scrollHeight, 16)}px`;
  }, [value]);

  // Hover and click states
  const [inputHover, setInputHover] = useState(false);
  const [inputClick, setInputClick] = useState(false);

  const setInputHoverFunc = () => {
    setInputClick(true);
  };

  // Dynamic styling for this component
  const style2 = {
    textStyle: {
      ...style.textStyle,
      ...gradientText,
      border:
        inputHover || inputClick
          ? `1px solid ${SrRefColour.Neutral2A}`
          : `1px solid ${SrRefColour.Neutral2B}`
    }
  };

  return (
    <div>
      <textarea
        name={name}
        placeholder={placeholder}
        limit={limit}
        isShown={isShown}
        value={value}
        readOnly={readOnly ? readOnly : false}
        rows={rows ? rows : 1}
        onChange={onChange}
        onMouseEnter={() => setInputHover(true)}
        onMouseLeave={() => setInputHover(false)}
        onClick={onClick}
        style={style2.textStyle}
        ref={textAreaRef}
        required
      />
      {!noLimit && (
        <SizeLimit isShown={isShown} lengthOfDesc={value ? value.length : 0} lengthLimit={limit} />
      )}
    </div>
  );
};

export default TextAreaIntRe12;
