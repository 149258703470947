import { useContext } from "react";
import MainContext from "../../helpers/context/mainContext";
import TxtInter from "../../01_atoms/text/Inter";

const OrgDescription = () => {
  const { startup } = useContext(MainContext);

  return (
    <TxtInter
      size={21.6}
      weight={600}
      content={startup.brief ? startup.brief : "Startup description"}
    />
  );
};

export default OrgDescription;
