import React, { useState, useContext, useRef, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Importing colors
import SrRefColour from "../../../../ref/colours";

// Importing helper functions
import { returnRGBA } from "../../../helpers/themeColors";

// Importing APIs
import { changePhase3Data } from "../../../helpers/startup";
import { imageUploadApi } from "../../../helpers/baseFuncs";

// Importing contexts
import MainContext from "../../../helpers/context/mainContext";

// Importing Texts
import TxtInter from "../../../01_atoms/text/Inter";
import TxtRubik from "../../../01_atoms/text/rubik";

// Importing components
import RectUpload from "../../../01_atoms/avatar/ReactUpload";
import Avatar from "../../../01_atoms/avatar/Avatar";
import NextFAB from "../../../01_atoms/buttons/FABs/NextFab";
import BackFAB from "../../../01_atoms/buttons/FABs/BackFAB";
import Dot from "../../../01_atoms/dot";
import TextInputIntRe12 from "../../../01_atoms/textInputs/TextInputIntRe12";
import CropperPopup from "../cropperPopup/CropperPopup";
import TextAreaIntRe12 from "../../textAreas";

import { style } from "./style";

const EditPopup4 = (props) => {
  // Main context
  const {
    breakpoint3,
    scrollBoxWidth,
    // API related,
    userStartup,
    startup,
    setStartup,

    primaryColor,
    snackbar,
    savePhase3Data
  } = useContext(MainContext);

  // Scroll view
  const [screen, setScreen] = useState(0);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollLeft = screen * scrollBoxWidth;
  }, [screen]);

  // Startup theme colours
  const returnPrimary = returnRGBA(primaryColor?.r, primaryColor?.g, primaryColor?.b, 1);

  // useStates
  const [problem, setProblem] = useState(startup.pitch.problem);
  const [problemEdit, setProblemEdit] = useState(false);
  const [solution, setSolution] = useState(startup.pitch.solution);
  const [solutionEdit, setSolutionEdit] = useState(false);
  const [features, setFeatures] = useState(startup.pitch.features ? startup.pitch.features : "• ");
  const [featuresEdit, setFeaturesEdit] = useState(false);
  const [traction, setTraction] = useState(startup.pitch.traction ? startup.pitch.traction : "• ");
  const [tractionEdit, setTractionEdit] = useState(false);
  const [market, setMarket] = useState(startup.pitch.market ? startup.pitch.market : "• ");
  const [marketEdit, setMarketEdit] = useState(false);
  const [videoUrl, setVideoUrl] = useState(startup.pitch.video.link);
  const [videoUrlEdit, setVideoUrlEdit] = useState(false);
  const [videoImg, setVideoImg] = useState(startup.pitch.video.thumbnail);
  const [website, setWebsite] = useState(startup.pitch.website);
  const [websiteEdit, setWebsiteEdit] = useState(false);
  const [challenge1, setChallenge1] = useState(startup.pitch.challenge1);
  const [challenge1Edit, setChallenge1Edit] = useState(false);
  const [mentorSectors, setMentorSectors] = useState(startup.pitch.mentorSectors);
  const [mentorSectorsEdit, setMentorSectorsEdit] = useState(false);
  const [pitchDeck, setPitchDeck] = useState(startup.pitch.pitchDeck);
  const [pitchDeckEdit, setPitchDeckEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const video = {
    link: videoUrl,
    thumbnail: videoImg
  };

  // Image
  const imageInputRef = useRef();
  const [image, setImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  // Upload image
  const uploadFile = async (videoImage) => {
    if (videoImage.size / 1024 / 1024 <= 10) {
      var json = await imageUploadApi(`/platform/thumbnails`, videoImage);

      if (!json.success) {
        console.log(json);
        setShowCropper(false);
        snackbar("An error occurred, please try again.");
        setLoading(false);
        return;
      }
      setVideoImg(json.url);
      setStartup({
        ...startup,
        pitch: {
          ...startup.pitch,
          video: {
            link: videoUrl,
            thumbnail: json.url
          },
          website: website
        }
      });
      setShowCropper(false);
      snackbar("Image uploaded successfully");
      setLoading(false);
    } else {
      setShowCropper(false);
      snackbar("Image size should be less than 10mb");
    }
  };

  const saveData = async () => {
    const body = {
      problem,
      solution,
      features,
      traction,
      market,
      video: videoUrl,
      thumbnail: videoImg,
      challenge1,
      // mentorSectors,
      pitch_deck: pitchDeck
    };

    await savePhase3Data(props.orgId, body);
  };

  useEffect(() => {
    return saveData;
  }, [problem, solution, features, traction, market, videoUrl, videoImg, challenge1, pitchDeck]);

  const saveWebsite = async () => {
    const body = {
      website
    };

    // Org ID
    const id = Number(window.location.pathname.split("/")[3]);
    const json = await changePhase3Data(id, { data: body });

    if (!json.success) {
      console.log(json);
      return;
    }
  };

  useEffect(() => {
    return saveWebsite;
  }, [website]);

  //for dynamic styling
  const style2 = {
    backgroundWrap: {
      ...style.alignItemsCenter,
      justifyContent: breakpoint3 ? "none" : "center"
    },
    cardWrap: {
      ...style.cardWrap,
      width: `${scrollBoxWidth}px`,
      border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
      marginTop: breakpoint3 ? "24px" : 0
    },
    scrollViewContainer: {
      ...style.scrollViewContainer,
      width: `${scrollBoxWidth}px`
    },
    nameContainer: {
      ...style.paddingHor24,
      width: `${scrollBoxWidth}px`
    }
  };

  return (
    <div>
      {/* Background */}
      <div style={style2.backgroundWrap}>
        {/* Bordered card */}
        <div style={style2.cardWrap}>
          {/* Shram logo at the top */}
          <div style={style.logoOuterWrap}>
            {/* <img src={require("../../../media/logo.png")} height={50} alt={"Shram logo"} /> */}
            <div style={style.logoInnerWrap}>
              {/* User org logo */}
              <Avatar pic={props.startupLogo} width={50} borderColor={returnPrimary} />

              {/* User org name */}
              <TxtRubik
                size={36}
                weight={600}
                content={props.startupName}
                style={style.marginLeft12}
              />
            </div>
          </div>

          {/* Scroll view */}
          <div style={style2.scrollViewContainer} ref={scrollRef}>
            {/* Enter problem screen */}
            {props.problem && (
              <div style={style.scroll}>
                <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                  {/* Text */}
                  <TxtInter
                    size={21.6}
                    weight={400}
                    content={"What problem are you solving?"}
                    state={"enabled"}
                    style={style.colorBlack}
                  />

                  {/* Input for problem */}
                  <div style={style.marginTop18}>
                    <TextAreaIntRe12
                      isShown={true}
                      limit="200"
                      name="problem"
                      placeholder={
                        "What is the specific pain point your target customer faces? What are the shortcomings to current solutions?"
                      }
                      value={problem}
                      onChange={(e) => {
                        if (e.target.value.length < 201) {
                          setProblem(e.target.value);

                          setStartup({
                            ...startup,
                            pitch: {
                              ...startup.pitch,
                              ...{
                                problem: e.target.value.trim()
                              }
                            }
                          });
                        }
                      }}
                      onClick={() => setProblemEdit(true)}
                      rows={4}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Enter solution screen */}
            {props.solution && (
              <div style={style.scroll}>
                <div style={style2.nameContainer}>
                  {/* Text */}
                  <TxtInter
                    size={21.6}
                    weight={400}
                    content={"What is your solution?"}
                    state={"enabled"}
                    style={style.colorBlack}
                  />

                  {/* Input for solution */}
                  <div style={style.marginTop18}>
                    <TextAreaIntRe12
                      isShown={true}
                      limit="225"
                      name="solution"
                      placeholder={
                        "Describe your value proposition briefly. Is it a product or a service? How does it deliver value to your customer?"
                      }
                      value={solution}
                      onChange={(e) => {
                        if (e.target.value.length < 226) {
                          setSolution(e.target.value);

                          setStartup({
                            ...startup,
                            pitch: {
                              ...startup.pitch,
                              ...{
                                solution: e.target.value.trim()
                              }
                            }
                          });
                        }
                      }}
                      onClick={() => setSolutionEdit(true)}
                      rows={4}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Enter features screen */}
            {props.features && (
              <div style={style.scroll}>
                <div style={style2.nameContainer}>
                  {/* Text */}
                  <TxtInter
                    size={21.6}
                    weight={400}
                    content={"Unique Features of your product/service"}
                    state={"enabled"}
                    style={style.colorBlack}
                  />

                  {/* Input for features */}
                  <div style={style.marginTop18}>
                    <TextAreaIntRe12
                      name="features"
                      isShown={true}
                      limit="200"
                      placeholder={
                        "Articulate the features of your product/service. These need not be unique but should paint a picture of what a user can do with it."
                      }
                      value={features}
                      onChange={(e) => {
                        if (e.target.value.length < 201) {
                          var text = e.target.value;
                          if (text.charAt(text.length - 1) === "\n") {
                            var initialText = features;
                            if (initialText.charAt(initialText.length - 1) !== "•") text += "• ";
                          }
                          setFeatures(text);

                          setStartup({
                            ...startup,
                            pitch: {
                              ...startup.pitch,
                              ...{
                                features: e.target.value.trim()
                              }
                            }
                          });
                        }
                      }}
                      onClick={() => setFeaturesEdit(true)}
                      rows={4}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Enter traction screen */}
            {props.traction && (
              <div style={style.scroll}>
                <div style={style2.nameContainer}>
                  {/* Text */}
                  <TxtInter
                    size={21.6}
                    weight={400}
                    content={"What are your Traction?"}
                    state={"enabled"}
                    style={style.colorBlack}
                  />

                  {/* Input for traction */}
                  <div style={style.marginTop18}>
                    <TextAreaIntRe12
                      isShown={true}
                      limit="200"
                      type="text"
                      name="traction"
                      placeholder={
                        "Please write about metrics focused on product usage, customer behaviour, engagement, other operational aspects and if possible, the unit economics of your business."
                      }
                      value={traction}
                      onChange={(e) => {
                        if (e.target.value.length < 201) {
                          var text = e.target.value;
                          if (text.charAt(text.length - 1) === "\n") {
                            var initialText = traction;
                            if (initialText.charAt(initialText.length - 1) !== "•") text += "• ";
                          }
                          setTraction(text);

                          setStartup({
                            ...startup,
                            pitch: {
                              ...startup.pitch,
                              ...{
                                traction: e.target.value.trim()
                              }
                            }
                          });
                        }
                      }}
                      onClick={() => setTractionEdit(true)}
                      rows={4}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Enter market screen */}
            {props.market && (
              <div style={style.scroll}>
                <div style={style2.nameContainer}>
                  {/* Text */}
                  <TxtInter
                    size={21.6}
                    weight={400}
                    content={"Who is your target market?"}
                    state={"enabled"}
                    style={style.colorBlack}
                  />

                  {/* Input for market */}
                  <div style={style.marginTop18}>
                    <TextAreaIntRe12
                      isShown={true}
                      limit="150"
                      type="text"
                      name="market"
                      placeholder={
                        "Who is your user? Can you describe them more accurately? Are there more than one customer segment you are targeting?"
                      }
                      value={market}
                      onChange={(e) => {
                        if (e.target.value.length < 151) {
                          var text = e.target.value;
                          if (text.charAt(text.length - 1) === "\n") {
                            var initialText = market;
                            if (initialText.charAt(initialText.length - 1) !== "•") text += "• ";
                          }
                          setMarket(text);

                          setStartup({
                            ...startup,
                            pitch: {
                              ...startup.pitch,
                              ...{
                                market: e.target.value.trim()
                              }
                            }
                          });
                        }
                      }}
                      onClick={() => setMarketEdit(true)}
                      rows={4}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Enter video screen */}
            {props.video && (
              <>
                {/* Enter video url screen */}
                <div style={style.scroll}>
                  <div style={style2.nameContainer}>
                    {/* Text */}
                    <TxtInter
                      size={21.6}
                      weight={400}
                      content={"Enter your video's URL"}
                      state={"enabled"}
                      style={style.colorBlack}
                    />

                    {/* Input for video url */}
                    <div style={style.marginTop18}>
                      <TextInputIntRe12
                        type="text"
                        name="videoUrl"
                        placeholder={`For ex: "https://www.youtube.com/xyz"`}
                        value={videoUrl}
                        onChange={(e) => [
                          setVideoUrl(e.target.value),

                          setStartup({
                            ...startup,
                            pitch: {
                              ...startup.pitch,
                              ...{
                                video: {
                                  ...startup.pitch.video,
                                  ...{
                                    link: e.target.value.trim()
                                  }
                                }
                              }
                            }
                          })
                        ]}
                        onClick={() => setVideoUrlEdit(true)}
                        noLimit={true}
                      />
                    </div>
                  </div>
                </div>

                {/* Enter video thumbnail screen */}
                <div style={style.scroll}>
                  <div style={style2.nameContainer}>
                    {/* Text */}
                    <TxtInter
                      size={21.6}
                      weight={400}
                      content={"Upload your video's thumbnail"}
                      state={"enabled"}
                      style={style.colorBlack}
                    />

                    {/* Video upload avatar */}
                    <div style={style.iconWrap}>
                      <RectUpload
                        width={240}
                        height={160}
                        pic={
                          startup.pitch.video.thumbnail ? startup.pitch.video.thumbnail : videoImg
                        }
                        imageInputRef={imageInputRef}
                        onChange={(e) => {
                          let files;
                          if (e.dataTransfer) {
                            files = e.dataTransfer.files;
                          } else if (e.target) {
                            files = e.target.files;
                          }
                          const reader = new FileReader();
                          reader.onload = () => {
                            setImage(reader.result);
                          };
                          reader.readAsDataURL(files[0]);
                          setShowCropper(true);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Enter website screen */}
            {props.website && (
              <div style={style.scroll}>
                <div style={style2.nameContainer}>
                  {/* Text */}
                  <TxtInter
                    size={21.6}
                    weight={400}
                    content={"Enter your website's URL"}
                    state={"enabled"}
                    style={style.colorBlack}
                  />

                  {/* Input for website */}
                  <div style={style.marginTop18}>
                    <TextInputIntRe12
                      type="text"
                      name="website"
                      placeholder={`For ex: "https://www.xyz.com"`}
                      value={website}
                      onChange={(e) => [
                        setWebsite(e.target.value),

                        setStartup({
                          ...startup,
                          pitch: {
                            ...startup.pitch,
                            ...{
                              website: e.target.value.trim()
                            }
                          }
                        })
                      ]}
                      onClick={() => setWebsiteEdit(true)}
                      noLimit={true}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Enter challenge screen */}
            {props.challenge && (
              <div style={style.scroll}>
                <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                  {/* Text */}
                  <TxtInter
                    size={21.6}
                    weight={400}
                    content={"What challenges are you facing?"}
                    state={"enabled"}
                    style={style.colorBlack}
                  />

                  {/* Input for challenge */}
                  <div style={style.marginTop18}>
                    <TextAreaIntRe12
                      isShown={true}
                      limit="100"
                      type="text"
                      name="challenge1"
                      placeholder={
                        "Outline the three main obstacles and limitations hindering the development of your product or service."
                      }
                      value={challenge1}
                      onChange={(e) => {
                        if (e.target.value.length < 101) {
                          setChallenge1(e.target.value);

                          setStartup({
                            ...startup,
                            pitch: {
                              ...startup.pitch,
                              ...{
                                challenge1: e.target.value.trim()
                              }
                            }
                          });
                        }
                      }}
                      onClick={() => setChallenge1Edit(true)}
                      rows={4}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Enter mentor sectors screen */}
            {props.mentorSectors && (
              <div style={style.scroll}>
                <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                  {/* Text */}
                  <TxtInter
                    size={21.6}
                    weight={400}
                    content={"In what areas do you need mentorship?"}
                    state={"enabled"}
                    style={style.colorBlack}
                  />

                  {/* Input for mentorSectors */}
                  <div style={style.marginTop18}>
                    <TextAreaIntRe12
                      isShown={true}
                      limit="200"
                      name="mentorSectors"
                      placeholder={
                        "What are the primary areas where you need guidance and support crucial for achieving success?"
                      }
                      value={mentorSectors}
                      onChange={(e) => {
                        if (e.target.value.length < 201) {
                          setMentorSectors(e.target.value);

                          setStartup({
                            ...startup,
                            pitch: {
                              ...startup.pitch,
                              ...{
                                mentorSector1: e.target.value.trim()
                              }
                            }
                          });
                        }
                      }}
                      onClick={() => setMentorSectorsEdit(true)}
                      rows={4}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* Enter pitch deck screen */}
            {props.pitchDeck && (
              <div style={style.scroll}>
                <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                  {/* Text */}
                  <TxtInter
                    size={21.6}
                    weight={400}
                    content={"Enter your pitch deck's URL"}
                    state={"enabled"}
                    style={style.colorBlack}
                  />

                  {/* Input for pitchDeck */}
                  <div style={style.marginTop18}>
                    <TextInputIntRe12
                      type="text"
                      name="pitchDeck"
                      placeholder={`For ex: "https://www.xyz.com"`}
                      value={pitchDeck}
                      onChange={(e) => [
                        setPitchDeck(e.target.value),

                        setStartup({
                          ...startup,
                          pitch: {
                            ...startup.pitch,
                            ...{
                              pitchDeck: e.target.value.trim()
                            }
                          }
                        })
                      ]}
                      onClick={() => setPitchDeckEdit(true)}
                      noLimit={true}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {!props.video && (
            <div>
              {/* Back button */}
              <BackFAB
                onClick={() => [
                  props.setEdit(false),
                  props.setEditHover(false),
                  props.allowScroll()
                ]}
              />

              {/* Next button */}
              <NextFAB
                content={"Done"}
                onClick={() => [
                  props.setEdit(false),
                  props.setEditHover(false),
                  props.allowScroll()
                ]}
              />
            </div>
          )}

          {props.video && (
            <div>
              {/* Back button */}
              <BackFAB
                onClick={() =>
                  screen === 0
                    ? [props.setEdit(false), props.setEditHover(false), props.allowScroll()]
                    : setScreen((p) => p - 1)
                }
              />

              {/* Dot carousal */}
              <div style={style.dotArray}>
                <Dot screen={screen} actualScreen={0} />
                <Dot screen={screen} actualScreen={1} />
              </div>

              {/* Next button */}
              <NextFAB
                content={screen === 1 ? "Done" : "Next"}
                onClick={() =>
                  screen === 1
                    ? [props.setEdit(false), props.setEditHover(false), props.allowScroll()]
                    : setScreen((p) => p + 1)
                }
              />

              {/* Image cropper */}
              {showCropper && (
                <CropperPopup
                  image={image}
                  onClick={uploadFile}
                  setImage={setImage}
                  aspectRatio={1.5}
                />
              )}
            </div>
          )}
        </div>
      </div>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default EditPopup4;
