import React from "react";
import { Routes as Switch, Route } from "react-router-dom";

// Onboarding
import Login from "../pages/Onboarding/Login";
import Choice from "../pages/Onboarding/Choice";
import UserType from "../pages/Onboarding/UserType";
import UserProfile from "../pages/Onboarding/UserProfile";
import OrgCreate from "../pages/Onboarding/OrgCreate";
import OrgJoin from "../pages/Onboarding/OrgJoin";

// Platform
import Landing from "../pages/Platform/Landing";
import Landing2 from "../pages/Platform/Landing2";
import Home from "../pages/Platform/Home";
import Startup from "../pages/Platform/Startup";
import StartupEdit from "../pages/Platform/StartupEdit";
import Community from "../pages/Platform/Community";

// App
import Prototype from "../pages/App/Prototype";

const Routes = () => {
  return (
    <Switch>
      {/* Onboarding */}
      <Route path="/login" element={<Login />} />
      <Route path="/userType" element={<UserType />} />
      <Route path="/choice" element={<Choice />} />
      <Route path="/userProfile" element={<UserProfile />} />
      <Route path="/orgCreate" element={<OrgCreate />} />
      <Route path="/orgJoin" element={<OrgJoin />} />

      {/* Platform */}
      <Route path="/" element={<Landing />} />
      <Route path="/landing" element={<Landing2 />} />
      {/* <Route path="/home" element={<Home />} /> */}
      <Route path="/startup/:id" element={<Startup />} />
      <Route path="/startup/edit/:id" element={<StartupEdit />} />

      {/* App */}
      {/* <Route path="/app/prototype" element={<Prototype />} /> */}

      {/* Communities */}
      <Route path="/community/:id" element={<Community />} />
    </Switch>
  );
};

export default Routes;
