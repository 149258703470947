import { useContext } from "react";

// Importing references
import SrRefColour from "../../../ref/colours";
import { gradientBgTextBox, gradientBorderTextBox, gradientText } from "../../helpers/themeColors";

// Importng context
import MainContext from "../../helpers/context/mainContext";

// Importing helper functions
import { newLineText } from "../../helpers/themeColors";

// Importing components
import TxtInter from "../../01_atoms/text/Inter";
import EditBtn0 from "../../01_atoms/buttons/iconBtns/EditBtn0";
import style from "./style";

// EditComponent which renders on startEdit page
const EditComponent = () => {
  // Importing states from mainContext
  const {
    startup,
    secondaryColor,
    primaryColor,
    invertBackground,
    setEdit7,
    setAllHoverFalse,
    setEditHover7,
    editHover7
  } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.editOuterDiv,
      backgroundColor: editHover7 && SrRefColour.Neutral1C
    },
    innerDiv: {
      ...style.editInnerDiv,
      border: `1px dotted ${SrRefColour.Neutral2B}`
    },
    contentWrap: {
      ...style.textWrap,
      ...gradientBgTextBox(secondaryColor, primaryColor, 0.6, invertBackground),
      ...gradientBorderTextBox(secondaryColor, primaryColor, invertBackground)
    }
  };

  return (
    <div
      onClick={() => setEdit7(true)}
      onMouseEnter={() => [setAllHoverFalse(), setEditHover7(true)]}
      onMouseLeave={() => setEditHover7(false)}
      style={style2.outerDiv}
    >
      <div style={style2.innerDiv}>
        <div style={style2.contentWrap}>
          <TxtInter
            size={21.6}
            weight={600}
            content={"Traction"}
            style={gradientText(secondaryColor, primaryColor, invertBackground)}
          />

          {/* Traction text */}
          <div style={style.marginTop6}>
            {startup.pitch.traction
              ? newLineText(startup.pitch.traction, secondaryColor, primaryColor, invertBackground)
              : newLineText(
                  "Please write about metrics focused on product usage, customer behaviour, engagement, other operational aspects and if possible, the unit economics of your business.",
                  secondaryColor,
                  primaryColor,
                  invertBackground
                )}
          </div>

          <div style={{ position: "absolute", top: "11px", right: "11px" }}>
            <EditBtn0 selected={editHover7} />
          </div>
        </div>
      </div>
    </div>
  );
};

// Component which renders on startup page
const Component = () => {
  // Importing states from mainContext
  const { startup, secondaryColor, primaryColor, invertBackground } = useContext(MainContext);

  // Dynamic styles for this component
  const style2 = {
    outerDiv: {
      ...style.outerDiv,
      ...gradientBgTextBox(secondaryColor, primaryColor, 0.6, invertBackground),
      ...gradientBorderTextBox(secondaryColor, primaryColor, invertBackground)
    }
  };

  return (
    <div style={style2.outerDiv}>
      <TxtInter
        size={21.6}
        weight={600}
        content={"Traction"}
        style={gradientText(secondaryColor, primaryColor, invertBackground)}
      />

      {/* Traction text */}
      <div style={style.marginTop6}>
        {startup.pitch.traction
          ? newLineText(startup.pitch.traction, secondaryColor, primaryColor, invertBackground)
          : newLineText(
              "Talk about how your product solves the pain point you’ve just described in a unique way. How does this set you apart from your competitors?",
              secondaryColor,
              primaryColor,
              invertBackground
            )}
      </div>
    </div>
  );
};

const Traction = () => {
  // State to check if user is on startup page or startupEdit pae
  const { ifEdit } = useContext(MainContext);

  return ifEdit ? <EditComponent /> : <Component />;
};

export default Traction;
