import SrRefColour from "../../../../ref/colours";

const style = {
  // flexRow: {
  //   display: "flex",
  //   flexDirection: "row"
  // },
  // spaceBetween: {
  //   display: "flex",
  //   justifyContent: "space-between"
  // },
  // alignItemsCenter: {
  //   display: "flex",
  //   alignItems: "center"
  // },
  // overflowAuto: {
  //   overflow: "auto"
  // },
  colorGrey: {
    color: SrRefColour.Neutral2A
  },
  maxWidth: {
    width: "max-content"
  },
  marginLeft9: {
    marginLeft: "9px"
  },

  // Widths, heights and margins

  //custom styling for this component
  colorButton: {
    display: "flex",
    alignItems: "center",
    width: "inherit",
    marginLeft: "6px",
    marginRight: "6px",
    padding: "6px 15px 6px 6px",
    borderRadius: "24px",
    cursor: "pointer"
  },
  colorCircle: {
    width: "32px",
    height: "32px",
    borderRadius: "16px",
    transition: "0.2s ease-in"
  },
  colorCirle2: {
    width: "32px",
    height: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "16px"
  },
  plusBtn: {
    display: "flex",
    alignItems: "center",
    width: "inherit",
    marginLeft: "6px",
    marginRight: "6px",
    padding: "6px 15px 6px 6px",
    borderRadius: "24px",
    cursor: "pointer",
    transition: "0.2s ease-in"
  }
};

export default style;
