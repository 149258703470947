const style = {
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  alignItemsCenter: {
    display: "flex",
    alignItems: "center"
  },
  paddingVert1: { padding: "1px 0" },
  icon20: {
    width: "20px",
    height: "20px",
    cursor: "pointer"
  },
  gap6: {
    gap: "6px"
  },
  gap3: {
    gap: "3px"
  },
  status: { display: "flex", justifyContent: "center", width: "100px" },
  greenDot: {
    width: "16px",
    height: "16px",
    borderRadius: "8px",
    backgroundColor: "#4AA13B"
  },
  redDot: {
    width: "16px",
    height: "16px",
    borderRadius: "8px",
    backgroundColor: "#DD2828"
  },

  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    // margin: `${breakpoint2 ? 24 : 0}px 12px 0`
    width: "calc(100% - 24px)",
    marginLeft: "12px",
    marginRight: "12px",
    padding: "12px",
    backgroundImage: "linear-gradient(to bottom right, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.03))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)",
    borderRadius: "12px",
    overflow: "scroll"
  },
  outerDiv: {
    display: "flex",
    flexDirection: "column",
    gap: "6px"
  },
  innerDiv: {
    display: "flex",
    flexDirection: "row",
    gap: "6px"
  },
  expandedWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "222px" // 300px - 72px for 3 icons and -6px for gap
  },
  expandedInnerWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "6px"
  },
  guestEditOuterWrap: {
    display: "flex",
    flexDirection: "row",
    gap: "12px"
  },
  guestEditInnerWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "3px",
    padding: "3px 6px 3px 9px",
    backgroundImage: "linear-gradient(to bottom right, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.03))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)",
    borderRadius: "6px",
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },

  cancelBtnWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "3px",
    padding: "3px 6px 3px 9px",
    backgroundImage: "linear-gradient(to bottom right, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.03))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)",
    borderRadius: "6px",
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  }
};

export default style;
