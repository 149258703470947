import React, { useState } from "react";
import SrRefColour from "../../../../../ref/colours";
import TxtInter from "../../../text/Inter";
import style from "./style";

// Back FAB button
const BackFAB = ({ onClick }) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    divStyle: {
      ...style.divStyle,
      borderWidth: `1px solid ${SrRefColour.Neutral1D}`,
      backgroundColor: btnHover ? SrRefColour.Neutral1C : SrRefColour.Neutral1D
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={onClick}
      style={style.clickable}
    >
      <div style={style2.divStyle}>
        <TxtInter
          content={"Back"}
          state={"enabled"}
          style={style.colorBlack}
          weight={300}
          size={14.4}
        />
        {/* <SrRefTextIntLi10 content={"Back"} state={"enabled"} style={style.colorBlack} /> */}
      </div>
    </div>
  );
};

export default BackFAB;
