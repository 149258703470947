import SrRefColour from "../../../ref/colours";

const style = {
  deleteBtn: {
    position: "absolute",
    top: "3px",
    right: "3px",
    zIndex: 1
  },
  borderRadius6: { borderRadius: "6px" },
  colorBlack: { color: "black" },

  outerWrap: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    width: "fit-content",
    height: "fit-content"
  },
  innerWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "3px"
  }
};

export default style;
