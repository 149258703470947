import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Importing colors
import SrRefColour from "../../../ref/colours";

// Importing APIs
import { deleteAdmin } from "../../helpers/community";

// Importing components
import TxtInter from "../../01_atoms/text/Inter";
import DeleteBtn0 from "../../01_atoms/buttons/iconBtns/DeleteBtn0";

import style from "./style";

const speakerImgWidth = 120;
const speakerNameLength = 10;

// Returns speaker objects
const AdminList = ({ eventEdit, speakers, setFetchMore }) => {
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  // func to delete speakers
  const handleSDel = async (sid) => {
    const json = await deleteAdmin(sid);
    if (!json.success) {
      snackbar("Error in removing admin");
      return;
    }
    snackbar("Admin removed");
    setFetchMore(true);
  };

  // Dynamic styling for this component
  const style2 = {
    adminWrap: {
      border: `0.5px solid ${SrRefColour.Neutral2A}`,
      ...style.borderRadius6,
      cursor: !eventEdit && "pointer"
    }
  };

  // For each speaker, return an image with apt picture, href, name and designation
  return speakers?.map((e, i) => (
    <div style={style.outerWrap} key={i}>
      {/* Speaker's picture */}
      <img
        onClick={() => {
          if (!eventEdit) {
            window.open(e?.url, "_blank", "noreferrer");
          }
        }}
        src={e?.picture}
        width={speakerImgWidth}
        height={speakerImgWidth}
        alt="Speaker's picture"
        style={style2.adminWrap}
      />

      <div style={style.innerWrap}>
        {/* Speaker's name */}
        <TxtInter
          weight={400}
          size={17.28}
          content={
            (e.fname + " " + e.lname)?.length > speakerNameLength + 2
              ? (e.fname + " " + e.lname).slice(0, speakerNameLength) + "..."
              : (e.fname ? e.fname : "User") + (e.lname ? e.lname : "")
          }
          style={{ maxWidth: `${speakerImgWidth}px`, ...style.colorBlack }}
        />
      </div>

      {/* Edit button position */}
      {eventEdit && (
        <>
          {/* <Edit button /> */}
          {/* <div onClick={() => setSpeakerEdit(i)} style={style.editBtn}>
            <EditBtn0 />
          </div> */}

          <div onClick={() => handleSDel(e.id)} style={style.deleteBtn}>
            <DeleteBtn0 />
          </div>
        </>
      )}
    </div>
  ));
};

export default AdminList;
