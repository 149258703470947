const style = {
  positionAbsolute: {
    position: "absolute"
  },
  fitContentWidth: {
    width: "fit-content"
  },
  fitContentHeight: {
    height: "fit-content"
  },

  centeredTextWrap: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  centeredText: {
    position: "absolute",
    marginLeft: "48px",
    marginRight: "48px",
    textAlign: "center"
  },

  borderRadius12: { borderRadius: "12px" },

  // Widths, heights and margins
  marginTop6: {
    marginTop: "6px"
  },

  // Custom styling for this component
  outerDiv: {
    width: "240px",
    height: "160px",
    borderRadius: "24px",
    marginTop: "24px",
    transition: "0.2s ease-in"
  },
  linkStyle: {
    position: "absolute",
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },

  // Custom styling for EditComponent
  editContainer: {
    width: "fit-content",
    height: "fit-content",
    borderRadius: "18px",
    padding: "3px",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  },
  editOuterWrap: {
    borderRadius: "16px",
    position: "relative",
    padding: "8px",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  }
};

export default style;
