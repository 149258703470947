import React, { useState, useContext, useRef, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Importing Colors
import SrRefColour from "../../../../ref/colours";

// Importing helper functions
import { Industries } from "../../../helpers/localData";
import { returnRGBA } from "../../../helpers/themeColors";

// Importing APIs
import { imageUploadApi } from "../../../helpers/baseFuncs";

// Importing Contexts
import MainContext from "../../../helpers/context/mainContext";

// Importing Texts
import TxtInter from "../../../01_atoms/text/Inter";
import TxtRubik from "../../../01_atoms/text/rubik";

// Importing Components
import AvatarUpload from "../../../01_atoms/avatar/AvatarUpload";
import Avatar from "../../../01_atoms/avatar/Avatar";
import BackFAB from "../../../01_atoms/buttons/FABs/BackFAB";
import NextFAB from "../../../01_atoms/buttons/FABs/NextFab";
import Dot from "../../../01_atoms/dot";
import ListInputInt12 from "../../../01_atoms/listInputs/ListInputInt12";
import ListPopupInt12 from "../../../01_atoms/listInputs/ListPopupInt12";
import TextInputIntRe12 from "../../../01_atoms/textInputs/TextInputIntRe12";
import CropperPopup from "../cropperPopup/CropperPopup";

import { style } from "./style";

const EditPopup1 = ({
  currentScreen,
  startupName,
  startupLogo,
  allowScroll,
  setEdit,
  setEditHover
}) => {
  // Main context
  const {
    breakpoint3,
    scrollBoxWidth,
    // API related,
    setupNew,
    startup,
    setStartup,
    setLoadMore,

    primaryColor,
    savePhase2Data,
    snackbar
  } = useContext(MainContext);

  // Scroll view
  const [screen, setScreen] = useState(currentScreen === -1 ? 0 : currentScreen);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollLeft = screen * scrollBoxWidth;
  }, [screen]);

  const returnPrimary = returnRGBA(primaryColor?.r, primaryColor?.g, primaryColor?.b, 1);

  // useStates
  const [orgName, setOrgName] = useState(startup.name);
  const [orgNameEdit, setOrgNameEdit] = useState(false);
  const [logo, setLogo] = useState(startup.logo);
  const [orgBrief, setOrgBrief] = useState(startup.brief);
  const [orgBriefEdit, setOrgBriefEdit] = useState(false);
  const [orgIndustry, setOrgIndustry] = useState(startup.industry);
  const [sector1, setSector1] = useState(startup.sectors[0]);
  const [sector1Edit, setSector1Edit] = useState(false);
  const [sector2, setSector2] = useState(startup.sectors[1]);
  const [sector2Edit, setSector2Edit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isOrgIndSelecting, setOrgIndSelecting] = useState(false);

  const id = Number(window.location.pathname.split("/")[3]);

  // Image
  const imageInputRef = useRef();
  const [image, setImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [lenOfDesc1, setLenOfDesc1] = useState(0);
  const [lenOfDesc2, setLenOfDesc2] = useState(0);
  const [lenOfDesc3, setLenOfDesc3] = useState(0);

  // Upload image
  const uploadFile = async (image) => {
    if (image.size / 1024 / 1024 <= 10) {
      var json = await imageUploadApi(`/empCreateOrg/orgLogo`, image);

      if (!json.success) {
        setShowCropper(false);
        snackbar("An error occurred, please try again.");
        setLoading(false);
        return;
      }

      setLogo(json.url);
      setStartup({
        ...startup,
        logo: json.url
      });
      setShowCropper(false);
      snackbar("Image uploaded successfully");
      setLoading(false);
    } else {
      setShowCropper(false);
      snackbar("Image size should be less than 10mb");
    }
  };

  useEffect(() => {
    // Used to save data before unmounting
    return async () => {
      const body = {
        name: orgName,
        logo,
        brief: orgBrief,
        industry: orgIndustry,
        sector1,
        sector2
      };

      await savePhase2Data(id, body);
      setLoadMore(true);
    };
  }, [orgName, logo, orgBrief, orgIndustry, sector1, sector2]);

  //dynamic styling for this component
  const style2 = {
    backgroundWrap: {
      ...style.alignItemsCenter,
      justifyContent: breakpoint3 ? "none" : "center"
    },
    cardContainer: {
      ...style.cardContainer,
      width: `${scrollBoxWidth}px`,
      border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
      marginTop: breakpoint3 ? "24px" : 0
    },
    scrollViewContainer: {
      ...style.scrollWrap,
      ...style.marginTop48,
      width: `${scrollBoxWidth}px`
    },
    nameContainer: {
      ...style.paddingHor24,
      width: `${scrollBoxWidth}px`
    }
  };

  return (
    <div>
      {/* Background */}
      <div style={style2.backgroundWrap}>
        {/* Bordered card */}
        <div style={style2.cardContainer}>
          {/* Shram logo at the top */}
          <div style={style.logoOuterWrap}>
            <div style={style.logoInnerWrap}>
              {/* User org logo */}
              <Avatar pic={startupLogo} width={50} borderColor={returnPrimary} />

              {/* User org name */}
              <TxtRubik weight={600} size={36} content={startupName} style={style.marginLeft12} />
            </div>
          </div>

          {/* Scroll view */}
          <div style={style2.scrollViewContainer} ref={scrollRef}>
            {/* Upload org logo screen */}
            <div style={style.scroll}>
              <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                {/* Text */}
                <TxtInter
                  weight={400}
                  size={21.6}
                  content={"Upload your organization's logo"}
                  state={"enabled"}
                />

                {/* Image upload avatar */}
                <div style={style.uploadWrap}>
                  <AvatarUpload
                    width={122}
                    pic={setupNew.addOrg.logo ? setupNew.addOrg.logo : logo}
                    imageInputRef={imageInputRef}
                    onChange={(e) => {
                      let files;
                      if (e.dataTransfer) {
                        files = e.dataTransfer.files;
                      } else if (e.target) {
                        files = e.target.files;
                      }
                      const reader = new FileReader();
                      reader.onload = () => {
                        setImage(reader.result);
                      };
                      reader.readAsDataURL(files[0]);
                      setShowCropper(true);
                    }}
                    innerWidth={6}
                  />
                </div>

                {/* Note to users */}
                {/* <div style={style.marginTop24}>
                  <SrRefTextIntLi10
                    content={
                      "Sadly, this image picker only accepts images less than size 1mb and resolution 1000x1000px. Sorry :)"
                    }
                  />
                </div> */}
              </div>
            </div>

            {/* Enter org name screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <TxtInter
                  weight={400}
                  size={21.6}
                  content={"What is your organization called?"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for org name */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="orgName"
                    isShown={true}
                    limit="25"
                    placeholder={"Organization's short name"}
                    value={orgName}
                    onChange={(e) => {
                      if (e.target.value.length < 26) {
                        setOrgName(e.target.value),
                          setStartup({
                            ...startup,
                            name: e.target.value.trim()
                          });
                      }
                    }}
                    onClick={() => setOrgNameEdit(true)}
                  />
                </div>
              </div>
            </div>

            {/* Enter org brief screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <TxtInter
                  weight={400}
                  size={21.6}
                  content={"Describe what you do in one line"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for org brief */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="orgBrief"
                    isShown={true}
                    limit="50"
                    placeholder={`"Food libraries in your neighbourhood"`}
                    value={orgBrief}
                    onChange={(e) => {
                      if (e.target.value.length < 51) {
                        setOrgBrief(e.target.value),
                          setStartup({
                            ...startup,
                            brief: e.target.value.trim()
                          });
                      }
                    }}
                    onClick={() => setOrgBriefEdit(true)}
                  />
                </div>
              </div>
            </div>

            {/* Enter org industry screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <TxtInter
                  weight={400}
                  size={21.6}
                  content={"What Industry do you belong to?"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* List input for industry */}
                <div style={style.marginTop18}>
                  <ListInputInt12
                    onPress={() =>
                      isOrgIndSelecting ? setOrgIndSelecting(false) : setOrgIndSelecting(true)
                    }
                    placeholder={"Industry"}
                    condition={orgIndustry !== -1}
                    // -1 because industry are stored in array in
                    // frontend which starts from 0 and industry in db from 1
                    content={Industries[orgIndustry - 1]}
                  />
                </div>

                {/* List popup for industry */}
                {isOrgIndSelecting && (
                  <ListPopupInt12
                    array={Industries}
                    value={orgIndustry}
                    setValue={setOrgIndustry}
                    setValueSelect={setOrgIndSelecting}
                    // If setting up industry through the setup guide
                    setupIndustry={true}
                    setStartup={setStartup}
                    startup={startup}
                  />
                )}
              </div>
            </div>

            {/* Enter sectors screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <TxtInter
                  weight={400}
                  size={21.6}
                  content={"Add two sectors you are related to"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for sector 1 */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="sector1"
                    isShown={true}
                    limit="20"
                    placeholder={"For ex: Food delivery"}
                    topText={"Sector 1"}
                    value={sector1}
                    onChange={(e) => {
                      if (e.target.value.length < 21) {
                        setSector1(e.target.value),
                          setStartup({
                            ...startup,
                            sectors: [...[e.target.value.trim(), sector2]]
                          });
                      }
                    }}
                    onClick={() => setSector1Edit(true)}
                  />
                </div>

                {/* Input for sector 2 */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="sector2"
                    isShown={true}
                    limit="20"
                    placeholder={"For ex: Logistics"}
                    topText={"Sector 2"}
                    value={sector2}
                    onChange={(e) => {
                      if (e.target.value.length < 21) {
                        setSector2(e.target.value),
                          setStartup({
                            ...startup,
                            sectors: [...[sector1, e.target.value.trim()]]
                          });
                      }
                    }}
                    onClick={() => setSector2Edit(true)}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Back button */}
          <BackFAB
            onClick={() =>
              screen === 0
                ? [setEdit(false), setEditHover(false), allowScroll()]
                : setScreen((p) => p - 1)
            }
          />

          {/* Dot carousal */}
          <div style={style.dotArray}>
            <Dot screen={screen} actualScreen={0} />
            <Dot screen={screen} actualScreen={1} />
            <Dot screen={screen} actualScreen={2} />
            <Dot screen={screen} actualScreen={3} />
            <Dot screen={screen} actualScreen={4} />
          </div>

          {/* Next button */}
          <NextFAB
            content={screen === 4 ? "Done" : "Next"}
            onClick={() =>
              screen === 4
                ? [setEdit(false), setEditHover(false), allowScroll()]
                : setScreen((p) => p + 1)
            }
          />

          {/* Image cropper */}
          {showCropper && <CropperPopup image={image} onClick={uploadFile} setImage={setImage} />}
        </div>
      </div>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default EditPopup1;
