const style = {
  marginHor6: {
    marginLeft: "6px",
    marginRight: "6px"
  },
  outerWrap: {
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll"
  },
  textWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "47px",
    minWidth: "47px",
    height: "47px",
    minHeight: "47px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "47px",
    marginLeft: "6px",
    marginRight: "6px"
  },

  // Custom styling for EditComponent
  editOuterContainer: {
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll"
  },
  teamSize: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "47px",
    minWidth: "47px",
    height: "47px",
    minHeight: "47px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "47px"
  }
};

export default style;
