import SrRefColour from "../../../ref/colours";

const style = {
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  popup: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    zIndex: 25
  },
  positionAbsolute: {
    position: "absolute"
  },
  surfaceBorder: {
    border: `0.5px solid ${SrRefColour.Neutral2A}`,
    boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
    zIndex: 30
  },
  surfaceBlur: {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    backgroundImage:
      "linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)"
  },
  clickable: {
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },
  backgroundWhite: { backgroundColor: SrRefColour.Neutral0 },

  // Widths, heights and margins

  // Custom style for this component

  outerDiv: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    zIndex: 25,
    position: "absolute"
  },
  containerDiv: {
    display: "flex",
    flexDirection: "column",
    border: `0.5px solid ${SrRefColour.Neutral2A}`,
    boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
    zIndex: 30,
    backgroundColor: SrRefColour.Neutral0
  },

  blurContainer: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    zIndex: 25,
    position: "absolute",
    backgroundColor: "rgba(0, 0, 0, 0.8)",
    backgroundImage:
      "linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)",
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  }
};

export default style;
