import SrRefColour from "../../../../../ref/colours";

const style = {
  icon24: { width: "24px", height: "24px" },
  clickable: {
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },
  transition: {
    transition: "0.2s ease-in"
  },

  // Widths, heights and margins
  marginHor6: {
    marginLeft: "6px",
    marginRight: "6px"
  },

  iconStyle: {
    width: "20px",
    height: "20px",
    marginLeft: "6px"
  },

  // Custom style for EditBtn component
  editPhoneContainer: {
    padding: "12px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)",
    transition: "0.2s ease-in"
  },

  editScreenContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxHeight: "50px",
    padding: "14px 17px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)",
    transition: "0.2s ease-in"
  }
};

export default style;
