const style = {
  marginLeft6: {
    marginLeft: "6px"
  },
  outerDiv: {
    display: "flex",
    flexDirectiion: "row",
    alignItems: "center"
  }
};

export default style;
