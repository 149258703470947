import SrRefColour from "../../../ref/colours";

const style = {
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  status: { width: "100px", textAlign: "center" },

  marginTop12: {
    marginTop: "12px"
  },

  marginBot12: {
    marginBottom: "12px"
  },
  guestWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    marginBottom: "12px"
  },
  guestHeadingWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "calc(100% - 48px)",
    margin: "36px 12px 0",
    overflow: "scroll"
  },
  attendeeWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "12px"
  },
  specialGuestOuterDiv: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    marginBottom: "12px"
  },
  specialGuestInnerDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "calc(100% - 48px)",
    margin: "36px 12px 0",
    overflow: "scroll"
  },
  specialGuestWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "12px"
  },
  startupOuterWrap: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    marginBottom: "12px"
  },
  startupInnerWrap: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    width: "calc(100% - 48px)",
    margin: "36px 12px 0",
    overflow: "scroll"
  },
  startupWrap: {
    display: "flex",
    flexDirection: "coloumn",
    gap: "12px"
  }
};

export default style;
