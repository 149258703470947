import React, { useState } from "react";
import SrRefColour from "../../../../ref/colours";
import { style } from "./style";

// Text input for Inter regular font size 12
const TextInputIntRe12Small = ({ type, min, max, placeholder, value, onChange, onClick }) => {
  const [inputHover, setInputHover] = useState(false);
  const [inputClick, setInputClick] = useState(false);

  const setInputHoverFunc = () => {
    setInputClick(true);
  };

  // Dynamic styling for this compoennt
  const style2 = {
    inputWrap: {
      border:
        inputHover || inputClick
          ? `1px solid ${SrRefColour.Neutral2A}`
          : `1px solid ${SrRefColour.Neutral2B}`,
      ...style.textInputSmall,
      ...style.intRe12,
      ...style.transition
    }
  };

  return (
    <input
      type={type}
      min={min}
      max={max}
      name={name}
      placeholder={placeholder}
      id={"textInput"}
      value={value}
      onChange={onChange}
      onMouseEnter={() => setInputHover(true)}
      onMouseLeave={() => setInputHover(false)}
      onClick={onClick}
      style={style2.inputWrap}
      required
    />
  );
};

export default TextInputIntRe12Small;
