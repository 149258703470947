import React, { useState } from "react";
import SrRefColour from "../../../../../ref/colours";
import { SrCompIconHome } from "../../../../01_atoms/icons";
import style from "./style";

// Home button
const HomeBtn = ({ pressed, onClick }) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    innerDiv: {
      ...style.homeInnerDiv,
      borderColor: pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={onClick}
        style={style.clickable}
      >
        <div style={style2.innerDiv}>
          <div style={style.icon24}>
            <SrCompIconHome size={24} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBtn;
