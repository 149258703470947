import { useContext } from "react";

// Importing Context
import MainContext from "../../helpers/context/mainContext";

// Importing Components
import OrgDetails from "../../04_organisms/OrgDetails";
import TeamDetails from "../../04_organisms/TeamDetails";
import OtherDetails from "../../04_organisms/OtherDetails";
import Problem from "../../04_organisms/Problem";
import Solution from "../../04_organisms/Solution";
import Infographic from "../../04_organisms/Infographic";
import Video from "../../04_organisms/Video";
import Features from "../../04_organisms/Features";
import Market from "../../04_organisms/Market";
import Traction from "../../04_organisms/Traction";
import Website from "../../04_organisms/Website";

import style from "./style";

const Component = () => {
  const { startup, returnPrimary, fullWidth } = useContext(MainContext);
  //Dynamic and combined styling for this component
  const style2 = {
    mainSectionOuterWrap: {
      ...style.mainSectionOuterWrap,
      borderColor: returnPrimary
    }
  };

  return (
    <div style={style.outerWrap}>
      {/* Startup listing */}
      <div style={style.innerWrap}>
        {/* Org details */}
        <OrgDetails />

        <div style={style.teamOuterWrap}>
          {/* Team */}
          <TeamDetails startup={startup} returnPrimary={returnPrimary} />

          {/* Other */}
          <OtherDetails />
        </div>
      </div>

      <div style={style2.mainSectionOuterWrap}>
        {/* Bottom top section */}
        <div style={style.bottomTopWrap}>
          {/* Problem */}
          <Problem />

          {/* Solution */}
          <Solution />
        </div>

        {/* Bottom center section */}
        <div style={style.bottomCenterWrap}>
          {/* Infographic */}
          <div style={style.marginTop24}>{<Infographic w={fullWidth} startup={startup} />}</div>

          {/* Video */}
          <Video />
        </div>

        {/* Bottom bottom section */}
        <div style={style.bottomBottomWrap}>
          {/* Features */}
          <Features />

          {/* Traction */}
          <Traction />

          {/* Target market */}
          <Market />

          {/* Website */}
          <Website />
        </div>
      </div>

      {/* BITS section */}
      {/* <div style={style2.mainSectionOuterWrap}>
        <div style={style2.bottomTopWrap}>
          <Challenges />
        </div>

        <div style={style2.bottomBottomWrap}>
          <MentorshipAreas />

          <PitchDeck />
        </div>
      </div> */}
    </div>
  );
};

const EditComponent = ({ imageInputRef, setImage, setShowCropper }) => {
  // Get states from MainContext
  const { returnPrimary, fullWidth } = useContext(MainContext);

  // Dynamic style for this component
  const style2 = {
    bottomSectionWrap: {
      ...style.bottomSectionWrap,
      borderColor: returnPrimary
    },

    bitsWrap: {
      ...style.bitsWrap,
      borderColor: returnPrimary
    }
  };

  return (
    <div style={style.outerWrap}>
      {/* Startup listing */}
      <div style={style.orgWrap}>
        {/* Org details */}
        <OrgDetails />

        <div style={style.teamWrap}>
          {/* Team */}
          <TeamDetails />

          {/* Other */}
          <OtherDetails />
        </div>
      </div>

      <div style={style2.bottomSectionWrap}>
        {/* Bottom top section */}
        <div style={style.bottomRightWrap}>
          {/* Problem */}
          <Problem />

          {/* Solution */}
          <Solution />
        </div>

        {/* Bottom center section */}
        <div style={style.bottomCenterWrap}>
          {/* Infographic */}
          <div>
            <Infographic
              w={fullWidth}
              imageInputRef={imageInputRef}
              setShowCropper={setShowCropper}
              setImage={setImage}
            />
          </div>

          {/* Video */}
          <Video />
        </div>

        {/* Bottom bottom section */}
        <div style={style.bottomBottomWrap}>
          <div style={style.width100}>
            {/* Features */}
            <Features />

            {/* Unique features */}
            <Traction />

            {/* Target market */}
            <Market />
          </div>

          {/* Website */}
          <Website />
        </div>
      </div>

      {/* BITS section */}
      {/* <div style={style2.bitsWrap}>
        <div style={style.BITSLeftOuterDiv}>
          <Challenges />
        </div>

        <div style={style.BITSRightOuterDiv}>
          <MentorshipAreas />

          <PitchDeck />
        </div>
      </div> */}
    </div>
  );
};

const SmallTablet = ({ imageInputRef, setImage, setShowCropper }) => {
  const { ifEdit } = useContext(MainContext);

  return ifEdit ? (
    <EditComponent
      imageInputRef={imageInputRef}
      setImage={setImage}
      setShowCropper={setShowCropper}
    />
  ) : (
    <Component />
  );
};

export default SmallTablet;
