import SrRefColour from "../../../ref/colours";

const style = {
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  spaceBetween: {
    justifyContent: "space-between"
  },
  positionPicker: {
    position: "fixed",
    bottom: "112px"
  },
  colorPickerWrap: {
    padding: "6px",
    borderRadius: "6px",
    backgroundColor: "rgba(0,0,0,0.8)",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)"
  },
  smallBtn: {
    width: "fit-content",
    marginTop: "6px",
    padding: "3px 6px",
    borderRadius: "6px"
  },
  fitContent: {
    width: "fit-content"
  },
  surfaceBorder: {
    border: `0.5px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "18px",
    zIndex: 10
  },
  surfaceBlur: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    backgroundImage:
      "linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)"
  },
  clickable: {
    cursor: "pointer"
  },
  colorWhite: {
    color: SrRefColour.Neutral0
  },

  //Custom style for this component
  outerDiv: {
    width: "fit-content",
    // backgroundColor: "rgba(255, 255, 255, 0.8)",
    backgroundImage:
      "linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)",
    border: `0.5px solid ${SrRefColour.Neutral2A}`,
    // borderRadius: "18px",
    zIndex: 10,
    padding: "6px",
    borderRadius: "6px",
    backgroundColor: "rgba(0,0,0,0.8)"
  },
  innerDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  inputStyle: {
    color: SrRefColour.Neutral0,
    cursor: "pointer"
  }
};

export default style;
