import { apiendpoint } from "./apiendpoint";
import Error from "./error";

const apiCall = async (route, method, headers, body) => {
  // Constructing the API endpoint URI
  const URI = apiendpoint + route;

  // Initiating the API call and getting the response
  const res = await fetch(URI, {
    method,
    headers: {
      "Content-Type": "application/json",
      ...headers
    },
    body: JSON.stringify(body)
  });

  // If the request fails, throw customized error
  if (!res.ok) return Error(res.status);

  // Parsing response into JSON data
  const response = await res.json();

  // Return the JSON data
  return {
    data: response,
    success: true
  };
};

const imageUploadApi = async (route, image) => {
  // Fetching JWT from local store
  const jwt = localStorage.getItem("@jwtauth");

  // Constructing the API endpoint URI
  const URI = apiendpoint + route;

  //  Constructing the API body
  const body = new FormData();
  body.append("file", image);

  // Initiating the API call and getting the response
  const res = await fetch(URI, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${jwt}`
    },
    body
  });

  // If the request fails, throw customized error
  if (!res.ok) return Error(res.status);

  // Parsing response into JSON data
  const response = await res.json();

  // Return the JSON data
  return {
    ...response,
    success: true
  };
};

const get = async (route, defaultJWT) => {
  // Fetching JWT from local store
  const jwt = defaultJWT || localStorage.getItem("@jwtauth");

  // Defining parameters
  const method = "GET";
  const headers = {
    Authorization: jwt && `Bearer ${jwt}`
  };

  // Making the API call
  const response = await apiCall(route, method, headers);

  // Returning the JSON data / customized error
  return response;
};

const post = async (route, body, defaultJWT) => {
  // Fetching JWT from local store
  const jwt = defaultJWT || localStorage.getItem("@jwtauth");

  // Defining parameters
  const method = "POST";
  const headers = {
    Authorization: `Bearer ${jwt}`
  };

  // Making the API call
  const response = await apiCall(route, method, headers, body);

  // Returning the JSON data / customized error
  return response;
};

const put = async (route, body, defaultJWT) => {
  // Fetching JWT from local store
  const jwt = defaultJWT || localStorage.getItem("@jwtauth");

  // Defining parameters
  const method = "PUT";
  const headers = {
    Authorization: `Bearer ${jwt}`
  };

  // Making the API call
  const response = await apiCall(route, method, headers, body);

  // Returning the JSON data / customized error
  return response;
};

const patch = async (route, body, defaultJWT) => {
  // Fetching JWT from local store
  const jwt = defaultJWT || localStorage.getItem("@jwtauth");

  // Defining parameters
  const method = "PATCH";
  const headers = {
    Authorization: `Bearer ${jwt}`
  };

  // Making the API call
  const response = await apiCall(route, method, headers, body);

  // Returning the JSON data / customized error
  return response;
};

const deleteApi = async (route, body) => {
  // Fetching JWT from local store
  const jwt = localStorage.getItem("@jwtauth");

  // Defining parameters
  const method = "DELETE";
  const headers = {
    Authorization: `Bearer ${jwt}`
  };

  // Making the API call
  const response = await apiCall(route, method, headers, body);

  // Returning the JSON data / customized error
  return response;
};

export { deleteApi, get, imageUploadApi, patch, post, put };
