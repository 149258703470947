import SrRefColour from "../../../../../ref/colours";
import { useState } from "react";
// import { SrRefTextIntMe10 } from "../../../../../ref/text";
import TxtInter from "../../../../01_atoms/text/Inter";
import { SrCompIconArrowBack } from "../../../../01_atoms/icons";
import style from "./style";

// Login button
const LoginBtn = ({ onClick, text }) => {
  const [btnHover, setBtnHover] = useState(false);

  const style2 = {
    loginTextContainer: {
      ...style.loginTextContainer,
      borderColor: btnHover ? SrRefColour.Neutral1A : SrRefColour.Neutral2A
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={onClick}
      style={style.clickable}
    >
      <div style={style2.loginTextContainer}>
        {/* Login text */}
        <TxtInter
          weight={500}
          size={14.4}
          content={`Continue to ${text}`}
          state={"enabled"}
          style={style.colorBlack}
        />

        {/* Arrow icon */}
        <div style={style.arrowContainer}>
          <div style={style.invertIcon}>
            <SrCompIconArrowBack size={24} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginBtn;
