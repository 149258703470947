import { useContext } from "react";
import MainContext from "../../helpers/context/mainContext";
import WebsiteBtn from "../../03_compounds/WebsiteBtn";
import style from "./style";

// EditComponent which is visible on the startupEdit page
const EditComponent = () => {
  const { startup } = useContext(MainContext);

  return startup.pitch.website ? (
    <WebsiteBtn content={"Website"} />
  ) : (
    <WebsiteBtn content={"Your website"} />
  );
};

// Component which is visible on the startup page
const Component = () => {
  // Importing states from mainContext
  const { startup, primaryColor, invertBackground, setCardHover4, cardHover4 } =
    useContext(MainContext);

  return startup.pitch.website ? (
    <a
      onMouseEnter={() => setCardHover4(true)}
      onMouseLeave={() => setCardHover4(false)}
      href={`${startup.pitch.website}`}
      target="_blank"
      rel="noopener noreferrer"
      style={style.linkStyle}
    >
      <WebsiteBtn content={"Website"} />
    </a>
  ) : (
    <div style={style.outerDiv}>
      <WebsiteBtn
        startup={startup}
        primaryColor={primaryColor}
        invertBackground={invertBackground}
        cardHover4={cardHover4}
        content={"Your website"}
      />
    </div>
  );
};

const Website = () => {
  // ifEdit state to check if user is on startup page or startupEdit page
  const { ifEdit } = useContext(MainContext);
  return ifEdit ? <EditComponent /> : <Component />;
};

export default Website;
