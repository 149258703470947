import { useContext } from "react";

// Importing Context
import MainContext from "../../helpers/context/mainContext";

// Importing Context
import OrgDetails from "../../04_organisms/OrgDetails";
import TeamDetails from "../../04_organisms/TeamDetails";
import OtherDetails from "../../04_organisms/OtherDetails";
import Problem from "../../04_organisms/Problem";
import Solution from "../../04_organisms/Solution";
import Infographic from "../../04_organisms/Infographic";
import Video from "../../04_organisms/Video";
import Features from "../../04_organisms/Features";
import Market from "../../04_organisms/Market";
import Traction from "../../04_organisms/Traction";
import Website from "../../04_organisms/Website";

import style from "./style";

const Component = () => {
  const { fullWidthPlus, returnPrimary } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    sectionOuterWrap: {
      ...style.sectionOuterWrap,
      borderColor: returnPrimary
    }
  };

  return (
    <div style={style.outerDiv}>
      {/* Startup listing */}
      <div style={{ ...style.flexColumn, marginTop: `${74 + 12 + 36}px` }}>
        {/* Org name, description and industry+sectors chips */}
        <OrgDetails />

        <div style={style.teamWrap}>
          {/* Team */}
          <TeamDetails />

          {/* Other */}
          <div style={style.marginHor12}>
            <OtherDetails />
          </div>
        </div>
      </div>

      <div style={style2.sectionOuterWrap}>
        {/* Bottom top section */}
        <div style={style.sectionInnerWrap}>
          {/* Problem */}
          <Problem />

          {/* Solution */}
          <Solution />
        </div>

        {/* Bottom center section */}
        <div style={style.bottomCenterWrap}>
          {/* Infographic */}
          <div style={style.marginTop24}>
            <Infographic w={fullWidthPlus} />
          </div>

          {/* Video */}
          <Video />
        </div>

        {/* Bottom bottom section */}
        <div style={style.bottomBottomWrap}>
          {/* Features */}
          <Features />

          {/* Traction */}
          <Traction />

          {/* Target market */}
          <Market />

          {/* Website */}
          <Website />
        </div>
      </div>

      {/* BITS section */}
      {/* <div style={style2.sectionOuterWrap}>
        <div style={style2.sectionInnerWrap}>
          <Challenges />
        </div>
        <div style={style.bottomBottomWrap}>
          <MentorshipAreas />

          <PitchDeck />
        </div>
      </div> */}
    </div>
  );
};

const EditComponent = ({ imageInputRef, setImage, setShowCropper }) => {
  const { returnPrimary, fullWidthPlus } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    bottomOuterDiiv: {
      ...style.bottomOuterDiiv,
      borderColor: returnPrimary
    },
    bitsWrap: {
      ...style.bitsWrap2,
      borderColor: returnPrimary
    }
  };

  return (
    <div style={style.outerDiv}>
      {/* Startup listing */}
      <div style={style.innerDiv}>
        {/* Org name, description and industry+sectors chips */}
        <OrgDetails />

        <div style={style.editTeamWrap}>
          {/* Team */}
          <TeamDetails />

          {/* Other */}
          <OtherDetails />
        </div>
      </div>

      <div style={style2.bottomOuterDiiv}>
        {/* Bottom top section */}
        <div style={style.bottomDiv}>
          {/* Problem */}
          <Problem />

          {/* Solution */}
          <Solution />
        </div>

        {/* Bottom center section */}
        <div style={style.bottomDiv}>
          {/* Infographic */}
          <div>
            <Infographic
              w={fullWidthPlus}
              imageInputRef={imageInputRef}
              setShowCropper={setShowCropper}
              setImage={setImage}
            />
          </div>

          {/* Video */}
          <Video />
        </div>

        {/* Bottom bottom section */}
        <div style={style2.bottonBottomWrap}>
          <div style={style.width100}>
            {/* Features */}
            <Features />

            {/* Traction */}
            <Traction />

            {/* Target market */}
            <Market />
          </div>

          {/* Website */}
          <Website />
        </div>
      </div>

      {/* BITS section */}
      {/* <div style={style2.bitsWrap}>
        <Challenges />

        <div style={style.bitsWrap}>
          <MentorshipAreas />
          <PitchDeck />
        </div>
      </div> */}
    </div>
  );
};

const PhoneScreen = ({ setShowCropper, setImage, imageInputRef }) => {
  const { ifEdit } = useContext(MainContext);

  return ifEdit ? (
    <EditComponent
      imageInputRef={imageInputRef}
      setImage={setImage}
      setShowCropper={setShowCropper}
    />
  ) : (
    <Component />
  );
};

export default PhoneScreen;
