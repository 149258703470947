import React, { useState } from "react";

// Importing colors
import SrRefColour from "../../../../../ref/colours";

// Importing texts
import TxtInter from "../../../../01_atoms/text/Inter";

// Importing icons
import { SrCompIconEdit } from "../../../../01_atoms/icons";
import style from "./style";

// Edit button
const EditBtn = ({ pressed, onClick, breakpoint3 }) => {
  const [btnHover, setBtnHover] = useState(false);

  const style2 = {
    phoneIconContainer: {
      ...style.editPhoneContainer,
      borderColor: pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    },
    screenIconContainer: {
      ...style.editScreenContainer,
      borderColor: pressed
        ? SrRefColour.Neutral1A
        : btnHover
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={onClick}
        style={style.clickable}
      >
        {breakpoint3 ? (
          // For phone
          <div style={style2.phoneIconContainer}>
            <div style={style.icon24}>
              <SrCompIconEdit size={24} />
            </div>
          </div>
        ) : (
          // For larger screens
          <div style={style2.screenIconContainer}>
            <TxtInter weight={400} size={17.28} content={"Edit"} style={style.transition} />
            <div style={style.iconStyle}>
              <SrCompIconEdit size={20} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EditBtn;
