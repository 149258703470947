const style = {
  centeredTextWrap: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  centeredText: {
    position: "absolute",
    marginLeft: "48px",
    marginRight: "48px",
    textAlign: "center"
  },

  borderRadius12: { borderRadius: "12px" },

  justifyContentCenter: {
    display: "flex",
    justifyContent: "center"
  },

  // Widths, heights and margins

  marginTop6: {
    marginTop: "6px"
  }

  // Custom styling for this component
};

export default style;
