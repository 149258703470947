import { useContext } from "react";
import MainContext from "../../helpers/context/mainContext";
import TxtInter from "../../01_atoms/text/Inter";
import style from "./style";

const Founding = ({ onClick }) => {
  const { ifEdit, startup } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      marginLeft: ifEdit ? "8px" : "12px",
      ...style.outerDiv
    }
  };

  // Click listener which only runs in Edit state
  const handleClick = () => {
    if (!ifEdit) return;
    onClick();
  };

  return (
    <div style={style2.outerDiv} onClick={handleClick}>
      {/* Founding year */}
      <TxtInter size={17.28} weight={400} content={"Founded in"} />
      <TxtInter
        size={17.28}
        weight={600}
        content={`${startup.other.year ? startup.other.year : "year"}, ${
          startup.other.location ? startup.other.location : "location"
        }`}
        style={style.marginLeft6}
      />
    </div>
  );
};

export default Founding;
