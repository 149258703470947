const style = {
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  spaceBetween: {
    justifyContent: "space-between"
  },
  overflowAuto: {
    overflow: "auto"
  },
  // Widths, heights and margins
  width100: {
    width: "100%"
  },
  marginTop6: {
    marginTop: "6px"
  },
  marginTop12: {
    marginTop: "12px"
  },
  marginTop18: {
    marginTop: "18px"
  },
  marginHor21: {
    marginLeft: "21px",
    marginRight: "21px"
  },
  marginHor24: {
    marginLeft: "24px",
    marginRight: "24px"
  },

  paddingHor8: {
    paddingRight: "8px",
    paddingLeft: "8px"
  },

  borderRadius18: { borderRadius: "18px" },
  borderRadius16: { borderRadius: "16px" },

  // Custom styling for Component
  orgNameWrap: {
    marginTop: "12px",
    marginLeft: "24px",
    marginRight: "24px"
  },
  orgDescWrap: {
    marginTop: "6px",
    marginLeft: "24px",
    marginRight: "24px"
  },
  industryChipWrap: {
    overflow: "auto",
    marginTop: "18px"
  },

  // Custom styling for Component on large screen
  logoWrap: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  orgWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  largeIndustryChipWrap: {
    overflow: "auto",
    marginTop: "12px"
  },

  // Custom styling for EditComponent
  editOuterDiv: {
    marginTop: "12px",
    marginLeft: "24px",
    marginRight: "24px",
    borderRadius: "18px",
    cursor: "pointer",
    textDecoration: "none",
    padding: "3px",
    transition: "0.2s ease-in"
  },
  editInnerDiv: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "16px",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in",
    position: "relative",
    padding: "8px 0"
  },
  editOrgNameWrap: {
    marginTop: "12px",
    paddingLeft: "8px",
    paddingRight: "8px"
  },
  editOrgDescWrap: {
    marginTop: "6px",
    paddingLeft: "8px",
    paddingRight: "8px"
  },
  editIndustryChipWrap: {
    overflow: "auto",
    marginTop: "18px"
  },

  // Large screen EditComponent style
  largeEditOuterDiv: {
    width: "100%",
    borderRadius: "18px",
    padding: "3px",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  },

  largeEditInnerDiv: {
    display: "flex",
    borderRadius: "16px",
    position: "relative",
    padding: "8px",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  }
};

export default style;
