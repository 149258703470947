const style = {
  marginLeft6: {
    marginLeft: "6px"
  },

  //style for status chip component
  statusOuterDiv: {
    display: "flex",
    flexDirection: "row",
    width: "max-content",
    padding: "6px 12px",
    borderRadius: "9px"
  }
};

export default style;
