const style = {
  marginLeft6: {
    marginLeft: "6px"
  },
  flexRow: {
    display: "flex",
    flexDirection: "row"
  }
};

export default style;
