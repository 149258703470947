import React from "react";
import { ChromePicker } from "react-color";
import TxtInter from "../text/Inter";
import {
  white,
  gradientText,
  gradientBgTextBox,
  concatColorMixer,
  gradientBorderTextBox
} from "../../helpers/themeColors";
import style from "./style";

// AlphaPicker
// BlockPicker
// ChromePicker
// CirclePicker
// CompactPicker
// GithubPicker
// HuePicker
// MaterialPicker
// PhotoshopPicker
// SketchPicker
// SliderPicker
// SwatchesPicker
// TwitterPicker

const ColorPicker = ({
  startup,
  setStartup,
  color,
  fromCommunity,
  onChange,
  setColor,
  secondary,
  accent,
  primary,
  tertiary,
  setColorEdit
}) => {
  // Dynamic styling for this component
  const style2 = {
    positionPicker: {
      ...style.positionPicker,
      bottom: fromCommunity ? "300px" : "112px"
    },
    textWrap: {
      ...style.smallBtn,
      ...gradientBorderTextBox(color, "", "N"),
      border: color
        ? `1px solid ${concatColorMixer([color.r, color.g, color.b], white, color.a)}`
        : "1px solid rgba(0,0,0,0"
    },
    textWrap2: {
      border: "1px solid rgba(0,0,0,0",
      ...style.smallBtn,
      ...gradientBgTextBox(color, "", 0.6, "N"),
      ...gradientBorderTextBox(color, "", "N")
    },
    inputStyle2: {
      ...style.clickable,
      ...gradientText(color, "", "N")
    }
  };

  return (
    <div style={style2.positionPicker}>
      <div style={style.outerDiv}>
        <ChromePicker color={color} onChange={onChange} />

        {/* Done btn */}
        <div style={style.innerDiv}>
          <div
            onClick={() => (
              setColor(""),
              primary &&
                setStartup({
                  ...startup,
                  theme: {
                    ...startup.theme,
                    primary: { color: "" }
                  }
                }),
              secondary &&
                setStartup({
                  ...startup,
                  theme: {
                    ...startup.theme,
                    secondary: { color: "" }
                  }
                }),
              tertiary &&
                setStartup({
                  ...startup,
                  theme: {
                    ...startup.theme,
                    tertiary: { color: "" }
                  }
                }),
              accent &&
                setStartup({
                  ...startup,
                  theme: {
                    ...startup.theme,
                    accent: { color: "" }
                  }
                }),
              setColorEdit(false)
            )}
            style={style2.textWrap}
          >
            {/* <SrRefTextIntLi10 content={"Remove colour"} style={style.inputStyle} /> */}
            <TxtInter weight={300} size={14.4} content={"Remove colour"} style={style.inputStyle} />
          </div>

          <div onClick={() => setColorEdit(false)} style={style2.textWrap2}>
            <TxtInter weight={300} size={14.4} content={"Save"} style={style2.inputStyle2} />
            {/* <SrRefTextIntLi10 content={"Save"} style={style2.inputStyle2} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;
