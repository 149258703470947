import SrRefColour from "../../../ref/colours";

const style = {
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  alignItemsCenter: {
    display: "flex",
    alignItems: "center"
  },
  shramStamp: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "72px",
    maxWidth: "1600px",
    margin: "0 auto",
    borderWidth: "1px 0 0 0",
    borderStyle: "solid",
    borderColor: SrRefColour.Neutral2B,
    backgroundColor: SrRefColour.Neutral1D
  },
  colorGrey: { color: SrRefColour.Neutral2A },
  clickable: {
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },

  // Widths, heights and margins
  height30: {
    height: "30px"
  },
  marginLeft12: {
    marginLeft: "12px"
  },

  //custom styles for this component
  innerDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  imgWrap: {
    height: "30px",
    marginLeft: "12px"
  }
};

export default style;
