import SrRefColour from "../../../../ref/colours";

const style = {
  fitContent: {
    width: "fit-content"
  },
  button: {
    width: "max-content",
    height: "50px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)"
  },
  clickable: {
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },
  transition: {
    transition: "0.2s ease-in"
  },
  colorBlack: { color: SrRefColour.Neutral1A },
  customPadding: { padding: "12px 18px" },

  // Widths, heights and margins
  marginHor6: {
    marginLeft: "6px",
    marginRight: "6px"
  },

  // Custom style for component
  divStyle: {
    width: "max-content",
    height: "50px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)",
    padding: "12px 18px",
    transition: "0.2s ease-in"
  },
  buttonWrap: {
    width: "fit-content",
    cursor: "pointer",
    border: "none",
    textDecoration: "none",
    marginLeft: "6px",
    marginRight: "6px"
  }
};

export default style;
