import React, { useState, useContext, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";

// Importing Colors
import SrRefColour from "../../../../ref/colours";

// Importing heper functions
import { returnRGBA } from "../../../helpers/themeColors";

// Importing Contexts
import MainContext from "../../../helpers/context/mainContext";

// Importing texts
import TxtInter from "../../../01_atoms/text/Inter";
import TxtRubik from "../../../01_atoms/text/rubik";

// Importing Components
import Avatar from "../../../01_atoms/avatar/Avatar";
import NextFAB from "../../../01_atoms/buttons/FABs/NextFab";
import BackFAB from "../../../01_atoms/buttons/FABs/BackFAB";
import TextInputIntRe12 from "../../../01_atoms/textInputs/TextInputIntRe12";

import { style } from "./style";

const EditPopup6 = ({ startupLogo, startupName, setEdit, setEditHover, allowScroll }) => {
  // Main context
  const {
    breakpoint3,
    scrollBoxWidth,
    // API related,
    startup,
    setStartup,

    primaryColor,
    savePhase3Data
  } = useContext(MainContext);

  // Startup theme colours
  const returnPrimary = returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, 1);

  // useStates
  const [mentorSector1, setMentorSector1] = useState(startup.pitch.mentorSector1);
  const [mentorSector1Edit, setMentorSector1Edit] = useState(false);
  const [mentorSector2, setMentorSector2] = useState(startup.pitch.mentorSector2);
  const [mentorSector2Edit, setMentorSector2Edit] = useState(false);
  const [mentorSector3, setMentorSector3] = useState(startup.pitch.mentorSector3);
  const [mentorSector3Edit, setMentorSector3Edit] = useState(false);
  const [mentorSector4, setMentorSector4] = useState(startup.pitch.mentorSector4);
  const [mentorSector4Edit, setMentorSector4Edit] = useState(false);
  const [mentorSector5, setMentorSector5] = useState(startup.pitch.mentorSector5);
  const [mentorSector5Edit, setMentorSector5Edit] = useState(false);

  const saveData = async () => {
    const body = {
      mentor_sector1: mentorSector1,
      mentor_sector2: mentorSector2,
      mentor_sector3: mentorSector3,
      mentor_sector4: mentorSector4,
      mentor_sector5: mentorSector5
    };
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    const id = Number(window.location.pathname.split("/")[3]);
    await savePhase3Data(id, body);
  };

  useEffect(() => {
    return saveData;
  }, [mentorSector1, mentorSector2, mentorSector3, mentorSector4, mentorSector5]);

  //for dynamic styling
  const style2 = {
    backgroundWrap: {
      ...style.alignItemsCenter,
      justifyContent: breakpoint3 ? "none" : "center"
    },
    cardWrap: {
      ...style.cardWrap,
      width: `${scrollBoxWidth}px`,
      border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
      marginTop: breakpoint3 ? "24px" : 0
    },

    scrollViewContainer: {
      ...style.scrollViewContainer,
      width: `${scrollBoxWidth}px`
    },
    nameContainer: {
      ...style.paddingHor24,
      width: `${scrollBoxWidth}px`
    }
  };

  return (
    <div>
      {/* Background */}
      <div style={style2.backgroundWrap}>
        {/* Bordered card */}
        <div style={style2.cardWrap}>
          {/* Shram logo at the top */}
          <div style={style.logoOuterWrap}>
            <div style={style.avatarWrap}>
              {/* User org logo */}
              <Avatar pic={startupLogo} width={50} borderColor={returnPrimary} />

              {/* User org name */}
              <TxtRubik size={36} weight={600} content={startupName} style={style.marginLeft12} />
            </div>
          </div>

          {/* Scroll view */}
          <div style={style2.scrollViewContainer}>
            {/* Enter key mentorSectors screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <TxtInter
                  size={21.6}
                  weight={400}
                  content={"Your key mentorship areas"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                <div style={style.vpScrollable}>
                  {/* Input for mentorSector1 link */}
                  <div style={style.inputWrap1}>
                    <div style={style.inputStyle}>
                      <TextInputIntRe12
                        type="text"
                        name="mentorSector1"
                        placeholder={"Your first mentorship area."}
                        topText={"Mentorship area 1"}
                        value={mentorSector1}
                        onChange={(e) => {
                          setMentorSector1(e.target.value);

                          setStartup({
                            ...startup,
                            pitch: {
                              ...startup.pitch,
                              ...{
                                mentorSector1: e.target.value.trim()
                              }
                            }
                          });
                        }}
                        onClick={() => setMentorSector1Edit(true)}
                        noLimit={true}
                      />
                    </div>
                  </div>

                  {/* Input for mentorSector2 link */}
                  <div style={style.inputWrap1}>
                    <div style={style.inputStyle}>
                      <TextInputIntRe12
                        type="text"
                        name="mentorSector2"
                        placeholder={"Your second mentorship area."}
                        topText={"Mentorship area 2"}
                        value={mentorSector2}
                        onChange={(e) => {
                          setMentorSector2(e.target.value);

                          setStartup({
                            ...startup,
                            pitch: {
                              ...startup.pitch,
                              ...{
                                mentorSector2: e.target.value.trim()
                              }
                            }
                          });
                        }}
                        onClick={() => setMentorSector2Edit(true)}
                        noLimit={true}
                      />
                    </div>
                  </div>

                  {/* Input for mentorSector3 link */}
                  <div style={style.inputWrap1}>
                    <div style={style.inputStyle}>
                      <TextInputIntRe12
                        type="text"
                        name="mentorSector3"
                        placeholder={"Your third mentorship area."}
                        topText={"Mentorship area 3"}
                        value={mentorSector3}
                        onChange={(e) => {
                          setMentorSector3(e.target.value);

                          setStartup({
                            ...startup,
                            pitch: {
                              ...startup.pitch,
                              ...{
                                mentorSector3: e.target.value.trim()
                              }
                            }
                          });
                        }}
                        onClick={() => setMentorSector3Edit(true)}
                        noLimit={true}
                      />
                    </div>
                  </div>

                  {/* Input for mentorSector4 link */}
                  <div style={style.inputWrap1}>
                    <div style={style.inputStyle}>
                      <TextInputIntRe12
                        type="text"
                        name="mentorSector4"
                        placeholder={"Your fourth mentorship area."}
                        topText={"Mentorship area 4"}
                        value={mentorSector4}
                        onChange={(e) => {
                          setMentorSector4(e.target.value);

                          setStartup({
                            ...startup,
                            pitch: {
                              ...startup.pitch,
                              ...{
                                mentorSector4: e.target.value.trim()
                              }
                            }
                          });
                        }}
                        onClick={() => setMentorSector4Edit(true)}
                        noLimit={true}
                      />
                    </div>
                  </div>

                  {/* Input for mentorSector5 link */}
                  <div style={style.inputWrap1}>
                    <div style={style.inputStyle}>
                      <TextInputIntRe12
                        type="text"
                        name="mentorSector5"
                        placeholder={"Your fifth mentorship area."}
                        topText={"Mentorship area 5"}
                        value={mentorSector5}
                        onChange={(e) => {
                          setMentorSector5(e.target.value);

                          setStartup({
                            ...startup,
                            pitch: {
                              ...startup.pitch,
                              ...{
                                mentorSector5: e.target.value.trim()
                              }
                            }
                          });
                        }}
                        onClick={() => setMentorSector5Edit(true)}
                        noLimit={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Back button */}
          <BackFAB
            onClick={() => {
              setEdit(false), setEditHover(false), allowScroll();
            }}
          />

          {/* Next button */}
          <NextFAB
            content={"Done"}
            onClick={() => {
              setEdit(false), setEditHover(false), allowScroll();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EditPopup6;
