import { useContext } from "react";

// Importing colours
import SrRefColour from "../../../ref/colours";

// Importing Contexts
import CommunityContext from "../../helpers/context/communityContext";
import MainContext from "../../helpers/context/mainContext";

// Importing Texts
import TxtInter from "../../01_atoms/text/Inter";
import TxtRubik from "../../01_atoms/text/rubik";

// Importing Component
import { SrCompIconGroup } from "../../01_atoms/icons";
import { SrCompIconContentCopy } from "../../01_atoms/icons";
import Avatar from "../../01_atoms/avatar/Avatar";
import AddBtn0 from "../../01_atoms/buttons/iconBtns/AddBtn0";
import EditBtn0 from "../../01_atoms/buttons/iconBtns/EditBtn0";

import AdminList from "../../03_compounds/AdminList";
import style from "./style";

const CommunityCover = ({ w, snackbar, handleEventStatus, communityInviteLink }) => {
  const {
    editHover,
    setEditHover,
    isAdmin,
    speakers,
    setSpeakerEdit,
    setSpeakerAdd,
    setFetchMore,
    eventEdit,
    communityDefaultValue,
    setEventInfoEdit
  } = useContext(CommunityContext);

  const {
    screenWidth,
    breakpoint1,
    breakpoint2,
    breakpoint3,
    // API related
    user,

    // Community related
    eventData
  } = useContext(MainContext);

  const style2 = {
    eventCoverOuterWrap: {
      ...style.eventCoverOuterWrap,
      paddingTop: `${(breakpoint2 ? 12 * 2 : 36 * 2) + 74 + 24}px` // marginVertical wrt screen size + height of the UserPanel
    },
    eventCoverInnerWrap: {
      ...style.eventCoverInnerWrap,
      flexDirection: breakpoint3 ? "column" : "row",
      alignItems: breakpoint3 ? "flex-start" : "flex-end"
    },

    eventDetailInnerDiv: {
      ...style.eventDetailInnerDiv,
      maxWidth: `${w}px`
    },
    avatarOuterWrap: {
      ...style.avatarOuterWrap,
      backgroundImage:
        editHover &&
        "linear-gradient(to bottom right, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.03))",
      cursor: eventEdit && "pointer"
    },
    avatarInnerWrap: {
      ...style.avatarInnerWrap,
      padding: eventEdit ? "14px 8px" : "15px 8px",
      border: eventEdit && `1px dotted ${SrRefColour.Neutral2B}`
    },
    eventTitle: {
      ...style.eventTitle,
      marginLeft: eventEdit ? "6px" : "7px",
      marginRight: eventEdit ? "6px" : "7px"
    },
    eventBrief: {
      color: "black",
      maxWidth: screenWidth > 768 ? "768px" : `${w}px`,
      marginLeft: eventEdit ? "6px" : "7px",
      marginRight: eventEdit ? "6px" : "7px"
    },

    adminInnerWrap: {
      ...style.adminInnerWrap,
      maxWidth: `${w}px`
    },
    adminListContainer: {
      ...style.adminListContainer,
      paddingLeft: `calc(50% - ${w / 2 - 12}px)`,
      paddingRight: `calc(50% - ${w / 2 - 12}px)`
    },
    inviteLinkOuterWrap: {
      ...style.inviteLinkOuterWrap,
      paddingLeft: `calc(50% - ${w / 2 - 12}px)`,
      paddingRight: `calc(50% - ${w / 2 - 12}px)`
    },

    startupCardHolder: {
      ...style.startupCardHolder,
      width: !breakpoint1 && `${w}px`,
      maxWidth: `${w}px`
    },

    publishBtnInnerWrap: {
      ...style.publishBtnInnerWrap,
      maxWidth: `${w}px`
    }
  };

  return (
    // {/* Event cover */}
    <div style={style2.eventCoverOuterWrap}>
      <div style={style2.eventCoverInnerWrap}>
        <div style={style.flexColumn}>
          {/* Event Details  */}
          <div style={style.eventDetailOuterDiv}>
            <div style={style2.eventDetailInnerDiv}>
              <div
                onClick={() => eventEdit && setEventInfoEdit(true)}
                onMouseEnter={() => eventEdit && setEditHover(true)}
                onMouseLeave={() => eventEdit && setEditHover(false)}
                style={style2.avatarOuterWrap}
              >
                <div style={style2.avatarInnerWrap}>
                  {/* Event logo */}
                  <Avatar
                    width={95}
                    pic={eventData?.logo ? eventData?.logo : require("../../../media/no_org.png")}
                    borderColor={SrRefColour.Neutral2A}
                  />

                  {/* Event title */}
                  <div style={style2.eventTitle}>
                    {/* Event title */}
                    <TxtRubik
                      weight={600}
                      size={36}
                      content={eventData?.name ? eventData?.name : communityDefaultValue.name}
                      style={style.colorBlack}
                    />
                  </div>

                  {/* Event brief */}
                  <TxtInter
                    weight={400}
                    size={17.28}
                    content={
                      eventData?.description
                        ? eventData?.description
                        : communityDefaultValue.description
                    }
                    style={style2.eventBrief}
                  />

                  {eventEdit && (
                    <div style={style.editBtnWrap} onClick={() => setEditHover(true)}>
                      <EditBtn0 selected={editHover} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Admins */}
            {isAdmin && (
              <div style={style.adminOuterWrap}>
                <div style={style2.adminInnerWrap}>
                  {/* Admins title*/}
                  {speakers.length > 0 && (
                    <TxtRubik
                      weight={600}
                      size={25.92}
                      content={"Admins"}
                      style={style.adminTitle}
                    />
                  )}

                  {/* Add Title modal */}
                  {eventEdit && <AddBtn0 onClick={() => setSpeakerAdd(true)} />}
                </div>

                {/* Admin */}
                <div style={style2.adminListContainer}>
                  {/* List of Admin */}
                  <AdminList
                    speakers={speakers}
                    setSpeakerEdit={setSpeakerEdit}
                    setFetchMore={setFetchMore}
                    eventEdit={eventEdit}
                  />
                </div>
              </div>
            )}

            {isAdmin && (
              <div
                style={style2.inviteLinkOuterWrap}
                onClick={() => {
                  // Copy text to clipboard
                  navigator.clipboard.writeText(
                    `Join ${eventData?.name} on Shram's Growth Platform by using this link here: ${communityInviteLink}`
                  );
                  snackbar("Invite link copied to clipboard");
                }}
              >
                <div style={style.inviteLinkInnerDiv}>
                  <TxtInter
                    weight={400}
                    size={17.28}
                    content={`Invite link: ${communityInviteLink}`}
                  />

                  {/* Copy icon */}
                  <div style={style.copyBtnWrap}>
                    <SrCompIconContentCopy size={20} color={SrRefColour.Neutral1A} />
                  </div>
                </div>
              </div>
            )}

            {isAdmin && eventData.status !== "live" && (
              <div style={style.publishBtnOuterWrap}>
                <div style={style2.publishBtnInnerWrap}>
                  {eventData.status === "pending" && (
                    <div
                      // Add two functions for the following:
                      // 1. Publish event if not already published
                      // 2. Make event live if event is published
                      onClick={() => handleEventStatus("live")}
                      style={style.publishBtn}
                    >
                      <TxtInter weight={400} size={17.28} content={"GO LIVE"} />
                      <SrCompIconGroup />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityCover;
