import React, { useState, useContext, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Importing colors
import SrRefColour from "../../../../ref/colours";

// Importing APIs
import { apiendpoint } from "../../../helpers/apiendpoint";

// Importing Context
import MainContext from "../../../helpers/context/mainContext";

// Importing texts
import TxtInter from "../../../01_atoms/text/Inter";

// Importing components
import BackFAB from "../../../01_atoms/buttons/FABs/BackFAB";
import NextFAB from "../../../01_atoms/buttons/FABs/NextFab";
import Dot from "../../../01_atoms/dot";
import TextInputIntRe12 from "../../../01_atoms/textInputs/TextInputIntRe12";

import { style } from "./style";

const WaitlistPopup = ({ setEdit, setEditHover, allowScroll }) => {
  // Main context
  const {
    breakpoint3,
    scrollBoxWidth
    // API related,
  } = useContext(MainContext);

  // Scroll view
  const [screen, setScreen] = useState(0);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollLeft = screen * scrollBoxWidth;
  }, [screen]);

  // Snackbar
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  // useStates
  const [name, setName] = useState("");
  const [nameEdit, setNameEdit] = useState(false);
  const [startup, setStartup] = useState("");
  const [startupEdit, setStartupEdit] = useState(false);
  const [mobile, setMobile] = useState(-1);
  const [mobileEdit, setMobileEdit] = useState(false);
  const [email, setEmail] = useState("");
  const [emailEdit, setEmailEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  // Remove first character if it is 0
  const remove0 = (num) => {
    if (num.charAt(0) === "0") {
      num = num.substring(1);
    }
    return num;
  };

  // checks if digits in the mobile number is equal to 10
  const validatePhoneNumber = (num) => {
    const regEx = /^\d{10}$/;
    return regEx.test(remove0(num));
  };

  // Does email validation
  const validateEmail = (email) => {
    const regEx =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    return regEx.test(email);
  };

  const waitList = async () => {
    fetch(`${apiendpoint}/waitlist`, {
      method: "POST",
      headers: {
        "Content-type": "application/json"
      },
      body: JSON.stringify({
        name: name,
        email: email,
        org_name: startup,
        phone_no: remove0(mobile)
      })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Unauthorized");
      })
      .then((json) => {
        // snackbar(
        //   (json.msg = "Already in WaitList."
        //     ? "You are already in our waitlist!"
        //     : " You have been added to our waitlist. Thank you!")
        // );
        setScreen(screen + 1);
        setTimeout(() => {
          setEdit(false);
        }, 2000);
      })
      .catch((error) => {
        snackbar("An error occurred, please try again!");
        console.log(error);
        setEdit(false);
      });
  };

  // handleSubmit
  const handleSubmit = async () => {
    if (screen === 0 && (name === "" || startup === ""))
      snackbar("Please add your basic information to continue");
    else if (screen === 1 && !validateEmail(email) && !validatePhoneNumber(mobile))
      snackbar("Please add your contact details to continue");
    else if (screen === 1 && !validateEmail(email) && validatePhoneNumber(mobile))
      snackbar("Please enter a valid email address");
    else if (screen === 1 && validateEmail(email) && !validatePhoneNumber(mobile))
      snackbar("Please enter a valid mobile number");
    else if (screen === 1 && validateEmail(email) && validatePhoneNumber(mobile)) {
      waitList();
    } else setScreen(screen + 1);
  };

  //for dynamic styling
  const style2 = {
    backgroundWrap: {
      ...style.alignItemsCenter,
      justifyContent: breakpoint3 ? "none" : "center"
    },
    cardWrap: {
      ...style.cardWrap,
      width: `${scrollBoxWidth}px`,
      border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
      marginTop: breakpoint3 ? "24px" : 0
    },
    scrollViewContainer: {
      ...style.scrollViewContainer,
      width: `${scrollBoxWidth}px`,
      height: "316px"
    },
    nameContainer: {
      ...style.paddingHor24,
      width: `${scrollBoxWidth}px`
    }
  };

  return (
    <div>
      {/* Background */}
      <div style={style2.backgroundWrap}>
        {/* Bordered card */}
        <div style={style2.cardWrap}>
          {/* Shram logo at the top */}
          <div>
            // <img src={require("../../../../media/logo.png")} height={50} alt={"Shram logo"} />
            Devstak
          </div>

          {/* Scroll view */}
          <div style={style2.scrollViewContainer} ref={scrollRef}>
            {/* Enter basic information screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <TxtInter
                  size={21.6}
                  weight={400}
                  content={"Your basic information"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for name */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="name"
                    placeholder={"Full name"}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    onClick={() => setNameEdit(true)}
                    noLimit={true}
                  />
                </div>

                {/* Input for year */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="startup"
                    placeholder={"Startup's name"}
                    value={startup}
                    onChange={(e) => setStartup(e.target.value)}
                    onClick={() => setStartupEdit(true)}
                    noLimit={true}
                  />
                </div>
              </div>
            </div>

            {/* Enter contact details screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <TxtInter
                  size={21.6}
                  weight={400}
                  content={"Your contact details"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for email id */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="email"
                    placeholder={"Email ID"}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onClick={() => setEmailEdit(true)}
                    noLimit={true}
                  />
                </div>

                {/* Input for phone number */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="number"
                    name="mobile"
                    placeholder={"Phone number"}
                    value={mobile === -1 ? "" : mobile}
                    onChange={(e) => setMobile(e.target.value)}
                    onClick={() => setMobileEdit(true)}
                    noLimit={true}
                  />
                </div>
              </div>
            </div>

            {/* AHA moment screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                <div style={style.imgWrap}>
                  {/* Cover image */}
                  <img
                    src={require("../../../../media/setup_guide_aha_3.png")}
                    width={180}
                    alt={"Waitlisted!"}
                  />

                  {/* Aha moment text */}
                  <TxtInter
                    size={21.6}
                    weight={400}
                    content={"Joined waitlist!"}
                    state={"enabled"}
                    style={{ ...style.marginTop24, ...style.textCenter, ...style.colorBlack }}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Back button */}
          {screen !== 2 && (
            <BackFAB
              onClick={() =>
                screen === 0
                  ? [setEdit(false), setEditHover(false), allowScroll()]
                  : setScreen((p) => p - 1)
              }
            />
          )}

          {/* Dot carousal */}
          {screen !== 2 && (
            <div style={style.dotArray}>
              <Dot screen={screen} actualScreen={0} />
              <Dot screen={screen} actualScreen={1} />
            </div>
          )}

          {/* Next button */}
          {screen !== 2 && (
            <NextFAB content={screen === 1 ? "Done" : "Next"} onClick={() => handleSubmit()} />
          )}
        </div>
      </div>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default WaitlistPopup;
