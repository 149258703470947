import React from "react";
import TxtInter from "../../text/Inter";
import { gradientText, gradientBgTextBox } from "../../../helpers/themeColors";
import style from "./style";
import { StatusArr } from "../../../helpers/localData";

// Chip for status
const StatusChip = ({ accentColor, primaryColor, status }) => {
  // Dynamic styles for this component
  const style2 = {
    outerDiv: {
      ...style.statusOuterDiv,
      ...gradientBgTextBox(accentColor, primaryColor, 0.75)
    },
    textStyle: {
      ...style.marginLeft6,
      ...gradientText(accentColor, primaryColor)
    },
    statusStyle: {
      margin: "2px",
      ...gradientText(accentColor, primaryColor)
    }
  };

  return (
    <div style={style2.outerDiv}>
      <TxtInter
        weight={600}
        size={14.4}
        content={status !== -1 ? `${StatusArr[status]}` : "Funding status"}
        style={style2.statusStyle}
      />

      <TxtInter weight={400} size={17.28} content={"at present"} style={style2.textStyle} />
    </div>
  );
};

export default StatusChip;
