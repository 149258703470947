import React, { useState } from "react";
import SrRefColour from "../../../../../ref/colours";
// import { SrRefTextIntRe12 } from "../../../../../ref/text";
import TxtInter from "../../../../01_atoms/text/Inter";
import { SrCompIconStarRateFilled, SrCompIconStarRateUnfilled } from "../../../../01_atoms/icons";
import style from "./style";

const RateIcon20 = ({ pressed, onChange, value, darkMode }) => {
  return (
    <div style={style.icon20Clickable} onClick={() => onChange({ target: { value: value } })}>
      {pressed && pressed > value - 1 ? (
        <SrCompIconStarRateFilled
          size={20}
          color={darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
        />
      ) : (
        <SrCompIconStarRateUnfilled
          size={20}
          color={darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
        />
      )}
    </div>
  );
};

const RateIcon24 = ({ onChange, value, pressed, darkMode }) => {
  return (
    <div style={style.icon24Clickable} onClick={() => onChange({ target: { value: value } })}>
      {pressed && pressed > value - 1 ? (
        <SrCompIconStarRateFilled
          size={24}
          color={darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
        />
      ) : (
        <SrCompIconStarRateUnfilled
          size={24}
          color={darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
        />
      )}
    </div>
  );
};

// Rate button
const RateBtn = ({ pressed, onClick, breakpoint3, darkMode, onChange }) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.outerDiv,
      backgroundColor: darkMode ? "rgb(0,0,0,0.15)" : "rgb(255,255,255,0.8)",
      borderColor:
        pressed && pressed > 1
          ? darkMode
            ? SrRefColour.Neutral0
            : SrRefColour.Neutral1A
          : btnHover
          ? darkMode
            ? SrRefColour.Neutral2B
            : SrRefColour.Neutral2A
          : darkMode
          ? SrRefColour.Neutral2A
          : SrRefColour.Neutral2B
    },

    largeOuterDiv: {
      ...style.largeOuterDiv,
      backgroundColor: darkMode ? "rgb(0,0,0,0.15)" : "rgb(255,255,255,0.8)",
      borderColor:
        pressed && pressed > 1
          ? darkMode
            ? SrRefColour.Neutral0
            : SrRefColour.Neutral1A
          : btnHover
          ? darkMode
            ? SrRefColour.Neutral2B
            : SrRefColour.Neutral2A
          : darkMode
          ? SrRefColour.Neutral2A
          : SrRefColour.Neutral2B
    },

    largeInnerDiv: {
      ...style.largeInnerDiv,
      color: darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={onClick}
        style={{ ...style.positionRelative, cursor: "default" }}
      >
        {breakpoint3 ? (
          <div style={style2.outerDiv}>
            {/* Star icon inputs for rating */}
            <RateIcon24 value={1} onChange={onChange} pressed={pressed} darkMode={darkMode} />
            <RateIcon24 value={2} onChange={onChange} pressed={pressed} darkMode={darkMode} />
            <RateIcon24 value={3} onChange={onChange} pressed={pressed} darkMode={darkMode} />
            <RateIcon24 value={4} onChange={onChange} pressed={pressed} darkMode={darkMode} />
            <RateIcon24 value={5} onChange={onChange} pressed={pressed} darkMode={darkMode} />
          </div>
        ) : (
          // For larger screens
          <div style={style2.largeOuterDiv}>
            <TxtInter size={17.28} weight={400} content={"Rate"} style={style2.largeInnerDiv} />
            {/* Star icon inputs for rating */}
            <RateIcon20 value={1} onChange={onChange} pressed={pressed} darkMode={darkMode} />
            <RateIcon20 value={2} onChange={onChange} pressed={pressed} darkMode={darkMode} />
            <RateIcon20 value={3} onChange={onChange} pressed={pressed} darkMode={darkMode} />
            <RateIcon20 value={4} onChange={onChange} pressed={pressed} darkMode={darkMode} />
            <RateIcon20 value={5} onChange={onChange} pressed={pressed} darkMode={darkMode} />
          </div>
        )}
      </div>
    </div>
  );
};

export default RateBtn;
