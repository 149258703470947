import SrRefColour from "../../../../../ref/colours";

const style = {
  clickable: {
    width: "fit-content",
    cursor: "pointer",
    textDecoration: "none"
  },

  invertIcon: { transform: "rotateY(180deg)" },
  colorBlack: { color: SrRefColour.Neutral1A },
  colorWhite: { color: SrRefColour.Neutral0 },

  //custom styling for components
  arrowContainer: {
    width: "24px",
    height: "24px",
    marginLeft: "12px"
  },

  CTAInnerDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "fit-content",
    padding: "12px 18px",
    borderRadius: "12px",
    backgroundImage: "linear-gradient(to bottom right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  },

  //custom styling for cta button
  ctaOuterDiv: {
    width: "fit-content",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  }
};

export default style;
