import React, { useState } from "react";
import SrRefColour from "../../../../ref/colours";
import { SrCompIconEdit } from "../../icons";
import Avatar from "../Avatar";
import style from "./style";

const AvatarUpload = ({ innerWidth, imageInputRef, width, pic, onChange }) => {
  const [btnHover, setBtnHover] = useState(false);

  // For dynamic styling
  const style2 = {
    outerDiv: {
      ...style.positionAbsolute,
      bottom: `${innerWidth}px`,
      right: `${innerWidth}px`
    },

    innerDiv: {
      ...style.avatarUpInnerDiv,
      borderColor: btnHover ? SrRefColour.Neutral2A : SrRefColour.Neutral2B,
      backgroundColor: btnHover ? SrRefColour.Neutral1C : SrRefColour.Neutral0
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={() => {
        imageInputRef.current.click();
      }}
      style={style.clickable}
    >
      <div style={style.positionRelative}>
        {/* Avatar */}
        <Avatar width={width} pic={pic} borderColor={SrRefColour.Neutral2B} />

        <div style={style2.outerDiv}>
          {/* Edit button */}
          <div style={style2.innerDiv}>
            {/* Input */}
            <input
              type="file"
              ref={imageInputRef}
              onChange={onChange}
              style={{ display: "none" }}
            />

            {/* Edit icon */}
            <SrCompIconEdit size={20} color={SrRefColour.Neutral2A} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvatarUpload;
