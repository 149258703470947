import SrRefColour from "../../../../../ref/colours";

const style = {
  clickable: {
    width: "fit-content",
    cursor: "pointer",
    textDecoration: "none"
  },
  button: {
    width: "fit-content",
    padding: "12px 24px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "24px",
    backgroundColor: SrRefColour.Neutral1C
  },
  icon: { width: "24px", height: "24px" },
  colorBlack: { color: SrRefColour.Neutral1A },
  colorWhite: { color: SrRefColour.Neutral0 },

  // Widths, heights and margins
  marginLeft12: { marginLeft: "12px" },

  //custom styling for share button
  shareOuterDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "0.2s ease-in",
    padding: "12px",
    borderRadius: "12px",
    cursor: "pointer"
  }
};

export default style;
