import { deleteApi, get, patch, post, put } from "./baseFuncs";

const getAllPlatformData = async () => {
  const data = await get("/platform/");
  return data;
};

const getUserStartupData = async (org_id) => {
  const data = await get(`/platform/org/${org_id}`);
  return data;
};

const getCommunityData = async (community_id) => {
  const data = await get(`/community?community_id=${community_id}`);
  return data;
};

// API to change the status of the community
const changeCommunityStatus = async (community_id, body) => {
  const data = await put(`/community/${community_id}`, body.data);
  return data;
};

const addAdmin = async (eventId, body) => {
  const data = await put(`/community/addAdmin/${eventId}`, body.data);
  return data;
};

const deleteAdmin = async (adminId) => {
  const data = await deleteApi(`/community/admin/${adminId}`);
  return data;
};

const inviteMember = async (eventId) => {
  const data = post(`/community/inviteMember/${eventId}`);
  return data;
};

export {
  getAllPlatformData,
  getUserStartupData,
  getCommunityData,
  changeCommunityStatus,
  addAdmin,
  deleteAdmin,
  inviteMember
};
