import { useContext } from "react";

// Importing Context
import MainContext from "../../helpers/context/mainContext";

// Importing Components
import OrgDetails from "../../04_organisms/OrgDetails";
import TeamDetails from "../../04_organisms/TeamDetails";
import OtherDetails from "../../04_organisms/OtherDetails";
import Problem from "../../04_organisms/Problem";
import Solution from "../../04_organisms/Solution";
import Infographic from "../../04_organisms/Infographic";
import Video from "../../04_organisms/Video";
import Features from "../../04_organisms/Features";
import Market from "../../04_organisms/Market";
import Traction from "../../04_organisms/Traction";
import Website from "../../04_organisms/Website";
import style from "./style";

const EditComponent = ({ imageInputRef, setImage, setShowCropper }) => {
  const { returnPrimary, halfWidth } = useContext(MainContext);

  // Dynamic style for this component
  const style2 = {
    bottomSectionWrap: {
      ...style.bottomSectionWrap,
      borderColor: returnPrimary
    },
    bottomRightWrap: {
      ...style.bottomRightWrap,
      width: `${halfWidth}px`
    },
    websiteWrap: {
      ...style.websiteWrap,
      width: `${halfWidth + 24}px`
    },
    bitsWrap: {
      ...style.bitsWrap,
      borderColor: returnPrimary
    },
    BITSLeftOuterDiv: {
      ...style.flexColumn,
      width: `${halfWidth + 24}px`
    },
    BITSRightOuterDiv: {
      ...style.BITSRightOuterDiv,
      width: `${halfWidth + 24}px`
    },
    pitchWrap: {
      ...style.pitchWrap,
      width: `${halfWidth + 24}px`
    }
  };

  return (
    <div style={style.outerWrap}>
      {/* Startup listing */}
      <div style={style.TopSectionWrap}>
        {/* Org details */}
        <OrgDetails />

        <div style={style.teamWrap}>
          {/* Team */}
          <TeamDetails />

          {/* Other */}
          <OtherDetails />
        </div>
      </div>

      <div style={style2.bottomSectionWrap}>
        {/* Bottom left section */}
        <div
          style={{
            ...style.flexColumn,
            width: `${halfWidth + 24}px`
          }}
        >
          {/* Problem */}
          <Problem />

          {/* Solution */}
          <Solution />

          {/* Features */}
          <Features />

          {/* Unique features */}
          <Traction />

          {/* Target market */}
          <Market />
        </div>

        {/* Bottom right section */}
        <div style={style2.bottomRightWrap}>
          {/* Infographic */}
          <Infographic
            w={halfWidth}
            imageInputRef={imageInputRef}
            setShowCropper={setShowCropper}
            setImage={setImage}
          />

          <div style={style2.websiteWrap}>
            {/* Website */}
            <Website />

            {/* Video */}
            <Video />
          </div>
        </div>
      </div>

      {/* BITS section */}
      {/* <div style={style2.bitsWrap}>
        <div style={style2.BITSLeftOuterDiv}>
          <Challenges />
        </div>

        <div style={style2.BITSRightOuterDiv}>
          <MentorshipAreas />

          <div style={style2.pitchWrap}>
            <PitchDeck />
          </div>
        </div>
      </div> */}
    </div>
  );
};

const Component = () => {
  const { startup, returnPrimary, halfWidth } = useContext(MainContext);

  //Dynamic and combined styling for this component
  const style2 = {
    mainSectionOuterWrap: {
      ...style.mainSectionOuterWrap,
      borderColor: returnPrimary
    },

    bottomLeftOuterWrap: {
      ...style.flexColumn,
      width: `${halfWidth}px`
    },

    bottomRightOuterWrap: {
      ...style.bottomRightOuterWrap,
      width: `${halfWidth}px`
    },

    websiteOuterWrap: {
      ...style.websiteOuterWrap,
      width: `${halfWidth}px`
    }
  };

  return (
    <div style={style.outerWrap}>
      {/* Startup listing */}
      <div style={style.innerWrap}>
        {/* Org details */}
        <OrgDetails />

        <div style={style.teamOuterWrap}>
          {/* Team */}
          <TeamDetails />

          {/* Other */}
          <OtherDetails />
        </div>
      </div>

      <div style={style2.mainSectionOuterWrap}>
        {/* Bottom left section */}
        <div style={style2.bottomLeftOuterWrap}>
          {/* Problem */}
          <Problem />

          {/* Solution */}
          <Solution />

          <div style={style.marginTop24}>
            {/* Features */}
            <Features />

            {/* Traction */}
            <Traction />

            {/* Target market */}
            <Market />
          </div>
        </div>

        {/* Bottom right section */}
        <div style={style2.bottomRightOuterWrap}>
          {/* Infographic */}
          <Infographic w={halfWidth} startup={startup} />

          <div style={style2.websiteOuterWrap}>
            {/* Website */}
            <Website />

            {/* Video */}
            <Video />
          </div>
        </div>
      </div>

      {/* BITS section */}
      {/* <div style={style2.mainSectionOuterWrap}>
        <div style={style2.BITSLeftOuterDiv}>
          <Challenges />
        </div>

        <div style={style2.BITSRightOuterDiv}>
          <MentorshipAreas />

          <PitchDeck />
        </div>
      </div> */}
    </div>
  );
};

const BigTablet = ({ imageInputRef, setImage, setShowCropper }) => {
  const { ifEdit } = useContext(MainContext);
  return ifEdit ? (
    <EditComponent
      imageInputRef={imageInputRef}
      setImage={setImage}
      setShowCropper={setShowCropper}
    />
  ) : (
    <Component />
  );
};

export default BigTablet;
