import React, { useState } from "react";
import SrRefColour from "../../../../../ref/colours";
import { SrCompIconAdd } from "../../../icons";
import style from "./style";

// Add button
const AddBtn0 = ({ selected, onClick }) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    btnWrap: {
      ...style.button,
      ...style.transition,
      borderColor: selected || btnHover ? SrRefColour.Neutral2A : SrRefColour.Neutral2B,
      backgroundColor: selected || btnHover ? SrRefColour.Neutral1C : SrRefColour.Neutral0
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={onClick}
      style={style.clickable}
    >
      {/* Edit button */}
      <div style={style2.btnWrap}>
        {/* Add icon */}
        <SrCompIconAdd size={20} color={SrRefColour.Neutral2A} />
      </div>
    </div>
  );
};

export default AddBtn0;
