import React, { useState, useContext } from "react";

// Importing colors
import SrRefColour from "../../../ref/colours";

// Importing contexts
import MainContext from "../../../components/helpers/context/mainContext";

// Importing texts
import TxtInter from "../../01_atoms/text/Inter";

// Importing components
import { SrCompIconCheckBox, SrCompIconCheckBoxOutlineBlank } from "../../01_atoms/icons";
import ColorPickerBtn from "../../01_atoms/buttons/colorBtns";
import ColorPicker from "../../01_atoms/colorPickerComp";

import style from "./style";

const ColorPanel = (props) => {
  // Context
  const {
    screenWidth,
    breakpoint2,
    breakpoint3,
    // API related
    startup,
    setStartup,

    // Primary color
    primaryColor,
    setPrimaryColor,
    primaryColorEdit,
    setPrimaryColorEdit,

    // Secondary color
    secondaryColor,
    setSecondaryColor,
    secondaryColorEdit,
    setSecondaryColorEdit,

    // Tertiary color
    tertiaryColor,
    setTertiaryColor,
    tertiaryColorEdit,
    setTertiaryColorEdit,

    // Accent color
    accentColor,
    setAccentColor,
    accentColorEdit,
    setAccentColorEdit,

    invertBackground,
    setInvertBackground
  } = useContext(MainContext);

  const [showAdvanced, setShowAdvanced] = useState(false);
  // const [invertBackground, setInvertBackground] = useState(startup.theme.invertBackground);

  // Check box
  const CheckBox = ({ status, setInvertBackground, setStartup }) => {
    const [btnHover, setBtnHover] = useState(false);
    const [checkBoxFill, setCheckBoxFill] = useState(status);

    return (
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={() => {
          setInvertBackground(invertBackground === "Y" ? "N" : "Y");
          setStartup({
            ...startup,
            theme: {
              ...startup.theme,
              invertBackground: invertBackground === "Y" ? "N" : "Y"
            }
          });
        }}
        style={style.innerDiv}
      >
        {/* Checkbox for orglead */}
        {!checkBoxFill ? (
          <SrCompIconCheckBox
            size={24}
            color={btnHover ? SrRefColour.Neutral1A : SrRefColour.Neutral2A}
          />
        ) : (
          <SrCompIconCheckBoxOutlineBlank
            size={24}
            color={btnHover ? SrRefColour.Neutral1A : SrRefColour.Neutral2A}
          />
        )}

        <div style={style.marginLeft3}>
          <TxtInter
            size={17.28}
            weight={300}
            content={"Fill"}
            style={{ color: btnHover ? SrRefColour.Neutral1A : SrRefColour.Neutral2A }}
          />
        </div>
      </div>
    );
  };

  // Show more/less options link
  const Link = ({ onClick, content }) => {
    const [linkHover, setLinkHover] = useState(false);

    //for dynamic styling
    const style2 = {
      textStyle: {
        ...style.linkTextStyle,
        width: "max-content",
        color: linkHover ? SrRefColour.Neutral1A : SrRefColour.Neutral2A,
        textDecoration: `underline ${linkHover ? SrRefColour.Neutral1A : SrRefColour.Neutral2A}`
      }
    };

    return (
      <div
        onMouseEnter={() => setLinkHover(true)}
        onMouseLeave={() => setLinkHover(false)}
        onClick={onClick}
        style={style.outerDiv}
      >
        <TxtInter size={17.28} weight={300} content={content} style={style2.textStyle} />
      </div>
    );
  };

  //for dynamic styling
  const style2 = {
    outerDiv: {
      ...style.userPanelWrap,
      bottom: props.changePosition
        ? breakpoint3
          ? props.scrollPosition + props.windowHeight - (props.bodyScrollHeight - (60 + 36))
          : props.scrollPosition + props.windowHeight - (props.bodyScrollHeight - (60 + 48))
        : 0
    },
    innerDiv: {
      ...style.positionRelative, //this style is not present in style
      ...style.colorPanelInnerDiv,
      margin: "0 auto",
      boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
      maxWidth: breakpoint2 && `${screenWidth - 24}px`
    }
  };

  return (
    <div style={style2.outerDiv}>
      <div style={style2.innerDiv}>
        {/* User panel surface */}
        <div style={style.userPanelContainer}>
          <div style={style.userPanelInnerDiv}>
            {/* Colour buttons */}
            {/* Primary colour button */}
            <ColorPickerBtn
              color={primaryColor}
              content={"Primary color"}
              onClick={() => (
                setPrimaryColorEdit(!primaryColorEdit),
                setSecondaryColorEdit(false),
                setTertiaryColorEdit(false),
                setAccentColorEdit(false)
              )}
            />

            {/* Secondary colour button */}
            <ColorPickerBtn
              color={secondaryColor}
              content={"Secondary color"}
              onClick={() => (
                setPrimaryColorEdit(false),
                setSecondaryColorEdit(!secondaryColorEdit),
                setTertiaryColorEdit(false),
                setAccentColorEdit(false)
              )}
            />

            {/* Show more colour pickers when advanced options is clicked */}
            {showAdvanced && (
              <>
                {/* Tertiary colour button */}
                <ColorPickerBtn
                  color={tertiaryColor}
                  content={"Tertiary color"}
                  onClick={() => (
                    setPrimaryColorEdit(false),
                    setSecondaryColorEdit(false),
                    setTertiaryColorEdit(!tertiaryColorEdit),
                    setAccentColorEdit(false)
                  )}
                />

                {/* Accent colour button */}
                <ColorPickerBtn
                  color={accentColor}
                  content={"Accent color"}
                  onClick={() => (
                    setPrimaryColorEdit(false),
                    setSecondaryColorEdit(false),
                    setTertiaryColorEdit(false),
                    setAccentColorEdit(!accentColorEdit)
                  )}
                />

                {/* Invert colour checkbox */}
                <CheckBox
                  status={invertBackground === "Y"}
                  invertBackground={invertBackground}
                  setInvertBackground={setInvertBackground}
                  setStartup={setStartup}
                  startup={startup}
                />
              </>
            )}

            {/* Vertical line to separate the left from right section */}
            <div style={style.verticalLine}></div>

            {/* Advanced option */}
            <Link
              onClick={() => {
                setShowAdvanced(!showAdvanced);
                setTertiaryColorEdit(false);
                setAccentColorEdit(false);
              }}
              content={showAdvanced ? "Less" : "More"}
              style={{ width: "max-content" }}
            />
          </div>

          <div style={style.iconTextContainer}>
            {/* <SrCompIconFormatPaint size={20} color={SrRefColour.Neutral2A} /> */}
            <div style={style.marginLeft6}>
              <TxtInter
                size={17.28}
                weight={300}
                content={"Personalize your listing here"}
                style={style.colorGrey}
              />
            </div>
          </div>

          {/* Colour pickers */}
          {/* Primary colour picker */}
          {primaryColorEdit && (
            <ColorPicker
              color={primaryColor}
              setColor={setPrimaryColor}
              onChange={props.onPrimaryChange}
              setColorEdit={setPrimaryColorEdit}
              setStartup={setStartup}
              startup={startup}
              primary={true}
            />
          )}

          {/* Secondary colour picker */}
          {secondaryColorEdit && (
            <ColorPicker
              color={secondaryColor}
              setColor={setSecondaryColor}
              onChange={props.onSecondaryChange}
              setColorEdit={setSecondaryColorEdit}
              setStartup={setStartup}
              startup={startup}
              secondary={true}
            />
          )}

          {/* Tertiary colour picker */}
          {tertiaryColorEdit && (
            <ColorPicker
              color={tertiaryColor}
              setColor={setTertiaryColor}
              onChange={props.onTertiaryChange}
              setColorEdit={setTertiaryColorEdit}
              setStartup={setStartup}
              startup={startup}
              tertiary={true}
            />
          )}

          {/* Accent colour picker */}
          {accentColorEdit && (
            <ColorPicker
              color={accentColor}
              setColor={setAccentColor}
              onChange={props.onAccentChange}
              setColorEdit={setAccentColorEdit}
              setStartup={setStartup}
              startup={startup}
              accent={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ColorPanel;
