import SrRefColour from "../../../ref/colours";

export const style = {
  screen: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100vw",
    minHeight: "100vh",
    maxWidth: "1600px",
    margin: "0 auto",
    backgroundColor: SrRefColour.Neutral0,
    overflowX: "hidden"
  },
  scrollBox: {
    position: "relative",
    minHeight: "510px"
  },
  surfaceBorder: {
    borderRadius: "12px",
    backgroundColor: SrRefColour.Neutral0
  },
  shramLogo: {
    height: "50px",
    margin: "48px 24px 72px"
  },

  //custom style for this component
  borderCard: {
    borderRadius: "12px",
    backgroundColor: SrRefColour.Neutral0,
    position: "relative",
    minHeight: "510px"
  }
};
