import SrRefColour from "../../../../../ref/colours";

const style = {
  shell: {
    padding: "12px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    backgroundColor: "rgb(255,255,255,0.8)"
  },

  icon20: { width: "20px", height: "20px" },

  icon24: { width: "24px", height: "24px" },
  transition: {
    transition: "0.2s ease-in"
  },

  // Widths, heights and margins
  marginHor6: {
    marginLeft: "6px",
    marginRight: "6px"
  },
  marginLeft6: {
    marginLeft: "6px"
  },

  // Custom styling
  outerDiv: {
    cursor: "pointer",
    border: "none",
    textDecoration: "none",
    position: "relative"
  },
  laptopOuterDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxHeight: "50px",
    padding: "14px 17px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "12px",
    transition: "0.2s ease-in"
  }
};

export default style;
