const style = {
  outerDiv: {
    display: "inline-flex",
    flexDirection: "row",
    overflow: "auto",
    marginLeft: "21px",
    marginRight: "21px"
  }
};

export default style;
