const style = {
  button: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "32px",
    height: "32px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "16px"
  },
  clickable: {
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },

  //custom style for this component
  divStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "32px",
    height: "32px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "16px",
    transition: "0.2s ease-in"
  }
};

export default style;
