import React, { useContext, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

// Importing helper functions
import SrRefColour from "../../../../../ref/colours";

// Importing Contexts
import MainContext from "../../../../helpers/context/mainContext";

// Importing Components
import NextFAB from "../../../../01_atoms/buttons/FABs/NextFab";
import Popup from "../../../../01_atoms/popup";

import style from "./style";

const CropperPopup2 = (props) => {
  const { breakpoint3, scrollBoxWidth, screenWidth } = useContext(MainContext);

  const [cropper, setCropper] = useState();

  const getCropData = () => {
    if (cropper) {
      // Base 64 string image
      var dataurl = cropper.getCroppedCanvas().toDataURL();
      // Converting base 64 to file
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      props.onClick(new File([u8arr], "image.png", { type: mime }));
    }
  };

  // Dynamic styling for this component
  const style2 = {
    cropperOuterWrap: {
      ...style.alignCenter,
      justifyContent: breakpoint3 ? "none" : "center"
    },
    cropperWrap: {
      ...style.cropperWrap,
      height: "510px",
      borderRadius: "12px",
      backgroundColor: SrRefColour.Neutral0
    },
    cropper: {
      width: breakpoint3 ? screenWidth : "418px",
      height: breakpoint3 ? screenWidth : "418px"
    },
    borderCard: {
      ...style.surfaceBorder,
      ...style.scrollBox,
      width: `${scrollBoxWidth}px`,
      border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
      marginTop: breakpoint3 ? "24px" : 0
    }
  };

  return (
    <Popup
      onClick={props.onClick2}
      scrollPosition={props.scrollPosition}
      noPadding={props.noPadding}
      modal={breakpoint3 ? true : false}
    >
      <div style={style2.cropperOuterWrap}>
        {/* Bordered card */}
        <div style={style2.borderCard}>
          <div style={style.absolutePosition}>
            <div style={style2.cropperOuterWrap}>
              <div style={style2.cropperWrap}>
                {/* Cropper for image */}
                <Cropper
                  zoomTo={1}
                  aspectRatio={props.aspectRatio ? props.aspectRatio : 1}
                  initialAspectRatio={1}
                  preview=".img-preview"
                  src={props.image}
                  viewMode={2}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  cropBoxResizable={true}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                  guides={true}
                  style={style2.cropper}
                />

                {/* Crop button */}
                <NextFAB content={"Crop image"} onClick={getCropData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default CropperPopup2;
