import React from "react";
import defaultStyle from "./style";

const Avatar = ({ width, key, onClick, pic, style, borderColor }) => {
  const style2 = {
    outerDiv: {
      ...defaultStyle.pic,
      ...style,
      width: `${width + 5}px`,
      height: `${width + 5}px`,
      borderRadius: `${width + 5}px`,
      borderColor: borderColor
    },
    imageStyle: {
      width: `${width}px`,
      height: `${width}px`,
      borderRadius: `${width}px`
    }
  };

  return (
    <div style={style2.outerDiv} key={key} onClick={onClick}>
      <img src={pic} width={width} style={style2.imageStyle} alt={"Picture"} />
    </div>
  );
};

export default Avatar;
