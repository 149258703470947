import React from "react";

// Inter text component
const TxtInter = ({ size, weight, content, color, wrap, noTransition, style, onClick }) => {
  // Transition property for icons
  const transition = "font 0.2s ease-in-out, color 0.2s ease-in-out";

  return (
    <p
      onClick={onClick}
      style={{
        margin: 0,
        fontFamily: "Inter",
        fontSize: `${size}px`,
        fontWeight: weight,
        lineHeight: `${Math.ceil(size * 1.25)}px`,
        color: color,
        whiteSpace: wrap && "nowrap", // Ensures that text is rendered on one line
        transition: !noTransition && transition,
        ...style
      }}
    >
      {content}
    </p>
  );
};

export default TxtInter;
