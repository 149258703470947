import React, { useContext } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MainContext from "../../../helpers/context/mainContext";
import { returnRGBA } from "../../../helpers/themeColors";
import SrRefColour from "../../../../ref/colours";

import Avatar from "../../../01_atoms/avatar/Avatar";
import BackFAB from "../../../01_atoms/buttons/FABs/BackFAB";
import NextFAB from "../../../01_atoms/buttons/FABs/NextFab";
import TextAreaIntRe12 from "../../../02_molecules/textAreas";
import TxtInter from "../../../01_atoms/text/Inter";
import TxtRubik from "../../../01_atoms/text/rubik";

import { style } from "./style";

const CommentPopup = ({ comment, setCommentClicked, setComment, handleSubmit }) => {
  // Main context
  const {
    breakpoint3,
    scrollBoxWidth,
    defaultColor,
    // API related,
    user,
    userStartup
  } = useContext(MainContext);

  // Startup theme colours
  const primaryColor =
    user.userType === "emp" ? userStartup.theme?.primary.color : "rgba(200,200,200,1)";
  const returnPrimary = returnRGBA(primaryColor.r, primaryColor.g, primaryColor.b, 1);

  // Dynamic styling for this component
  const style2 = {
    outerWrap: {
      ...style.alignItemsCenter,
      justifyContent: breakpoint3 ? "none" : "center"
    },
    borderCardWrap: {
      ...style.surfaceBorder,
      ...style.scrollBox,
      width: `${scrollBoxWidth}px`,
      border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
      marginTop: breakpoint3 ? "24px" : 0
    },

    scrollViewOuterDiv: {
      ...style.scrollViewOuterDiv,
      width: `${scrollBoxWidth}px`
    },
    scrollViewInnerDiv: {
      width: `${scrollBoxWidth}px`,
      ...style.paddingHor24
    }
  };

  return (
    <div>
      {/* Background */}
      <div style={style2.outerWrap}>
        {/* Bordered card */}
        <div style={style2.borderCardWrap}>
          {/* Shram logo at the top */}
          <div style={style.logoWrap}>
            {user.userType === "emp" ? (
              <div style={style.logoInnerWrap}>
                {/* User org logo */}
                <Avatar
                  pic={userStartup.logo}
                  width={50}
                  borderColor={returnPrimary}
                  type={"logo"}
                />

                {/* User org name */}
                <TxtRubik
                  weight={600}
                  size={36}
                  content={userStartup.name}
                  style={style.marginLeft12}
                />
              </div>
            ) : (
              // Shram logo at the top
              <div style={style.logoWrap2}>
                <img src={require("../../../../media/logo.png")} height={50} alt={"Shram logo"} />
              </div>
            )}
          </div>

          {/* Scroll view */}
          <div style={style2.scrollViewOuterDiv}>
            <div style={style2.scrollViewInnerDiv}>
              <TxtInter
                weight={400}
                size={21.6}
                content={"Share feedback"}
                state={"enabled"}
                style={style.colorBlack}
              />
              {/* Input for problem */}
              <div style={style.marginTop18}>
                <TextAreaIntRe12
                  isShown={true}
                  limit="500"
                  name="problem"
                  placeholder={"Ex: Loved your idea! Let's discuss it further over GMeet?"}
                  value={comment}
                  onChange={(e) => {
                    if (e.target.value.length < 501) {
                      setComment(e.target.value);
                    }
                  }}
                  onClick={() => setCommentClicked(true)}
                  rows={4}
                />
              </div>
            </div>
          </div>

          {/* Back button */}
          <BackFAB onClick={() => setCommentClicked(false)} />

          {/* Next button */}
          <NextFAB content={"Submit"} onClick={() => handleSubmit()} />
        </div>
      </div>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default CommentPopup;
