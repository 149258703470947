import SrRefColour from "../../../../ref/colours";

const style = {
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  alignItemsCenter: {
    alignItems: "center"
  },
  clickable: {
    cursor: "pointer",
    border: "none"
  },
  card: {
    position: "relative",
    padding: "18px 0",
    borderRadius: "18px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    transition: "0.2s ease-in",
    zIndex: 1
  },
  surfaceBlur: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    backgroundImage:
      "linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)"
  },
  positionRelative: {
    position: "relative"
  },
  name: {
    margin: "6px 18px 0 18px"
  },
  description: {
    margin: "6px 18px 0 18px"
  },
  scrollWrap: {
    maxWidth: "360px",
    overflow: "auto"
  },
  scroll: {
    display: "inline-flex"
  },
  icon: { width: "24px", height: "24px", borderRadius: "6px" },
  transition: {
    transition: "0.2s ease-in"
  },
  colorBlack: { color: SrRefColour.Neutral1A },

  // Widths, heights and margins
  customMargin: {
    margin: " 12px 15px 0"
  },
  marginLeft18: { marginLeft: "18px" },

  // Custom styling for components
  blurEffect: {
    position: "absolute",
    top: "-12px",
    left: "-12px",
    zIndex: 0
  },
  nameStyle: {
    margin: "6px 18px 0 18px",
    color: SrRefColour.Neutral1A
  },
  descriptionStyle: {
    margin: "6px 18px 0 18px",
    color: SrRefColour.Neutral1A
  },
  chipInnerDiv: {
    display: "inline-flex",
    margin: " 12px 15px 0"
  },

  outerDiv: {
    cursor: "pointer",
    border: "none",
    transition: "0.2s ease-in"
  },

  innerDiv: {
    position: "relative",
    padding: "18px 0",
    borderRadius: "18px",
    border: `1px solid ${SrRefColour.Neutral2A}`,
    transition: "0.2s ease-in",
    zIndex: 1,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    backgroundImage:
      "linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)"
  }
};

export default style;
