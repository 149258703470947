const style = {
  // Widths, heights and margins
  marginRight6: {
    marginRight: "6px"
  },

  // Custom style for this component
  outerDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "48px",
    borderRadius: "12px",
    padding: "12px",
    transition: "0.2s ease-in"
  },

  // Custom styling for EditComponent
  editOuterWrap: {
    borderRadius: "18px",
    padding: "3px",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  },

  editInnerWrap: {
    borderRadius: "16px",
    position: "relative",
    padding: "8px",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  },

  editTextWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "48px",
    borderRadius: "12px",
    padding: "12px",
    transition: "0.2s ease-in"
  }
};

export default style;
