const style = {
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  alignItemsCenter: {
    display: "flex",
    alignItems: "center"
  },
  surfaceBlur: {
    backgroundImage: "linear-gradient(to bottom right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.05))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)"
  },
  clickable: {
    cursor: "pointer"
  },
  fitContent: {
    width: "fit-content"
  },
  icon: { width: "24px", height: "24px", borderRadius: "6px" },
  borderRadius12: { borderRadius: "12px" },
  transition: {
    transition: "0.2s ease-in"
  },
  customPadding: { padding: "9px 6px 6px" },
  paddingHor6: { paddingLeft: "6px", paddingRight: "6px" },
  paddingBot12: {
    paddingBottom: "12px"
  },

  // Widths, heights and margins
  marginLeft6: {
    marginLeft: "6px"
  },

  //Custom styling of components
  textWrap: {
    width: "fit-content",
    paddingBottom: "12px",
    paddingLeft: "6px",
    paddingRight: "6px"
  },
  innerDiv: {
    backgroundImage: "linear-gradient(to bottom right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.05))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)",
    paddingLeft: "6px",
    paddingRight: "6px",
    borderRadius: "12px",
    transition: "0.2s ease-in"
  },

  dropdownWrap: {
    display: "flex",
    flexDirection: "row",
    padding: "9px 6px 6px"
  },

  //custom styling for link component
  textStyle: {
    cursor: "pointer",
    transition: "0.2s ease-in"
  }
};

export default style;
