const style = {
  // Custom styling for this component
  linkStyle: {
    marginTop: "24px",
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },
  outerDiv: {
    marginTop: "24px",
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  }
};

export default style;
