import React, { useState } from "react";

// Importing references
import SrRefColour from "../../../../ref/colours";
import {
  gradientText,
  gradientBgTextBox,
  gradientBorderTextBox
} from "../../../helpers/themeColors";

// Importing components
import TxtInter from "../../text/Inter";
import { SrCompIconAdd } from "../../icons";
import style from "./style";

const ColorPickerBtn = ({ color, onClick, content }) => {
  const [btnHover, setBtnHover] = useState(false);

  // For dynamic styling
  const style2 = {
    colorButton: {
      ...style.colorButton,
      ...gradientBgTextBox(color, "", 0.7, false),
      ...gradientBorderTextBox(color, "", false)
    },

    colorCircle: {
      ...style.colorCircle,
      border: `${btnHover ? 1 : 2}px solid ${SrRefColour.Neutral0}`
    },

    colorCirle2: {
      ...style.colorCirle2,
      border: `1px solid ${SrRefColour.Neutral2A}`
    },

    plusBtn: {
      ...style.plusBtn,
      border: `1px solid ${SrRefColour.Neutral2A}`,
      backgroundColor: btnHover ? SrRefColour.Neutral1C : SrRefColour.Neutral0
    }
  };

  return (
    <div>
      {color ? (
        <div style={{ width: "max-content" }}>
          {/* Colour button */}
          <div
            onMouseEnter={() => setBtnHover(true)}
            onMouseLeave={() => setBtnHover(false)}
            style={style2.colorButton}
            onClick={onClick}
          >
            {/* Colour circle */}
            <div style={style2.colorCircle} />

            {/* Colour text */}
            <div style={style.marginLeft9}>
              <TxtInter
                weight={400}
                size={17.28}
                content={content}
                style={gradientText(color, "", false)}
              />
              {/* <SrRefTextIntRe12 /> */}
            </div>
          </div>
        </div>
      ) : (
        <div style={style.maxWidth}>
          {/* Plus btn */}
          <div
            onMouseEnter={() => setBtnHover(true)}
            onMouseLeave={() => setBtnHover(false)}
            style={style2.plusBtn}
            onClick={onClick}
          >
            {/* Colour circle */}
            <div style={style2.colorCirle2}>
              <SrCompIconAdd size={24} color={SrRefColour.Neutral2A} />
            </div>

            {/* Colour text */}
            <div style={style.marginLeft9}>
              <TxtInter content={content} weight={400} size={17.28} style={style.colorGrey} />
              {/* <SrRefTextIntRe12 content={content} style={style.colorGrey} /> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ColorPickerBtn;
