import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
// import MContext from "./mainContext";
import CommunityContext from "./communityContext";

const CommunityState = (props) => {
  const [editHover, setEditHover] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [orgs, setOrgs] = useState([]);

  // Al orgs which are not approved yet
  const [pendingOrg, setPendingOrgs] = useState([]);
  const [pendingStartups, setPendingStartups] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  // Default community iinfo
  const [communityDefaultValue, setCommunityDefaultValue] = useState({
    name: "Community's Name",
    logo: "https://shram-mvp-logo.s3.ap-south-1.amazonaws.com/org_logos/org_02.png",
    description: "A brief description of your community",
    cover: "https://shram-mvp-logo.s3.ap-south-1.amazonaws.com/platform/event/5RVEYLx7N.png"
  });

  // useStates for Events
  const [guest, setGuest] = useState([]);
  const [guestAdd, setGuestAdd] = useState(false);
  const [specialGuest, setSpecialGuest] = useState([]);
  const [specialGuestAdd, setSpecialGuestAdd] = useState(false);
  const [speakers, setSpeakers] = useState([]);
  const [speakerEdit, setSpeakerEdit] = useState(-1);
  const [speakerAdd, setSpeakerAdd] = useState(false);
  const [startups, setStartups] = useState([]);
  const [startupAdd, setStartupAdd] = useState(false);
  const [fetchMore, setFetchMore] = useState(false);

  // Used to show edit state of account
  const [accountEdit, setAccountEdit] = useState(false);

  // Used to show edit state of community
  const [eventEdit, setEventEdit] = useState(false);

  const [showPreview, setShowPreview] = useState(false);

  // Allow scrolling on body element in DOM
  const allowScroll = () => {
    const body = document.body;
    body.style.overflowY = "auto";
  };

  const navigate = useNavigate();

  // Navigate to community
  const navigateToCommunity = () => {
    let communityId = localStorage.getItem("@communityId");
    let orgid = localStorage.getItem("@orgid");
    if (communityId) {
      navigate(`/community/${communityId}`);
      return;
    }

    // If community does not exist
    if (orgid) {
      navigate(`/startup/${orgid}`);
    }
  };

  const [eventInfoEdit, setEventInfoEdit] = useState(false);
  //   const [eventData, setEventData] = useState({});

  // const [editHover, setEditHover] = useState(false);
  const [editHover1, setEditHover1] = useState(false);
  const [showInvites, setShowInvites] = useState(false);

  return (
    <CommunityContext.Provider
      value={{
        editHover,
        setEditHover,
        showLogin,
        setShowLogin,
        orgs,
        setOrgs,
        pendingOrg,
        setPendingOrgs,
        pendingStartups,
        setPendingStartups,
        isAdmin,
        setIsAdmin,
        communityDefaultValue,
        setCommunityDefaultValue,

        guest,
        setGuest,
        guestAdd,
        setGuestAdd,
        specialGuest,
        setSpecialGuest,
        specialGuestAdd,
        setSpecialGuestAdd,
        speakers,
        setSpeakers,
        speakerEdit,
        setSpeakerEdit,
        speakerAdd,
        setSpeakerAdd,
        startups,
        setStartups,
        startupAdd,
        setStartupAdd,
        fetchMore,
        setFetchMore,

        accountEdit,
        setAccountEdit,
        eventEdit,
        setEventEdit,

        allowScroll,

        navigateToCommunity,
        eventInfoEdit,
        setEventInfoEdit,

        editHover1,
        setEditHover1,

        showPreview,
        setShowPreview,
        showInvites,
        setShowInvites
      }}
    >
      {props.children}
    </CommunityContext.Provider>
  );
};

export default CommunityState;
