const style = {
  marginHor12: {
    marginLeft: "12px",
    marginRight: "12px"
  },
  marginTop12: {
    marginTop: "12px"
  },

  // Custom styling for this component
  outerDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%"
  },
  outerDiv2: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "50%"
  },

  // Custom styling for EditComponent
  editOuterDiv: {
    borderRadius: "18px",
    padding: "3px",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  },

  editInnerDiv: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: "16px",
    position: "relative",
    padding: "8px 0",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  },

  //Custom styling for EditComponent for large screen
  screenEditOuterDiv: {
    borderRadius: "18px",
    padding: "3px",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  },

  screenEditInnerDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "16px",
    position: "relative",
    height: "145px",
    padding: "8px 0",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  }
};

export default style;
