import { useContext } from "react";
import MainContext from "../../helpers/context/mainContext";

import OrgDetails from "../../04_organisms/OrgDetails";
import TeamDetails from "../../04_organisms/TeamDetails";
import OtherDetails from "../../04_organisms/OtherDetails";
import Problem from "../../04_organisms/Problem";
import Solution from "../../04_organisms/Solution";
import Infographic from "../../04_organisms/Infographic";
import Video from "../../04_organisms/Video";
import Features from "../../04_organisms/Features";
import Market from "../../04_organisms/Market";
import Traction from "../../04_organisms/Traction";
import Website from "../../04_organisms/Website";

import style from "./style";

const EditComponent = ({ imageInputRef, setImage, setShowCropper }) => {
  const { returnPrimary, oneThirdWidth, halfWidth } = useContext(MainContext);

  // Dynamic styling for this component
  const style2 = {
    bottomOuterDiiv: {
      ...style.bottomOuterDiiv,
      borderColor: returnPrimary
    },
    bottomInnerDiv: {
      ...style.flexColumn,
      ...style.spaceBetween,
      width: `${oneThirdWidth + 24}px`
    },
    bottonRightWrap: {
      ...style.bottonRightWrap,
      width: `${oneThirdWidth + 24}px`
    },
    bitsWrap: {
      ...style.bitsWrap,
      borderColor: returnPrimary
    },
    bitsRightWrap: {
      ...style.bitsRightWrap,
      width: `${halfWidth + 24}px`
    },
    bitsLeftWrap: {
      ...style.flexColumn,
      ...style.spaceBetween,
      width: `${halfWidth + 24}px`
    }
  };

  return (
    <div style={style.outerDiv}>
      {/* Startup listing */}
      <div style={style.innerDiv}>
        <div style={style.orgWrap}>
          {/* Org details */}
          <OrgDetails />
        </div>

        <div style={style.teamWrap}>
          {/* Team */}
          <TeamDetails />

          {/* Other */}
          <OtherDetails />
        </div>
      </div>

      <div style={style2.bottomOuterDiiv}>
        {/* Bottom left section */}
        <div style={style2.bottomInnerDiv}>
          <div>
            {/* Problem */}
            <Problem />

            {/* Solution */}
            <Solution />
          </div>

          <div style={style.marginTop24}>
            {/* Video */}
            <Video />
          </div>
        </div>

        {/* Bottom center section */}
        {/* Infographic */}
        <Infographic
          w={oneThirdWidth}
          imageInputRef={imageInputRef}
          setShowCropper={setShowCropper}
          setImage={setImage}
        />

        {/* Bottom right section */}
        <div style={style2.bottonRightWrap}>
          <div style={style.width100}>
            {/* Features */}
            <Features />

            {/* Unique features */}
            <Traction />

            {/* Target market */}
            <Market />
          </div>

          {/* Website */}
          <Website />
        </div>
      </div>

      {/* BITS section */}
      {/* <div style={style2.bitsWrap}>
        <div style={style2.bitsLeftWrap}>
          <Challenges />
        </div>

        <div style={style2.bitsRightWrap}>
          <MentorshipAreas />

          <PitchDeck />
        </div>
      </div> */}
    </div>
  );
};

const Component = () => {
  // Dynamic styling for this component

  const { returnPrimary, cardHover4, setCardHover4, halfWidth, oneThirdWidth, invertBackground } =
    useContext(MainContext);

  const style2 = {
    innerWrap: {
      ...style.flexRow,
      ...style.marginHor48,
      marginTop: `${74 + 36 + 36}px`
    },
    mainSectionOuterWrap: {
      ...style.mainSectionOuterWrap,
      borderColor: returnPrimary
    },

    bottomLeftOuterDiv: {
      ...style.flexColumn,
      ...style.spaceBetween,
      width: `${oneThirdWidth}px`
    },

    bottomRightOuterDiv: {
      ...style.bottonRightWrap,
      width: `${oneThirdWidth}px`
    },

    BITSLeftOuterDiv: {
      ...style.flexColumn,
      ...style.spaceBetween,
      width: `${halfWidth}px`
    },

    BITSRightOuterDiv: {
      ...style.bitsRightWrap,
      width: `${halfWidth}px`
    }
  };

  return (
    <div style={style.outerWrap}>
      {/* Startup listing */}
      <div style={style2.innerWrap}>
        <div style={{ ...style.flexRow, ...style.width50 }}>
          {/* Org details */}
          <OrgDetails />
        </div>

        <div style={style.teamWrap}>
          {/* Team */}
          <TeamDetails />

          {/* Other */}
          <OtherDetails />
        </div>
      </div>

      <div style={style2.mainSectionOuterWrap}>
        {/* Bottom left section */}
        <div style={style2.bottomLeftOuterDiv}>
          <div style={{ ...style.flexColumn, ...style.width100 }}>
            {/* Problem */}
            <Problem />

            {/* Solution */}
            <Solution />
          </div>

          {/* Video */}
          <Video />
        </div>

        {/* Bottom center section */}
        {/* Infographic */}
        <Infographic w={oneThirdWidth} />

        {/* Bottom right section */}
        <div style={style2.bottomRightOuterDiv}>
          <div style={{ ...style.flexColumn, ...style.width100 }}>
            {/* Features */}
            <Features />

            {/* traction */}
            <Traction />

            {/* Target market */}
            <Market />
          </div>

          {/* Website */}
          <Website cardHover4={cardHover4} setCardHover4={setCardHover4} />
        </div>
      </div>
    </div>
  );
};

const Laptop = ({ imageInputRef, setImage, setShowCropper }) => {
  const { ifEdit } = useContext(MainContext);

  return ifEdit ? (
    <EditComponent
      imageInputRef={imageInputRef}
      setImage={setImage}
      setShowCropper={setShowCropper}
    />
  ) : (
    <Component />
  );
};

export default Laptop;
