import React, { useState, useContext, useRef, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Importing Colors
import SrRefColour from "../../../../ref/colours";

// Importing helper functions
import { returnRGBA } from "../../../helpers/themeColors";

// Importing APIs
import { editOrgUser } from "../../../helpers/startup";

// Importing Contexts
import MainContext from "../../../helpers/context/mainContext";

// Importing Texts
import TxtInter from "../../../01_atoms/text/Inter";
import TxtRubik from "../../../01_atoms/text/rubik";

// Importing Components
import { SrCompIconCheckBox, SrCompIconCheckBoxOutlineBlank } from "../../../01_atoms/icons";
import Avatar from "../../../01_atoms/avatar/Avatar";
import BackFAB from "../../../01_atoms/buttons/FABs/BackFAB";
import NextFAB from "../../../01_atoms/buttons/FABs/NextFab";
import Dot from "../../../01_atoms/dot";
import TextInputIntRe12 from "../../../01_atoms/textInputs/TextInputIntRe12";
import ShareInviteBtn from "../../buttons/iconBtns/ShareInviteBtn";

import { style } from "./style";

const EditPopup2 = ({ startupName, startupLogo, setEdit, setEditHover, allowScroll }) => {
  // Main context
  const {
    breakpoint3,
    scrollBoxWidth,
    // API related,
    startup,
    setStartup,
    primaryColor,
    snackbar,
    savePhase2Data
  } = useContext(MainContext);

  // Scroll view
  const [screen, setScreen] = useState(0);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollLeft = screen * scrollBoxWidth;
  }, [screen]);

  // Startup theme colours
  const returnPrimary = returnRGBA(primaryColor?.r, primaryColor?.g, primaryColor?.b, 1);

  // useStates
  const [teamSize, setTeamSize] = useState(startup.teamSize);
  const [teamSizeEdit, setTeamSizeEdit] = useState(false);
  const [cofounderCount, setCofounderCount] = useState(startup.cofounderCount);
  const [cofounderCountEdit, setCofounderCountEdit] = useState(false);
  const [team, setTeam] = useState(startup.team);
  const [loading, setLoading] = useState(false);

  const saveData = async () => {
    const body = {
      cofounder_count: Number(cofounderCount),
      team_size: Number(teamSize)
    };

    // Org ID
    const id = Number(window.location.pathname.split("/")[3]);

    await savePhase2Data(id, body);
  };

  const saveMembers = async () => {
    var temp = team;
    var teamData = [];
    temp.forEach((emp) => {
      teamData.push({
        id: emp.id,
        fname: emp.fname,
        lname: emp.lname,
        designation: emp.designation,
        picture: emp.pic,
        linkedIn: emp.linkedin,
        org_id: emp.orgId,
        is_org_lead: emp.orgLead,
        representer: emp.representer
      });
    });
    var body = { changeEmpDetails: teamData };

    const json = await editOrgUser({ data: body });
    if (!json.success) {
      snackbar("updateTeam failed");
    }
  };

  useEffect(() => {
    return () => {
      saveData();
      saveMembers();
    };
  }, [cofounderCount, teamSize, team]);

  const changeRepresenter = (id) => {
    var temp = team;
    temp.forEach((t) => {
      if (t.id === id) t.representer = "Y";
      else t.representer = "N";
    });
    setStartup({
      ...startup,
      team: temp
    });
    setTeam(temp);
  };

  const setOrgLead = (id) => {
    var temp = team;
    temp.forEach((t) => {
      if (t.id === id) t.orgLead === "Y" ? (t.orgLead = "N") : (t.orgLead = "Y");
    });
    setTeam(temp);
    setStartup({
      ...startup,
      team: temp
    });
  };

  const CheckBox = ({ status, onClick }) => {
    const [btnHover, setBtnHover] = useState(false);
    const [checkBoxFill, setCheckBoxFill] = useState(status);

    return (
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={
          // setCheckBoxFill(!checkBoxFill);
          onClick
        }
      >
        {/* Checkbox for orglead */}
        {checkBoxFill ? (
          <SrCompIconCheckBox size={24} />
        ) : (
          <SrCompIconCheckBoxOutlineBlank size={24} />
        )}
      </div>
    );
  };

  //dynamic styling for this component
  const style2 = {
    backgroundWrap: {
      ...style.alignItemsCenter,
      justifyContent: breakpoint3 ? "none" : "center"
    },
    cardContainer: {
      ...style.cardContainer,
      width: `${scrollBoxWidth}px`,
      border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
      marginTop: breakpoint3 ? "24px" : 0
    },
    scrollViewContainer: {
      ...style.scrollViewContainer,
      width: `${scrollBoxWidth}px`,
      height: "316px"
    },
    nameContainer: {
      ...style.paddingHor24,
      width: `${scrollBoxWidth}px`
    }
  };

  return (
    <div>
      {/* Background */}
      <div style={style2.backgroundWrap}>
        {/* Bordered card */}
        <div style={style2.cardContainer}>
          {/* Shram logo at the top */}
          <div style={style.logoOuterWrap}>
            <div style={style.logoInnerWrap}>
              {/* User org logo */}
              <Avatar pic={startupLogo} width={50} borderColor={returnPrimary} />

              {/* User org name */}
              <TxtRubik size={36} weight={600} content={startupName} style={style.marginLeft12} />
            </div>
          </div>

          {/* Scroll view */}
          <div style={style2.scrollViewContainer} ref={scrollRef}>
            {/* Enter team size screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}

                <TxtInter
                  weight={400}
                  size={21.6}
                  content={"What is your team size?"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for cofounder count */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="number"
                    name="cofounderCount"
                    placeholder={"Total no of co-founders"}
                    topText={"Total no of co-founders"}
                    value={cofounderCount}
                    onChange={(e) => [
                      setCofounderCount(e.target.value),
                      setStartup({
                        ...startup,
                        cofounderCount: e.target.value.trim()
                      })
                    ]}
                    onClick={() => setCofounderCountEdit(true)}
                    noLimit={true}
                  />
                </div>

                {/* Input for team size */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="number"
                    name="teamSize"
                    placeholder={"Total no of team members"}
                    topText={"Total no of team members"}
                    value={teamSize}
                    onChange={(e) => [
                      setTeamSize(e.target.value),
                      setStartup({
                        ...startup,
                        teamSize: e.target.value.trim()
                      })
                    ]}
                    onClick={() => setTeamSizeEdit(true)}
                    noLimit={true}
                  />
                </div>
              </div>
            </div>

            {/* Invite team members */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <TxtInter
                  weight={400}
                  size={21.6}
                  content={"Invite team members"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for team size */}
                <div style={style.marginTop18}>
                  <TxtInter
                    weight={400}
                    size={17.28}
                    content={
                      "Share this code with your team members to invite them on this platform"
                    }
                  />
                </div>

                {/* Share invite code button */}

                <div style={style.marginTop24}>
                  <ShareInviteBtn
                    startup={startup}
                    color={startup.theme.primary.color}
                    snackbar={snackbar}
                  />
                </div>
              </div>
            </div>

            {/* Edit team representation screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <TxtInter
                  weight={400}
                  size={21.6}
                  content={"Your team representation"}
                  style={style.colorBlack}
                />

                <div style={style.vpScrollable}>
                  {/* Table headers */}
                  <div style={style.flexRowReverse}>
                    <div style={style.textWrap}>
                      <TxtInter weight={400} size={10} content={"Co-founder"} />
                    </div>
                    <div style={style.textWrap2}>
                      <TxtInter weight={400} size={10} content={"Representer"} />
                    </div>
                  </div>

                  {/* All team members */}
                  {team.map((m) => (
                    <div
                      style={{
                        marginTop: team.indexOf(m) ? "12px" : 0
                      }}
                      key={m.id}
                    >
                      <div style={style.avatarOuterDiv}>
                        {/* Team lead details */}
                        <div style={style.avatarInnerDiv}>
                          {/* Team lead pic */}
                          <Avatar
                            width={42}
                            pic={m.pic ? m.pic : require("../../../../media/no_user.png")}
                            borderColor={returnPrimary}
                            key={m}
                          />

                          {/* Team lead details */}
                          <div style={style.marginLeft12}>
                            {/* Name */}
                            <TxtInter weight={600} size={17.28} content={`${m.fname} ${m.lname}`} />

                            {/* Designation */}
                            <TxtInter weight={400} size={17.28} content={m.designation} />
                          </div>
                        </div>

                        {/* Team lead options */}
                        <div style={style.flexRow}>
                          {/* CheckBox for representer */}
                          <div style={style.checkboxWrap}>
                            <CheckBox
                              onClick={() => changeRepresenter(m.id)}
                              showRepresenter={false}
                              status={m.representer === "Y"}
                            />
                          </div>

                          {/* CheckBox for co-founder */}
                          <div style={style.checkboxWrap2}>
                            <CheckBox onClick={() => setOrgLead(m.id)} status={m.orgLead === "Y"} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Back button */}
          <BackFAB
            onClick={() =>
              screen === 0
                ? [setEdit(false), setEditHover(false), allowScroll()]
                : setScreen((p) => p - 1)
            }
          />

          {/* Dot carousal */}
          <div style={style.dotArray}>
            <Dot screen={screen} actualScreen={0} />
            <Dot screen={screen} actualScreen={1} />
            <Dot screen={screen} actualScreen={2} />
          </div>

          {/* Next button */}
          <NextFAB
            content={screen === 2 ? "Done" : "Next"}
            onClick={() =>
              screen === 2
                ? [setEdit(false), setEditHover(false), allowScroll()]
                : setScreen((p) => p + 1)
            }
          />
        </div>
      </div>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default EditPopup2;
