import React, { useState } from "react";

// import { SrRefTextIntRe12 } from "../../../../../ref/text";
import TxtInter from "../../../../01_atoms/text/Inter";
import { SrCompIconArrowBack } from "../../../../01_atoms/icons";

import style from "./style";

// Call to action button
const CTABtn = ({ onClick, text }) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling
  const style2 = {
    outerDiv: {
      ...style.ctaOuterDiv,
      padding: "2px",
      border: btnHover ? "1px solid rgba(0,0,0,1)" : "1px solid rgba(0,0,0,0)",
      borderRadius: "14px"
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={onClick}
      style={style.clickable}
    >
      <div style={style2.outerDiv}>
        <div style={style.CTAInnerDiv}>
          {/* CTA text */}
          <TxtInter
            weight={400}
            size={17.28}
            content={`${text}`}
            state={"enabled"}
            style={style.colorWhite}
          />
          {/* Arrow icon */}
          <div style={style.arrowContainer}>
            <div style={style.invertIcon}>
              <SrCompIconArrowBack size={24} color={"#ffffff"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTABtn;
