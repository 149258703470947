import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SrRefColour from "../../../ref/colours";

import TxtInter from "../../../components/01_atoms/text/Inter";
import { apiendpoint } from "../../../components/helpers/apiendpoint";
import MainContext from "../../../components/helpers/context/mainContext";
import { ProfilePics, Viewer, ViewerName } from "../../../components/helpers/localData.js";

import AvatarUpload from "../../../components/01_atoms/avatar/AvatarUpload";
import BackFAB from "../../../components/01_atoms/buttons/FABs/BackFAB";
import NextFAB from "../../../components/01_atoms/buttons/FABs/NextFab";
import Dot from "../../../components/01_atoms/dot";
import ListInputInt12 from "../../../components/01_atoms/listInputs/ListInputInt12";
import ListPopupInt12 from "../../../components/01_atoms/listInputs/ListPopupInt12";
import SetupTemplate from "../../../components/01_atoms/setupTemplate";
import TextInputIntRe12 from "../../../components/01_atoms/textInputs/TextInputIntRe12";
import CropperPopup2 from "../../../components/02_molecules/setupPopups/cropperPopup/CropperPopup2";

import { style } from "./style";

const UserProfile = () => {
  // Main context
  const {
    scrollBoxWidth,
    // API related
    setupNew,
    setUserId,
    setNew,
    loadUser
  } = useContext(MainContext);
  const navigate = useNavigate();

  // To check if Invite Code is present the params
  const [searchParams] = useSearchParams();

  // If user doesn't exist, navigate to login

  // useEffect(() => {
  //   !setupNew.newProfile === true && navigateToLogin();
  // }, []);

  // Navigate to main startup listing
  const navigateHome = () => {
    navigate("/home");
  };

  const navigateToCommunity = () => {
    let communityId = localStorage.getItem("@communityId");
    let orgid = localStorage.getItem("@orgid");
    if (communityId) {
      navigate(`/community/${communityId}`);
      return;
    }

    // If community does not exist
    if (orgid) {
      navigate(`/startup/${orgid}`);
    }
  };

  // Navigate to main startup listing
  const navigateToLogin = () => {
    navigate("/login");
  };

  // Navigate to userType page
  const navigateToUserType = () => {
    setNew({ ...setupNew, userType: "viewer" });
    navigate("/userType");
  };

  // Navigate to choice page only if the user is a startup employee
  const navigateToChoice = () => {
    setNew({ ...setupNew, userType: "employee" });
    navigate("/choice");
  };

  // Navigate to OrgCreate
  const navigateToOrgCreate = () => {
    setNew({
      ...setupNew,
      addEmp: {
        ...setupNew.addEmp,
        fName: fName.trim(),
        lName: lName.trim(),
        emailId: setupNew.addEmp.emailId,
        pp: setupNew.addEmp.pp ? setupNew.addEmp.pp : pp,
        whatsappNo:
          setupNew.userType === "viewer" ? setupNew.addEmp.phoneNo : remove0(phoneNoWA.trim()),
        designation: designation.trim(),
        userType: viewer
      }
    });

    // Add referrral_code if its present in url
    const referral_code = searchParams.get("referral_code");
    if (referral_code) {
      navigate(`/orgCreate?referral_code=${referral_code}`);
    } else {
      navigate("/orgCreate");
    }
  };

  // Add employee and continue to Home page if user is viewer
  const addViewer = (referral_code) => {
    setLoading(true);
    // do all the api calls
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    // URL to send request
    // Only difference in empExistingOrgAndDeletePsued is it first deletes psuedo employee
    const url = `${apiendpoint}/viewerExistingOrg`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        addEmp: {
          designation: setupNew.addEmp.designation.trim(),
          DOB: null,
          fname: setupNew.addEmp.fName.trim(),
          whatsapp_no: setupNew.addEmp.whatsappNo,
          email_id: setupNew.addEmp.emailId,
          gender: null,
          linkedin: null,
          lname: setupNew.addEmp.lName.trim(),
          picture: setupNew.addEmp.pp,
          is_setup: "Y"
        },
        referral_code: referral_code
      })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("Unauthorized");
      })
      .then((json) => {
        const fetchStorage = async () => {
          localStorage.setItem("@empId", String(json.data.emp_id));
          localStorage.setItem("@orgid", String(json.data.org_id));
          setUserId(parseInt(json.data.emp_id));
        };
        fetchStorage();
        loadUser();
        // navigateHome();
        navigateToCommunity();
      })
      .catch((error) => {
        snackbar("An error occurred, please try again!");
        // console.log(error);
      });
  };

  // Navigate to OrgJoin
  const navigateToOrgJoin = () => {
    setNew({
      ...setupNew,
      addEmp: {
        ...setupNew.addEmp,
        fName: fName.trim(),
        lName: lName.trim(),
        emailId: setupNew.addEmp.emailId,
        pp: setupNew.addEmp.pp ? setupNew.addEmp.pp : pp,
        whatsappNo:
          setupNew.userType === "viewer" ? setupNew.addEmp.phoneNo : remove0(phoneNoWA.trim()),
        designation: designation.trim(),
        userType: viewer
      }
    });

    // Add Invite Code in the link if it is present in the link
    var link = "";
    const referral_code = searchParams.get("referralCode");
    const code = searchParams.get("inviteCode");
    link = code ? `/orgJoin?inviteCode=${code}` : "/orgJoin";
    if (referral_code) {
      // If user is viewer
      addViewer();
      return;
    }
    navigate(link);
  };

  // scrollPosition
  const [scrollPosition, setScrollposition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset; // Find the top offset of the window
    setScrollposition(position); // Set scrollPosition = top offset of the window
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true }); // listen to scroll event
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll view
  const [screen, setScreen] = useState(0);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollLeft = screen * scrollBoxWidth;
  }, [screen]);

  // Snackbar
  const snackbar = (message) => {
    toast(message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
  };

  // useStates
  const [fName, setFName] = useState(setupNew.addEmp.fName || "");
  const [fNameEdit, setFNameEdit] = useState(false);
  const [lName, setLName] = useState(setupNew.addEmp.lName || "");
  const [lNameEdit, setLNameEdit] = useState(false);
  const [pp, setPP] = useState(ProfilePics[Math.floor(Math.random() * 10)]);
  const [emailIdEdit, setEmailIdEdit] = useState("");
  const [phoneNoWA, setPhoneNoWA] = useState(setupNew.addEmp.whatsappNo || "");
  const [phoneNoWAEdit, setPhoneNoWAEdit] = useState("" || "");
  const [linkHover, setLinkHover] = useState(false);
  const [designation, setDesignation] = useState(setupNew.addEmp.designation || "");
  const [designationEdit, setDesignationEdit] = useState("");
  const [viewer, setViewer] = useState(-1);
  const [loading, setLoading] = useState(false);

  const [isViewerSelecting, setViewerSelecting] = useState(false);

  // Remove first character if it is 0
  const remove0 = (num) => {
    if (num.charAt(0) === "0") {
      num = num.substring(1);
    }
    return num;
  };

  // checks if digits in the mobile number is equal to 10
  const validatePhoneNumber = (num) => {
    const regEx = /^\d{10}$/;
    return regEx.test(remove0(num));
  };

  // Does email validation
  const validateEmail = (email) => {
    const regEx =
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;
    return regEx.test(email);
  };

  // Image
  const imageInputRef = useRef();
  const [image, setImage] = useState(null);
  const [showCropper, setShowCropper] = useState(false);

  // Upload image
  const uploadFile = (image) => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";

    if (image.size / 1024 / 1024 <= 10) {
      const fd = new FormData();
      fd.append("file", image);

      fetch(`${apiendpoint}/empCreateOrg/empPP`, {
        method: "POST",
        body: fd,
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
        .then((response) => {
          if (response.ok) return response.json();
          else {
            throw new Error("unauthorised");
          }
        })
        .then((json) => {
          console.log("json", json);
          setPP(json.url);
          setNew({
            ...setupNew,
            addEmp: {
              ...setupNew.addEmp,
              pp: json.url
            }
          });
          setShowCropper(false);
          snackbar("Image uploaded successfully");
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setShowCropper(false);
          snackbar("An error occurred, please try again.");
          setLoading(false);
        });
    } else {
      setShowCropper(false);
      snackbar("Image size should be less than 10mb");
    }
  };

  const createViewer = () => {
    var jwt = localStorage.getItem("@jwtauth");
    if (!jwt) jwt = "";
    const body = {
      designation: viewer === 3 ? "Explorer" : ViewerName[viewer],
      DOB: null,
      fname: fName,
      lname: lName,
      gender: null,
      whatsapp_no: setupNew.userType === "viewer" ? setupNew.addEmp.phoneNo : phoneNoWA,
      picture: pp,
      linkedin: null,
      user_type: viewer === 1 || viewer === 2 ? "exp" : Viewer[viewer]
    };
    fetch(`${apiendpoint}/newUserCreate`, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${jwt}`
      },
      body: JSON.stringify({
        data: body
      })
    })
      .then((res) => {
        if (res.ok) return res.json();
        else throw new Error("Unauthorized");
      })
      .then((json) => {
        loadUser();
        // navigateHome();
        const initialLocation = localStorage.getItem("@initial");
        initialLocation && localStorage.removeItem("@initial");

        // Navigate to home if initial link is not present
        initialLocation ? navigate(initialLocation) : navigateToCommunity();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // handleSubmit
  const handleSubmit = async () => {
    if (screen === 0 && (setupNew.fname === "" || setupNew.lname === "")) {
      snackbar("Please write your full name to continue", "warning", true);
    } // If you are just a viewer, only validate the email id
    else if (screen === 2 && setupNew.userType !== "viewer" && !validatePhoneNumber(phoneNoWA)) {
      snackbar("Please enter a valid mobile number", "warning", true);
    } else if (screen === 3 && setupNew.userType === "viewer" && viewer === -1) {
      snackbar("Please select what describes you best to continue", "warning", true);
    } else if (screen === 3 && setupNew.userType !== "viewer" && designation === "") {
      snackbar("Please write the designation you hold in your organization", "warning", true);
    } else if (screen === 3 && setupNew.userType === "viewer" && viewer !== -1) {
      setScreen(screen + 1);
      setTimeout(() => {
        createViewer();
      }, 2000);
    } else if (screen === 3 && setupNew.userType !== "viewer" && designation !== "") {
      setScreen(screen + 1);
      setTimeout(() => {
        if (setupNew.orgJoin === false) {
          navigateToOrgCreate();
        } else {
          navigateToOrgJoin();
        }
      }, 2000);
    } else setScreen(screen + 1);
  };

  return (
    <div style={style.outerMost}>
      <SetupTemplate>
        {/* Scroll view */}
        <div
          style={{ ...style.scrollWrap, ...{ width: `${scrollBoxWidth}px`, height: "316px" } }}
          ref={scrollRef}
        >
          {/* Enter name screen */}
          <div style={style.scroll}>
            <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
              {/* Text */}
              <TxtInter
                size={21.6}
                weight={400}
                content={"What is your name?"}
                state={"enabled"}
                style={style.colorBlack}
              />

              {/* Input for first name */}
              <div style={style.marginTop18}>
                <TextInputIntRe12
                  type="text"
                  name="fName"
                  isShown={true}
                  limit="20"
                  placeholder={"First name"}
                  topText={"First name"}
                  value={fName}
                  onChange={(e) => {
                    if (e.target.value.length < 21) {
                      setFName(e.target.value);
                    }
                  }}
                  onClick={() => setFNameEdit(true)}
                />
              </div>

              {/* Input for last name */}
              <div style={style.marginTop18}>
                <TextInputIntRe12
                  type="text"
                  name="lName"
                  isShown={true}
                  limit="20"
                  placeholder={"Last name"}
                  topText={"Last name"}
                  value={lName}
                  onChange={(e) => {
                    if (e.target.value.length < 21) {
                      setLName(e.target.value);
                    }
                  }}
                  onClick={() => setLNameEdit(true)}
                />
              </div>
            </div>
          </div>

          {/* Upload profile pic screen */}
          <div style={style.scroll}>
            <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
              {/* Text */}
              <TxtInter
                size={21.6}
                weight={400}
                content={"Upload your profile picture"}
                state={"enabled"}
                style={style.colorBlack}
              />

              {/* Image upload avatar */}
              <div
                style={{
                  ...style.justifyContentCenter,
                  ...style.marginTop24
                }}
              >
                <AvatarUpload
                  width={122}
                  pic={setupNew.addEmp.pp ? setupNew.addEmp.pp : pp}
                  imageInputRef={imageInputRef}
                  onChange={(e) => {
                    let files;
                    if (e.dataTransfer) {
                      files = e.dataTransfer.files;
                    } else if (e.target) {
                      files = e.target.files;
                    }
                    const reader = new FileReader();
                    reader.onload = () => {
                      setImage(reader.result);
                    };
                    reader.readAsDataURL(files[0]);
                    setShowCropper(true);
                  }}
                  innerWidth={6}
                />
              </div>

              {/* Note to users */}
              {/* <div style={style.marginTop24}>
                <SrRefTextIntLi10
                  content={
                    "Sadly, this image picker only accepts images less than size 1mb and resolution 1000x1000px. Sorry :)"
                  }
                />
              </div> */}
            </div>
          </div>

          {/* Enter contact details screen */}
          <div style={style.scroll}>
            <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
              {/* Text */}
              <TxtInter
                size={21.6}
                weight={400}
                content={"What are your other contact details?"}
                state={"enabled"}
                style={style.colorBlack}
              />

              {/* User email id text input */}
              {/* <div style={style.marginTop18}>
                <TextInputIntRe12
                  type="text"
                  name="emailId"
                  placeholder={"Email ID"}
                  topText={"Email ID"}
                  value={emailId}
                  onChange={(e) => setEmailId(e.target.value)}
                  onClick={() => setEmailIdEdit(true)}
                  noLimit={true}
                />
              </div> */}

              {/* Show this only if the user type os not "viewer" */}
              {setupNew.userType !== "viewer" && (
                <>
                  {/* User phone no text input */}
                  <div style={style.marginTop18}>
                    <TextInputIntRe12
                      type="number"
                      name="phoneNoWA"
                      placeholder={"Phone Number"}
                      topText={"Phone Number"}
                      value={phoneNoWA}
                      onChange={(e) => setPhoneNoWA(e.target.value)}
                      onClick={() => setPhoneNoWAEdit(true)}
                      noLimit={true}
                    />
                  </div>

                  {/* Show this if the phone number entered above is not the same as login */}
                  <div style={style.marginTop6}>
                    {phoneNoWA !== setupNew.addEmp.phoneNo && (
                      <div
                        onMouseEnter={() => setLinkHover(true)}
                        onMouseLeave={() => setLinkHover(false)}
                        onClick={() => setPhoneNoWA(setupNew.addEmp.phoneNo)}
                        style={style.clickable}
                      >
                        <TxtInter
                          size={14.4}
                          weight={500}
                          content={"Same as login number?"}
                          state={"enabled"}
                          style={{
                            color: linkHover ? SrRefColour.Neutral1A : SrRefColour.Neutral2A,
                            ...style.transition
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          {setupNew.userType === "viewer" ? (
            // Enter viewer status screen
            <div style={style.scroll}>
              <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                {/* Text */}
                <TxtInter
                  size={21.6}
                  weight={400}
                  content={"What describes you best?"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* List input for viewer */}
                <div style={style.marginTop18}>
                  <ListInputInt12
                    onPress={() =>
                      isViewerSelecting ? setViewerSelecting(false) : setViewerSelecting(true)
                    }
                    placeholder={"Ex: Investor"}
                    condition={viewer !== -1}
                    content={ViewerName[Viewer.indexOf(Viewer[viewer])]}
                  />
                </div>

                {/* List popup for viewer */}
                {isViewerSelecting && (
                  <ListPopupInt12
                    array={ViewerName}
                    value={viewer}
                    setValue={setViewer}
                    setValueSelect={setViewerSelecting}
                  />
                )}
              </div>
            </div>
          ) : (
            // Enter designation screen
            <div style={style.scroll}>
              <div style={{ ...{ width: `${scrollBoxWidth}px` }, ...style.paddingHor24 }}>
                {/* Text */}
                <TxtInter
                  size={21.6}
                  weight={400}
                  content={"What is your designation?"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for designation */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="designation"
                    isShown={true}
                    limit="20"
                    placeholder={`For ex: "Software Developer"`}
                    value={designation}
                    onChange={(e) => {
                      if (e.target.value.length < 21) {
                        setDesignation(e.target.value);
                      }
                    }}
                    onClick={() => setDesignationEdit(true)}
                  />
                </div>
              </div>
            </div>
          )}

          {/* AHA moment screen */}
          <div style={style.scroll}>
            <div
              style={{
                ...{ width: `${scrollBoxWidth}px` },
                ...style.paddingHor24
              }}
            >
              <div
                style={{
                  ...style.flexColumn,
                  ...style.alignItemsCenter
                }}
              >
                {/* Cover image */}
                <img
                  src={require("../../../media/setup_guide_aha_3.png")}
                  width={180}
                  alt={"Profile created!"}
                />

                {/* Aha moment text */}
                <TxtInter
                  size={21.6}
                  weight={400}
                  content={"Profile created!"}
                  state={"enabled"}
                  style={{ ...style.marginTop24, ...style.textCenter, ...style.colorBlack }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Back button */}
        {screen !== 4 && (
          <BackFAB
            onClick={() =>
              setupNew.userType === "viewer"
                ? navigateToUserType()
                : screen === 0
                ? navigateToUserType()
                : setScreen((p) => p - 1)
            }
          />
        )}

        {/* Dot carousal */}
        {screen !== 4 && (
          <div style={style.dotArray}>
            <Dot screen={screen} actualScreen={0} />
            <Dot screen={screen} actualScreen={1} />
            <Dot screen={screen} actualScreen={2} />
            <Dot screen={screen} actualScreen={3} />
          </div>
        )}

        {/* Next button */}
        {screen !== 4 && (
          <NextFAB
            content={screen === 1 && !setupNew.addEmp.pp ? "Skip" : "Next"}
            onClick={() => handleSubmit()}
            // onClick={() => (screen === 2 ? setScreen(1) : setScreen((p) => p + 1))}
          />
        )}
      </SetupTemplate>

      {/* Image cropper */}
      {showCropper && (
        <CropperPopup2
          image={image}
          setImage={setImage}
          onClick={uploadFile}
          onClick2={() => setShowCropper(false)}
          scrollPosition={scrollPosition}
          noPadding={true}
        />
      )}

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default UserProfile;
