import { deleteApi, get, patch, post, put } from "./baseFuncs";

const getPlatformOrgData = async (orgId) => {
  const data = await get(`/platform/org/${orgId}`);
  return data;
};

const changeStatus = async (orgId, body) => {
  const data = await post(`/community/changeStatus/${orgId}`, body.data);
  return data;
};

const changePhase2Data = async (id, body) => {
  const data = await post(`/platform/phase2/${id}`, body.data);
  return data;
};

const changePhase3Data = async (id, body) => {
  const data = await post(`/platform/phase3/${id}`, body.data);
  return data;
};

const editOrgUser = async (body) => {
  const data = await put(`/platform/orgUser`, body.data);
  return data;
};

const getOrgUser = async () => {
  const data = await get("/platform/orgUser");
  return data;
};

const changeUpvote = async (body) => {
  const data = await post("/platform/upvote", body.data);
  return data;
};

const handleFeedback = async (body) => {
  const data = await post("/platform/feedback", body.data);
  return data;
};

export {
  getPlatformOrgData,
  changeStatus,
  changePhase2Data,
  changePhase3Data,
  editOrgUser,
  getOrgUser,
  changeUpvote,
  handleFeedback
};
