import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Importing Contexts
import MainContext from "../../../components/helpers/context/mainContext";
import CommunityContext from "../../../components/helpers/context/communityContext";

// Importing APIs
import { getCommunityData, changeCommunityStatus } from "../../../components/helpers/community";

import { Industries } from "../../../components/helpers/localData";

import TxtInter from "../../../components/01_atoms/text/Inter";
import Loading from "../../../components/01_atoms/Loading";
import Popup from "../../../components/01_atoms/popup";
import ShramStamp from "../../../components/01_atoms/shramStamp";
import AccountPopup from "../../../components/02_molecules/setupPopups/AccountPopup";
import AdminPopup from "../../../components/02_molecules/setupPopups/AdminPopup";
import EventPopup from "../../../components/02_molecules/setupPopups/EventPopup";
import CommunitySubCard from "../../../components/05_templates/CommunitySubCard";
import style from "./style";

const Community = () => {
  // Context
  const {
    breakpoint1,
    breakpoint3,
    defaultColor,
    // API related
    user,
    scrollToTop,
    eventData,
    setEventData,
    fetchUserStartupData,
    snackbar
  } = useContext(MainContext);

  const {
    showLogin,
    setShowLogin,
    setOrgs,
    setPendingOrgs,
    setPendingStartups,
    isAdmin,
    setIsAdmin,
    setGuest,
    setSpecialGuest,
    setSpeakers,
    fetchMore,
    setFetchMore,
    accountEdit,
    setAccountEdit,
    allowScroll,
    setEditHover1,

    eventInfoEdit,
    setEventInfoEdit,

    speakerAdd,
    speakerEdit,
    setSpeakerAdd,
    speakers,
    setSpeakerEdit
  } = useContext(CommunityContext);

  const navigate = useNavigate();

  // Get basic page and user details
  let { id } = useParams();

  // If user doesn't exist, navigate to login
  window.onload = function () {
    if (!user) {
      localStorage.setItem("@initial", window.location.pathname);
      // navigateToLogin();
    }
  };

  window.onbeforeunload = function () {
    setLoading(true);
    localStorage.setItem("loading", "true");
  };

  const [showNotLivePopup, setShowNotLivePopup] = useState(false);
  const [showEventEndedPopup, setShowEventEndedPopup] = useState(false);

  const [orgId, setOrgId] = useState();

  const [loading, setLoading] = useState(localStorage.getItem("loading") === "true" || true);

  // Navigate to main startup listing
  const navigateToLogin = () => {
    navigate("/login");
  };

  // Navigate to a particular startup listing
  const navigateToStartup = (e) => {
    navigate(`/startup/${e.id}`, { state: { id: e.id } });
    setOrgId(e.id);
    scrollToTop();
  };

  // Navigate to a particular startup listing
  const navigateToOwnStartup = (e) => {
    navigate(`/startup/${e}`, { state: { id: e } });
    setOrgId(e);
    scrollToTop();
  };

  // Constants
  const largeCardWidth = 348 - 6;

  // scrollPosition
  const [scrollPosition, setScrollPosition] = useState(0);

  // Function to handle scrolling
  const handleScroll = () => {
    const position = window.pageYOffset; // Find the top offset of the window
    setScrollPosition(position); // Set scrollPosition = top offset of the window
  };

  // scrollRef
  const scrollRef = useRef();
  const [darkMode, setDarkMode] = useState(false);

  // Function to handle scrolling
  useEffect(() => {
    const handleScrollPosition = () => {
      if (scrollRef.current) {
        setDarkMode(scrollPosition >= scrollRef.current.offsetTop - 48);
      }
    };

    handleScrollPosition();

    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScrollPosition, { passive: true });
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener("scroll", handleScrollPosition);
      }
    };
  }, [scrollPosition]);

  // Popup effect
  const body = document.body;

  // Disallow scroll when login popup is visible
  useEffect(() => {
    body.style.overflowY = showLogin ? "hidden" : "auto";
  }, [showLogin]); // Whenever scroll position chamges

  const [showInvites, setShowInvites] = useState(!user ? false : isAdmin ? true : false);

  // Fetch community data
  const fetchEventData = async () => {
    const json = await getCommunityData(id);

    if (!json.success) {
      setLoading(false);
      snackbar("An error has occurred");
      return;
    }

    const data = json.data;
    for (let num in data.event.speaker) {
      if (data.event.speaker[num].id === user?.id) {
        setIsAdmin(true);
        break;
      }
    }

    setGuest(data?.eventGuest?.guest);
    setSpecialGuest(data?.eventGuest?.specialGuests);
    setSpeakers(data.event.speaker);
    setPendingStartups(data.pendingStartups);

    // Process data to be able show in largeCard component
    let temp = [];
    data.startups.forEach((item) => {
      item = item.org;
      temp = [
        ...temp,
        {
          id: item.id,
          name: item.name,
          description: item.description,
          logo: item.logo,
          theme: {
            primary: {
              color: item.primary_colour ? item.primary_colour : defaultColor
            },
            accent: { color: item.accent_colour ? item.accent_colour : "" },
            invertBackground: item.invert_background === "Y" ? true : false
          },
          industry: Industries[item.industry],
          sectors: [item.sector1, item.sector2],
          event_id: item.event_id
        }
      ];
    });
    setOrgs(temp);

    //Process the pending startups to be able to show in largeCard component
    temp = [];
    data.pendingStartups.forEach((item) => {
      item = item.org;
      temp = [
        ...temp,
        {
          id: item.id,
          name: item.name,
          description: item.description,
          logo: item.logo,
          theme: {
            primary: {
              color: item.primary_colour ? item.primary_colour : defaultColor
            },
            accent: { color: item.accent_colour ? item.accent_colour : "" },
            invertBackground: item.invert_background === "Y" ? true : false
          },
          industry: Industries[item.industry],
          sectors: [item.sector1, item.sector2],
          event_id: item.event_id
        }
      ];
    });

    setPendingOrgs(temp);
    setEventData(data.event.eventData);
  };

  // func to change event status
  const handleEventStatus = async (s) => {
    const json = await changeCommunityStatus(id, { data: { event_details: { status: s } } });
    if (!json.success) {
      snackbar("Error occured in changing you community's status :(");
      return;
    }

    setFetchMore(true);
    if (s === "live")
      snackbar("Your community is live! Now, attendees can interact with one-another.");
    if (s === "pending") snackbar("Event published! Now you can invite attendees to the event.");
  };

  // Fetch event data after every change
  useEffect(() => {
    async function fetchData() {
      await fetchEventData(); // Fetch community's data
      await setFetchMore(false);
    }
    fetchData();
  }, [fetchMore]);

  // To handle scrolling after navigating to other page
  // And to handle API call
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true }); // listen to scroll event

    // HAndle API call
    async function fetchData() {
      setLoading(true);
      let orgId = localStorage.getItem("@orgid");
      await fetchUserStartupData(orgId);

      // API call to get all "co" startups platform data
      // await getBasicData();
      setLoading(false);
    }
    fetchData();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const CommunityPage = (w) => {
    return (
      <CommunitySubCard
        navigateToOwnStartup={navigateToOwnStartup}
        darkMode={darkMode}
        scrollPosition={scrollPosition}
        id={id}
        handleEventStatus={handleEventStatus}
        scrollRef={scrollRef}
        navigateToStartup={navigateToStartup}
        showInvites={showInvites}
        setShowInvites={setShowInvites}
        w={w}
      />
    );
  };

  return loading ? (
    <Loading />
  ) : (
    <div
      style={{
        ...style.outerMost,
        overflowY: showLogin ? "hidden" : "auto"
      }}
    >
      {breakpoint3
        ? // For phones with width less than 480px
          CommunityPage(largeCardWidth + 6 + 12 * 2)
        : breakpoint1
        ? // For tablets with width more than 480px and less than 1280px
          CommunityPage((largeCardWidth + 6) * 2 + 24 + 12 * 2)
        : // For screens with width more than 1280px
          CommunityPage((largeCardWidth + 6) * 3 + 24 * 2 + 12 * 2)}

      {/* Shram stamp */}
      {<ShramStamp />}

      {/* Account popup for handling users's information */}
      {accountEdit && (
        <Popup
          onClick={() => {
            setAccountEdit(false);
            setEditHover1(false);
            allowScroll();
          }}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          {/* Edit1 popup content */}
          <AccountPopup
            setEdit={setAccountEdit}
            setEditHover={setEditHover1}
            allowScroll={allowScroll}
          />
        </Popup>
      )}

      {/* community info edit to handle community's information  */}
      {eventInfoEdit && (
        <Popup
          onClick={() => {
            setAccountEdit(false);
            setEditHover1(false);
            setEventInfoEdit(false);
            allowScroll();
          }}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          <EventPopup
            eventId={id}
            setFetchMore={setFetchMore}
            setEventInfoEdit={setEventInfoEdit}
            setEdit={setEventInfoEdit}
            setEditHover={setEditHover1}
            allowScroll={allowScroll}
            setEventData={setEventData}
            eventData={eventData}
          />
        </Popup>
      )}

      {/* Admin popup to handle admin's information */}
      {(speakerEdit >= 0 || speakerAdd) && (
        <Popup
          onClick={() => {
            setSpeakerEdit(-1);
            setSpeakerAdd(false);
            // setEditHover1(false);
            // setEventInfoEdit(false);
            // allowScroll();
          }}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          <AdminPopup
            data={speakers[speakerEdit]}
            setSpeakerAdd={setSpeakerAdd}
            setSpeakerEdit={setSpeakerEdit}
            setFetchMore={setFetchMore}
            // if we want to add speaker
            speakerAdd={speakerAdd}
            eventId={eventData.id}
            title={eventData?.name}
          />
        </Popup>
      )}

      {/* Login popup */}
      {showLogin && (
        <Popup
          onClick={() => [setShowLogin(false), allowScroll()]}
          scrollPosition={scrollPosition}
          narrow={true}
        >
          {/* Popup content */}
          <TxtInter
            size={17.28}
            weight={300}
            content={
              "Please submit your listing for review. Once verified, you can view other startups"
            }
          />
        </Popup>
      )}

      {/* Not live popup */}
      {showNotLivePopup && (
        <Popup
          onClick={() => [setShowNotLivePopup(false), allowScroll()]}
          scrollPosition={scrollPosition}
          narrow={true}
        >
          {/* Popup content */}
          <TxtInter
            size={17.28}
            weight={300}
            content={
              "The event isn't live yet. We apologize but you might have to wait a little longer before you can check out these awesome startups!"
            }
          />
        </Popup>
      )}

      {/* Event ended popup */}
      {showEventEndedPopup && (
        <Popup
          onClick={() => [setShowEventEndedPopup(false), allowScroll()]}
          scrollPosition={scrollPosition}
          narrow={true}
        >
          {/* Popup content */}
          <TxtInter
            size={17.28}
            weight={300}
            content={
              "The event has now ended. If you were part of the event, you will soon receive an email about the highlights of the event!"
            }
          />
        </Popup>
      )}

      {/* Referral Code Popup
      {showReferral && (
        <Popup
          onClick={() => [setShowReferral(false), setShowReferralHover(false), allowScroll()]}
          scrollPosition={scrollPosition}
          noPadding={true}
          modal={breakpoint3 ? true : false}
        >
          {/* Referral popup popup content */}
      {/* <ReferralPopup
            setEdit={setShowReferral}
            setEditHover={setShowReferralHover}
            allowScroll={allowScroll}
            setShowWaitlist={setShowWaitlist}
          /> */}
      {/* </Popup> */}
      {/* // )} */}

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default Community;
