import SrRefColour from "../../../ref/colours";

const style = {
  userPanelWrap: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: "0 auto",
    paddingBottom: "12px"
  },
  innerWrap: {
    position: "relative",
    // height: "100px",
    padding: "12px 0 6px",
    borderRadius: "18px",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    backgroundImage:
      "linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)",
    border: `0.5px solid ${SrRefColour.Neutral2A}`,
    zIndex: 10,
    margin: "0 auto",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.15)"
  },
  buttonOuterWrap: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "18px"
  },
  buttonWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    overflow: "auto",
    paddingLeft: "6px",
    paddingRight: "6px",
    paddingBottom: "6px"
  }
};

export default style;
