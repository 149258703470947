import React, { useContext, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

// Importing helper functions
import SrRefColour from "../../../../../ref/colours";

// Importing Contexts
import MainContext from "../../../../helpers/context/mainContext";

// Importing Components
import NextFAB from "../../../../01_atoms/buttons/FABs/NextFab";

import style from "./style";

const CropperPopup = (props) => {
  // Import from context
  const { breakpoint3, scrollBoxWidth, screenWidth } = useContext(MainContext);

  // Cropper
  const [cropper, setCropper] = useState();

  // Function to retrieve cropped image data
  const getCropData = () => {
    if (cropper) {
      // Get base64 string representation of the cropped image
      var dataurl = cropper.getCroppedCanvas().toDataURL();

      // Converting base64 to file
      var arr = dataurl.split(","); // Splitting the base64 string
      var mime = arr[0].match(/:(.*?);/)[1]; // Extracting the MIME type
      var bstr = atob(arr[1]); // Decoding the base64 string
      var n = bstr.length;
      var u8arr = new Uint8Array(n); // Create Uint8Array to store the binary data

      // Iterate over the binary string and assign character codes to the Uint8Array
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      // Create a new File object with the binary data, filename, and MIME type
      props.onClick(new File([u8arr], "image.png", { type: mime }));
    }
  };

  // Dynamic styling for this component
  const style2 = {
    cropperOuterWrap: {
      ...style.alignCenter,
      justifyContent: breakpoint3 ? "none" : "center"
    },
    cropperWrap: {
      ...style.cropper,
      width: `${scrollBoxWidth}px`,
      border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`
    },
    cropper: {
      width: breakpoint3 ? screenWidth : "418px",
      height: breakpoint3 ? screenWidth : "418px"
    }
  };

  return (
    <div style={style.absolutePosition}>
      <div style={style2.cropperOuterWrap}>
        <div style={style2.cropperWrap}>
          {/* Cropper for image */}
          <Cropper
            zoomTo={1}
            aspectRatio={props.aspectRatio ? props.aspectRatio : 1}
            initialAspectRatio={1}
            preview=".img-preview"
            src={props.image}
            viewMode={2}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            checkOrientation={false}
            cropBoxResizable={true}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={true}
            style={style2.cropper}
          />

          {/* Crop button */}
          <NextFAB content={"Crop image"} onClick={getCropData} />
        </div>
      </div>
    </div>
  );
};

export default CropperPopup;
