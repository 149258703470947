import React, { useState, useContext, useEffect, useRef } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Importing Colors
import SrRefColour from "../../../../ref/colours";

// Importing helper functions
import { StageArr, StageArrShort, StatusArr } from "../../../helpers/localData";
import { returnRGBA } from "../../../helpers/themeColors";

// Importing contexts
import MainContext from "../../../helpers/context/mainContext";

// Importing texts
import TxtRubik from "../../../01_atoms/text/rubik";
import TxtInter from "../../../01_atoms/text/Inter";

// Importing components
import {
  SrCompIconDiscord,
  SrCompIconFacebook,
  SrCompIconInstagram,
  SrCompIconLinkedin,
  SrCompIconMedium,
  SrCompIconPinterest,
  SrCompIconReddit,
  SrCompIconSubstack,
  SrCompIconTwitter,
  SrCompIconWhatsapp,
  SrCompIconYoutube
} from "../../../01_atoms/icons";
import Avatar from "../../../01_atoms/avatar/Avatar";
import BackFAB from "../../../01_atoms/buttons/FABs/BackFAB";
import NextFAB from "../../../01_atoms/buttons/FABs/NextFab";
import Dot from "../../../01_atoms/dot";
import ListInputInt12 from "../../../01_atoms/listInputs/ListInputInt12";
import ListPopupInt12 from "../../../01_atoms/listInputs/ListPopupInt12";
import TextInputIntRe12 from "../../../01_atoms/textInputs/TextInputIntRe12";
import { style } from "./style";

const EditPopup3 = ({
  startupName,
  startupLogo,
  currentScreen,
  setEdit,
  setEditHover,
  allowScroll
}) => {
  // Main context
  const {
    breakpoint3,
    scrollBoxWidth,
    popupWidth,
    // API related,
    startup,
    setStartup,
    setLoadMore,
    primaryColor,
    snackbar,
    savePhase2Data
  } = useContext(MainContext);

  // Scroll view
  const [screen, setScreen] = useState(currentScreen === -1 ? 0 : currentScreen);
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current.scrollLeft = screen * scrollBoxWidth;
  }, [screen]);

  // Startup theme colours

  const returnPrimary = returnRGBA(primaryColor?.r, primaryColor?.g, primaryColor?.b, 1);

  // useStates
  const [orgYear, setOrgYear] = useState(startup.other.year);
  const [orgYearEdit, setOrgYearEdit] = useState(false);
  const [location, setLocation] = useState(startup.other.location);
  const [locationEdit, setLocationEdit] = useState(false);
  const [orgStage, setOrgStage] = useState(startup.other.stage);
  const [orgStatus, setOrgStatus] = useState(startup.other.status);
  const [orgStatusEdit, setOrgStatusEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isOrgStageSelecting, setOrgStageSelecting] = useState(false);
  const [isOrgStatusSelecting, setOrgStatusSelecting] = useState(false);

  const [discord, setDiscord] = useState(startup.other.socialMedia.discord);
  const [discordEdit, setDiscordEdit] = useState(false);
  const [facebook, setFacebook] = useState(startup.other.socialMedia.facebook);
  const [facebookEdit, setFacebookEdit] = useState(false);
  const [instagram, setInstagram] = useState(startup.other.socialMedia.instagram);
  const [instagramEdit, setInstagramEdit] = useState(false);
  const [linkedIn, setLinkedIn] = useState(startup.other.socialMedia.linkedIn);
  const [linkedInEdit, setLinkedInEdit] = useState(false);
  const [medium, setMedium] = useState(startup.other.socialMedia.medium);
  const [mediumEdit, setMediumEdit] = useState(false);
  const [pinterest, setPinterest] = useState(startup.other.socialMedia.pinterest);
  const [pinterestEdit, setPinterestEdit] = useState(false);
  const [reddit, setReddit] = useState(startup.other.socialMedia.reddit);
  const [redditEdit, setRedditEdit] = useState(false);
  const [substack, setSubstack] = useState(startup.other.socialMedia.substack);
  const [substackEdit, setSubstackEdit] = useState(false);
  const [twitter, setTwitter] = useState(startup.other.socialMedia.twitter);
  const [twitterEdit, setTwitterEdit] = useState(false);
  const [whatsapp, setWhatsapp] = useState(startup.other.socialMedia.whatsapp);
  const [whatsappEdit, setWhatsappEdit] = useState(false);
  const [youtube, setYoutube] = useState(startup.other.socialMedia.youtube);
  const [youtubeEdit, setYoutubeEdit] = useState(false);

  const saveData = async () => {
    const body = {
      establishment_year: orgYear,
      location,
      stage: StageArrShort[orgStage],
      org_status: orgStatus,
      discord,
      facebook,
      instagram,
      linkedin: linkedIn,
      medium,
      pinterest,
      reddit,
      substack,
      twitter,
      whatsapp,
      youtube
    };

    // Org ID
    const id = Number(window.location.pathname.split("/")[3]);
    await savePhase2Data(id, body);
    setLoadMore(true);
  };

  useEffect(() => {
    return saveData;
  }, [
    orgYear,
    location,
    orgStage,
    orgStatus,
    discord,
    facebook,
    instagram,
    linkedIn,
    medium,
    pinterest,
    reddit,
    substack,
    twitter,
    whatsapp,
    youtube
  ]);

  const socialMedia = {
    discord: discord,
    facebook: facebook,
    instagram: instagram,
    linkedIn: linkedIn,
    medium: medium,
    pinterest: pinterest,
    reddit: reddit,
    substack: substack,
    twitter: twitter,
    whatsapp: whatsapp,
    youtube: youtube
  };

  const validateURL = (url) => {
    try {
      if (!url) return true;
      new URL(url);
      return true;
    } catch (err) {
      snackbar("Please upload a valid url");
      return false;
    }
  };

  //for dynamic styling
  const style2 = {
    backgroundWrap: {
      ...style.alignItemsCenter,
      justifyContent: breakpoint3 ? "none" : "center"
    },
    cardWrap: {
      ...style.cardWrap,
      width: `${scrollBoxWidth}px`,
      border: breakpoint3 ? "none" : `0.5px solid ${SrRefColour.Neutral2B}`,
      marginTop: breakpoint3 ? "24px" : 0
    },

    scrollViewContainer: {
      ...style.scrollViewContainer,
      width: `${scrollBoxWidth}px`
    },
    nameContainer: {
      ...style.paddingHor24,
      width: `${scrollBoxWidth}px`
    }
  };

  return (
    <div>
      {/* Background */}
      <div style={style2.backgroundWrap}>
        {/* Bordered card */}
        <div style={style2.cardWrap}>
          {/* Shram logo at the top */}
          <div style={style.logoOuterWrap}>
            <div style={style.avatarWrap}>
              {/* User org logo */}
              <Avatar pic={startupLogo} width={50} borderColor={returnPrimary} />

              {/* User org name */}
              <TxtRubik size={36} weight={600} content={startupName} style={style.marginLeft12} />
            </div>
          </div>

          {/* Scroll view */}
          <div style={style2.scrollViewContainer} ref={scrollRef}>
            {/* Enter org details screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <TxtInter
                  size={21.6}
                  weight={400}
                  content={"Your founding details"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* Input for location */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="text"
                    name="location"
                    isShown={true}
                    limit="20"
                    placeholder={"Location"}
                    topText={"Location"}
                    value={location}
                    onChange={(e) => {
                      if (e.target.value.length < 21) {
                        setLocation(e.target.value),
                          setStartup({
                            ...startup,
                            other: {
                              ...setStartup.other,
                              location: e.target.value.trim(),
                              year: orgYear,
                              stage: orgStage,
                              socialMedia: socialMedia,
                              status: orgStatus
                            }
                          });
                      }
                    }}
                    onClick={() => setLocationEdit(true)}
                  />
                </div>

                {/* Input for year */}
                <div style={style.marginTop18}>
                  <TextInputIntRe12
                    type="number"
                    min={0}
                    max={9}
                    name="orgYear"
                    placeholder={"Founding year"}
                    topText={"Founding year"}
                    value={orgYear}
                    onChange={(e) => [
                      setOrgYear(e.target.value),
                      setStartup({
                        ...startup,
                        other: {
                          ...setStartup.other,
                          ...{
                            location: location,
                            year: e.target.value.trim(),
                            stage: orgStage,
                            socialMedia: socialMedia,
                            status: orgStatus
                          }
                        }
                      })
                    ]}
                    onClick={() => setOrgYearEdit(true)}
                    noLimit={true}
                  />
                </div>
              </div>
            </div>

            {/* Enter org stage screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <TxtInter
                  size={21.6}
                  weight={400}
                  content={"What Stage are you on?"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* List input for stage */}
                <div style={style.marginTop18}>
                  <ListInputInt12
                    onPress={() =>
                      isOrgStageSelecting ? setOrgStageSelecting(false) : setOrgStageSelecting(true)
                    }
                    placeholder={"Stage"}
                    condition={orgStage !== -1}
                    content={StageArr[startup.other.stage]}
                  />
                </div>

                {/* List popup for stage */}
                {isOrgStageSelecting && (
                  <ListPopupInt12
                    array={StageArr}
                    value={orgStage}
                    setValue={setOrgStage}
                    setValueSelect={setOrgStageSelecting}
                    // If setting up industry through the setup guide
                    setStartup={setStartup}
                    startup={startup}
                    // Params for editing stage
                    setupStage={true}
                    location={location}
                    orgYear={orgYear}
                    socialMedia={socialMedia}
                    orgStatus={orgStatus}
                  />
                )}
              </div>
            </div>

            {/* Enter social media handles screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <TxtInter
                  size={21.6}
                  weight={400}
                  content={"Your social media handles"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                <div style={style.vpScrollable}>
                  {/* Input for discord link */}
                  <div style={style.inputWrap}>
                    <SrCompIconDiscord size={24} />

                    <div style={style.inputStyle}>
                      <TextInputIntRe12
                        type="text"
                        name="discord"
                        placeholder={"Discord link"}
                        top={"Discord link"}
                        value={discord}
                        onChange={(e) => [
                          setDiscord(e.target.value),
                          setStartup({
                            ...startup,
                            other: {
                              ...setStartup.other,
                              ...{
                                location: location,
                                year: orgYear,
                                stage: orgStage,
                                socialMedia: {
                                  discord: e.target.value.trim(),
                                  facebook: facebook,
                                  instagram: instagram,
                                  linkedIn: linkedIn,
                                  medium: medium,
                                  pinterest: pinterest,
                                  reddit: reddit,
                                  substack: substack,
                                  twitter: twitter,
                                  whatsapp: whatsapp,
                                  youtube: youtube
                                },
                                status: orgStatus
                              }
                            }
                          })
                        ]}
                        onClick={() => setDiscordEdit(true)}
                        noLimit={true}
                      />
                    </div>
                  </div>

                  {/* Input for facebook link */}
                  <div style={style.inputWrap1}>
                    <SrCompIconFacebook size={24} />

                    <div style={style.inputStyle}>
                      <TextInputIntRe12
                        type="text"
                        name="facebook"
                        placeholder={"Facebook link"}
                        topText={"Facebook link"}
                        value={facebook}
                        onChange={(e) => [
                          setFacebook(e.target.value),
                          setStartup({
                            ...startup,
                            other: {
                              ...setStartup.other,
                              ...{
                                location: location,
                                year: orgYear,
                                stage: orgStage,
                                socialMedia: {
                                  discord: discord,
                                  facebook: e.target.value.trim(),
                                  instagram: instagram,
                                  linkedIn: linkedIn,
                                  medium: medium,
                                  pinterest: pinterest,
                                  reddit: reddit,
                                  substack: substack,
                                  twitter: twitter,
                                  whatsapp: whatsapp,
                                  youtube: youtube
                                },
                                status: orgStatus
                              }
                            }
                          })
                        ]}
                        onClick={() => setFacebookEdit(true)}
                        noLimit={true}
                      />
                    </div>
                  </div>

                  {/* Input for instagram link */}
                  <div style={style.inputWrap1}>
                    <SrCompIconInstagram size={24} />

                    <div style={style.inputStyle}>
                      <TextInputIntRe12
                        type="text"
                        name="instagram"
                        placeholder={"Instagram link"}
                        topText={"Instagram link"}
                        value={instagram}
                        onChange={(e) => [
                          setInstagram(e.target.value),
                          setStartup({
                            ...startup,
                            other: {
                              ...setStartup.other,
                              ...{
                                location: location,
                                year: orgYear,
                                stage: orgStage,
                                socialMedia: {
                                  discord: discord,
                                  facebook: facebook,
                                  instagram: e.target.value.trim(),
                                  linkedIn: linkedIn,
                                  medium: medium,
                                  pinterest: pinterest,
                                  reddit: reddit,
                                  substack: substack,
                                  twitter: twitter,
                                  whatsapp: whatsapp,
                                  youtube: youtube
                                },
                                status: orgStatus
                              }
                            }
                          })
                        ]}
                        onClick={() => setInstagramEdit(true)}
                        noLimit={true}
                      />
                    </div>
                  </div>

                  {/* Input for linkedIn link */}
                  <div style={style.inputWrap1}>
                    <SrCompIconLinkedin size={24} />

                    <div style={style.inputStyle}>
                      <TextInputIntRe12
                        type="text"
                        name="linkedIn"
                        placeholder={"LinkedIn link"}
                        topText={"LinkedIn link"}
                        value={linkedIn}
                        onChange={(e) => [
                          setLinkedIn(e.target.value),
                          setStartup({
                            ...startup,
                            other: {
                              ...setStartup.other,
                              ...{
                                location: location,
                                year: orgYear,
                                stage: orgStage,
                                socialMedia: {
                                  discord: discord,
                                  facebook: facebook,
                                  instagram: instagram,
                                  linkedIn: e.target.value.trim(),
                                  medium: medium,
                                  pinterest: pinterest,
                                  reddit: reddit,
                                  substack: substack,
                                  twitter: twitter,
                                  whatsapp: whatsapp,
                                  youtube: youtube
                                },
                                status: orgStatus
                              }
                            }
                          })
                        ]}
                        onClick={() => setLinkedInEdit(true)}
                        noLimit={true}
                      />
                    </div>
                  </div>

                  {/* Input for medium link */}
                  <div style={style.inputWrap1}>
                    <SrCompIconMedium size={24} />

                    <div style={style.inputStyle}>
                      <TextInputIntRe12
                        type="text"
                        name="medium"
                        placeholder={"Medium link"}
                        topText={"Medium link"}
                        value={medium}
                        onChange={(e) => [
                          setMedium(e.target.value),
                          setStartup({
                            ...startup,
                            other: {
                              ...setStartup.other,
                              ...{
                                location: location,
                                year: orgYear,
                                stage: orgStage,
                                socialMedia: {
                                  discord: discord,
                                  facebook: facebook,
                                  instagram: instagram,
                                  linkedIn: linkedIn,
                                  medium: e.target.value.trim(),
                                  pinterest: pinterest,
                                  reddit: reddit,
                                  substack: substack,
                                  twitter: twitter,
                                  whatsapp: whatsapp,
                                  youtube: youtube
                                },
                                status: orgStatus
                              }
                            }
                          })
                        ]}
                        onClick={() => setMediumEdit(true)}
                        noLimit={true}
                      />
                    </div>
                  </div>

                  {/* Input for pinterest link */}
                  <div style={style.inputWrap1}>
                    <SrCompIconPinterest size={24} />

                    <div style={style.inputStyle}>
                      <TextInputIntRe12
                        type="text"
                        name="pinterest"
                        placeholder={"Pinterest link"}
                        topText={"Pinterest link"}
                        value={pinterest}
                        onChange={(e) => [
                          setPinterest(e.target.value),
                          setStartup({
                            ...startup,
                            other: {
                              ...setStartup.other,
                              ...{
                                location: location,
                                year: orgYear,
                                stage: orgStage,
                                socialMedia: {
                                  discord: discord,
                                  facebook: facebook,
                                  instagram: instagram,
                                  linkedIn: linkedIn,
                                  medium: medium,
                                  pinterest: e.target.value.trim(),
                                  reddit: reddit,
                                  substack: substack,
                                  twitter: twitter,
                                  whatsapp: whatsapp,
                                  youtube: youtube
                                },
                                status: orgStatus
                              }
                            }
                          })
                        ]}
                        onClick={() => setPinterestEdit(true)}
                        noLimit={true}
                      />
                    </div>
                  </div>

                  {/* Input for reddit link */}
                  <div style={style.inputWrap1}>
                    <SrCompIconReddit size={24} />

                    <div style={style.inputStyle}>
                      <TextInputIntRe12
                        type="text"
                        name="reddit"
                        placeholder={"Reddit link"}
                        topText={"Reddit link"}
                        value={reddit}
                        onChange={(e) => [
                          setReddit(e.target.value),
                          setStartup({
                            ...startup,
                            other: {
                              ...setStartup.other,
                              ...{
                                location: location,
                                year: orgYear,
                                stage: orgStage,
                                socialMedia: {
                                  discord: discord,
                                  facebook: facebook,
                                  instagram: instagram,
                                  linkedIn: linkedIn,
                                  medium: medium,
                                  pinterest: pinterest,
                                  reddit: e.target.value.trim(),
                                  substack: substack,
                                  twitter: twitter,
                                  whatsapp: whatsapp,
                                  youtube: youtube
                                },
                                status: orgStatus
                              }
                            }
                          })
                        ]}
                        onClick={() => setRedditEdit(true)}
                        noLimit={true}
                      />
                    </div>
                  </div>

                  {/* Input for substack link */}
                  <div style={style.inputWrap1}>
                    <SrCompIconSubstack size={24} />

                    <div style={style.inputStyle}>
                      <TextInputIntRe12
                        type="text"
                        name="substack"
                        placeholder={"Substack link"}
                        topText={"Substack link"}
                        value={substack}
                        onChange={(e) => [
                          setSubstack(e.target.value),
                          setStartup({
                            ...startup,
                            other: {
                              ...setStartup.other,
                              ...{
                                location: location,
                                year: orgYear,
                                stage: orgStage,
                                socialMedia: {
                                  discord: discord,
                                  facebook: facebook,
                                  instagram: instagram,
                                  linkedIn: linkedIn,
                                  medium: medium,
                                  pinterest: pinterest,
                                  reddit: reddit,
                                  substack: e.target.value.trim(),
                                  twitter: twitter,
                                  whatsapp: whatsapp,
                                  youtube: youtube
                                },
                                status: orgStatus
                              }
                            }
                          })
                        ]}
                        onClick={() => setSubstackEdit(true)}
                        noLimit={true}
                      />
                    </div>
                  </div>

                  {/* Input for twitter link */}
                  <div style={style.inputWrap1}>
                    <SrCompIconTwitter size={24} />

                    <div style={style.inputStyle}>
                      <TextInputIntRe12
                        type="text"
                        name="twitter"
                        placeholder={"Twitter link"}
                        topText={"Twitter link"}
                        value={twitter}
                        onChange={(e) => [
                          setTwitter(e.target.value),
                          setStartup({
                            ...startup,
                            other: {
                              ...setStartup.other,
                              ...{
                                location: location,
                                year: orgYear,
                                stage: orgStage,
                                socialMedia: {
                                  discord: discord,
                                  facebook: facebook,
                                  instagram: instagram,
                                  linkedIn: linkedIn,
                                  medium: medium,
                                  pinterest: pinterest,
                                  reddit: reddit,
                                  substack: substack,
                                  twitter: e.target.value.trim(),
                                  whatsapp: whatsapp,
                                  youtube: youtube
                                },
                                status: orgStatus
                              }
                            }
                          })
                        ]}
                        onClick={() => setTwitterEdit(true)}
                        noLimit={true}
                      />
                    </div>
                  </div>

                  {/* Input for whatsapp link */}
                  <div style={style.inputWrap1}>
                    <SrCompIconWhatsapp size={24} />

                    <div style={style.inputStyle}>
                      <TextInputIntRe12
                        type="text"
                        name="whatsapp"
                        placeholder={"Whatsapp link"}
                        topText={"Whatsapp link"}
                        value={whatsapp}
                        onChange={(e) => [
                          setWhatsapp(e.target.value),
                          setStartup({
                            ...startup,
                            other: {
                              ...setStartup.other,
                              ...{
                                location: location,
                                year: orgYear,
                                stage: orgStage,
                                socialMedia: {
                                  discord: discord,
                                  facebook: facebook,
                                  instagram: instagram,
                                  linkedIn: linkedIn,
                                  medium: medium,
                                  pinterest: pinterest,
                                  reddit: reddit,
                                  substack: substack,
                                  twitter: twitter,
                                  whatsapp: e.target.value.trim(),
                                  youtube: youtube
                                },
                                status: orgStatus
                              }
                            }
                          })
                        ]}
                        onClick={() => setWhatsappEdit(true)}
                        noLimit={true}
                      />
                    </div>
                  </div>

                  {/* Input for youtube link */}
                  <div style={style.inputWrap1}>
                    <SrCompIconYoutube size={24} />

                    <div style={style.inputStyle}>
                      <TextInputIntRe12
                        type="text"
                        name="youtube"
                        placeholder={"Youtube link"}
                        topText={"Youtube link"}
                        value={youtube}
                        onChange={(e) => [
                          setYoutube(e.target.value),
                          setStartup({
                            ...startup,
                            other: {
                              ...setStartup.other,
                              ...{
                                location: location,
                                year: orgYear,
                                stage: orgStage,
                                socialMedia: {
                                  discord: discord,
                                  facebook: facebook,
                                  instagram: instagram,
                                  linkedIn: linkedIn,
                                  medium: medium,
                                  pinterest: pinterest,
                                  reddit: reddit,
                                  substack: substack,
                                  twitter: twitter,
                                  whatsapp: whatsapp,
                                  youtube: e.target.value.trim()
                                },
                                status: orgStatus
                              }
                            }
                          })
                        ]}
                        onClick={() => setYoutubeEdit(true)}
                        noLimit={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Enter org status screen */}
            <div style={style.scroll}>
              <div style={style2.nameContainer}>
                {/* Text */}
                <TxtInter
                  size={21.6}
                  weight={400}
                  content={"Your funding status"}
                  state={"enabled"}
                  style={style.colorBlack}
                />

                {/* List input for status */}
                <div style={style.marginTop18}>
                  <ListInputInt12
                    onPress={() =>
                      isOrgStatusSelecting
                        ? setOrgStatusSelecting(false)
                        : setOrgStatusSelecting(true)
                    }
                    placeholder={"Status"}
                    condition={orgStatus !== -1}
                    content={StatusArr[orgStatus]}
                  />
                </div>

                {/* List popup for status */}
                {isOrgStatusSelecting && (
                  <ListPopupInt12
                    array={StatusArr}
                    value={orgStatus}
                    setValue={setOrgStatus}
                    setValueSelect={setOrgStatusSelecting}
                    // If setting up industry through the setup guide
                    setStartup={setStartup}
                    startup={startup}
                    // Params for editing status
                    setupStatus={true}
                    location={location}
                    orgYear={orgYear}
                    orgStage={orgStage}
                    socialMedia={socialMedia}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Back button */}
          <BackFAB
            onClick={() =>
              screen === 0
                ? [setEdit(false), setEditHover(false), allowScroll()]
                : setScreen((p) => p - 1)
            }
          />

          {/* Dot carousal */}
          <div style={style.dotArray}>
            <Dot screen={screen} actualScreen={0} />
            <Dot screen={screen} actualScreen={1} />
            <Dot screen={screen} actualScreen={2} />
            <Dot screen={screen} actualScreen={3} />
          </div>

          {/* Next button */}
          <NextFAB
            content={screen === 3 ? "Done" : "Next"}
            onClick={() =>
              screen === 3
                ? [setEdit(false), setEditHover(false), allowScroll()]
                : setScreen((p) => p + 1)
            }
          />
        </div>
      </div>

      {/* Snackbar */}
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default EditPopup3;
