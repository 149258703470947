import React, { useState } from "react";

// import { SrRefTextIntRe12 } from "../../../../../ref/text";
import TxtInter from "../../../../01_atoms/text/Inter";

import {
  white,
  rgbToHex,
  colorMixer,
  getTextColor,
  gradientText,
  gradientBgTextBox,
  gradientBorderTextBox
} from "../../../../helpers/themeColors";

import { SrCompIconContentCopy } from "../../../../01_atoms/icons";

import style from "./style";

const ShareInviteBtn = ({ color, startup, content, snackbar }) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.shareOuterDiv,
      ...gradientBgTextBox(color, "", btnHover ? 1 : 0.8, "N"),
      ...gradientBorderTextBox(color, "", "N")
    },
    inputStyles: {
      ...style.clickable,
      ...gradientText(color, "", startup.theme.invertBackground)
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={() => {
        // Copy text to clipboard
        navigator.clipboard.writeText(
          `Join ${startup.name} on Shram's Growth Platform by using this invite link https://platform.shram.io/login?inviteCode=${startup.inviteCode}`
        );
        snackbar("Invite code copied to clipboard");
      }}
      style={style2.outerDiv}
    >
      {/* Invite code text */}
      <TxtInter
        weight={400}
        size={17.28}
        content={content ? content : `shram-${startup.inviteCode}`}
        style={style2.inputStyles}
      />

      {/* Copy icon */}
      <div style={style.marginLeft12}>
        <SrCompIconContentCopy
          size={24}
          color={getTextColor(
            rgbToHex(colorMixer([color.r, color.g, color.b], white, color.a)),
            startup.theme.invertBackground
          )}
        />
      </div>
    </div>
  );
};

export default ShareInviteBtn;
