const ProfilePics = [
  "https://shram-mvp-profile-pictures.s3.ap-south-1.amazonaws.com/profile_pics/avatar_01.png",
  "https://shram-mvp-profile-pictures.s3.ap-south-1.amazonaws.com/profile_pics/avatar_02.png",
  "https://shram-mvp-profile-pictures.s3.ap-south-1.amazonaws.com/profile_pics/avatar_03.png",
  "https://shram-mvp-profile-pictures.s3.ap-south-1.amazonaws.com/profile_pics/avatar_04.png",
  "https://shram-mvp-profile-pictures.s3.ap-south-1.amazonaws.com/profile_pics/avatar_05.png",
  "https://shram-mvp-profile-pictures.s3.ap-south-1.amazonaws.com/profile_pics/avatar_06.png",
  "https://shram-mvp-profile-pictures.s3.ap-south-1.amazonaws.com/profile_pics/avatar_07.png",
  "https://shram-mvp-profile-pictures.s3.ap-south-1.amazonaws.com/profile_pics/avatar_08.png",
  "https://shram-mvp-profile-pictures.s3.ap-south-1.amazonaws.com/profile_pics/avatar_09.png",
  "https://shram-mvp-profile-pictures.s3.ap-south-1.amazonaws.com/profile_pics/avatar_10.png"
];

const OrgLogos = [
  "https://shram-mvp-logo.s3.ap-south-1.amazonaws.com/org_logos/org_01.png",
  "https://shram-mvp-logo.s3.ap-south-1.amazonaws.com/org_logos/org_02.png",
  "https://shram-mvp-logo.s3.ap-south-1.amazonaws.com/org_logos/org_03.png",
  "https://shram-mvp-logo.s3.ap-south-1.amazonaws.com/org_logos/org_04.png",
  "https://shram-mvp-logo.s3.ap-south-1.amazonaws.com/org_logos/org_05.png",
  "https://shram-mvp-logo.s3.ap-south-1.amazonaws.com/org_logos/org_06.png",
  "https://shram-mvp-logo.s3.ap-south-1.amazonaws.com/org_logos/org_07.png",
  "https://shram-mvp-logo.s3.ap-south-1.amazonaws.com/org_logos/org_08.png",
  "https://shram-mvp-logo.s3.ap-south-1.amazonaws.com/org_logos/org_09.png",
  "https://shram-mvp-logo.s3.ap-south-1.amazonaws.com/org_logos/org_10.png"
];

const Industries = [
  "Adtech",
  "Aerospace",
  "Agriculture",
  "Analytics",
  "B2B SaaS",
  "Biotech",
  "Community",
  "Construction",
  "Consultancy",
  "Consumer Services",
  "Crypto/Blockchain",
  "Design",
  "Deep Tech",
  "Developer Tools",
  "Edtech",
  "Energy",
  "Entertainment",
  "Financial Services",
  "Fitness",
  "Food/Beverage",
  "Gaming",
  "Government",
  "Hard Tech",
  "Hardware",
  "Healthcare",
  "Insurance",
  "Legal Services",
  "Logistics",
  "Manufacturing",
  "Marketing",
  "Marketplace",
  "Media",
  "Medical Devices",
  "Mental Health",
  "Private Equity/VC",
  "Real Estate",
  "Retail",
  "Recruiting",
  "Robotics",
  "Security",
  "Social work",
  "Software",
  "Spacetech",
  "Sustainability",
  "Transportation",
  "Travel/Tourism",
  "Virtual Reality/AR",
  "Wellness",
  "Other"
];

const StageArrShort = ["id", "va", "et", "sc"];
const StageArr = ["Ideation", "Validation", "Early traction", "Scaling"];

const StatusArr = [
  "Bootstrapped",
  "Open to investment",
  "Actively raising funds",
  "Adequately funded"
];

const Viewer = ["gst", "gst", "gst", "exp"];
const ViewerName = ["Investor", "Mentor", "Student", "Just an explorer"];

export {
  ProfilePics,
  OrgLogos,
  Industries,
  StageArrShort,
  StageArr,
  StatusArr,
  Viewer,
  ViewerName
};
