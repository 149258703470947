import React, { useState } from "react";
import SrRefColour from "../../../../ref/colours";
import TxtInter from "../../text/Inter";
import { SrCompIconExpandMore } from "../../../../components/01_atoms/icons";
import style from "./style";

// List input for font Int12
const ListInputInt12 = ({ onPress, content, placeholder, condition }) => {
  const [inputHover, setInputHover] = useState(false);
  const [inputClick, setInputClick] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    outerDiv: {
      ...style.inputOuterDiv,
      border:
        inputHover || inputClick
          ? `1px solid ${SrRefColour.Neutral2A}`
          : `1px solid ${SrRefColour.Neutral2B}`
    }
  };
  return (
    <div>
      {/* Placeholder text at the top */}
      {/* {!props.topTextAlwaysOn && props.topText && !props.content ? (
          <div></div>
        ) : (
          <div style={style.textInputTop}>
            <SrRefTextIntLi075
              content={props.topText}
              state={
                !props.content && props.condition
                  ? "enabled"
                  : !props.content
                  ? "disabled"
                  : props.condition
                  ? "pressed"
                  : "enabled"
              }
            />
          </div>
        )} */}

      {/* List item */}
      <div
        onMouseEnter={() => setInputHover(true)}
        onMouseLeave={() => setInputHover(false)}
        onClick={onPress}
        style={style2.outerDiv}
      >
        {/* List item text */}
        <TxtInter
          weight={400}
          size={17.28}
          content={!content ? placeholder : content}
          style={{
            color:
              !content && condition
                ? SrRefColour.Neutral2A
                : !content
                ? SrRefColour.Neutral2A
                : condition
                ? SrRefColour.Neutral1A
                : SrRefColour.Neutral2A
          }}
        />

        {/* Dropdown icon */}
        <SrCompIconExpandMore
          color={
            !content && condition
              ? SrRefColour.Neutral2A
              : !content
              ? SrRefColour.Neutral2A
              : condition
              ? SrRefColour.Neutral1A
              : SrRefColour.Neutral2A
          }
        />
      </div>
    </div>
  );
};

export default ListInputInt12;
