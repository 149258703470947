import React from "react";
import TxtInter from "../../text/Inter";
import { white, concatColorMixer, gradientText } from "../../../helpers/themeColors";
import style from "./style";

const SectorChip = ({ accentColor, primaryColor, sector }) => {
  // Dyanmic style for this component
  const style2 = {
    outerDiv: {
      ...style.sectorOuterDiv,
      backgroundColor: accentColor
        ? concatColorMixer(
            [accentColor.r, accentColor.g, accentColor.b],
            white,
            accentColor.a * 0.5
          )
        : concatColorMixer(
            [primaryColor.r, primaryColor.g, primaryColor.b],
            white,
            primaryColor.a * 0.5
          )
    }
  };

  return (
    <div style={style2.outerDiv}>
      <TxtInter
        weight={400}
        size={12.96}
        content={sector}
        style={gradientText(accentColor, primaryColor)}
      />
    </div>
  );
};
export default SectorChip;
