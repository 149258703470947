import SrRefColour from "../../../../../ref/colours";

const style = {
  clickable: {
    width: "fit-content",
    cursor: "pointer",
    textDecoration: "none"
  },
  icon: { width: "24px", height: "24px" },
  invertIcon: { transform: "rotateY(180deg)" },
  transition: {
    transition: "0.2s ease-in"
  },
  colorBlack: { color: SrRefColour.Neutral1A },

  innerDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "fit-content",
    padding: "9px 6px 9px 12px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "12px",
    backgroundImage:
      "linear-gradient(to bottom right, rgba(74, 161, 59, 0.6), rgba(74, 161, 59, 0.3))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)",
    transition: "0.2s ease-in"
  },
  arrowWrap: {
    width: "24px",
    height: "24px",
    marginLeft: "6px"
  }
};

export default style;
