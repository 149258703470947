import SrRefColour from "../../../../ref/colours";

const style = {
  pic: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "1.5px",
    borderWidth: "1px",
    borderStyle: "solid",
    backgroundColor: SrRefColour.Neutral0
  }
};

export default style;
