import React, { useState } from "react";
// import { SrRefTextIntSb12 } from "../../../../../ref/text";
import TxtInter from "../../../../01_atoms/text/Inter";
import { SrCompIconAdd } from "../../../../01_atoms/icons";
import style from "./style";

// Add new button
const AddNewBtn = ({ onClick, text }) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynamic styling for this component
  const style2 = {
    innerDiv: {
      ...style.innerDiv,
      borderColor: btnHover ? "rgba(74, 161, 59, 0.6)" : "rgba(74, 161, 59, 0.3)"
    }
  };

  return (
    <div
      onMouseEnter={() => setBtnHover(true)}
      onMouseLeave={() => setBtnHover(false)}
      onClick={onClick}
      style={style.clickable}
    >
      <div style={style2.innerDiv}>
        {/* Login text */}

        <TxtInter
          size={17.28}
          weight={600}
          content={`Add a new ${text}`}
          state={"enabled"}
          style={style.colorBlack}
        />

        {/* Arrow icon */}
        <div style={style.arrowWrap}>
          <div style={style.invertIcon}>
            <SrCompIconAdd size={24} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewBtn;
