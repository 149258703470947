import React from "react";

// Rubik text component
const TxtRubik = ({ size, weight, color, content, wrap, style }) => {
  // Transition property for icons
  const transition = "font 0.2s ease-in-out, color 0.2s ease-in-out";

  return (
    <p
      style={{
        margin: 0,
        fontFamily: "Rubik",
        fontSize: `${size}px`,
        fontWeight: weight,
        lineHeight: `${Math.ceil(size * 1.25)}px`,
        color: color,
        whiteSpace: wrap && "nowrap", // Ensures that text is rendered on one line
        transition: transition,
        ...style
      }}
    >
      {content}
    </p>
  );
};

export default TxtRubik;
