import React from "react";
import SrRefColour from "../../../ref/colours";
import { style } from "./style";

const Dot = ({ screen, actualScreen }) => {
  // Dynamic styling for this component
  const style2 = {
    divStyle: {
      ...style.dot,
      backgroundColor: screen === actualScreen ? SrRefColour.Neutral2A : SrRefColour.Neutral2B
    }
  };

  return <div style={style2.divStyle}></div>;
};

export default Dot;
