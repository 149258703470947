import SrRefColour from "../../../../../ref/colours";

const style = {
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between"
  },
  alignItemsCenter: {
    display: "flex",
    alignItems: "center"
  },
  clickable: {
    width: "fit-content",
    cursor: "pointer",
    textDecoration: "none"
  },
  button: {
    width: "fit-content",
    padding: "12px 24px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "24px",
    backgroundColor: SrRefColour.Neutral1C
  },
  button2: {
    width: "fit-content",
    padding: "9px 6px 9px 12px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "12px",
    backgroundImage:
      "linear-gradient(to bottom right, rgba(74, 161, 59, 0.6), rgba(74, 161, 59, 0.3))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)"
  },
  button3: {
    width: "fit-content",
    padding: "6px 6px 6px 12px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "6px"
  },
  CTABtn: {
    width: "fit-content",
    padding: "12px 18px",
    borderRadius: "12px"
  },
  surfaceBlur: {
    backgroundImage: "linear-gradient(to bottom right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)"
  },
  icon: { width: "24px", height: "24px" },
  invertIcon: { transform: "rotateY(180deg)" },
  transition: {
    transition: "0.2s ease-in"
  },
  colorBlack: { color: SrRefColour.Neutral1A },
  colorWhite: { color: SrRefColour.Neutral0 },

  // Widths, heights and margins
  marginLeft6: {
    marginLeft: "6px"
  },
  marginLeft12: { marginLeft: "12px" },

  //custom styling for components
  arrowContainer: {
    width: "24px",
    height: "24px",
    marginLeft: "12px"
  },

  CTAInnerDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "fit-content",
    padding: "12px 18px",
    borderRadius: "12px",
    backgroundImage: "linear-gradient(to bottom right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  },

  //custom styling for login button
  loginTextContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "fit-content",
    padding: "12px 24px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "24px",
    backgroundColor: SrRefColour.Neutral1C,
    transition: "0.2s ease-in"
  },

  //custom styling for share button
  shareOuterDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    transition: "0.2s ease-in",
    padding: "12px",
    borderRadius: "12px",
    cursor: "pointer"
  },

  //custom styling for cta button
  ctaOuterDiv: {
    width: "fit-content",
    cursor: "pointer",
    textDecoration: "none",
    transition: "0.2s ease-in"
  }
};

export default style;
