import SrRefColour from "../../../ref/colours";

const style = {
  outerMost: {
    position: "relative",
    width: "100vw",
    backgroundColor: SrRefColour.Neutral0
  },
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  flexRowReverse: {
    display: "flex",
    flexDirection: "row-reverse"
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  spaceBetween: {
    justifyContent: "space-between"
  },
  alignItemsCenter: {
    display: "flex",
    alignItems: "center"
  },
  alignItemsFlexEnd: {
    display: "flex",
    alignItems: "flex-end"
  },
  positionRelative: {
    position: "relative"
  },
  positionAbsolute: {
    position: "absolute"
  },
  positionFixed: {
    position: "fixed",
    zIndex: 20
  },
  screen: {
    width: "100vw",
    maxWidth: "1600px",
    margin: "0 auto",
    backgroundColor: SrRefColour.Neutral0,
    overflowX: "hidden"
  },
  centeredTextWrap: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  centeredText: {
    position: "absolute",
    marginLeft: "48px",
    marginRight: "48px",
    textAlign: "center"
  },
  overflowScroll: {
    overflowX: "scroll"
  },
  clickable: {
    cursor: "pointer",
    textDecoration: "none"
  },
  overflowAuto: {
    overflow: "auto"
  },
  inlineFlex: {
    display: "inline-flex"
  },
  icon: { width: "24px", height: "24px" },
  bottom: {
    padding: "24px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "24px"
  },
  textBox: {
    display: "flex",
    flexDirection: "column",
    padding: "12px",
    borderRadius: "12px"
  },
  teamSize: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "47px",
    minWidth: "47px",
    height: "47px",
    minHeight: "47px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "47px"
  },
  video: { width: "240px", height: "160px", borderRadius: "24px" },
  website: {
    height: "48px",
    borderRadius: "12px",
    padding: "12px"
  },
  logo: {
    width: "36px",
    height: "36px",
    borderRadius: "36px"
  },
  borderRadius6: { borderRadius: "6px" },
  borderRadius12: { borderRadius: "12px" },
  borderRadius16: { borderRadius: "16px" },
  borderRadius18: { borderRadius: "18px" },
  transition: {
    transition: "0.2s ease-in"
  },
  paddingHor18: {
    paddingLeft: "18px",
    paddingRight: "18px"
  },

  // Widths, heights and margins
  width100: {
    width: "100%"
  },
  width50: {
    width: "50%"
  },
  minHeight24: {
    minHeight: "24px"
  },
  minHeight127: {
    minHeight: "127px"
  },
  marginTop6: {
    marginTop: "6px"
  },
  marginTop12: {
    marginTop: "12px"
  },
  marginTop18: {
    marginTop: "18px"
  },
  marginTop24: {
    marginTop: "24px"
  },
  marginTop36: {
    marginTop: "36px"
  },
  marginHor6: {
    marginLeft: "6px",
    marginRight: "6px"
  },
  marginHor12: {
    marginLeft: "12px",
    marginRight: "12px"
  },
  marginHor21: {
    marginLeft: "21px",
    marginRight: "21px"
  },
  marginHor24: {
    marginLeft: "24px",
    marginRight: "24px"
  },
  marginHor48: {
    marginLeft: "48px",
    marginRight: "48px"
  },
  marginLeft6: {
    marginLeft: "6px"
  },
  marginLeft12: {
    marginLeft: "12px"
  },
  marginRight6: {
    marginRight: "6px"
  }
};

export default style;
