import SrRefColour from "../../../ref/colours";

const style = {
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between"
  },
  alignItemsCenter: {
    display: "flex",
    alignItems: "center"
  },
  alignItemsEnd: {
    alignItems: "flex-end"
  },
  overflowAuto: {
    overflow: "auto"
  },
  surface: {
    padding: "12px 6px",
    borderRadius: "18px"
  },
  surfaceDim: {
    height: "74px",
    maxWidth: "1600px"
  },
  surfaceBorder: {
    border: `0.5px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "18px",
    zIndex: 10
  },
  surfaceBlur: {
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    backgroundImage:
      "linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)"
  },
  progressBar: {
    position: "absolute",
    bottom: 0,
    height: "100%",
    borderRadius: "18px",
    zIndex: 1
  },
  positionAbsolute: {
    position: "absolute",
    zIndex: 8
  },
  clickable: {
    cursor: "pointer",
    border: "none",
    textDecoration: "none"
  },
  transition: {
    transition: "0.2s ease-in"
  },

  // Widths, heights and margins
  marginRight12: {
    marginRight: "12px"
  },
  marginLeft9: {
    marginLeft: "9px"
  },

  //custom styling for this styling

  outerDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end"
  },

  userPanelInnerDiv: {
    display: "flex",
    flexDirection: "row",
    overflow: "auto",
    padding: "12px 6px",
    borderRadius: "18px",
    boxShadow: "0px 2px 4px rgba(0,0,0,0.15)",
    zIndex: 2,
    position: "relative"
  },

  userPanelOuterDiv: {
    position: "relative",
    height: "100%",
    borderRadius: "12px"
  },

  blurEffect: {
    position: "absolute",
    height: "74px",
    maxWidth: "1600px",
    backgroundColor: "rgba(255, 255, 255, 0.6)",
    backgroundImage:
      "linear-gradient(to bottom right, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0))",
    backdropFilter: "blur(9px)",
    "-webkit-backdrop-filter": "blur(9px)",
    border: `0.5px solid ${SrRefColour.Neutral2A}`,
    borderRadius: "18px",
    width: "100%",
    top: 0,
    zIndex: 0
  },
  btnWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  }
};

export default style;
