export const style = {
  dot: {
    width: "6px",
    height: "6px",
    marginLeft: "3px",
    marginRight: "3px",
    borderRadius: "3px"
  }

  // Widths, heights and margins
};
