import React, { useState } from "react";

// Importing Colors
import SrRefColour from "../../../../../ref/colours";

// Importing Texts
import TxtInter from "../../../../01_atoms/text/Inter";

// Importing icons
import { SrCompIconChatUnfilled, SrCompIconChatFilled } from "../../../../01_atoms/icons";
import style from "./style";

// Comment button
const CommentBtn = ({ pressed, onClick, breakpoint3, darkMode }) => {
  const [btnHover, setBtnHover] = useState(false);

  // Dynmic styling for this component
  const style2 = {
    phoneOuterDiv: {
      ...style.shell,
      backgroundColor: darkMode ? "rgb(0,0,0,0.15)" : "rgb(255,255,255,0.8)",
      borderColor: pressed
        ? darkMode
          ? SrRefColour.Neutral0
          : SrRefColour.Neutral1A
        : btnHover
        ? darkMode
          ? SrRefColour.Neutral2B
          : SrRefColour.Neutral2A
        : darkMode
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B,
      ...style.transition
    },
    laptopOuterDiv: {
      ...style.flexRow,
      ...style.alignItemsCenter,
      ...style.shell2,
      backgroundColor: darkMode ? "rgb(0,0,0,0.15)" : "rgb(255,255,255,0.8)",
      borderColor: pressed
        ? darkMode
          ? SrRefColour.Neutral0
          : SrRefColour.Neutral1A
        : btnHover
        ? darkMode
          ? SrRefColour.Neutral2B
          : SrRefColour.Neutral2A
        : darkMode
        ? SrRefColour.Neutral2A
        : SrRefColour.Neutral2B,
      ...style.transition
    }
  };

  return (
    <div style={style.marginHor6}>
      <div
        onMouseEnter={() => setBtnHover(true)}
        onMouseLeave={() => setBtnHover(false)}
        onClick={onClick}
        style={{ ...style.positionRelative, ...style.clickable }}
      >
        {breakpoint3 ? (
          <div style={style2.phoneOuterDiv}>
            <div style={style.icon24}>
              {pressed ? (
                <SrCompIconChatFilled
                  size={24}
                  color={darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              ) : (
                <SrCompIconChatUnfilled
                  size={24}
                  color={darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              )}
            </div>
          </div>
        ) : (
          // For larger screens
          <div style={style2.laptopOuterDiv}>
            <TxtInter
              weight={400}
              size={17.28}
              content={"Comment"}
              style={{
                ...style.transition,
                color: darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A
              }}
            />

            <div style={{ ...style.icon20, ...style.marginLeft6 }}>
              {pressed ? (
                <SrCompIconChatFilled
                  size={20}
                  color={darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              ) : (
                <SrCompIconChatUnfilled
                  size={20}
                  color={darkMode ? SrRefColour.Neutral0 : SrRefColour.Neutral1A}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentBtn;
